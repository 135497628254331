/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCurrentStreamInfo } from './GetCurrentStreamInfo';
import {
    GetCurrentStreamInfoFromJSON,
    GetCurrentStreamInfoFromJSONTyped,
    GetCurrentStreamInfoToJSON,
} from './GetCurrentStreamInfo';

/**
 * The currently used stream session info.
 * If there's no stream session, the value is null.
 * @export
 * @interface GetStreamInfo
 */
export interface GetStreamInfo {
    /**
     * 
     * @type {GetCurrentStreamInfo}
     * @memberof GetStreamInfo
     */
    currentStream: GetCurrentStreamInfo;
}

/**
 * Check if a given object implements the GetStreamInfo interface.
 */
export function instanceOfGetStreamInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentStream" in value;

    return isInstance;
}

export function GetStreamInfoFromJSON(json: any): GetStreamInfo {
    return GetStreamInfoFromJSONTyped(json, false);
}

export function GetStreamInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStreamInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentStream': GetCurrentStreamInfoFromJSON(json['currentStream']),
    };
}

export function GetStreamInfoToJSON(value?: GetStreamInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentStream': GetCurrentStreamInfoToJSON(value.currentStream),
    };
}

