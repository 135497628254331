/* eslint-disable react/jsx-no-literals */
import { useState } from 'react'
import { create, props } from '@stylexjs/stylex'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { Button } from '../../../components/Button'
import { spacing } from '../../../styles/token.stylex'
import { Edit } from '../../../components/icons/Edit'
import { Link } from '../../../components/Link'
import { IconButton } from '../../../components/IconButton'
import { Bookmark } from '../../../components/icons/Bookmarks'
import { Close } from '../../../components/icons/Close'
import { CategoryTag } from '../../../components/CategoryTag'
import { TagButton } from '../../../components/TagButton'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.large,
    width: '100%',
  },
  container: { width: 'fit-content' },
})

export const Buttons = () => {
  const [loading, setLoading] = useState(false)
  const [secondaryLoading, setSecondaryLoading] = useState(false)
  const [iconButtonLoading, setIconButtonLoading] = useState(false)
  const [closeButtonLoading, setCloseButtonLoading] = useState(false)

  const [selectedTab, setSelectedTab] = useState('Photos')
  const [selectedSelectableTab, setSelectedSelectableTab] = useState('Fans')

  const renderButtonLinks = (
    <StyleGuidePanel title="Button Links" direction="column">
      <Link to="/">Link</Link>
      <Link to="/style-guide">Active Link</Link>
      <Link to="/" disabled>
        Disabled Link
      </Link>
      <Link to="https://example.com" isExternal>
        External Link
      </Link>
    </StyleGuidePanel>
  )

  const renderTabLinks = (
    <StyleGuidePanel title="Tab Links" direction="column">
      <Link to="/style-guide" variant="tab">
        Streams
      </Link>
      <Link to="/" variant="tab">
        Posts
      </Link>
    </StyleGuidePanel>
  )

  const renderPrimaryButtons = (
    <StyleGuidePanel title="Primary buttons" direction="column">
      <Button variant="cta">Primary Button</Button>
      <Button variant="cta" disabled>
        Disabled Button
      </Button>
      <Button
        variant="cta"
        isLoading={loading}
        onClick={() => {
          setLoading(true)
          setTimeout(() => setLoading(false), 2000)
        }}
      >
        Loading Button
      </Button>
      <Button variant="cta">
        <Edit /> With Icon
      </Button>
    </StyleGuidePanel>
  )

  const renderSecondaryButtons = (
    <StyleGuidePanel title="Secondary buttons" direction="column">
      <Button>Secondary Button</Button>
      <Button disabled>Disabled Button</Button>
      <Button
        isLoading={secondaryLoading}
        onClick={() => {
          setSecondaryLoading(true)
          setTimeout(() => setSecondaryLoading(false), 2000)
        }}
      >
        Loading Button
      </Button>
      <Button>
        <Edit /> With Icon
      </Button>
    </StyleGuidePanel>
  )

  const renderCloseButtons = (
    <StyleGuidePanel title="Close buttons" direction="row">
      <IconButton icon={Close} variant="large" />
      <IconButton icon={Close} variant="large" disabled />
      <IconButton
        icon={Close}
        variant="large"
        isLoading={closeButtonLoading}
        onClick={() => {
          setCloseButtonLoading(true)
          setTimeout(() => setCloseButtonLoading(false), 2000)
        }}
      />
    </StyleGuidePanel>
  )

  const renderIconButtons = (
    <StyleGuidePanel title="Icon buttons" direction="column">
      <IconButton icon={Bookmark} />
      <IconButton icon={Bookmark} disabled />
      <IconButton
        icon={Bookmark}
        isLoading={iconButtonLoading}
        onClick={() => {
          setIconButtonLoading(true)
          setTimeout(() => setIconButtonLoading(false), 2000)
        }}
      />
    </StyleGuidePanel>
  )

  const renderCategoryTags = (
    <StyleGuidePanel title="Category tags" direction="column">
      <CategoryTag variant="small" font="extraSmallRegular">
        Small Tag
      </CategoryTag>
      <CategoryTag font="smallRegular">Default Tag</CategoryTag>
      <CategoryTag font="smallRegular" disabled>
        Disabled Tag
      </CategoryTag>
    </StyleGuidePanel>
  )

  const renderSelectableTabs = (
    <StyleGuidePanel title="Tabs" direction="row" gap="small">
      <TagButton
        isActive={selectedTab === 'Photos'}
        onClick={() => setSelectedTab('Photos')}
      >
        Photos
      </TagButton>
      <TagButton
        isActive={selectedTab === 'Videos'}
        onClick={() => setSelectedTab('Videos')}
      >
        Videos
      </TagButton>
      <TagButton
        isActive={selectedTab === 'Streams'}
        onClick={() => setSelectedTab('Streams')}
      >
        Streams
      </TagButton>
    </StyleGuidePanel>
  )

  const renderSelectableGroup = (
    <StyleGuidePanel title="Selectable Group" direction="row" gap="small">
      <TagButton
        variant="selectable"
        isActive={selectedSelectableTab === 'Fans'}
        onClick={() => setSelectedSelectableTab('Fans')}
      >
        Fans
      </TagButton>
      <TagButton
        variant="selectable"
        isActive={selectedSelectableTab === 'Recent'}
        onClick={() => setSelectedSelectableTab('Recent')}
      >
        Recent
      </TagButton>
      <TagButton
        variant="selectable"
        isActive={selectedSelectableTab === 'Following'}
        onClick={() => setSelectedSelectableTab('Following')}
      >
        Following
      </TagButton>
    </StyleGuidePanel>
  )

  return (
    <StyleGuidePageContainer title="Buttons">
      <div {...props(styles.mainContainer)}>
        <div {...props(styles.container)}>{renderButtonLinks}</div>
        <div {...props(styles.container)}>{renderPrimaryButtons}</div>
        <div {...props(styles.container)}>{renderSecondaryButtons}</div>
        <div {...props(styles.container)}>{renderCloseButtons}</div>
        <div {...props(styles.container)}>{renderIconButtons}</div>
        <div {...props(styles.container)}>{renderTabLinks}</div>
        <div {...props(styles.container)}>{renderCategoryTags}</div>
        <div {...props(styles.container)}>{renderSelectableTabs}</div>
        <div {...props(styles.container)}>{renderSelectableGroup}</div>
      </div>
    </StyleGuidePageContainer>
  )
}
