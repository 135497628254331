export const queryKeywords = {
  channel: 'channel',
  user: 'user',
  me: 'channel-me',
  posts: 'posts',
  postComments: 'post-comments',
  payments: 'payments',
  paymentHistory: 'payment-history',
  payouts: 'payouts',
  streamFeed: 'stream-feed',
  viewerCount: 'viewerCount',
  streamConfig: 'stream-config',
  promoPanel: 'promoPanel',
  mentionUsers: 'mention-users',
  balance: 'balance',
  subscriptions: 'subscriptions',
  subscriptionAvatars: 'subscriptionAvatars',
  notifications: 'notifications',
  bankInfo: 'bankInfo',
  topCategories: 'top-categories',
  currentStreamInfo: 'current-stream-info',
  // API Sandbox
  sandboxDefaultError: 'default-error',
  sandboxNotifyCallbackError: 'notify-callback-error',
  sandboxCustomError: 'custom-error',
  videos: 'videos',
  vods: 'vods',
}

export const queryKeys = {
  user: {
    default: [queryKeywords.user],
  },
  channel: {
    default: [queryKeywords.channel],
    slug: (slug: string) => [queryKeywords.channel, slug],
    me: (slug: string) => [queryKeywords.channel, queryKeywords.me, slug],
    vods: (slug: string) => [queryKeywords.channel, slug, queryKeywords.vods],
  },
  stream: {
    feed: [queryKeywords.streamFeed],
    currentInfo: [queryKeywords.currentStreamInfo],
    config: [queryKeywords.streamConfig],
    viewerCount: (streamId: string) => [queryKeywords.viewerCount, streamId],
  },
  categories: {
    topCategories: [queryKeywords.topCategories],
  },
  posts: {
    default: [queryKeywords.posts],
    single: (postId: string) => [queryKeywords.posts, postId],
    channel: (channelId: string) => [queryKeywords.posts, channelId],
    comments: (postId: string) => [queryKeywords.postComments, postId],
  },
  promoPanel: {
    default: (channelId: string) => [queryKeywords.promoPanel, channelId],
  },
  payments: {
    default: [queryKeywords.payments],
    vat: (price: number | string) => [queryKeywords.payments, price],
  },
  paymentHistory: {
    default: [queryKeywords.paymentHistory],
  },
  payouts: {
    default: [queryKeywords.payouts],
    balance: [queryKeywords.balance],
  },
  mentionUsers: {
    default: (query: string) => [queryKeywords.mentionUsers, query],
  },
  subscriptions: {
    default: queryKeywords.subscriptions,
  },
  subscriptionAvatars: {
    default: queryKeywords.subscriptionAvatars,
  },
  notifications: {
    filter: (filter: string) => [queryKeywords.notifications, filter],
  },
  bankInfo: {
    default: queryKeywords.bankInfo,
  },
  apiSandbox: {
    default: (code?: string) => [queryKeywords.sandboxDefaultError, code],
    notifyCallbackError: (code?: string) => [
      queryKeywords.sandboxNotifyCallbackError,
      code,
    ],
    customError: (code?: string) => [queryKeywords.sandboxCustomError, code],
  },
  videos: {
    single: (videoId: string) => [queryKeywords.videos, videoId],
  },
}
