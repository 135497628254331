import { useEffect, useRef, useState } from 'react'
import useLanguage from '../../translations/useLanguage'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useApi } from '../../api/ApiContext'
import { TextArea } from '../../components/input/TextArea'
import { EditForm } from '../../components/EditForm'
import { GetChannelInfoResponse, GetUserInfoResponse } from '../../client'
import { toast } from 'react-toastify'
import { create, props } from '@stylexjs/stylex'
import { color } from '../../styles/token.stylex'
import { Input } from '../../components/input/Input'

const styles = create({
  description: {
    color: color.dustyGray,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
})

type BasicDetailsProps = {
  channel: GetChannelInfoResponse
  user: GetUserInfoResponse
  reloadChannel: () => void
  reloadUser: () => void
}

export const BasicDetails: React.FC<BasicDetailsProps> = ({
  channel,
  user,
  reloadChannel,
  reloadUser,
}) => {
  const { t } = useLanguage()
  const { userApi } = useApi()

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const { control, reset, formState, handleSubmit } = useForm<{ bio: string }>({
    defaultValues: {
      bio: channel?.description || '',
    },
  })

  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus()
      //move the caret to the end of the text
      const { length } = textAreaRef.current.value
      textAreaRef.current.setSelectionRange(length, length)
    }
  }, [isEditing])

  useEffect(() => {
    reset({ bio: channel.description || '' })
  }, [channel.description, reset])

  const resetForm = () => {
    setIsEditing(false)
    reset()
    textAreaRef.current?.blur()
  }

  const { mutateAsync: updateBio, isLoading } = useMutation({
    mutationFn: (description: string) =>
      userApi.userChannelDescriptionPut({
        updateDescriptionRequest: { description },
      }),
    onSuccess: () => {
      reloadChannel()
      reloadUser()
      setIsEditing(false)
    },
    onError: () => {
      toast.error(t('myChannel.basicDetails.error'))
    },
  })

  const onSubmit = handleSubmit(({ bio }) => updateBio(bio))

  return (
    <div {...props(styles.container)}>
      <EditForm
        canEdit
        onSubmit={onSubmit}
        isEditing={isEditing}
        canSave={
          formState.isValid && formState.isDirty && !formState.isSubmitting
        }
        onEdit={() => setIsEditing(true)}
        onCancel={resetForm}
        isLoading={isLoading}
      >
        <div {...props(styles.container)}>
          <Controller
            control={control}
            name="bio"
            render={({ field }) => (
              <TextArea
                ref={textAreaRef}
                id={field.name}
                value={field.value}
                onChange={field.onChange}
                onFocus={() => setIsEditing(true)}
                onEscape={resetForm}
                placeholder={t('myChannel.basicDetails.placeholder')}
                maxLength={500}
                rows={4}
                label={t('myChannel.basicDetails.title')}
                instruction={t('myChannel.basicDetails.description')}
              />
            )}
          />
        </div>
      </EditForm>
      <Input
        value={user.email || t('myChannel.profileSettings.emailNotAvailable')}
        disabled
        label={t('myChannel.profileSettings.email')}
      ></Input>
    </div>
  )
}
