import { create, props } from '@stylexjs/stylex'
import { Link } from '../../components/Link'
import { GetChannelInfoResponse } from '../../client'
import useLanguage from '../../translations/useLanguage'
import { Outlet } from 'react-router-dom'
import { routes } from '../../router/routes'

type TabsProps = {
  channel: GetChannelInfoResponse
}

const styles = create({
  tabButtons: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 'fit-content',
    ':not(#_id) button': {
      width: 'fit-content',
    },
  },
})

export const Tabs: React.FC<TabsProps> = ({ channel }) => {
  const { t } = useLanguage()

  return (
    <>
      <div {...props(styles.tabButtons)}>
        <Link
          to={routes.channel.homeShort(channel.slug)}
          variant="tab"
          exact
          width="fit"
        >
          {t('channel.tabs.posts.title')}
        </Link>
        <Link
          to={routes.channel.streamsShort(channel.slug)}
          variant="tab"
          width="fit"
        >
          {t('channel.tabs.streams.title')}
        </Link>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  )
}
