import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'
import useLanguage from '../../translations/useLanguage'
import { Panel } from '../../components/Panel'
import { Button } from '../../components/Button'
import { queryKeys } from '../../constants/query-keys'
import { useApi } from '../../api/ApiContext'
import { useInfiniteQuery } from 'react-query'
import {
  GetSubscriptionResponse,
  GetSubscriptionResponsePagedApiResponse,
} from '../../client'
import { useErrorNotification } from '../../translations/useErrorNotification'
import { toast } from 'react-toastify'
import { LoadingScreen } from '../../components/indicators/LoadingScreen'
import { SubscriptionsRowItem } from './SubscriptionsRowItem'
import { gridStyle } from './subscriptionStyles'
import { PageHeader } from '../../layout/PageHeader'

const SUBSCRIPTIONS_PER_PAGE = 10
const largeScreen = '@media (min-width: 768px)'

const styles = create({
  wrapper: {
    width: '100%',
    maxWidth: '888px',
    marginInline: 'auto',
  },
  rowSpacing: {
    padding: spacing.xxxSmall,
    gap: spacing.xxSmall,
  },
  headerText: {
    color: color.dustyGray,
    paddingBlock: spacing.xSmall,
    display: 'flex',
    justifyContent: 'end',
  },
  buttonContainer: {
    marginTop: '2rem',
    textAlign: 'center',
    ':not(#_id) button': {
      width: '100%',
    },
  },
  emptyList: {
    padding: '1rem',
    textAlign: 'center',
  },
  innerWrapper: {
    paddingInline: {
      default: 0,
      [largeScreen]: spacing.small,
    },
  },
})

export const SubscriptionsPage: React.FC = () => {
  const { t } = useLanguage()
  const { subscriptionsApi } = useApi()
  const notifyError = useErrorNotification()

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery<GetSubscriptionResponsePagedApiResponse, Error>({
      queryKey: queryKeys.subscriptions.default,
      queryFn: async ({ pageParam = 1 }) =>
        subscriptionsApi.subscriptionsGet({
          pageNumber: pageParam,
          pageSize: SUBSCRIPTIONS_PER_PAGE,
        }),
      getNextPageParam: (lastPage) =>
        (lastPage.pageNumber ?? 1) < (lastPage.totalPages ?? 1)
          ? (lastPage.pageNumber ?? 1) + 1
          : undefined,
      onError: notifyError(() => toast.error(t('subscriptions.error'))),
    })

  if (!isLoading && data === undefined) {
    throw new Error('Subscriptions data is undefined')
  }

  const subscriptions: GetSubscriptionResponse[] = data
    ? data.pages.flatMap((page) => page.data)
    : []

  return (
    <div {...props(styles.wrapper)}>
      <PageHeader>{t('subscriptions.title').toUpperCase()}</PageHeader>
      <Panel>
        <div {...props(styles.innerWrapper)}>
          {!subscriptions.length && !isLoading && (
            <div {...props(styles.emptyList)}>{t('subscriptions.empty')}</div>
          )}
          {isLoading && <LoadingScreen />}
          {subscriptions.length !== 0 && (
            <div>
              {/* Header row */}
              <div {...props(gridStyle.gridRow, styles.rowSpacing)}>
                <div {...props(styles.headerText, fonts.smallMedium)}></div>
                <div {...props(styles.headerText, fonts.smallMedium)}>
                  {t('subscriptions.status.title').toUpperCase()}
                </div>
                <div {...props(styles.headerText, fonts.smallMedium)}>
                  {t('subscriptions.price').toUpperCase()}
                </div>
                <div {...props(styles.headerText, fonts.smallMedium)}>
                  {t('subscriptions.expirationDate').toUpperCase()}
                </div>
              </div>

              {/* Data rows */}
              {subscriptions.map((row, rowIndex) => {
                return <SubscriptionsRowItem key={rowIndex} row={row} />
              })}
              {hasNextPage && (
                <div {...props(styles.buttonContainer)}>
                  <Button
                    onClick={() => fetchNextPage()}
                    disabled={isFetchingNextPage}
                    isLoading={isFetchingNextPage}
                  >
                    {isFetchingNextPage
                      ? t('shared.buttons.loading')
                      : t('shared.buttons.showMore')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </Panel>
    </div>
  )
}

export default SubscriptionsPage
