import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { Skeleton } from '../../components/Skeleton'
import { spacing } from '../../styles/token.stylex'

const MIN_STEAM_CARD_WIDTH = 300
const largeScreen = '@media (min-width: 600px)'
const MAX_IMAGE_HEIGHT = 290

const styles = create({
  container: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${MIN_STEAM_CARD_WIDTH}px, 1fr))`,
    gap: `${spacing.normal} ${spacing.xxxSmall}`,
    justifyItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: spacing.xSmall,
    maxWidth: 500,
  },
  thumbnailContainer: {
    aspectRatio: '16 / 9',
    padding: {
      default: 0,
      [largeScreen]: `${spacing.xxxSmall} ${spacing.extraSmall}`,
    },
    marginLeft: {
      default: `calc(${spacing.normal} * -1)`,
      [largeScreen]: 0,
    },
    marginRight: {
      default: `calc(${spacing.normal} * -1)`,
      [largeScreen]: 0,
    },
  },
  image: {
    height: '100%',
    maxHeight: MAX_IMAGE_HEIGHT,
    width: '100%',
  },
  avatar: {
    width: '2.625rem',
    height: '2.625rem',
  },
  title: {
    width: 200,
    height: 16,
  },
  text: {
    width: 100,
    height: 12,
  },
  footer: {
    display: 'flex',
    gap: spacing.small,
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
  },
})

type StreamCardPlaceholderProps = {
  count?: number
}

export const StreamCardPlaceholder: React.FC<StreamCardPlaceholderProps> = ({
  count = 1,
}) => {
  return (
    <div {...props(styles.container)}>
      {Array.from({ length: count }, (_, i) => (
        <div key={i} {...props(styles.contentContainer)}>
          <div {...props(styles.thumbnailContainer)}>
            <Skeleton type="text" style={styles.image} />
          </div>
          <div {...props(styles.footer)}>
            <div>
              <Skeleton type="avatar" style={styles.avatar} />
            </div>
            <div {...props(styles.footerContent)}>
              <Skeleton type="text" style={styles.title} />
              <Skeleton type="text" style={styles.text} />
              <Skeleton type="text" style={styles.text} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
