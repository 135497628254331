import { Navigate } from 'react-router-dom'
import { useChannelContext } from '../../channel/context/channel-context'
import { LoadingScreen } from '../indicators/LoadingScreen'
import { routes } from '../../router/routes'
import VideoPage from './VideoPage'

type SmartVideoPageProps = {
  contentId: string
}

export const SmartVideoPage: React.FC<SmartVideoPageProps> = ({
  contentId,
}) => {
  const { channel, isLoading } = useChannelContext()

  if (isLoading) return <LoadingScreen />
  if (!channel) return <Navigate to={routes.notFound} />

  return <VideoPage contentId={contentId} channel={channel} />
}

export default SmartVideoPage
