import { useRef } from 'react'
import { useUserContext } from '../../auth/user-context'
import { ContentType, GetStreamFeedItemResponse } from '../../client'
import { mapFeedItemToCardProps } from '../../mappers/streamFeedMapper'
import useLanguage from '../../translations/useLanguage'
import { ConfirmDialog } from '../../components/confirm-dialog/ConfirmDialog'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from '../../constants/query-keys'
import { toast } from 'react-toastify'
import { useErrorNotification } from '../../translations/useErrorNotification'
import { useApi } from '../../api/ApiContext'
import { StreamCard } from './StreamCard'

type SmartStreamCardProps = {
  stream: GetStreamFeedItemResponse
  variant?: 'streamFeed' | 'channelPage'
}

export const SmartStreamCard: React.FC<SmartStreamCardProps> = ({
  stream,
  variant,
}) => {
  const { data: currentUser } = useUserContext()
  const { t } = useLanguage()
  const confirmDialogRef = useRef<HTMLDialogElement>(null)
  const notifyError = useErrorNotification()
  const { videoApi } = useApi()
  const queryClient = useQueryClient()

  const cardData = mapFeedItemToCardProps(stream)

  const { mutate: deleteVideo, isLoading } = useMutation({
    mutationFn: () =>
      videoApi.videoVideoIdDelete({ videoId: cardData!.referenceId }),
    onSuccess: () => {
      if (variant == 'streamFeed') {
        queryClient.invalidateQueries(queryKeys.stream.feed)
      }

      if (variant == 'channelPage') {
        queryClient.invalidateQueries(
          queryKeys.channel.vods(cardData?.streamer.channelSlug || '')
        )
      }
    },
    onSettled: () => confirmDialogRef.current?.close(),
    onError: notifyError(() => {
      toast.error(t('post.postCard.postCardActions.delete.failed'))
    }, false),
  })

  if (!cardData) return null

  const isOwnVod =
    currentUser?.id === cardData.streamer.id &&
    cardData.type === ContentType.Vod

  const contextMenuItems = [
    ...(isOwnVod && variant == 'channelPage'
      ? [
          {
            label: t('vods.actions.delete.title'),
            action: () => confirmDialogRef.current?.showModal(),
          },
        ]
      : []),
  ]

  return (
    <>
      <StreamCard
        {...cardData}
        contextMenuItems={contextMenuItems}
        variant={variant}
      />
      {!!currentUser && isOwnVod && (
        <ConfirmDialog
          ref={confirmDialogRef}
          options={{
            title: t('vods.actions.delete.title'),
            description: t('vods.actions.delete.message'),
            onConfirm: () => {
              if (cardData && cardData.type === ContentType.Vod) {
                deleteVideo()
              }
            },
            isLoading: isLoading,
            translate: t,
          }}
        />
      )}
    </>
  )
}
