import { GetCommentResponse } from '../../../client'

export const mockedChatMessages: GetCommentResponse[] = [
  {
    id: 'chat1',
    postId: 'dummy-post-id',
    text: 'Hey Amanda! How’s your day going? 😄',
    postedAt: new Date(),
    author: {
      id: 1,
      name: 'DevGirl58',
      slug: 'devgirl58',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=D',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat2',
    postId: 'dummy-post-id',
    text: 'You’re the best, Amanda! Thanks for always bringing such amazing energy here.',
    postedAt: new Date(),
    author: {
      id: 2,
      name: 'Joshua239',
      slug: 'joshua239',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=J',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat3',
    postId: 'dummy-post-id',
    text: 'Do you ever do lives where you just hang out and vibe with music?',
    postedAt: new Date(),
    author: {
      id: 3,
      name: 'AlexOne',
      slug: 'alexone',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=A',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat4',
    postId: 'dummy-post-id',
    text: 'Just subscribed! Let’s goooo 🚀',
    postedAt: new Date(),
    author: {
      id: 4,
      name: 'HypeModeOn',
      slug: 'hypemodeon',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=H',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat5',
    postId: 'dummy-post-id',
    text: 'Amanda, your stream always makes my day better ❤️',
    postedAt: new Date(),
    author: {
      id: 5,
      name: 'SunnyVibes',
      slug: 'sunnyvibes',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=S',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat6',
    postId: 'dummy-post-id',
    text: 'What song is this? It’s 🔥',
    postedAt: new Date(),
    author: {
      id: 6,
      name: 'BeatHunter',
      slug: 'beathunter',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=B',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat7',
    postId: 'dummy-post-id',
    text: 'First time catching you live. You’ve got a great vibe!',
    postedAt: new Date(),
    author: {
      id: 7,
      name: 'LurkSquad',
      slug: 'lurksquad',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=L',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat8',
    postId: 'dummy-post-id',
    text: 'BRB grabbing snacks 🍿',
    postedAt: new Date(),
    author: {
      id: 8,
      name: 'SnackAttack',
      slug: 'snackattack',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=S',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat9',
    postId: 'dummy-post-id',
    text: 'Amanda with the flawless transitions again 👏',
    postedAt: new Date(),
    author: {
      id: 9,
      name: 'MixMasterJ',
      slug: 'mixmasterj',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=M',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat10',
    postId: 'dummy-post-id',
    text: 'How long have you been streaming?',
    postedAt: new Date(),
    author: {
      id: 10,
      name: 'CuriousCat',
      slug: 'curiouscat',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=C',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat11',
    postId: 'dummy-post-id',
    text: 'Let’s get some hype in the chat! 🎉',
    postedAt: new Date(),
    author: {
      id: 11,
      name: 'ChatChampion',
      slug: 'chatchampion',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=CC',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat12',
    postId: 'dummy-post-id',
    text: 'I’m always looking forward to your streams. Keep killing it 🔥',
    postedAt: new Date(),
    author: {
      id: 12,
      name: 'NightOwl99',
      slug: 'nightowl99',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=N',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat13',
    postId: 'dummy-post-id',
    text: 'That last beat drop was insane 🔥🔥🔥',
    postedAt: new Date(),
    author: {
      id: 13,
      name: 'DropTheBass',
      slug: 'dropthebass',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=DB',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat14',
    postId: 'dummy-post-id',
    text: 'LOL that transition caught me off guard 😂',
    postedAt: new Date(),
    author: {
      id: 14,
      name: 'LaughTrack',
      slug: 'laughtrack',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=LT',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat15',
    postId: 'dummy-post-id',
    text: 'What mic are you using? The sound is super clean 👌',
    postedAt: new Date(),
    author: {
      id: 15,
      name: 'TechTuned',
      slug: 'techtuned',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=TT',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat16',
    postId: 'dummy-post-id',
    text: 'Just dropped a follow ✨ Let’s gooo',
    postedAt: new Date(),
    author: {
      id: 16,
      name: 'NewFan23',
      slug: 'newfan23',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=NF',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat17',
    postId: 'dummy-post-id',
    text: 'I’m vibin’ so hard right now 😎',
    postedAt: new Date(),
    author: {
      id: 17,
      name: 'LoFiLover',
      slug: 'lofilover',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=L',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat18',
    postId: 'dummy-post-id',
    text: 'Who else has this on in the background while working? ✋',
    postedAt: new Date(),
    author: {
      id: 18,
      name: 'ProductiveVibes',
      slug: 'productivevibes',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=PV',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat19',
    postId: 'dummy-post-id',
    text: 'I legit forgot I was working, this mix goes too hard 😂',
    postedAt: new Date(),
    author: {
      id: 19,
      name: 'DanceBreak',
      slug: 'accidentaldancebreak',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=AD',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat20',
    postId: 'dummy-post-id',
    text: 'Thanks for keeping my Friday lit 🔥💯',
    postedAt: new Date(),
    author: {
      id: 20,
      name: 'FridayVibes',
      slug: 'fridayvibes',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=F',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat21',
    postId: 'dummy-post-id',
    text: 'I’m gonna recommend this stream to my friends 💌',
    postedAt: new Date(),
    author: {
      id: 21,
      name: 'StreamSharer',
      slug: 'streamsharer',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=SS',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
  {
    id: 'chat22',
    postId: 'dummy-post-id',
    text: 'Alright, I’m heading out — amazing stream as always ✨',
    postedAt: new Date(),
    author: {
      id: 22,
      name: 'PeaceOutPete',
      slug: 'peaceoutpete',
      profilePicture: {
        source: 'https://placehold.co/40x40?text=PP',
        variants: [],
        srcset: '',
      },
    },
    canDelete: false,
  },
]
