import useLanguage from '../translations/useLanguage'
import { BannerImage } from './BannerImage'
import { BasicDetails } from './panels/BasicDetails'
import { ProfilePreview } from './ProfilePreview'
import { Panel } from '../components/Panel'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { ChangeDisplayName } from './panels/ChangeDisplayName'
import { useChannelContext } from '../channel/context/channel-context'
import { create, props } from '@stylexjs/stylex'
import { useUserContext } from '../auth/user-context'
import { PageHeader } from '../layout/PageHeader'
import { ChangeUserName } from './panels/ChangeUsername'

const styles = create({
  settings: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  container: {
    margin: 'auto',
    maxWidth: '888px',
  },
})

export const Dashboard: React.FC = () => {
  const { t } = useLanguage()
  const {
    isLoading: isChannelLoading,
    channel,
    reloadChannel,
  } = useChannelContext()
  const { reloadUser, isLoading: isUserLoading, data: user } = useUserContext()
  if (isChannelLoading) return <LoadingScreen />

  if (!channel) {
    throw new Error('Channel is undefined')
  }

  if (!user) {
    throw new Error('User is undefined')
  }

  return (
    <div {...props(styles.container)}>
      <PageHeader>{t('myChannel.pageTitle').toUpperCase()}</PageHeader>
      <Panel title={t('myChannel.profilePreview.title')}>
        <ProfilePreview
          user={channel.user}
          reloadUser={reloadUser}
          reloadChannel={reloadChannel}
          isLoading={isChannelLoading || isUserLoading}
        />
      </Panel>
      <Panel title={t('myChannel.bannerImage.title')}>
        <BannerImage
          channel={channel}
          reloadChannel={reloadChannel}
          isLoading={isChannelLoading || isUserLoading}
        />
      </Panel>
      <Panel title={t('myChannel.profileSettings.title')}>
        <div {...props(styles.settings)}>
          <ChangeDisplayName user={user} />
          <ChangeUserName user={user} />
          <BasicDetails
            channel={channel}
            user={user}
            reloadChannel={reloadChannel}
            reloadUser={reloadUser}
          />
        </div>
      </Panel>
    </div>
  )
}
