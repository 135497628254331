/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The currently used stream session info.
 * @export
 * @interface GetCurrentStreamInfo
 */
export interface GetCurrentStreamInfo {
    /**
     * The category id of the currently used stream session.
     * @type {number}
     * @memberof GetCurrentStreamInfo
     */
    categoryId: number;
    /**
     * The title of the currently used stream session.
     * @type {string}
     * @memberof GetCurrentStreamInfo
     */
    title: string;
}

/**
 * Check if a given object implements the GetCurrentStreamInfo interface.
 */
export function instanceOfGetCurrentStreamInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categoryId" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function GetCurrentStreamInfoFromJSON(json: any): GetCurrentStreamInfo {
    return GetCurrentStreamInfoFromJSONTyped(json, false);
}

export function GetCurrentStreamInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCurrentStreamInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': json['categoryId'],
        'title': json['title'],
    };
}

export function GetCurrentStreamInfoToJSON(value?: GetCurrentStreamInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'title': value.title,
    };
}

