import type { SVGProps } from 'react'

export const Bank: React.FC<SVGProps<SVGElement>> = ({
  fill = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3831_972)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.02675 1.04C9.64825 0.764419 10.3517 0.764419 10.9732 1.04L18.1662 4.22948C19.8126 4.95949 19.3086 7.49998 17.5174 7.49998H2.48255C0.691386 7.49998 0.187404 4.95948 1.83372 4.22948L9.02675 1.04ZM10.3244 2.60635C10.1172 2.5145 9.88274 2.5145 9.67558 2.60635L2.8423 5.63625C2.75998 5.67275 2.78603 5.79576 2.87607 5.79576H17.1239C17.214 5.79576 17.24 5.67275 17.1577 5.63625L10.3244 2.60635Z"
      />
      <path d="M1.66667 18.3333C1.66667 17.8731 2.03977 17.5 2.50001 17.5H17.5C17.9603 17.5 18.3333 17.8731 18.3333 18.3333C18.3333 18.7936 17.9603 19.1667 17.5 19.1667H2.50001C2.03977 19.1667 1.66667 18.7936 1.66667 18.3333Z" />
      <path d="M9.16667 15.8333C9.16667 16.2936 9.53976 16.6666 10 16.6666C10.4603 16.6666 10.8333 16.2936 10.8333 15.8333V9.16665C10.8333 8.7064 10.4603 8.33331 10 8.33331C9.53976 8.33331 9.16667 8.7064 9.16667 9.16665V15.8333Z" />
      <path d="M5.00001 16.6666C4.53977 16.6666 4.16667 16.2936 4.16667 15.8333V9.16665C4.16667 8.7064 4.53976 8.33331 5.00001 8.33331C5.46024 8.33331 5.83334 8.7064 5.83334 9.16665V15.8333C5.83334 16.2936 5.46025 16.6666 5.00001 16.6666Z" />
      <path d="M14.1667 15.8333C14.1667 16.2936 14.5398 16.6666 15 16.6666C15.4603 16.6666 15.8333 16.2936 15.8333 15.8333V9.16665C15.8333 8.7064 15.4603 8.33331 15 8.33331C14.5398 8.33331 14.1667 8.7064 14.1667 9.16665V15.8333Z" />
    </g>
    <defs>
      <clipPath id="clip0_3831_972">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
