import { create, props } from '@stylexjs/stylex'
import type { SVGProps } from 'react'

const styles = create({
  circle: {
    opacity: 0.25,
  },
  path: {
    opacity: 0.75,
  },
})

export const ButtonLoader: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.125rem',
  width = '1.125rem',
}) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        {...props(styles.circle)}
        cx="12"
        cy="12"
        r="10"
        stroke={stroke}
        strokeWidth="4"
      />
      <path
        {...props(styles.path)}
        fill={stroke}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  )
}
