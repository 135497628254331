import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { titleFonts } from '../styles/font-styles'
import { Button } from '../components/Button'
import { useApi } from '../api/ApiContext'
import { useMutation } from 'react-query'
import { useUserContext } from '../auth/user-context'
import useLanguage from '../translations/useLanguage'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    backgroundColor: color.codGrayLight,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraLarger,
    padding: spacing.small,
  },
  buttonContainer: {
    marginTop: spacing.normal,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    ':not(#_id) button': {
      width: {
        [largeScreen]: '15.625rem',
      },
    },
  },
})

export const CreatorRequirementsFulfilledCard: React.FC = () => {
  const { onboardingApi } = useApi()
  const { reloadUser } = useUserContext()
  const { t } = useLanguage()

  const mutation = useMutation({
    mutationFn: () => onboardingApi.onboardingBecomeCreatorPost(),
  })

  const handleClick = async () => {
    await mutation.mutateAsync()
    reloadUser()
  }

  return (
    <div {...props(styles.container)}>
      <div {...props(titleFonts.xlSemiBold)}>
        {t('becomeACreator.requirementsFulfilled.title')}
      </div>
      <div {...props(styles.buttonContainer)}>
        <div {...props(styles.button)}>
          <Button variant="cta" onClick={handleClick}>
            {t('becomeACreator.requirementsFulfilled.buttonTitle')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreatorRequirementsFulfilledCard
