import React, { useState, useRef } from 'react'
import { MentionsTextArea } from '../../components/input/MentionTextArea'
import { useApi } from '../../api/ApiContext'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from '../../constants/query-keys'
import useLanguage from '../../translations/useLanguage'
import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import useMentions from '../create/hooks/useMentions'
import { useUserContext } from '../../auth/user-context'
import { AvatarImage } from '../../components/avatars/AvatarImage'
import { IconButton } from '../../components/IconButton'
import { Send } from '../../components/icons/Send'
import { EmojiPicker, EmojiWithNative } from '../../components/EmojiPicker'
import { getTrimmedComment, isCommentEmpty } from './utils/commentUtils'

type CreateCommentProps = {
  postId: string
}

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {},
  contentContainer: {
    display: 'flex',
    gap: spacing.small,
    paddingTop: 10,
  },
  textAreaWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
  },
  actionButtonsWrapper: {
    display: 'flex',
    gap: {
      default: spacing.xxSmall,
      [largeScreen]: spacing.small,
    },
    ':not(#_id) button': {
      height: 24,
      width: 24,
    },
  },
  divider: {
    borderBottom: `1px solid ${color.mineShaft}`,
    marginLeft: `calc(${spacing.normal} * -1)`,
    marginRight: `calc(${spacing.normal} * -1)`,
  },
})

const TEXTAREA_MAX_LENGTH = 500

const CreateComment: React.FC<CreateCommentProps> = ({ postId }) => {
  const [commentContent, setCommentContent] = useState('')
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const { postApi } = useApi()
  const { t } = useLanguage()
  const queryClient = useQueryClient()
  const { data: user, isLoading: isUserLoading } = useUserContext()

  const { mentionUsers, handlePostContentChange } = useMentions(
    commentContent,
    setCommentContent
  )

  const { mutate: createComment, isLoading } = useMutation({
    mutationFn: () =>
      postApi.postPostIdCommentsPost({
        postId,
        addCommentRequest: {
          text: getTrimmedComment(commentContent),
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.posts.single(postId))
      queryClient.invalidateQueries(queryKeys.posts.comments(postId))
      setCommentContent('')
    },
  })

  const handleCreateComment = () => {
    if (isCommentEmpty(commentContent)) return
    createComment()
  }

  const handleEmojiSelect = (emoji: EmojiWithNative) => {
    if (commentContent.length >= TEXTAREA_MAX_LENGTH) return

    if (!textAreaRef.current) return

    const textarea = textAreaRef.current
    const { selectionStart, selectionEnd } = textarea

    textarea.setRangeText(emoji.native, selectionStart, selectionEnd, 'end')

    setCommentContent(textarea.value)

    const event = new Event('input', { bubbles: true })
    textarea.dispatchEvent(event)

    textarea.focus()
  }

  if (!user) return null

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.contentContainer)}>
        <div>
          <AvatarImage
            isLoading={isUserLoading}
            alt={user.displayName}
            src={user.profilePicture?.source}
          />
        </div>
        <div {...props(styles.textAreaWrapper)}>
          <MentionsTextArea
            id="create-comment-input"
            value={commentContent}
            onChange={handlePostContentChange}
            mentionData={mentionUsers}
            placeholder={t('post.comment.actions.create.placeholder')}
            required
            ref={textAreaRef}
            maxLength={TEXTAREA_MAX_LENGTH}
            showCharacterCount={false}
            variant="comment"
          />
        </div>
        <div {...props(styles.actionButtonsWrapper)}>
          <EmojiPicker
            onEmojiSelect={handleEmojiSelect}
            focusRef={textAreaRef}
          />
          <IconButton
            onClick={handleCreateComment}
            disabled={isLoading || isCommentEmpty(commentContent)}
            isLoading={isLoading}
            icon={Send}
            color={isCommentEmpty(commentContent) ? undefined : 'white'}
          />
        </div>
      </div>
      <div {...props(styles.divider)} />
    </div>
  )
}

export default CreateComment
