import { create, props } from '@stylexjs/stylex'
import { IconButton } from '../../components/IconButton'
import { Bell } from '../../components/icons/Bell'
import { Mail } from '../../components/icons/Mail'
import { NotificationBell } from '../../components/icons/NotificationBell'
import { ProfileMenu } from '../desktop/ProfileMenu'
import { responsiveStyles } from './styles'
import { NavContext } from './types'

type LoggedInStateProps = {
  slug: string
} & NavContext

const styles = create({
  profileMenuContainer: {
    marginLeft: '0.75rem',
  },
})

export const LoggedInState: React.FC<LoggedInStateProps> = ({
  avatarImageSrc,
  notificationCount = 0,
  onNotificationClick,
  onMailClick,
  slug,
  showMessages,
}) => {
  const notificationIcon = () =>
    notificationCount > 0 ? (
      <NotificationBell notificationCount={notificationCount} />
    ) : (
      <Bell />
    )
  const mailIcon = () => <Mail notificationCount={notificationCount} />

  return (
    <div {...props(responsiveStyles.desktopFlex)}>
      <IconButton icon={notificationIcon} onClick={onNotificationClick} />
      {showMessages && <IconButton icon={mailIcon} onClick={onMailClick} />}
      <div {...props(styles.profileMenuContainer)}>
        <ProfileMenu avatarImageSrc={avatarImageSrc} slug={slug} />
      </div>
    </div>
  )
}
