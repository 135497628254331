import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { Skeleton } from '../../components/Skeleton'
import { spacing } from '../../styles/token.stylex'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '320px',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing.extraSmall,
  },
  avatar: {
    width: 50,
    height: 42,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraSmall,
    width: '100%',
  },
  displayName: {
    height: 20,
    width: '70%',
  },
  username: {
    height: 20,
    width: '50%',
  },
  prices: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraSmall,
  },
  priceDisplay: {
    height: 20,
    width: '70%',
  },
  priceTotal: {
    height: 20,
    width: '50%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraSmall,
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxSmall,
  },
  legendText: {
    height: 20,
    width: '100%',
  },
  label: {
    width: '50%',
    height: 20,
  },
  input: {
    height: 40,
    width: '100%',
  },
  button: {
    width: '100%',
    height: 40,
  },
})

export const PaidSubscriptionModalSkeleton: React.FC = () => {
  return (
    <div {...props(styles.container)}>
      <div {...props(styles.avatarContainer)}>
        <Skeleton type="avatar" style={styles.avatar} />
        <div {...props(styles.nameContainer)}>
          <Skeleton type="text" style={styles.displayName} />
          <Skeleton type="text" style={styles.username} />
        </div>
      </div>
      <div {...props(styles.prices)}>
        <Skeleton type="text" style={styles.priceDisplay} />
        <Skeleton type="text" style={styles.priceTotal} />
      </div>
      <div {...props(styles.inputContainer)}>
        <Skeleton type="text" style={styles.label} />
        <Skeleton type="text" style={styles.input} />
      </div>
      <div {...props(styles.legendContainer)}>
        <Skeleton type="text" style={styles.legendText} />
        <Skeleton type="text" style={styles.legendText} />
        <Skeleton type="text" style={styles.legendText} />
        <Skeleton type="text" style={styles.legendText} />
      </div>
      <div>
        <Skeleton type="text" style={styles.button} />
      </div>
    </div>
  )
}
