/* eslint-disable sonarjs/no-nested-functions */
import { forwardRef } from 'react'
import { useApi } from '../../api/ApiContext'
import { queryKeys } from '../../constants/query-keys'
import { COMMENTS_PER_PAGE } from '../../constants'
import {
  GetCommentResponse,
  GetCommentResponsePagedApiResponse,
} from '../../client'
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { create, props } from '@stylexjs/stylex'
import { Button } from '../../components/Button'
import CommentCard from './CommentCard'
import { color, spacing } from '../../styles/token.stylex'
import LoadingIndicator from '../../components/indicators/LoadingIndicator'
import useLanguage from '../../translations/useLanguage'
import CreateComment from './CreateComment'

type CommentsProps = {
  postId: string
}

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },
  divider: {
    borderBottom: `1px solid ${color.mineShaft}`,
    marginLeft: `calc(${spacing.normal} * -1)`,
    marginRight: `calc(${spacing.normal} * -1)`,
  },
})

const getEmptyPage = (): InfiniteData<GetCommentResponsePagedApiResponse> => ({
  pages: [
    {
      data: [],
      pageNumber: 1,
      pageSize: COMMENTS_PER_PAGE,
      totalItemCount: 0,
      totalPages: 0,
    },
  ],
  pageParams: [undefined],
})

export const Comments = forwardRef<HTMLDivElement, CommentsProps>(
  ({ postId }, ref) => {
    const { postApi } = useApi()
    const { t } = useLanguage()
    const queryClient = useQueryClient()

    const {
      mutate: deleteComment,
      isLoading: isDeleting,
      variables,
    } = useMutation({
      mutationFn: (commentId: string) =>
        postApi.postPostIdCommentsCommentIdDelete({ postId, commentId }),
      onMutate: async (commentId: string) => {
        await queryClient.cancelQueries(queryKeys.posts.comments(postId))

        const previousData =
          queryClient.getQueryData<GetCommentResponsePagedApiResponse>(
            queryKeys.posts.comments(postId)
          )

        queryClient.setQueryData<
          InfiniteData<GetCommentResponsePagedApiResponse>
        >(queryKeys.posts.comments(postId), (oldData) => {
          if (!oldData) return getEmptyPage()

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              data: page.data.filter((comment) => comment.id !== commentId),
            })),
          }
        })

        return { previousData }
      },
      onError: (_error, _commentId, context) => {
        if (context?.previousData) {
          queryClient.setQueryData(
            queryKeys.posts.comments(postId),
            context.previousData
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.posts.comments(postId))
        queryClient.invalidateQueries(queryKeys.posts.single(postId))
      },
    })

    const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } =
      useInfiniteQuery<GetCommentResponsePagedApiResponse, Error>({
        queryKey: queryKeys.posts.comments(postId),
        queryFn: async ({ pageParam = 1 }) =>
          postApi.postPostIdCommentsGet({
            postId,
            pageNumber: pageParam,
            pageSize: COMMENTS_PER_PAGE,
          }),
        getNextPageParam: (lastPage) =>
          (lastPage.pageNumber ?? 1) < lastPage.totalPages
            ? (lastPage.pageNumber ?? 1) + 1
            : undefined,
      })

    const handleDelete = (commentId: string) => {
      deleteComment(commentId)
    }

    if (isLoading) {
      return <LoadingIndicator />
    }

    if (!data) {
      return null
    }

    const comments: GetCommentResponse[] = data.pages.flatMap(
      (page) => page.data
    )

    return (
      <div ref={ref} {...props(styles.container)}>
        {<div {...props(styles.divider)} />}
        <CreateComment postId={postId} />
        {comments.map((comment) => (
          <CommentCard
            key={comment.id}
            comment={comment}
            canDelete={comment.canDelete}
            onDelete={() => handleDelete(comment.id)}
            isDeleting={isDeleting && variables === comment.id}
          />
        ))}
        {hasNextPage && (
          <div>
            <Button
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
              isLoading={isFetchingNextPage}
              variant="textLink"
            >
              {isFetchingNextPage
                ? t('shared.buttons.loading')
                : t('post.comment.viewMore.title')}
            </Button>
          </div>
        )}
      </div>
    )
  }
)

Comments.displayName = 'Comments'

export default Comments
