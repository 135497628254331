import React, { forwardRef } from 'react'
import { create, props } from '@stylexjs/stylex'
import { Dialog } from '../../components/dialog/Dialog'
import useLanguage from '../../translations/useLanguage'
import { Input } from '../../components/input/Input'
import { Button } from '../../components/Button'
import { titleFonts } from '../../styles/font-styles'
import { spacing } from '../../styles/token.stylex'

import { Controller, useForm } from 'react-hook-form'
import { witdrawalAmountValidator, WithdrawalFormData } from './utils'
import { useUnderConstructionDialog } from '../../style-guide/components/under-construction/under-construction-context'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.large,
    width: {
      default: '100%',
      [largeScreen]: '400px',
    },
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'end',
    gap: spacing.normal,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },
  maxButton: {
    minWidth: 'fit-content',
  },
})

type WithdrawalDialogProps = {
  currency: string
  minPayout: number
  maxPayout: number
}

const WithdrawalDialogInternal = (
  { currency, minPayout, maxPayout }: WithdrawalDialogProps,
  ref: React.Ref<HTMLDialogElement>
) => {
  const { t, formatCurrency } = useLanguage()
  const { openDialog } = useUnderConstructionDialog()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<WithdrawalFormData>({
    defaultValues: {
      withdrawalAmount: Math.floor(maxPayout).toString(),
    },
    mode: 'onChange',
    resolver: (data) =>
      witdrawalAmountValidator(
        data,
        minPayout,
        maxPayout,
        currency,
        t,
        formatCurrency
      ),
  })

  const onClose = () => {
    reset()
    ;(ref as React.RefObject<HTMLDialogElement>).current?.close()
  }

  const onWithdrawal = handleSubmit(async () => {
    openDialog('86c0yv300')
  })

  return (
    <Dialog
      ref={ref}
      cancel={{
        label: t('shared.buttons.cancel'),
        action: () =>
          (ref as React.RefObject<HTMLDialogElement>).current?.close(),
      }}
      showCloseButton={false}
    >
      <div {...props(styles.container)}>
        <div {...props(titleFonts.normalMedium)}>
          {t('balance.dialog.payout')}
        </div>
        <div {...props(styles.content)}>
          <Controller
            control={control}
            name="withdrawalAmount"
            render={({ field }) => (
              <Input
                type="number"
                prefix={currency.toUpperCase() === 'JPY' ? '¥' : ''}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                instruction={t('balance.dialog.minimum', {
                  minPayout: formatCurrency(minPayout, {
                    currency: currency,
                    maximumFractionDigits: 0,
                  }),
                })}
                placeholder={t('balance.dialog.placeholder')}
                error={errors.withdrawalAmount?.message}
              />
            )}
          />
          <div {...props(styles.buttonRow)}>
            <Button onClick={onClose}>{t('shared.buttons.cancel')}</Button>
            <Button onClick={onWithdrawal} variant="cta" disabled={!isValid}>
              {t('balance.dialog.buttonTitle')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export const WithdrawalDialog = forwardRef(WithdrawalDialogInternal)
