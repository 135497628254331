import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { AvatarImage, AvatarImageSizeVariant } from './AvatarImage'
import { LiveBadge } from '../LiveBadge'

const styles = create({
  avatarContainer: {
    position: 'relative',
    display: 'inline-block',
  },
})

type AvatarProps = {
  src?: string
  alt: string
  url?: string
  isLive?: boolean
  showBorder?: boolean
  imageVariant?: AvatarImageSizeVariant
}

export const Avatar: React.FC<AvatarProps> = ({
  src,
  alt,
  isLive,
  url,
  showBorder,
  imageVariant,
}) => {
  return (
    <div {...props(styles.avatarContainer)}>
      <AvatarImage
        src={src}
        url={url}
        alt={alt}
        variant={imageVariant}
        border={showBorder}
      />
      {isLive && <LiveBadge variant="bottom" font="xxSmallSemiBold" />}
    </div>
  )
}
