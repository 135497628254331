import { create } from '@stylexjs/stylex'

const largeScreen = '@media (min-width: 768px)'

export const gridStyle = create({
  gridRow: {
    display: 'grid',
    gridTemplateColumns: {
      default: '1.4fr 1.2fr 0.9fr 1.8fr',
      [largeScreen]: '1.25fr 1fr 1fr 1fr',
    },
    alignItems: 'center',
  },
})
