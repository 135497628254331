import { StyleGuidePanel } from '../StyleGuidePanel'
import { ArrowsExpand } from '../../../components/icons/ArrowsExpand'
import { Coin } from '../../../components/icons/Coin'
import { Mail } from '../../../components/icons/Mail'
import { Menu } from '../../../components/icons/Menu'
import { Emoji } from '../../../components/icons/Emoji'
import { NotificationBell } from '../../../components/icons/NotificationBell'
import { Search } from '../../../components/icons/Search'
import { Send } from '../../../components/icons/Send'
import { Lock } from '../../../components/icons/Lock'
import { Heart } from '../../../components/icons/Heart'
import { Comments } from '../../../components/icons/Comments'
import { Bookmark } from '../../../components/icons/Bookmarks'
import { Currency } from '../../../components/icons/Currency'
import { CheckBig } from '../../../components/icons/CheckBig'
import { Eye } from '../../../components/icons/Eye'
import { ChatDots } from '../../../components/icons/ChatDots'
import { create, props } from '@stylexjs/stylex'
import { Edit } from '../../../components/icons/Edit'
import { CheckedCircle } from '../../../components/icons/CheckedCircle'
import { UncheckedCircle } from '../../../components/icons/UncheckedCircle'
import { color } from '../../../styles/token.stylex'
import { Share } from '../../../components/icons/Share'
import { Report } from '../../../components/icons/Report'
import { Close } from '../../../components/icons/Close'
import { VerifiedSecondary } from '../../../components/icons/VerifiedSecondary'
import { Profile } from '../../../components/icons/Profile'
import { HelpAndSupport } from '../../../components/icons/HelpAndSupport'
import { Logout } from '../../../components/icons/Logout'
import { Wallet } from '../../../components/icons/Wallet'
import { Language } from '../../../components/icons/Language'
import { Settings } from '../../../components/icons/Settings'
import { Media } from '../../../components/icons/Media'
import { Star } from '../../../components/icons/Star'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  container: {
    maxWidth: 'fit-content',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    gap: '50px',
  },
  mailContainer: {
    display: 'flex',
    gap: '20px',
  },
})

export const Icons: React.FC = () => {
  return (
    <StyleGuidePageContainer title="Icons">
      <div {...props(styles.container)}>
        <StyleGuidePanel direction="row" wrap="wrapOnMobile">
          <Search />
          <NotificationBell notificationCount={2} />
          <NotificationBell notificationCount={99} />
          <NotificationBell notificationCount={120} />
          <NotificationBell notificationCount={0} />
          <div {...props(styles.mailContainer)}>
            <Mail />
            <Mail notificationCount={2} />
            <Mail notificationCount={99} />
            <Mail notificationCount={120} />
          </div>
          <Menu />
          <ArrowsExpand />
          <Coin />
          <Emoji />
          <Send />
          <Lock />
          <Wallet />
        </StyleGuidePanel>
        <StyleGuidePanel direction="row">
          <Heart />
          <Comments />
          <Bookmark />
          <Currency />
          <ChatDots />
          <Edit />
          <Share />
          <Report />
          <Close />
        </StyleGuidePanel>
        <StyleGuidePanel direction="row">
          <Eye />
          <CheckBig />
          <VerifiedSecondary width="1.5rem" height="1.5rem" />
        </StyleGuidePanel>
        <StyleGuidePanel direction="row">
          <CheckedCircle primary={color.gold} />
          <UncheckedCircle stroke={color.gold} />
        </StyleGuidePanel>
        <StyleGuidePanel direction="row">
          <Profile />
          <HelpAndSupport />
          <Logout />
          <Language />
          <Settings />
          <Media />
          <Star />
        </StyleGuidePanel>
      </div>
    </StyleGuidePageContainer>
  )
}
