import { create, props } from '@stylexjs/stylex'
import { fonts } from '../styles/font-styles'
import { LoadingIndicator } from '../components/indicators/LoadingIndicator'
import { color } from '../styles/token.stylex'
import { useEffect, useState } from 'react'
import { PlusSign } from '../components/icons/PlusSign'

type PreviewImageProps = {
  previewImageUrl?: string
  onPreviewClick: () => void
}

const styles = create({
  imagePreviewPlaceHolder: {
    width: '80%',
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  imagePreviewContainer: {
    borderStyle: 'dashed',
    borderWidth: '1px',
  },
  imagePreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    aspectRatio: '16 / 9',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
})

export const PreviewImageSection: React.FC<PreviewImageProps> = ({
  previewImageUrl,
  onPreviewClick,
}) => {
  const [imageLoading, setImageLoading] = useState(true)

  useEffect(() => {
    if (previewImageUrl) {
      const img = new Image()
      img.onloadstart = () => setImageLoading(true)
      img.onload = () => setImageLoading(false)
      img.onerror = () => setImageLoading(false)
      img.src = previewImageUrl
    }
  }, [previewImageUrl])

  return (
    <div {...props(styles.imagePreviewContainer)} onClick={onPreviewClick}>
      <div {...props(styles.previewContainer)}>
        {previewImageUrl && (
          <>
            {imageLoading && <LoadingIndicator stroke={color.gold} />}
            {!imageLoading && (
              <img
                {...props(styles.imagePreview)}
                src={previewImageUrl}
                alt="Preview"
              />
            )}
          </>
        )}
        {!previewImageUrl && (
          <div {...props(styles.imagePreviewPlaceHolder, fonts.xxSmallRegular)}>
            <PlusSign width="2rem" height="2rem" />
          </div>
        )}
      </div>
    </div>
  )
}
