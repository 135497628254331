import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { Balance } from './balance/Balance'
import { Earnings } from './earnings/Earnings'
import { PageHeader } from '../layout/PageHeader'
import useLanguage from '../translations/useLanguage'

const styles = create({
  container: {
    width: '100%',
    maxWidth: '888px',
    marginInline: 'auto',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
  },
})

export const StatementsPage: React.FC = () => {
  const { t } = useLanguage()
  return (
    <div {...props(styles.container)}>
      <PageHeader>{t('statementsPageTitle').toUpperCase()}</PageHeader>
      <div {...props(styles.contentWrapper)}>
        <Balance />
        <Earnings />
      </div>
    </div>
  )
}

export default StatementsPage
