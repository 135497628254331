import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import 'videojs-hotkeys'
// import 'videojs-contrib-quality-menu'
import Player from 'video.js/dist/types/player'
import {
  registerIVSQualityPlugin,
  registerIVSTech,
  VideoJSIVSTech,
  VideoJSQualityPlugin,
} from 'amazon-ivs-player'
import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm?url'
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js?url'

export type PlayerOptions = typeof videojs.options
type PlayerWithIVS = Player & VideoJSIVSTech & VideoJSQualityPlugin

interface UseVideoJsPlayerParams {
  options: PlayerOptions
  previewMode?: boolean
  isLiveStream?: boolean
}

// --- Quality plugin typings temporarily disabled ---
// interface VideoJsPlayerWithPlugins extends Player {
//   qualityMenu: (options?: QualityMenuOptions) => void
//   qualityLevels: () => QualityLevelList
// }

const ensureIVSRegistered = () => {
  registerIVSTech(videojs, {
    wasmWorker: wasmWorkerPath,
    wasmBinary: wasmBinaryPath,
  })
  registerIVSQualityPlugin(videojs)
}

const handleFullscreenChange = (
  player: Player,
  setIsFullscreen: (value: boolean) => void
) => {
  const fullscreenState = player.isFullscreen()
  setIsFullscreen(fullscreenState || false)
}

const handlePreviewClick = (player: Player, previewMode: boolean) => {
  if (previewMode && !player.isFullscreen()) {
    player.requestFullscreen()
  }
}

const initializeIVSPlayer = (player: PlayerWithIVS, options: PlayerOptions) => {
  player.ready(() => {
    if (options.sources?.[0]?.src) {
      player.src(options.sources[0].src)
      player.enableIVSQualityPlugin()
    }
  })
}

const forceAutoplay = (player: Player) => {
  player.ready(() => {
    const promise = player.play()
    if (promise instanceof Promise) {
      promise.catch(() => {
        player.muted(true)
        player.play()
      })
    }
  })
}

// --- QUALITY MENU TEMPORARILY DISABLED ---
// const initializeQualityMenu = (player: Player) => {
//   player.ready(() => {
//     player.qualityMenu({
//       defaultResolution: 'Auto',
//       useResolutionLabels: true,
//       resolutionLabelBitrates: false,
//     })
//
//     player.on('qualitychange', () => {
//       const currentTime = player.currentTime() ?? 0
//       const seekToBufferedEnd = () => {
//         const buffered = player.buffered()
//         if (buffered?.length) {
//           const end = buffered.end(buffered.length - 1)
//           if (end - currentTime > 0.5) {
//             player.currentTime(end - 0.1)
//           }
//         }
//       }
//       player.one('loadeddata', seekToBufferedEnd)
//     })
//   })
// }

export const useVideoJsPlayer = ({
  options,
  previewMode = false,
  isLiveStream = false,
}: UseVideoJsPlayerParams) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<PlayerWithIVS>()
  const [isVideoPlayerReady, setIsVideoPlayerReady] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)

  useMemo(() => {
    if (!videoRef.current || !isVideoPlayerReady || playerRef.current) return

    const initialOptions: PlayerOptions = {
      controls: true,
      autoplay: options.autoplay ?? false,
      fluid: true,
      controlBar: {
        children: [
          'playToggle',
          'progressControl',
          'remainingTimeDisplay',
          'volumePanel',
          'currentTimeDisplay',
          'timeDivider',
          'durationDisplay',
          'liveDisplay',
          'chaptersButton',
          'fullscreenToggle',
        ],
      },
      plugins: {
        hotkeys: {
          volumeStep: 0.1,
          seekStep: 5,
          enableModifiersForNumbers: false,
          enableHoverScroll: true,
          fullscreenKey: (event: KeyboardEvent) =>
            event.key === 'F' || (event.ctrlKey && event.key === 'Enter'),
        },
      },
      html5: {
        iosNativeFullscreen: true,
      },
    }

    const techOrder = isLiveStream ? ['AmazonIVS'] : ['html5']
    if (isLiveStream) {
      ensureIVSRegistered()
    }

    playerRef.current = videojs(videoRef.current, {
      ...initialOptions,
      ...options,
      techOrder: [...techOrder],
    }) as PlayerWithIVS

    const player = playerRef.current

    if (isLiveStream) {
      initializeIVSPlayer(player, options)
    }

    // initializeQualityMenu(player) // 👈 Quality menu disabled

    if (options.autoplay) {
      forceAutoplay(player)
    }

    if (previewMode) {
      player.on('click', () => handlePreviewClick(player, previewMode))
      player.on('fullscreenchange', () =>
        handleFullscreenChange(player, setIsFullscreen)
      )
      player.on('touchstart', () => handlePreviewClick(player, previewMode))
    }

    return () => {
      player.dispose()
      playerRef.current = undefined
    }
  }, [isVideoPlayerReady, isLiveStream, options, previewMode])

  useLayoutEffect(() => {
    if (videoRef.current) {
      setIsVideoPlayerReady(true)
      videoRef.current.classList.add('vjs-waiting')
    }
  }, [])

  return { videoRef, isFullscreen }
}
