import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { spacing, color } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { DisplayName } from './DisplayName'

const styles = create({
  notificationItem: {
    padding: spacing.xxSmall,
    paddingBottom: spacing.extraSmall,
    borderBottom: `1px solid ${color.notificationItemSeparator}`,
    marginBottom: spacing.xSmall,
  },
  message: {
    paddingTop: spacing.xxSmall,
    color: color.notificationItemMessage,
    paddingRight: 'min(10%, 1rem)',
  },
  time: {
    paddingTop: spacing.xxxSmall,
    color: color.notificationItemTime,
  },
})

type NotificationItemProps = {
  displayName: string
  displayNameColor?: string
  verification?: 'simple' | 'fancy'
  time: string
}

export const NotificationItem: React.FC<
  React.PropsWithChildren<NotificationItemProps>
> = ({ displayName, displayNameColor, verification, time, children }) => (
  <div {...props(styles.notificationItem)}>
    <DisplayName
      text={displayName}
      color={displayNameColor}
      verification={verification}
      font="smallMedium"
    />
    <p {...props(styles.message, fonts.smallRegular)}>{children}</p>
    <p {...props(styles.time, fonts.extraSmallRegular)}>{time}</p>
  </div>
)
