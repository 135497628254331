import { create, props } from '@stylexjs/stylex'
import React, { PropsWithChildren } from 'react'
import { spacing } from '../../styles/token.stylex'

type StyleGuidePageContainerProps = {
  title: string
  variant?: 'padding' | 'noPadding' | 'noPaddingOnMobile'
}

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {},
  title: {
    padding: {
      default: spacing.normal,
      [largeScreen]: spacing.large,
    },
  },
  childrenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    padding: {
      default: 0,
      [largeScreen]: spacing.normal,
    },
  },
  padding: {
    padding: spacing.normal,
  },
  noPadding: {
    padding: 0,
  },
  noPaddingOnMobile: {
    padding: {
      default: 0,
      [largeScreen]: spacing.normal,
    },
  },
})

const StyleGuidePageContainer: React.FC<
  PropsWithChildren<StyleGuidePageContainerProps>
> = ({ title, variant = 'padding', children }) => {
  return (
    <div {...props(styles.container)}>
      <div {...props(styles.title)}>
        <h2>{title}</h2>
      </div>
      <div {...props(styles.childrenWrapper, variant && styles[variant])}>
        {children}
      </div>
    </div>
  )
}

export default StyleGuidePageContainer
