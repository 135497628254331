/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request to update the username for the channel.
 * @export
 * @interface UpdateUserNameRequest
 */
export interface UpdateUserNameRequest {
    /**
     * The new username for the channel.
     * Must be a non-empty string, maximum 24 characters long, and a valid slug.
     * A valid slug contains only lowercase letters, numbers, and single hyphens between segments.
     * @type {string}
     * @memberof UpdateUserNameRequest
     */
    newUserName?: string | null;
}

/**
 * Check if a given object implements the UpdateUserNameRequest interface.
 */
export function instanceOfUpdateUserNameRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserNameRequestFromJSON(json: any): UpdateUserNameRequest {
    return UpdateUserNameRequestFromJSONTyped(json, false);
}

export function UpdateUserNameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserNameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newUserName': !exists(json, 'newUserName') ? undefined : json['newUserName'],
    };
}

export function UpdateUserNameRequestToJSON(value?: UpdateUserNameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newUserName': value.newUserName,
    };
}

