import useLanguage from '../../translations/useLanguage'
import { create, props } from '@stylexjs/stylex'
import { Panel } from '../../components/Panel'
import { PageHeader } from '../PageHeader'
import StreamKeyAndURL from './StreamKeyAndURL'
import EditStreamInfo from './EditStreamInfo'

const styles = create({
  container: {
    maxWidth: '888px',
    width: '100%',
    marginInline: 'auto',
  },
})

export const StreamingPage: React.FC = () => {
  const { t } = useLanguage()

  return (
    <div {...props(styles.container)}>
      <PageHeader>{t('streamingPage.title').toUpperCase()}</PageHeader>
      <Panel title={t('streamingPage.editStreamInfo.title')}>
        <EditStreamInfo />
      </Panel>
      <Panel title={t('streamingPage.streamURLAndKey.title')}>
        <StreamKeyAndURL />
      </Panel>
    </div>
  )
}

export default StreamingPage
