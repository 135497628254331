import { create, props } from '@stylexjs/stylex'
import { spacing } from '../../../styles/token.stylex'
import { SmartStreamCard } from '../../../stream/card/SmartStreamCard'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { ContentType, GetStreamFeedItemResponse } from '../../../client'
import { StreamCardPlaceholder } from '../../../stream/card/StreamCardPlaceholder'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const mockStreamData: GetStreamFeedItemResponse[] = [
  {
    type: ContentType.Vod,
    referenceId: 'vod-1',
    streamer: {
      id: 1,
      name: 'amandapink',
      channelSlug: 'amandapink',
      profilePicture: {
        source:
          'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
        srcset: '',
        variants: [],
      },
    },
    videoInfo: {
      title: 'friday giveaways - highroll slots',
      sourceUrl: 'https://example.com/stream/1',
      thumbnailUrl:
        'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
      duration: 222400,
      startedAt: new Date('2025-01-01T12:00:00Z'),
      finishedAt: new Date('2025-01-01T15:30:00Z'),
    },
    streamInfo: undefined,
    category: {
      id: 12,
      name: 'gambling',
      key: 'gambling',
    },
  },
  {
    type: ContentType.Vod,
    referenceId: 'vod-2',
    streamer: {
      id: 2,
      name: 'johndoe',
      channelSlug: 'johndoe',
      profilePicture: {
        source:
          'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
        srcset: '',
        variants: [],
      },
    },
    videoInfo: {
      title: 'exciting bonus streams - beebet slots',
      sourceUrl: 'https://example.com/stream/2',
      thumbnailUrl:
        'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
      duration: 5000,
      startedAt: new Date('2023-12-01T14:00:00Z'),
      finishedAt: new Date('2023-12-01T16:00:00Z'),
    },
    streamInfo: undefined,
    category: {
      id: 12,
      name: 'gambling',
      key: 'gambling',
    },
  },
  {
    type: ContentType.LiveStream,
    referenceId: 'live-1',
    streamer: {
      id: 3,
      name: 'streamer123',
      channelSlug: 'streamer123',
      profilePicture: {
        source:
          'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/fb38dbf9-eacd-4df6-fc75-8166d7051200/default',
        srcset: '',
        variants: [],
      },
    },
    videoInfo: undefined,
    streamInfo: {
      title: 'live giveaway party - beebet specials',
      playbackUrl: 'https://example.com/stream/3',
      thumbnailUrl:
        'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/fb38dbf9-eacd-4df6-fc75-8166d7051200/default',
      viewerCount: 560,
    },
    category: {
      id: 12,
      name: 'gambling',
      key: 'gambling',
    },
  },
]

const styles = create({
  mainContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))',
    gap: spacing.large,
  },
})

export const StreamCards: React.FC = () => (
  <StyleGuidePageContainer title="Stream Cards" variant="noPaddingOnMobile">
    <StyleGuidePanel border="noBorderOnMobile" variant="noPaddingOnMobile">
      <div {...props(styles.mainContainer)}>
        {mockStreamData.map((stream) => (
          <SmartStreamCard key={stream.referenceId} stream={stream} />
        ))}
        <StreamCardPlaceholder count={1} />
      </div>
    </StyleGuidePanel>
  </StyleGuidePageContainer>
)
