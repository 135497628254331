import type { SVGProps } from 'react'

export const Share: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.17185 14.8286L14.8287 9.17178M7.0509 11.293L5.63669 12.7072C4.07459 14.2693 4.07429 16.8019 5.63639 18.364C7.19848 19.9261 9.73145 19.9261 11.2935 18.364L12.7072 16.9499M11.2929 7.05023L12.7072 5.63602C14.2693 4.07392 16.8018 4.07399 18.3639 5.63608C19.926 7.19818 19.9263 9.73078 18.3642 11.2929L16.9502 12.7071"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
