import { create, props } from '@stylexjs/stylex'
import { spacing } from '../../../styles/token.stylex'
import { useApi } from '../../../api/ApiContext'
import useLanguage from '../../../translations/useLanguage'
import { useQuery } from 'react-query'
import {
  GetChannelInfoResponse,
  GetStreamFeedItemResponse,
} from '../../../client'
import { queryKeys } from '../../../constants/query-keys'
import { toast } from 'react-toastify'
import { StreamCardPlaceholder } from '../../../stream/card/StreamCardPlaceholder'
import { SmartStreamCard } from '../../../stream/card/SmartStreamCard'
import { EmptyVods } from '../../../components/vod/EmptyVods'

const MIN_CARD_WIDTH = 280
const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'grid',
    gap: spacing.extraLarger,
    marginTop: {
      default: spacing.small,
      [largeScreen]: spacing.large,
    },
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${MIN_CARD_WIDTH}px, 1fr))`,
    gap: `${spacing.large} ${spacing.xxxSmall}`,
    justifyItems: 'center',
  },
})

type ChannelVodsProps = {
  channel: GetChannelInfoResponse
}

export const ChannelVods: React.FC<ChannelVodsProps> = ({ channel }) => {
  const { streamFeedApi } = useApi()
  const { t } = useLanguage()

  const { data, isLoading } = useQuery<GetStreamFeedItemResponse[], Error>({
    queryKey: queryKeys.channel.vods(channel.slug),
    queryFn: async () =>
      await streamFeedApi.streamfeedSlugVodsGet({ slug: channel.slug }),
    onError: () => toast.error(t('feeds.errorToast')),
  })

  const renderContent = () => {
    if (isLoading) return <StreamCardPlaceholder count={6} />

    if (!data || data.length === 0)
      return <EmptyVods isOwner={channel.isChannelOwner} />

    return (
      <div {...props(styles.contentContainer)}>
        {data.map((vod) => (
          <SmartStreamCard
            key={vod.referenceId}
            stream={vod}
            variant="channelPage"
          />
        ))}
      </div>
    )
  }

  return <div {...props(styles.container)}>{renderContent()}</div>
}

export default ChannelVods
