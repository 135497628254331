import { isValidIBAN, isValidBIC } from 'ibantools'
import { BankInfoData } from './BankPage'
import { LanguageKey } from '../../translations/useLanguage'

export const bankInfoValidator = (
  data: BankInfoData,
  translate: (key: LanguageKey, replacements?: Record<string, string>) => string
) => {
  const errors: { [key: string]: { message: string } } = {}

  if (!isValidIBAN(removeSpacesFromIban(data.iban))) {
    errors.iban = { message: translate('bank.invalidIban') }
  }

  if (!isValidBIC(data.bic)) {
    errors.bic = { message: translate('bank.invalidBic') }
  }

  return { values: data, errors }
}

export const removeSpacesFromIban = (iban: string): string => {
  return iban.replace(/\s+/g, '')
}
