import { create, props } from '@stylexjs/stylex'
import { GetChannelInfoResponse } from '../client'
import { Button } from '../components/Button'
import useLanguage from '../translations/useLanguage'
import { useNavigate } from 'react-router-dom'
import { routes } from '../router/routes'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  editorButton: {
    ':not(#_id) button': {
      width: {
        default: '100%',
        [largeScreen]: 'fit-content',
      },
    },
  },
})

type EditButtonProps = {
  channel: GetChannelInfoResponse
}

const EditButton: React.FC<EditButtonProps> = ({ channel }) => {
  const { t } = useLanguage()
  const navigate = useNavigate()

  if (!channel.isChannelOwner) return null

  return (
    <div {...props(styles.editorButton)}>
      <Button
        onClick={() => navigate(routes.channel.home(channel.slug))}
        variant="cta"
        type="button"
      >
        {t('myChannel.promoPanel.exitEditingButtonLabel')}
      </Button>
    </div>
  )
}

export default EditButton
