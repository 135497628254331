import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CreatorInfo } from '../components/CreatorInfo'
import { Navigation, Pagination } from 'swiper/modules'
import {
  swiperNavigationStyle as swiperStyle,
  swiperStyleProps,
} from './slides/slidesStyles'
import { useApi } from '../api/ApiContext'
import { useQuery } from 'react-query'
import { queryKeys } from '../constants/query-keys'
import { CreatorInfoSkeleton } from './SubscriptionAvatarAnimation'
import { props } from '@stylexjs/stylex'

export const SubscriptionAvatars: React.FC = () => {
  const { subscriptionsApi } = useApi()

  const { data: avatars, isLoading } = useQuery({
    queryKey: queryKeys.subscriptionAvatars.default,
    queryFn: () => subscriptionsApi.subscriptionsAvatarsGet(),
  })

  return (
    <Swiper
      modules={[Navigation, Pagination]}
      navigation
      slidesPerView="auto"
      slidesPerGroup={4}
      spaceBetween={6}
      allowTouchMove
      style={{ margin: '0', userSelect: 'none', ...swiperStyleProps }}
      {...props(swiperStyle.navigation)}
      breakpoints={{
        768: {
          spaceBetween: 19,
          allowTouchMove: false,
          slidesPerGroup: 4,
        },
      }}
    >
      {!isLoading &&
        avatars?.map((avatar) => (
          <SwiperSlide
            key={avatar.id}
            style={{ width: '80px', paddingTop: '2px' }}
          >
            <CreatorInfo
              profilePictureUrl={avatar.user.profilePicture?.source}
              fullName={avatar.user.slug}
              variant="instaStory"
              avatarImageSize="large"
              font="extraSmallRegular"
              profileUrl={avatar.user.slug}
              imageBorder
              isLive={avatar.user.isLive}
            />
          </SwiperSlide>
        ))}
      {isLoading &&
        [...Array(9)].map((_, i) => (
          <SwiperSlide key={i} style={{ width: '80px' }}>
            <CreatorInfoSkeleton />
          </SwiperSlide>
        ))}
    </Swiper>
  )
}
