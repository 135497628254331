import { create, props, keyframes, StyleXStyles } from '@stylexjs/stylex'

const shimmerAnimation = keyframes({
  from: { transform: 'translateX(-100%)' },
  to: { transform: 'translateX(100%)' },
})

const styles = create({
  base: {
    backgroundColor: '#202020',
    borderRadius: '0.25rem',
    display: 'inline-flex',
    lineHeight: 1,
    position: 'relative',
    userSelect: 'none',
    overflow: 'hidden',
    ':after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      backgroundImage: 'linear-gradient(90deg, #202020, #444, #202020)',
      backgroundRepeat: 'no-repeat',
      transform: 'translateX(-100%)',
      animation: `${shimmerAnimation} 1.5s ease-in-out infinite`,
      borderRadius: '4px',
      marginBottom: '2px',
    },
  },
  text: {
    height: '1em',
  },
  image: {
    height: '0',
    paddingBottom: '56.25%',
  },
  avatar: {
    borderRadius: '50%',
  },
})

type SkeletonProps = {
  type: 'text' | 'image' | 'avatar'
  style?: StyleXStyles<{
    width?: string | number
    height?: string | number
    minHeight?: string | number
    maxHeight?: string | number
    marginBottom?: string | number
    borderRadius?: string | number
  }>
}

export const Skeleton: React.FC<SkeletonProps> = ({ type, style }) => (
  <div {...props(styles.base, styles[type], style)} />
)
