import { create, props } from '@stylexjs/stylex'
import React from 'react'
import useLanguage from '../translations/useLanguage'
import { PageHeader } from '../layout/PageHeader'
import { Notifications } from './Notifications'

const styles = create({
  container: {
    width: '100%',
    maxWidth: '888px',
    marginInline: 'auto',
  },
})

export const NotificationsPage: React.FC = () => {
  const { t } = useLanguage()

  return (
    <div {...props(styles.container)}>
      <PageHeader>{t('notifications.title').toUpperCase()}</PageHeader>
      <div>
        <Notifications />
      </div>
    </div>
  )
}

export default NotificationsPage
