import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { routes } from './routes'
import { ErrorLayout } from '../layout/ErrorLayout'
import { RootLayout } from '../layout/RootLayout'
import { ProtectedRoute } from './ProtectedRoute'
import { MainLayout } from '../layout/MainLayout'
import { HomePageLayout } from '../layout/HomePageLayout'
import { PageLayout } from '../layout/PageLayout'
import NotFoundPage from '../layout/pages/NotFoundPage'
import CallbackPage from '../auth/CallbackPage'
import SignUpPage from '../auth/SignUpPage'
import LogoutPage from '../auth/LogoutPage'
import LoginPage from '../auth/LoginPage'
import FeedsPage from '../post/FeedsPage'
import { CreatorRoute } from './CreatorRoute'
import { NotCreatorRoute } from './NotCreatorRoute'
import { ChannelPage } from '../channel/ChannelPage'
import { ChannelPostsPage } from '../channel/tabs/posts/ChannelPostsPage'
import { SingleChannelPage } from '../channel/SingleChannelPage'
import { CreatePostPage } from '../post/CreatePostPage'
import ProfileMenu from '../layout/menu/ProfileMenu'
import MyProfile from '../profile/MyProfile'
import SubscriptionsPage from '../subscription/subscriptions/SubscriptionsPage'
import StreamingPage from '../layout/streaming/StreamingPage'
import SubscriptionPricePage from '../subscription/subscription-price/SubscriptionPricePage'
import BecomeACreator from '../become-a-creator/BecomeACreator'
import StatementsPage from '../statements/StatementsPage'
import PaymentHistoryPage from '../payment-history/PaymentHistoryPage'
import NotificationsPage from '../notifications/NotificationsPage'
import BankPage from '../components/bank/BankPage'
import StreamsFeedPage from '../stream/StreamsFeedPage'

// Style guide
import { StyleGuideLayout } from '../style-guide/layout/StyleGuideLayout'
import { Api } from '../style-guide/components/sections/Api'
import Fonts from '../style-guide/components/sections/Fonts'
import Colors from '../style-guide/components/sections/colors/Colors'
import { Buttons } from '../style-guide/components/sections/Buttons'
import { Inputs } from '../style-guide/components/sections/Inputs'
import { Icons } from '../style-guide/components/sections/Icons'
import CreatorCards from '../style-guide/components/sections/CreatorCards'
import { PostCards } from '../style-guide/components/sections/PostCards'
import { StreamCards } from '../style-guide/components/sections/StreamCards'
import Links from '../style-guide/components/sections/Links'
import { NavbarLogoIcons } from '../style-guide/components/sections/NavbarLogoIcons'
import { Loading } from '../style-guide/components/sections/Loading'
import { ToastNotifications } from '../style-guide/components/sections/ToastNotifications'
import { Dialogs } from '../style-guide/components/sections/Dialogs'
import { Media } from '../style-guide/components/sections/Media'
import { Layout } from '../style-guide/components/sections/Layout'
import { Menus } from '../style-guide/components/sections/Menus'
import { Navigation } from '../style-guide/components/sections/Navigation'
import StyleGuidePage from '../style-guide/pages/StyleGuidePage'
import { Notification } from '../style-guide/components/sections/Notification'
import { Messages } from '../style-guide/components/sections/Messages'
import { Banners } from '../style-guide/components/sections/Banners'
import PromoPanelEditorPage from '../promo-panels/PromoPanelEditorPage'
import { ChannelVodsPage } from '../channel/tabs/vods/ChannelVodsPage'
import { SingleVideoPage } from '../components/video/SingleVideoPage'

// Note: Lazy load pages to improve performance, check vite solution
const LazyPages = {
  StoryblokStatic: lazy(() => import('../story-blok/StoryblokStaticPage')),
}

export const Router = () => {
  return (
    <Routes>
      {/* Main layout */}
      <Route element={<RootLayout />}>
        <Route element={<MainLayout />}>
          {/* Public Routes */}
          <Route element={<PageLayout />}>
            <Route element={<HomePageLayout />}>
              <Route path={routes.feedsHome} element={<FeedsPage />} />
              <Route path={routes.home} element={<StreamsFeedPage />} />
            </Route>
          </Route>

          <Route path={routes.auth.callback} element={<CallbackPage />} />

          <Route element={<ChannelPage />}>
            <Route element={<ChannelVodsPage />}>
              <Route path={routes.channel.streamsShort(':slug')} />
              <Route path={routes.channel.streamsFull(':slug')} />
            </Route>
            <Route element={<ChannelPostsPage />}>
              <Route path={routes.channel.homeFull(':slug')} />
              <Route path={routes.channel.homeShort(':slug')} />
            </Route>
          </Route>
          <Route element={<SingleChannelPage />}>
            <Route path={routes.channel.postByIdFull(':postId', ':slug')} />
            <Route path={routes.channel.postByIdShort(':postId', ':slug')} />
          </Route>
          <Route element={<SingleVideoPage />}>
            <Route path={routes.channel.videosFull(':slug', ':videoId')} />
            <Route path={routes.channel.videosShort(':slug', ':videoId')} />
          </Route>

          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route
              path={routes.profileRoutes.myProfile}
              element={<MyProfile />}
            />
            <Route
              path={routes.profileRoutes.paymentHistory}
              element={<PaymentHistoryPage />}
            />
            <Route
              path={routes.profileRoutes.subscriptions}
              element={<SubscriptionsPage />}
            />

            <Route
              path={routes.notifications}
              element={<NotificationsPage />}
            />

            {/* Creator Routes */}
            <Route element={<CreatorRoute />}>
              <Route
                path={routes.profileRoutes.streaming}
                element={<StreamingPage />}
              />
              <Route
                path={routes.profileRoutes.subscriptionPrice}
                element={<SubscriptionPricePage />}
              />
              <Route
                path={routes.profileRoutes.statements}
                element={<StatementsPage />}
              />
              <Route path={routes.post.create} element={<CreatePostPage />} />
              <Route path={routes.profileRoutes.bank} element={<BankPage />} />
              <Route
                path={routes.profileRoutes.banners}
                element={<PromoPanelEditorPage />}
              />
              <Route element={<LazyPages.StoryblokStatic />}>
                <Route path={routes.howToBecomeAffiliate} />
              </Route>
            </Route>

            {/* Not a Creator Routes */}
            <Route element={<NotCreatorRoute />}>
              <Route
                path={routes.becomeACreator}
                element={<BecomeACreator />}
              />
            </Route>
          </Route>

          {/* Storyblok static pages */}
          <Route element={<LazyPages.StoryblokStatic />}>
            <Route path={routes.communityGuidelines} />
            <Route path={routes.termsOfService} />
            <Route path={routes.dmcaPolicy} />
            <Route path={routes.privacyPolicy} />
            <Route path={routes.howToEmbed} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path={routes.profile} element={<ProfileMenu />} />
        </Route>
      </Route>

      <Route element={<StyleGuideLayout />}>
        <Route path={routes.styleGuide} element={<StyleGuidePage />} />
        <Route path={routes.styleGuideRoutes.api} element={<Api />} />
        <Route path={routes.styleGuideRoutes.fonts} element={<Fonts />} />
        <Route path={routes.styleGuideRoutes.colors} element={<Colors />} />
        <Route path={routes.styleGuideRoutes.banners} element={<Banners />} />
        <Route path={routes.styleGuideRoutes.buttons} element={<Buttons />} />
        <Route path={routes.styleGuideRoutes.inputs} element={<Inputs />} />
        <Route path={routes.styleGuideRoutes.icons} element={<Icons />} />
        <Route
          path={routes.styleGuideRoutes.creatorCards}
          element={<CreatorCards />}
        />
        <Route path={routes.styleGuideRoutes.messages} element={<Messages />} />
        <Route
          path={routes.styleGuideRoutes.postCard}
          element={<PostCards />}
        />
        <Route
          path={routes.styleGuideRoutes.streamCards}
          element={<StreamCards />}
        />
        <Route path={routes.styleGuideRoutes.links} element={<Links />} />
        <Route
          path={routes.styleGuideRoutes.mobileNavAndTapbar}
          element={<NavbarLogoIcons />}
        />
        <Route path={routes.styleGuideRoutes.loader} element={<Loading />} />
        <Route
          path={routes.styleGuideRoutes.notification}
          element={<Notification />}
        />
        <Route
          path={routes.styleGuideRoutes.toasts}
          element={<ToastNotifications />}
        />
        <Route path={routes.styleGuideRoutes.dialogs} element={<Dialogs />} />
        <Route path={routes.styleGuideRoutes.media} element={<Media />} />
        <Route path={routes.styleGuideRoutes.layout} element={<Layout />} />
        <Route path={routes.styleGuideRoutes.menu} element={<Menus />} />
        <Route
          path={routes.styleGuideRoutes.navigation}
          element={<Navigation />}
        />
      </Route>

      {/* Empty layout */}
      <Route path={routes.signup} element={<SignUpPage />} />
      <Route path={routes.logout} element={<LogoutPage />} />
      <Route path={routes.login} element={<LoginPage />} />

      {/* Error layout */}
      <Route element={<ErrorLayout />}>
        <Route path="*" element={<NotFoundPage />} />
        <Route path={routes.notFound} element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
