import { useState } from 'react'
import { Button } from '../../../components/Button'
import { LoadingScreen } from '../../../components/indicators/LoadingScreen'
import { color } from '../../../styles/token.stylex'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { LoadingIndicator } from '../../../components/indicators/LoadingIndicator'
import { create, props } from '@stylexjs/stylex'
import { Skeleton } from '../../../components/Skeleton'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2rem',
    width: 'fit-content',
  },
  skeleton: {
    width: '200px',
  },
})

export const Loading: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false)

  if (isFullScreenLoading || loading)
    return (
      <LoadingScreen
        height={40}
        width={40}
        stroke={color.gold}
        fullScreen={isFullScreenLoading}
      />
    )

  return (
    <StyleGuidePageContainer title="Loading">
      <div {...props(styles.container)}>
        <StyleGuidePanel title="Loading Indicator">
          <LoadingIndicator height={40} width={40} stroke={color.gold} />
        </StyleGuidePanel>
        <StyleGuidePanel title="Loading Screen">
          <Button
            variant="cta"
            isLoading={loading}
            onClick={() => {
              setLoading(true)
              setTimeout(() => setLoading(false), 2000)
            }}
          >
            {'Container Loading'}
          </Button>
          <Button
            variant="cta"
            isLoading={isFullScreenLoading}
            onClick={() => {
              setIsFullScreenLoading(true)
              setTimeout(() => setIsFullScreenLoading(false), 2000)
            }}
          >
            {'Full Screen Loading'}
          </Button>
        </StyleGuidePanel>
        <StyleGuidePanel title="Skeleton">
          <Skeleton type="text" style={styles.skeleton} />
          <Skeleton type="text" />
          <Skeleton type="image" />
        </StyleGuidePanel>
      </div>
    </StyleGuidePageContainer>
  )
}
