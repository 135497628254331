import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { spacing } from '../styles/token.stylex'
import { Skeleton } from '../components/Skeleton'

const styles = create({
  creatorInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing.extraSmall,
  },
  imageSkeleton: {
    width: '72px',
    height: '72px',
    borderRadius: '50%',
  },
  displayNameSkeleton: {
    width: '74px',
    height: '18px',
  },
})

export const CreatorInfoSkeleton: React.FC = () => {
  return (
    <div {...props(styles.creatorInfo)}>
      <Skeleton type="image" style={styles.imageSkeleton} />
      <Skeleton type="text" style={styles.displayNameSkeleton} />
    </div>
  )
}
