import { forwardRef } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import { InputPanel } from './InputPanel'
import { props } from '@stylexjs/stylex'
import { mentionsStyles } from './styles'
import { fonts } from '../../styles/font-styles'

type MentionTextAreaProps = {
  id: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  mentionData: { id: string; display: string }[]
  placeholder?: string
  error?: string
  maxLength?: number
  required?: boolean
  instruction?: string
  label?: string
  showCharacterCount?: boolean
  variant: 'post' | 'comment'
}

const handleChange = (
  e: { target: { value: string } },
  maxLength: number | undefined,
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
) => {
  const inputValue = e.target.value

  if (maxLength && inputValue.length > maxLength) {
    e.target.value = inputValue.substring(0, maxLength)
  }
  onChange(e as React.ChangeEvent<HTMLTextAreaElement>)
}

export const MentionsTextArea = forwardRef<
  HTMLTextAreaElement,
  MentionTextAreaProps
>(
  (
    {
      id,
      value,
      onChange,
      mentionData,
      placeholder,
      error,
      maxLength,
      required,
      instruction,
      label,
      showCharacterCount = true,
      variant,
    },
    ref
  ) => {
    return (
      <InputPanel
        id={id}
        label={label}
        error={error}
        maxLength={maxLength}
        required={required}
        instruction={instruction}
        characterCount={showCharacterCount ? value.length : undefined}
      >
        <div {...props(mentionsStyles[variant])}>
          <MentionsInput
            value={value}
            onChange={(e) => handleChange(e, maxLength, onChange)}
            placeholder={placeholder}
            spellCheck={false}
            inputRef={ref as React.Ref<HTMLTextAreaElement>}
            {...props(mentionsStyles.input, fonts.smallRegular)}
          >
            <Mention
              trigger="@"
              data={mentionData}
              markup="@[__display__](__id__)"
              displayTransform={(id, display) => `@${display}`}
            />
          </MentionsInput>
        </div>
      </InputPanel>
    )
  }
)

MentionsTextArea.displayName = 'MentionsTextArea'
