import { create } from '@stylexjs/stylex'
import { spacing } from '../styles/token.stylex'

const MIN_CARD_WIDTH = 280

export const styles = create({
  container: {
    display: 'grid',
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${MIN_CARD_WIDTH}px, 1fr))`,
    gap: `${spacing.large} ${spacing.xxxSmall}`,
    justifyItems: 'center',
  },
  loadMoreButton: {
    textAlign: 'center',
    paddingTop: spacing.large,
  },
  filter: {
    paddingBottom: spacing.normal,
  },
})
