/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetChannelRestrictionsResponse } from './GetChannelRestrictionsResponse';
import {
    GetChannelRestrictionsResponseFromJSON,
    GetChannelRestrictionsResponseFromJSONTyped,
    GetChannelRestrictionsResponseToJSON,
} from './GetChannelRestrictionsResponse';
import type { GetChannelStatsResponse } from './GetChannelStatsResponse';
import {
    GetChannelStatsResponseFromJSON,
    GetChannelStatsResponseFromJSONTyped,
    GetChannelStatsResponseToJSON,
} from './GetChannelStatsResponse';
import type { GetChannelTipConfigurationResponse } from './GetChannelTipConfigurationResponse';
import {
    GetChannelTipConfigurationResponseFromJSON,
    GetChannelTipConfigurationResponseFromJSONTyped,
    GetChannelTipConfigurationResponseToJSON,
} from './GetChannelTipConfigurationResponse';
import type { GetChannelUserResponse } from './GetChannelUserResponse';
import {
    GetChannelUserResponseFromJSON,
    GetChannelUserResponseFromJSONTyped,
    GetChannelUserResponseToJSON,
} from './GetChannelUserResponse';
import type { GetChannelVisibilityResponse } from './GetChannelVisibilityResponse';
import {
    GetChannelVisibilityResponseFromJSON,
    GetChannelVisibilityResponseFromJSONTyped,
    GetChannelVisibilityResponseToJSON,
} from './GetChannelVisibilityResponse';
import type { GetPurchaseConfigurationResponse } from './GetPurchaseConfigurationResponse';
import {
    GetPurchaseConfigurationResponseFromJSON,
    GetPurchaseConfigurationResponseFromJSONTyped,
    GetPurchaseConfigurationResponseToJSON,
} from './GetPurchaseConfigurationResponse';
import type { GetSubscriptionOptionsResponse } from './GetSubscriptionOptionsResponse';
import {
    GetSubscriptionOptionsResponseFromJSON,
    GetSubscriptionOptionsResponseFromJSONTyped,
    GetSubscriptionOptionsResponseToJSON,
} from './GetSubscriptionOptionsResponse';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetChannelInfoResponse
 */
export interface GetChannelInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetChannelInfoResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetChannelInfoResponse
     */
    slug: string;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetChannelInfoResponse
     */
    bannerPicture?: ImageVariantsResponse;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelInfoResponse
     */
    isBanned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetChannelInfoResponse
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelInfoResponse
     */
    isVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelInfoResponse
     */
    isChannelOwner: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelInfoResponse
     */
    isSystemUser: boolean;
    /**
     * Indicates if the channel is streaming live.
     * @type {boolean}
     * @memberof GetChannelInfoResponse
     */
    isLive: boolean;
    /**
     * 
     * @type {GetChannelUserResponse}
     * @memberof GetChannelInfoResponse
     */
    user: GetChannelUserResponse;
    /**
     * 
     * @type {GetChannelRestrictionsResponse}
     * @memberof GetChannelInfoResponse
     */
    restrictions: GetChannelRestrictionsResponse;
    /**
     * 
     * @type {GetChannelVisibilityResponse}
     * @memberof GetChannelInfoResponse
     */
    visibility: GetChannelVisibilityResponse;
    /**
     * 
     * @type {GetChannelStatsResponse}
     * @memberof GetChannelInfoResponse
     */
    stats: GetChannelStatsResponse;
    /**
     * 
     * @type {GetPurchaseConfigurationResponse}
     * @memberof GetChannelInfoResponse
     */
    getPurchaseConfigurationResponse: GetPurchaseConfigurationResponse;
    /**
     * 
     * @type {GetChannelTipConfigurationResponse}
     * @memberof GetChannelInfoResponse
     */
    tipConfiguration: GetChannelTipConfigurationResponse;
    /**
     * 
     * @type {GetSubscriptionOptionsResponse}
     * @memberof GetChannelInfoResponse
     */
    subscriptionOptions: GetSubscriptionOptionsResponse;
}

/**
 * Check if a given object implements the GetChannelInfoResponse interface.
 */
export function instanceOfGetChannelInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "isChannelOwner" in value;
    isInstance = isInstance && "isSystemUser" in value;
    isInstance = isInstance && "isLive" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "restrictions" in value;
    isInstance = isInstance && "visibility" in value;
    isInstance = isInstance && "stats" in value;
    isInstance = isInstance && "getPurchaseConfigurationResponse" in value;
    isInstance = isInstance && "tipConfiguration" in value;
    isInstance = isInstance && "subscriptionOptions" in value;

    return isInstance;
}

export function GetChannelInfoResponseFromJSON(json: any): GetChannelInfoResponse {
    return GetChannelInfoResponseFromJSONTyped(json, false);
}

export function GetChannelInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'slug': json['slug'],
        'bannerPicture': !exists(json, 'bannerPicture') ? undefined : ImageVariantsResponseFromJSON(json['bannerPicture']),
        'isBanned': !exists(json, 'isBanned') ? undefined : json['isBanned'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isVerified': !exists(json, 'isVerified') ? undefined : json['isVerified'],
        'isChannelOwner': json['isChannelOwner'],
        'isSystemUser': json['isSystemUser'],
        'isLive': json['isLive'],
        'user': GetChannelUserResponseFromJSON(json['user']),
        'restrictions': GetChannelRestrictionsResponseFromJSON(json['restrictions']),
        'visibility': GetChannelVisibilityResponseFromJSON(json['visibility']),
        'stats': GetChannelStatsResponseFromJSON(json['stats']),
        'getPurchaseConfigurationResponse': GetPurchaseConfigurationResponseFromJSON(json['getPurchaseConfigurationResponse']),
        'tipConfiguration': GetChannelTipConfigurationResponseFromJSON(json['tipConfiguration']),
        'subscriptionOptions': GetSubscriptionOptionsResponseFromJSON(json['subscriptionOptions']),
    };
}

export function GetChannelInfoResponseToJSON(value?: GetChannelInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'bannerPicture': ImageVariantsResponseToJSON(value.bannerPicture),
        'isBanned': value.isBanned,
        'description': value.description,
        'isVerified': value.isVerified,
        'isChannelOwner': value.isChannelOwner,
        'isSystemUser': value.isSystemUser,
        'isLive': value.isLive,
        'user': GetChannelUserResponseToJSON(value.user),
        'restrictions': GetChannelRestrictionsResponseToJSON(value.restrictions),
        'visibility': GetChannelVisibilityResponseToJSON(value.visibility),
        'stats': GetChannelStatsResponseToJSON(value.stats),
        'getPurchaseConfigurationResponse': GetPurchaseConfigurationResponseToJSON(value.getPurchaseConfigurationResponse),
        'tipConfiguration': GetChannelTipConfigurationResponseToJSON(value.tipConfiguration),
        'subscriptionOptions': GetSubscriptionOptionsResponseToJSON(value.subscriptionOptions),
    };
}

