/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetStreamFeedItemResponse,
  GetStreamFeedItemResponsePagedApiResponse,
  StreamFeedType,
  ViewerCountResponse,
} from '../models/index';
import {
    GetStreamFeedItemResponseFromJSON,
    GetStreamFeedItemResponseToJSON,
    GetStreamFeedItemResponsePagedApiResponseFromJSON,
    GetStreamFeedItemResponsePagedApiResponseToJSON,
    StreamFeedTypeFromJSON,
    StreamFeedTypeToJSON,
    ViewerCountResponseFromJSON,
    ViewerCountResponseToJSON,
} from '../models/index';

export interface StreamfeedContentIdGetRequest {
    contentId: string;
}

export interface StreamfeedGetRequest {
    categoryIds?: Array<number>;
    pageNumber?: number;
    pageSize?: number;
    feedType?: StreamFeedType;
}

export interface StreamfeedStreamSessionIdViewerCountGetRequest {
    streamSessionId: string;
}

export interface StreamfeedSlugVodsGetRequest {
    slug: string;
}

/**
 * 
 */
export class StreamFeedApi extends runtime.BaseAPI {

    /**
     * Retrieves a single stream or video feed item by its ID.
     */
    async streamfeedContentIdGetRaw(requestParameters: StreamfeedContentIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStreamFeedItemResponse>> {
        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId','Required parameter requestParameters.contentId was null or undefined when calling streamfeedContentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/streamfeed/{contentId}`.replace(`{${"contentId"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStreamFeedItemResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a single stream or video feed item by its ID.
     */
    async streamfeedContentIdGet(requestParameters: StreamfeedContentIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStreamFeedItemResponse> {
        const response = await this.streamfeedContentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a paginated list of stream feed items based on category and content type.
     */
    async streamfeedGetRaw(requestParameters: StreamfeedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStreamFeedItemResponsePagedApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.categoryIds) {
            queryParameters['categoryIds'] = requestParameters.categoryIds;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.feedType !== undefined) {
            queryParameters['feedType'] = requestParameters.feedType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/streamfeed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStreamFeedItemResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a paginated list of stream feed items based on category and content type.
     */
    async streamfeedGet(requestParameters: StreamfeedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStreamFeedItemResponsePagedApiResponse> {
        const response = await this.streamfeedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the current viewer count for a given stream session.
     */
    async streamfeedStreamSessionIdViewerCountGetRaw(requestParameters: StreamfeedStreamSessionIdViewerCountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ViewerCountResponse>> {
        if (requestParameters.streamSessionId === null || requestParameters.streamSessionId === undefined) {
            throw new runtime.RequiredError('streamSessionId','Required parameter requestParameters.streamSessionId was null or undefined when calling streamfeedStreamSessionIdViewerCountGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/streamfeed/{streamSessionId}/viewer-count`.replace(`{${"streamSessionId"}}`, encodeURIComponent(String(requestParameters.streamSessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewerCountResponseFromJSON(jsonValue));
    }

    /**
     * Gets the current viewer count for a given stream session.
     */
    async streamfeedStreamSessionIdViewerCountGet(requestParameters: StreamfeedStreamSessionIdViewerCountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ViewerCountResponse> {
        const response = await this.streamfeedStreamSessionIdViewerCountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all VODs for a specific channel (slug) from the last 30 days.
     */
    async streamfeedSlugVodsGetRaw(requestParameters: StreamfeedSlugVodsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetStreamFeedItemResponse>>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling streamfeedSlugVodsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/streamfeed/{slug}/vods`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetStreamFeedItemResponseFromJSON));
    }

    /**
     * Retrieves all VODs for a specific channel (slug) from the last 30 days.
     */
    async streamfeedSlugVodsGet(requestParameters: StreamfeedSlugVodsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetStreamFeedItemResponse>> {
        const response = await this.streamfeedSlugVodsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
