import type { SVGProps } from 'react'

export const UncheckedCircle: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1.48547"
      width="16"
      height="16"
      rx="8"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="1"
      y="1.48547"
      width="16"
      height="16"
      rx="8"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
