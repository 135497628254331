export const RESERVED_WORDS = {
  profile: 'profile',
  communityGuidelines: 'community-guidelines',
  termsOfService: 'terms-of-service',
  dmcaPolicy: 'dmca-policy',
  privacyPolicy: 'privacy-policy',
  howToEmbed: 'how-to-embed',
  affiliateGuide: 'affiliate-guide',
  callback: 'callback',
  auth: 'auth',
  feeds: 'feeds',
  login: 'login',
  logout: 'logout',
  signUp: 'signup',
  statements: 'statements',
  becomeACreator: 'become-a-creator',
  streaming: 'streaming',
  helpAndSupport: 'help-and-support',
  post: 'post',
  create: 'create',
  notFound: 'not-found',
  subscriptions: 'subscriptions',
  notifications: 'notifications',
  paymentHistory: 'payment-history',
  subscriptionPrice: 'subscription-price',
  myProfile: 'my-profile',
  myStream: 'my-stream',
  bank: 'bank',
  videos: 'videos',

  // Style Guide
  styleGuide: 'style-guide',
  api: 'api',
  fonts: 'fonts',
  colors: 'colors',
  banners: 'banners',
  buttons: 'buttons',
  inputs: 'inputs',
  icons: 'icons',
  creatorCards: 'creator-cards',
  postCard: 'post-card',
  streamCards: 'stream-cards',
  links: 'links',
  mobileNavAndTapbar: 'mobile-bars',
  loader: 'loader',
  notification: 'notification',
  toasts: 'toasts',
  dialogs: 'dialogs',
  media: 'media',
  layout: 'layout',
  menu: 'menu',
  navigation: 'navigation',
  messages: 'messages',
}
