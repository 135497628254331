/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetChannelResponse } from './GetChannelResponse';
import {
    GetChannelResponseFromJSON,
    GetChannelResponseFromJSONTyped,
    GetChannelResponseToJSON,
} from './GetChannelResponse';
import type { GetOnboardingResponse } from './GetOnboardingResponse';
import {
    GetOnboardingResponseFromJSON,
    GetOnboardingResponseFromJSONTyped,
    GetOnboardingResponseToJSON,
} from './GetOnboardingResponse';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetUserInfoResponse
 */
export interface GetUserInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof GetUserInfoResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    displayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInfoResponse
     */
    canChangeDisplayName?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUserInfoResponse
     */
    notificationCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInfoResponse
     */
    isContentCreator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInfoResponse
     */
    isSystemUser: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUserInfoResponse
     */
    subscriptionPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    email?: string | null;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetUserInfoResponse
     */
    profilePicture?: ImageVariantsResponse;
    /**
     * 
     * @type {GetChannelResponse}
     * @memberof GetUserInfoResponse
     */
    streamerChannel: GetChannelResponse;
    /**
     * 
     * @type {GetOnboardingResponse}
     * @memberof GetUserInfoResponse
     */
    onboarding: GetOnboardingResponse;
}

/**
 * Check if a given object implements the GetUserInfoResponse interface.
 */
export function instanceOfGetUserInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "notificationCount" in value;
    isInstance = isInstance && "isContentCreator" in value;
    isInstance = isInstance && "isSystemUser" in value;
    isInstance = isInstance && "streamerChannel" in value;
    isInstance = isInstance && "onboarding" in value;

    return isInstance;
}

export function GetUserInfoResponseFromJSON(json: any): GetUserInfoResponse {
    return GetUserInfoResponseFromJSONTyped(json, false);
}

export function GetUserInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'canChangeDisplayName': !exists(json, 'canChangeDisplayName') ? undefined : json['canChangeDisplayName'],
        'notificationCount': json['notificationCount'],
        'isContentCreator': json['isContentCreator'],
        'isSystemUser': json['isSystemUser'],
        'subscriptionPrice': !exists(json, 'subscriptionPrice') ? undefined : json['subscriptionPrice'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : ImageVariantsResponseFromJSON(json['profilePicture']),
        'streamerChannel': GetChannelResponseFromJSON(json['streamerChannel']),
        'onboarding': GetOnboardingResponseFromJSON(json['onboarding']),
    };
}

export function GetUserInfoResponseToJSON(value?: GetUserInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'canChangeDisplayName': value.canChangeDisplayName,
        'notificationCount': value.notificationCount,
        'isContentCreator': value.isContentCreator,
        'isSystemUser': value.isSystemUser,
        'subscriptionPrice': value.subscriptionPrice,
        'email': value.email,
        'profilePicture': ImageVariantsResponseToJSON(value.profilePicture),
        'streamerChannel': GetChannelResponseToJSON(value.streamerChannel),
        'onboarding': GetOnboardingResponseToJSON(value.onboarding),
    };
}

