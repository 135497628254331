import { PropsWithChildren } from 'react'
import { Link } from '../components/Link'
import { create, props } from '@stylexjs/stylex'
import { spacing } from '../styles/token.stylex'
import useLanguage from '../translations/useLanguage'
import { routes } from '../router/routes'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: {
      default: 'column-reverse',
      [largeScreen]: 'row',
    },
    paddingBottom: spacing.large,
    gap: {
      default: spacing.small,
      [largeScreen]: spacing.large,
    },
    justifyContent: 'space-between',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
  },
})

export const HomeNavigation: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useLanguage()
  return (
    <div {...props(styles.container)}>
      <div>{children}</div>
      <div {...props(styles.menuContainer)}>
        <Link to={routes.home} variant="tab" width="fit">
          {t('home.navigation.streams')}
        </Link>
        <Link to={routes.feedsHome} variant="tab" width="fit">
          {t('home.navigation.feeds')}
        </Link>
      </div>
    </div>
  )
}
