import { create, props } from '@stylexjs/stylex'
import { Bell } from '../../components/icons/Bell'
import { Mail } from '../../components/icons/Mail'
import { NotificationBell } from '../../components/icons/NotificationBell'
import { NavContext } from './types'
import { House } from '../../components/icons/House'
import { AvatarImage } from '../../components/avatars/AvatarImage'
import { Plus } from '../../components/icons/Plus'
import { color, elementSizes } from '../../styles/token.stylex'
import { Link } from '../../components/Link'
import { routes } from '../../router/routes'

type TapBarProps = {
  displayName: string
  isContentCreator?: boolean
  onContentCreatorClick?: () => void
} & NavContext

const styles = create({
  tapBarContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: elementSizes.footerHeight,
    backgroundColor: color.tapBarBackground,
    borderTop: `1px solid ${color.tapBarBorder}`,
  },
  avatarImage: {
    paddingBottom: '5px', // This is hack because of none of the icons are centered properly
  },
})

export const TapBar: React.FC<TapBarProps> = ({
  avatarImageSrc,
  notificationCount = 0,
  onMailClick,
  displayName,
  isContentCreator,
}) => (
  <div {...props(styles.tapBarContainer)}>
    <Link to={routes.home} variant="tabIcon">
      <House width="1.25rem" height="1.25rem" />
    </Link>
    <Link to={routes.notifications} variant="tabIcon">
      {notificationCount > 0 ? (
        <NotificationBell
          notificationCount={notificationCount}
          width="1.6rem"
          height="1.6rem"
        />
      ) : (
        <Bell width="1.6rem" height="1.6rem" />
      )}
    </Link>
    <Link
      to={isContentCreator ? routes.post.create : routes.becomeACreator}
      variant="tabIcon"
    >
      <Plus width="2.2rem" height="2.2rem" fill={color.primary} />
    </Link>
    <Link to={routes.home} onClick={onMailClick} variant="tabIcon">
      <Mail width="1.25rem" height="1.25rem" />
    </Link>
    <Link to={routes.profile} variant="tabIcon">
      <div {...props(styles.avatarImage)}>
        <AvatarImage
          src={avatarImageSrc}
          alt={`${displayName} profile image`}
        />
      </div>
    </Link>
  </div>
)
