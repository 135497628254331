import React from 'react'
import { useChannelContext } from '../../context/channel-context'
import { LoadingScreen } from '../../../components/indicators/LoadingScreen'
import ChannelVods from './ChannelVods'

export const ChannelVodsPage: React.FC = () => {
  const { channel, isLoading } = useChannelContext()

  if (isLoading) return <LoadingScreen />

  if (!channel) return null

  return <ChannelVods channel={channel} />
}

export default ChannelVodsPage
