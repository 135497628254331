import type { SVGProps } from 'react'

export const Ellipse: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
  >
    <circle cx="5" cy="5" r="5" fill={stroke} />
  </svg>
)
