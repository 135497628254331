import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { create } from '@stylexjs/stylex'
import { swiperStyleProps } from '../../post/slides/slidesStyles'
import { Skeleton } from '../../components/Skeleton'
import { AffiliateImage } from './AffiliateImage'

export type PanelItem = {
  id: string
  src?: string
  linkUrl?: string
}

type PromoPanelSliderProps = {
  slides: PanelItem[]
  isLoading: boolean
  type?: 'channel' | 'video'
}

export const styles = create({
  imageSkeleton: {
    width: '100%',
  },
})

const getBreakpoints = (type: 'channel' | 'video') => ({
  500: { slidesPerView: 2, spaceBetween: 20 },
  768: { slidesPerView: 3, spaceBetween: 20 },
  1280: { slidesPerView: 4, spaceBetween: 20 },
  1920: { slidesPerView: type === 'video' ? 5 : 4, spaceBetween: 20 },
  2560: { slidesPerView: type === 'video' ? 6 : 4, spaceBetween: 20 },
})

export const BannerSlider: React.FC<PromoPanelSliderProps> = ({
  slides,
  isLoading,
  type = 'channel',
}) => {
  const modules = [Navigation, Pagination, Autoplay]
  const paginationSettings = { clickable: true }
  const navigationSettings = true

  return (
    <Swiper
      modules={modules}
      navigation={navigationSettings}
      pagination={paginationSettings}
      allowTouchMove
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      style={swiperStyleProps}
      breakpoints={getBreakpoints(type)}
    >
      {!isLoading &&
        slides?.map((slide) => (
          <SwiperSlide key={slide.id}>
            <AffiliateImage {...slide} />
          </SwiperSlide>
        ))}

      {isLoading &&
        [...Array(9)].map((_, i) => (
          <SwiperSlide key={i}>
            <Skeleton type="image" style={styles.imageSkeleton} />
          </SwiperSlide>
        ))}
    </Swiper>
  )
}
