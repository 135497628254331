import { forwardRef } from 'react'
import { create, props } from '@stylexjs/stylex'
import { fonts } from '../../styles/font-styles'
import { inputStyles } from './styles'
import { InputDefaultProps } from './types'
import { InputPanel } from './InputPanel'

const styles = create({
  area: {
    resize: 'none',
  },
})

type TextAreaProps = {
  rows?: number
  onClick?: () => void
  onEscape?: () => void
  onFocus?: () => void
} & InputDefaultProps<HTMLTextAreaElement>

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      id,
      value,
      placeholder,
      maxLength,
      disabled,
      error,
      rows,
      label,
      instruction,
      required,
      onChange,
      onClick,
      onEscape,
      onFocus,
    },
    ref
  ) => {
    const currentLength = value?.toString().length ?? 0

    return (
      <InputPanel
        id={id}
        error={error}
        label={label}
        required={required}
        instruction={instruction}
        maxLength={maxLength}
        characterCount={currentLength}
      >
        <textarea
          onClick={onClick}
          ref={ref}
          {...props(
            inputStyles.container,
            styles.area,
            fonts.smallRegular,
            !!error && inputStyles.error
          )}
          id={id}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          rows={rows}
          spellCheck={false}
          disabled={disabled}
          onChange={onChange}
          onKeyDown={(e) => {
            if (onEscape && e.key === 'Escape') {
              onEscape()
            }
          }}
          onFocus={onFocus}
        />
      </InputPanel>
    )
  }
)

TextArea.displayName = 'TextArea'
