import { create, props } from '@stylexjs/stylex'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { PageHeader } from '../../../layout/PageHeader'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  container: {
    maxWidth: 'fit-content',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    gap: '50px',
  },
})

export const Navigation: React.FC = () => {
  return (
    <StyleGuidePageContainer title="Navigation">
      <div {...props(styles.container)}>
        <StyleGuidePanel
          title="Check on mobile (it shows navigation back)"
          direction="column"
        >
          <PageHeader>{'MENU'}</PageHeader>
        </StyleGuidePanel>
      </div>
    </StyleGuidePageContainer>
  )
}
