import { getMenuData } from '../../../layout/menu/menu-data'
import { Menu } from '../../../layout/menu/Menu'

import { StyleGuidePanel } from '../StyleGuidePanel'
import { create, props } from '@stylexjs/stylex'
import useLanguage from '../../../translations/useLanguage'
import { GetUserInfoResponse } from '../../../client'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'
import { spacing } from '../../../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: {
      default: spacing.large,
      [largeScreen]: spacing.extraLarge,
    },
  },
  navContainer: {
    width: '50rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  content: {
    height: '800px',
  },
})

const data = {
  profilePicture: {
    source:
      'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
  },
  displayName: '@AmandaPink',
  streamerChannel: {
    isVerified: true,
    slug: 'amandapink',
  },
  isContentCreator: true,
}

export const Menus: React.FC = () => {
  const { languageCode } = useLanguage()

  const creator = getMenuData(data as GetUserInfoResponse, languageCode, 53)

  const nonCreator = getMenuData(
    { ...data, isContentCreator: false } as GetUserInfoResponse,
    languageCode,
    0
  )

  const anonymUser = getMenuData(undefined, languageCode, 0)

  return (
    <StyleGuidePageContainer title="Menus">
      <div {...props(styles.container)}>
        <StyleGuidePanel
          title="Logged in as a Creator"
          contentStyle={styles.content}
        >
          <Menu
            items={creator.menuItems}
            footerItems={creator.footerItems}
            profile={creator.profile}
          />
        </StyleGuidePanel>
        <StyleGuidePanel
          title="Logged in as a non Creator"
          contentStyle={styles.content}
        >
          <Menu
            items={nonCreator.menuItems}
            footerItems={nonCreator.footerItems}
            profile={nonCreator.profile}
          />
        </StyleGuidePanel>
        <StyleGuidePanel title="Logged out" contentStyle={styles.content}>
          <Menu
            items={anonymUser.menuItems}
            footerItems={anonymUser.footerItems}
            profile={anonymUser.profile}
          />
        </StyleGuidePanel>
      </div>
    </StyleGuidePageContainer>
  )
}
