/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetNotificationChannelResponse } from './GetNotificationChannelResponse';
import {
    GetNotificationChannelResponseFromJSON,
    GetNotificationChannelResponseFromJSONTyped,
    GetNotificationChannelResponseToJSON,
} from './GetNotificationChannelResponse';
import type { GetNotificationParticipantResponse } from './GetNotificationParticipantResponse';
import {
    GetNotificationParticipantResponseFromJSON,
    GetNotificationParticipantResponseFromJSONTyped,
    GetNotificationParticipantResponseToJSON,
} from './GetNotificationParticipantResponse';
import type { GetNotificationPostResponse } from './GetNotificationPostResponse';
import {
    GetNotificationPostResponseFromJSON,
    GetNotificationPostResponseFromJSONTyped,
    GetNotificationPostResponseToJSON,
} from './GetNotificationPostResponse';
import type { NotificationSubType } from './NotificationSubType';
import {
    NotificationSubTypeFromJSON,
    NotificationSubTypeFromJSONTyped,
    NotificationSubTypeToJSON,
} from './NotificationSubType';
import type { NotificationType } from './NotificationType';
import {
    NotificationTypeFromJSON,
    NotificationTypeFromJSONTyped,
    NotificationTypeToJSON,
} from './NotificationType';

/**
 * 
 * @export
 * @interface GetNotificationResponse
 */
export interface GetNotificationResponse {
    /**
     * The unique identifier of the notification.
     * @type {string}
     * @memberof GetNotificationResponse
     */
    id: string;
    /**
     * 
     * @type {NotificationType}
     * @memberof GetNotificationResponse
     */
    type: NotificationType;
    /**
     * 
     * @type {NotificationSubType}
     * @memberof GetNotificationResponse
     */
    subType: NotificationSubType;
    /**
     * The timestamp when the notification was created.
     * @type {Date}
     * @memberof GetNotificationResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {GetNotificationParticipantResponse}
     * @memberof GetNotificationResponse
     */
    initiator: GetNotificationParticipantResponse;
    /**
     * Indicates whether the notification has been read by the recipient.
     * @type {boolean}
     * @memberof GetNotificationResponse
     */
    isRead: boolean;
    /**
     * 
     * @type {GetNotificationPostResponse}
     * @memberof GetNotificationResponse
     */
    relatedPost?: GetNotificationPostResponse;
    /**
     * 
     * @type {GetNotificationChannelResponse}
     * @memberof GetNotificationResponse
     */
    relatedChannel?: GetNotificationChannelResponse;
    /**
     * Amount if specified for notification type
     * @type {number}
     * @memberof GetNotificationResponse
     */
    amount: number;
    /**
     * Currency if specified for notification type
     * @type {string}
     * @memberof GetNotificationResponse
     */
    currency: string;
}

/**
 * Check if a given object implements the GetNotificationResponse interface.
 */
export function instanceOfGetNotificationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "subType" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "initiator" in value;
    isInstance = isInstance && "isRead" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;

    return isInstance;
}

export function GetNotificationResponseFromJSON(json: any): GetNotificationResponse {
    return GetNotificationResponseFromJSONTyped(json, false);
}

export function GetNotificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNotificationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': NotificationTypeFromJSON(json['type']),
        'subType': NotificationSubTypeFromJSON(json['subType']),
        'createdAt': (new Date(json['createdAt'])),
        'initiator': GetNotificationParticipantResponseFromJSON(json['initiator']),
        'isRead': json['isRead'],
        'relatedPost': !exists(json, 'relatedPost') ? undefined : GetNotificationPostResponseFromJSON(json['relatedPost']),
        'relatedChannel': !exists(json, 'relatedChannel') ? undefined : GetNotificationChannelResponseFromJSON(json['relatedChannel']),
        'amount': json['amount'],
        'currency': json['currency'],
    };
}

export function GetNotificationResponseToJSON(value?: GetNotificationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': NotificationTypeToJSON(value.type),
        'subType': NotificationSubTypeToJSON(value.subType),
        'createdAt': (value.createdAt.toISOString()),
        'initiator': GetNotificationParticipantResponseToJSON(value.initiator),
        'isRead': value.isRead,
        'relatedPost': GetNotificationPostResponseToJSON(value.relatedPost),
        'relatedChannel': GetNotificationChannelResponseToJSON(value.relatedChannel),
        'amount': value.amount,
        'currency': value.currency,
    };
}

