import React, { useEffect, useState } from 'react'
import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import useLanguage from '../translations/useLanguage'
import { useApi } from '../api/ApiContext'
import { useErrorNotification } from '../translations/useErrorNotification'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { queryKeys } from '../constants/query-keys'
import { toast } from 'react-toastify'
import LoadingIndicator from '../components/indicators/LoadingIndicator'
import { Panel } from '../components/Panel'
import { fonts } from '../styles/font-styles'
import { Button } from '../components/Button'
import { NotificationItem } from './NotificationItem'
import {
  GetNotificationResponse,
  GetNotificationResponsePagedApiResponse,
  NotificationType,
} from '../client'
import { TagButton } from '../components/TagButton'
import { NOTIFICATIONS_PER_PAGE } from '../constants'

const styles = create({
  headerContainer: {
    display: 'flex',
    gap: spacing.xSmall,
    overflowY: 'auto',
    paddingBottom: spacing.extraSmall,
    '::-webkit-scrollbar': { height: '5px' },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xSmall,
    backgroundColor: color.codGrayLight,
  },
  buttonContainer: {
    marginTop: '2rem',
    textAlign: 'center',
    ':not(#_id) button': {
      width: '100%',
    },
  },
  emptyList: {
    padding: spacing.normal,
    textAlign: 'center',
  },
  content: {
    paddingTop: spacing.normal,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '7.5rem',
  },
})

export const Notifications: React.FC = () => {
  const { t } = useLanguage()
  const { notificationsApi } = useApi()
  const notifyError = useErrorNotification()
  const [selectedFilter, setSelectedFilter] = useState<NotificationType>()
  const queryClient = useQueryClient()

  const { mutate: markAllAsRead } = useMutation({
    mutationFn: () => notificationsApi.notificationsReadPost(),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.user.default)
    },
  })

  useEffect(() => {
    return () => {
      markAllAsRead()
    }
  }, [markAllAsRead])

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery<GetNotificationResponsePagedApiResponse, Error>({
      queryKey: queryKeys.notifications.filter(
        selectedFilter?.toString() ?? ''
      ),
      queryFn: async ({ pageParam = 1 }) => {
        return notificationsApi.notificationsGet({
          pageNumber: pageParam,
          pageSize: NOTIFICATIONS_PER_PAGE,
          type: selectedFilter,
        })
      },
      getNextPageParam: (lastPage) =>
        (lastPage.pageNumber ?? 1) < (lastPage.totalPages ?? 1)
          ? (lastPage.pageNumber ?? 1) + 1
          : undefined,
      onError: notifyError(() => toast.error(t('notifications.error'))),
    })

  if (!isLoading && data === undefined) {
    throw new Error('Data is undefined')
  }

  const notifications: GetNotificationResponse[] = data
    ? data?.pages?.flatMap((page) => page.data)
    : []

  const renderFilter = () => {
    return (
      <div {...props(styles.headerContainer)}>
        <TagButton
          isActive={selectedFilter === undefined}
          onClick={() => setSelectedFilter(undefined)}
        >
          {t('notifications.filters.all')}
        </TagButton>
        <TagButton
          isActive={selectedFilter === NotificationType.Tip}
          onClick={() => setSelectedFilter(NotificationType.Tip)}
        >
          {t('notifications.filters.tips')}
        </TagButton>
        <TagButton
          isActive={selectedFilter === NotificationType.Purchase}
          onClick={() => setSelectedFilter(NotificationType.Purchase)}
        >
          {t('notifications.filters.purchases')}
        </TagButton>
        <TagButton
          isActive={selectedFilter === NotificationType.Subscription}
          onClick={() => setSelectedFilter(NotificationType.Subscription)}
        >
          {t('notifications.filters.subscriptions')}
        </TagButton>
        <TagButton
          isActive={selectedFilter === NotificationType.Comment}
          onClick={() => setSelectedFilter(NotificationType.Comment)}
        >
          {t('notifications.filters.comments')}
        </TagButton>
      </div>
    )
  }

  return (
    <Panel>
      {renderFilter()}
      <div {...props(styles.content)}>
        {!notifications.length && !isLoading && (
          <div {...props(styles.emptyList)}>{t('notifications.empty')}</div>
        )}

        {isLoading && (
          <div {...props(styles.loading)}>
            <LoadingIndicator width="2.5rem" height="2.5rem" />{' '}
          </div>
        )}
        {notifications.length !== 0 && (
          <div>
            <div {...props(styles.container, fonts.smallRegular)}>
              {notifications.map((notification, rowIndex) => (
                <NotificationItem key={rowIndex} notification={notification} />
              ))}
              {hasNextPage && (
                <div {...props(styles.buttonContainer)}>
                  <Button
                    onClick={() => fetchNextPage()}
                    disabled={isFetchingNextPage}
                    isLoading={isFetchingNextPage}
                  >
                    {isFetchingNextPage
                      ? t('shared.buttons.loading')
                      : t('shared.buttons.showMore')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Panel>
  )
}
