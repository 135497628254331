import React from 'react'
import { GetChannelInfoResponse } from '../../client'
import { useApi } from '../../api/ApiContext'
import { useQuery } from 'react-query'
import { queryKeys } from '../../constants/query-keys'
import { BannerSlider } from './BannerSlider'
import { mapPromoPanelsToSlides } from '../../promo-panels/mapping'
import { create, props } from '@stylexjs/stylex'
import { spacing } from '../../styles/token.stylex'
import { AffiliateGuideText } from './AffiliateGuideText'
import { useUserContext } from '../../auth/user-context'
import { Button } from '../../components/Button'
import { routes } from '../../router/routes'
import { useNavigate } from 'react-router-dom'
import useLanguage from '../../translations/useLanguage'

type SmartSliderProps = {
  channel: GetChannelInfoResponse
  hideControls?: boolean
  type?: 'channel' | 'video'
}

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    padding: {
      default: spacing.small,
      [largeScreen]: spacing.normal,
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      default: spacing.small,
      [largeScreen]: spacing.large,
    },
    paddingTop: {
      default: spacing.small,
      [largeScreen]: spacing.large,
    },
  },
})

const SmartBannerSlider: React.FC<SmartSliderProps> = ({
  channel,
  hideControls = false,
  type = 'channel',
}) => {
  const { promoPanelApi } = useApi()
  const { isContentCreator } = useUserContext()
  const navigate = useNavigate()
  const { t } = useLanguage()

  const { data: promoPanels, isLoading } = useQuery({
    queryKey: queryKeys.promoPanel.default(channel.id),
    queryFn: () =>
      promoPanelApi.promopanelChannelIdPromoPanelsGet({
        channelId: channel.id,
      }),
  })

  const slides = promoPanels ? mapPromoPanelsToSlides(promoPanels) : []

  const shouldShowContainer =
    slides.length > 0 || (channel.isChannelOwner && isContentCreator)

  return (
    <div {...props(shouldShowContainer && styles.container)}>
      {slides.length > 0 && (
        <BannerSlider slides={slides} isLoading={isLoading} type={type} />
      )}
      {!hideControls && channel.isChannelOwner && isContentCreator && (
        <div {...props(styles.infoContainer)}>
          <AffiliateGuideText />
          <Button
            variant="cta"
            onClick={() => navigate(routes.profileRoutes.banners)}
          >
            {t('channel.actions.editBanners.title')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default SmartBannerSlider
