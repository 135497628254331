/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum representing the type of content to fetch in the stream feed.
 * @export
 */
export const StreamFeedType = {
    VoDs: 'voDs',
    LiveStreams: 'liveStreams',
    Mixed: 'mixed'
} as const;
export type StreamFeedType = typeof StreamFeedType[keyof typeof StreamFeedType];


export function StreamFeedTypeFromJSON(json: any): StreamFeedType {
    return StreamFeedTypeFromJSONTyped(json, false);
}

export function StreamFeedTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreamFeedType {
    return json as StreamFeedType;
}

export function StreamFeedTypeToJSON(value?: StreamFeedType | null): any {
    return value as any;
}

