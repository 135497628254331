import { create, props } from '@stylexjs/stylex'
import React from 'react'
import { AvatarImage } from '../components/avatars/AvatarImage'
import { Close } from '../components/icons/Close'
import { color, spacing } from '../styles/token.stylex'
import { Creator } from '../components/types'
import useLanguage from '../translations/useLanguage'
import { IconButton } from '../components/IconButton'
import { fonts } from '../styles/font-styles'
import { Ellipse } from '../components/icons/Ellipse'
import { Verified } from '../components/icons/Verified'

type MessagePreviewProps = {
  message: string
  date: Date
  isRead: boolean
  isActive?: boolean
  onClick: () => void
  onClose: () => void
} & Creator

const styles = create({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.small,
    cursor: 'pointer',
    maxWidth: '500px',
    padding: `${spacing.extraSmall} ${spacing.normal}`,
    backgroundColor: {
      default: color.codGrayLight,
      ':hover': color.torchRed,
    },
    ':not(#_id):hover div': {
      color: color.white,
    },
    ':not(#_id):hover svg': {
      color: color.white,
    },
    transition: 'all 0.2s',
  },
  isActive: {
    backgroundColor: color.torchRed,
    ':not(#_id) div': {
      color: color.white,
    },
    ':not(#_id) svg': {
      color: color.white,
    },
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
  },
  displayName: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.extraSmall,
  },
  messageText: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-all',
  },
  textRead: {
    color: color.dustyGray,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: spacing.xxSmall,
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxSmall,
    color: color.gold,
    whiteSpace: 'nowrap',
  },
  dateRead: {
    color: color.dustyGray,
  },
})

export const MessagePreview: React.FC<MessagePreviewProps> = ({
  message,
  date,
  fullName,
  profilePictureUrl,
  profileUrl,
  isVerified,
  isRead,
  isActive,
  onClick,
  onClose,
}) => {
  const { formatMessageDate } = useLanguage()

  return (
    <div
      {...props(styles.container, isActive && styles.isActive)}
      onClick={onClick}
    >
      <div {...props(styles.avatar)}>
        <AvatarImage
          variant="medium"
          alt={`${fullName} profile`}
          src={profilePictureUrl}
          url={profileUrl}
        />
      </div>
      <div {...props(styles.content)}>
        <div {...props(styles.displayName)}>
          <div {...props(fonts.normalMedium, styles.messageText)}>
            <span>{fullName}</span>
          </div>
          <span>{isVerified && <Verified />}</span>
        </div>
        <div
          {...props(
            styles.messageText,
            isRead ? fonts.normalRegular : fonts.normalSemiBold,
            isRead && styles.textRead
          )}
        >
          {message}
        </div>
      </div>

      <div {...props(styles.actions)}>
        <IconButton
          variant="large"
          icon={Close}
          onClick={(event) => {
            event.stopPropagation()
            onClose()
          }}
        />
        <div
          {...props(
            styles.date,
            fonts.extraSmallRegular,
            isRead && styles.dateRead
          )}
        >
          {!isRead && <Ellipse width={10} height={10} />}
          {formatMessageDate(date)}
        </div>
      </div>
    </div>
  )
}
