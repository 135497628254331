import type { SVGProps } from 'react'

export const Emoji: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.3625 14.1C9.525 14.425 10.5 15.725 12.6125 15.725C14.725 15.725 15.7 14.425 15.8625 14.1M20.9 11.5C20.9 16.1668 17.1168 19.95 12.45 19.95C7.78319 19.95 4 16.1668 4 11.5C4 6.83318 7.78319 3.04999 12.45 3.04999C17.1168 3.04999 20.9 6.83318 20.9 11.5ZM10.175 10.2C10.175 10.559 9.95674 10.85 9.6875 10.85C9.41826 10.85 9.2 10.559 9.2 10.2C9.2 9.841 9.41826 9.54999 9.6875 9.54999C9.95674 9.54999 10.175 9.841 10.175 10.2ZM15.7 10.2C15.7 10.559 15.4817 10.85 15.2125 10.85C14.9433 10.85 14.725 10.559 14.725 10.2C14.725 9.841 14.9433 9.54999 15.2125 9.54999C15.4817 9.54999 15.7 9.841 15.7 10.2Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
