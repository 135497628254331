import { props } from '@stylexjs/stylex'
import { styleGuideMenuItems } from '../layout/menuItems'
import { StyleGuideMenu } from '../layout/StyleGuideMenu'
import { responsiveStyles } from '../../layout/navbar/styles'
import StyleGuidePageContainer from '../layout/StyleGuidePageContainer'

export const StyleGuidePage: React.FC = () => {
  return (
    <div>
      <div {...props(responsiveStyles.phoneFlex)}>
        <StyleGuideMenu items={styleGuideMenuItems} variant="mobile" />
      </div>
      <div {...props(responsiveStyles.desktopFlex)}>
        <StyleGuidePageContainer title="Please select a component from the menu"></StyleGuidePageContainer>
      </div>
    </div>
  )
}

export default StyleGuidePage
