import { FieldErrors, ResolverResult } from 'react-hook-form'
import { LanguageKey } from '../../translations/useLanguage'

export type WithdrawalFormData = {
  withdrawalAmount: string
}

export const witdrawalAmountValidator = (
  formValues: WithdrawalFormData,
  minPayout: number,
  maxPayout: number,
  currency: string,
  translate: (
    key: LanguageKey,
    replacements?: Record<string, string>
  ) => string,
  formatCurrency: (
    value: number,
    {
      currency,
      displayCurrency,
      locale,
      minimumFractionDigits,
      maximumFractionDigits,
    }?: {
      currency?: string
      displayCurrency?: boolean
      locale?: string
      minimumFractionDigits?: number
      maximumFractionDigits?: number
    }
  ) => string
) => {
  const errs: FieldErrors<WithdrawalFormData> = {}
  const { withdrawalAmount } = formValues

  const numericVal = parseFloat(withdrawalAmount || '0')

  if (!withdrawalAmount) {
    errs.withdrawalAmount = {
      type: 'required',
      message: translate('balance.dialog.defaultMessage'),
    }
  } else if (numericVal < minPayout) {
    errs.withdrawalAmount = {
      type: 'min',
      message: translate('balance.dialog.minimumPayout', {
        minPayout: formatCurrency(minPayout, {
          currency,
          maximumFractionDigits: 0,
        }),
      }),
    }
  } else if (numericVal > maxPayout) {
    errs.withdrawalAmount = {
      type: 'max',
      message: translate('balance.dialog.maximumPayout', {
        maxPayout: formatCurrency(maxPayout, {
          currency,
          maximumFractionDigits: 0,
        }),
      }),
    }
  }

  return {
    values: Object.keys(errs).length ? {} : formValues,
    errors: errs,
  } as ResolverResult<WithdrawalFormData>
}
