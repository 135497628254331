import { Controller, useForm } from 'react-hook-form'
import useLanguage from '../../translations/useLanguage'
import { useEffect, useRef, useState } from 'react'
import { useApi } from '../../api/ApiContext'
import { toast } from 'react-toastify'
import { Input } from '../../components/input/Input'
import { UsernameData, usernameValidator } from './utils'
import { EditForm } from '../../components/EditForm'
import { useMutation } from 'react-query'
import { useUserContext } from '../../auth/user-context'
import { GetUserInfoResponse } from '../../client'
import { BEESTREAM_FRONTEND_URL } from '../../constants/sites'

type ChangeUsernameProps = {
  user: GetUserInfoResponse
}

export const ChangeUserName: React.FC<ChangeUsernameProps> = ({ user }) => {
  const { t } = useLanguage()
  const { userApi } = useApi()
  const [isEditing, setIsEditing] = useState(false)
  const { reloadUser } = useUserContext()
  const inputRef = useRef<HTMLInputElement>(null)
  const { slug } = user.streamerChannel

  useEffect(() => {
    if (isEditing) inputRef.current?.focus()
  }, [isEditing])

  const resetForm = () => {
    setIsEditing(false)
    reset()
    inputRef.current?.blur()
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
  } = useForm<UsernameData>({
    defaultValues: { username: slug },
    resolver: (data) => usernameValidator(data, t),
    mode: 'onChange',
  })

  useEffect(() => {
    reset({ username: slug })
  }, [slug, reset])

  const handleError = (error: Error) => {
    if (error.message === 'errors.specific.usernameNotAvailable') {
      setError('username', {
        message: t('myChannel.changeUsername.info.usernameAlreadyTaken'),
      })
    }
  }

  const { mutateAsync: changeUsername, isLoading: isMutationLoading } =
    useMutation({
      mutationFn: (newUserName: string) =>
        userApi.userUsernamePut({ updateUserNameRequest: { newUserName } }),
      onSuccess: () => {
        toast.success(t('myChannel.changeUsername.success'))
        setIsEditing(false)
        reloadUser()
      },
      onError: (error: Error) => handleError(error),
    })

  const onEdit = () => {
    setIsEditing(true)
  }

  const onSubmit = handleSubmit(async ({ username }: { username: string }) => {
    if (errors.username) return
    await changeUsername(username.trim())
  })

  return (
    <EditForm
      canEdit={true}
      canSave={isDirty && !isMutationLoading && !errors.username}
      isEditing={isEditing}
      onCancel={resetForm}
      onSubmit={onSubmit}
      onEdit={onEdit}
      isLoading={isMutationLoading}
    >
      <Controller
        control={control}
        name="username"
        render={({ field }) => (
          <Input
            ref={inputRef}
            id={field.name}
            value={field.value}
            onChange={(e) => field.onChange(e)}
            onFocus={onEdit}
            onEscape={resetForm}
            maxLength={24}
            error={errors.username?.message}
            label={t('myChannel.changeUsername.title')}
            instruction={`${BEESTREAM_FRONTEND_URL}/${field.value.toLowerCase()}`}
          />
        )}
      />
    </EditForm>
  )
}
