import React from 'react'
import { create, props } from '@stylexjs/stylex'
import {
  fontSize,
  fontWeight,
  spacing,
  color,
} from '../../../../styles/token.stylex'

const styles = create({
  colorCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '10rem',
    height: '10rem',
    marginTop: spacing.xxl,
    backgroundColor: color.deepGray,
    borderRadius: '0.5rem',
    padding: spacing.small,
    position: 'relative',
    overflow: 'visible',
  },
  colorCircle: {
    width: '5rem',
    height: '5rem',
    borderRadius: '50%',
    backgroundColor: '#000',
    position: 'absolute',
    top: '-2.5rem',
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  colorName: {
    fontSize: fontSize.small,
    fontWeight: fontWeight.semiBold,
    paddingBottom: spacing.normal,
    paddingTop: spacing.xxl,
    color: color.white,
  },
  colorHex: {
    fontSize: fontSize.extraSmall,
    color: '#9A9A9A',
    textTransform: 'uppercase',
    paddingBottom: spacing.small,
  },
  colorVariant: {
    fontSize: fontSize.small,
    fontWeight: fontWeight.semiBold,
    color: color.dustyGray,
  },
})

type ColorItemProps = {
  name: string
  hex: string
  variant: string
}

const ColorItem: React.FC<ColorItemProps> = ({ name, hex, variant }) => (
  <div {...props(styles.colorCard)}>
    <div {...props(styles.colorCircle)} style={{ backgroundColor: hex }}></div>
    <div {...props(styles.colorName)}>{name}</div>
    <div {...props(styles.colorHex)}>{hex}</div>
    <div {...props(styles.colorVariant)}>{variant}</div>
  </div>
)

export default ColorItem
