import { create, props } from '@stylexjs/stylex'
import { useChannelContext } from '../channel/context/channel-context'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { SmartPromoPanelEditor } from './SmartPromoPanelEditor'
import { spacing } from '../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      default: spacing.small,
      [largeScreen]: spacing.normal,
    },
    width: {
      default: '100%',
      [largeScreen]: '888px',
    },
    maxWidth: '100%',
    margin: '0 auto',
  },
})

const SmartEditor = () => {
  const { channel, isLoading } = useChannelContext()

  if (isLoading) return <LoadingScreen />

  if (!channel) return null

  return (
    <div {...props(styles.container)}>
      <SmartPromoPanelEditor channel={channel} />
    </div>
  )
}

export default SmartEditor
