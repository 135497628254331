import { useState, useEffect, useRef } from 'react'
import data, { Emoji } from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import japaneseLanguage from '@emoji-mart/data/i18n/ja.json'
import englishLanguage from '@emoji-mart/data/i18n/en.json'
import { init } from 'emoji-mart'
import useLanguage from '../translations/useLanguage'
import { IconButton } from './IconButton'
import { Emoji as EmojiIcon } from './icons/Emoji'
import { create, props } from '@stylexjs/stylex'
import { fonts } from '../styles/font-styles'

init({ data })

const largeScreen = '@media (min-width: 768px)'

export interface EmojiWithNative extends Emoji {
  native: string
}

type EmojiPickerProps = {
  onEmojiSelect: (emoji: EmojiWithNative) => void
  focusRef?:
    | React.RefObject<HTMLTextAreaElement>
    | React.RefObject<HTMLInputElement>
}

const styes = create({
  container: { position: 'relative' },
  pickerContainer: {
    position: 'absolute',
    right: 5,
    zIndex: 1000,
    ':not(#_id) em-emoji-picker': {
      '--background-rgb': '25, 27, 31',
      '--border-radius': '4px',
      '--category-icon-size': '16px',
      '--color-border-over': 'rgba(0, 0, 0, 0.1)',
      '--color-border': 'rgba(255, 255, 255, 0.05)',
      '--font-family':
        '"Poppins", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
      '--font-size': '14px',
      '--rgb-accent': '255, 255, 255',
      '--rgb-background': '25, 27, 31',
      '--rgb-color': '255, 255, 255',
      '--rgb-input': '51, 51, 51',
      width: '311px',
      height: '30vh',
      minHeight: '300px',
      maxHeight: '600px',
      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
      borderLeft: '1px solid rgba(255, 255, 255, 0.2)',
      borderRight: '1px solid rgba(255, 255, 255, 0.2)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    },
  },
  emojiPicker: {
    display: {
      default: 'none',
      [largeScreen]: 'block',
    },
  },
})

export const EmojiPicker = ({ onEmojiSelect, focusRef }: EmojiPickerProps) => {
  const { languageCode } = useLanguage()
  const [isOpen, setIsOpen] = useState(false)
  const pickerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!pickerRef.current) return
      if (!pickerRef.current.contains(event.target as Node)) setIsOpen(false)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleEmojiSelect = (emoji: EmojiWithNative) => {
    onEmojiSelect(emoji)
  }

  const toggleEmojiPicker = () => {
    setIsOpen(!isOpen)

    if (!focusRef?.current) return

    focusRef.current.focus()
  }

  return (
    <div ref={pickerRef} {...props(styes.container)}>
      <div {...props(styes.emojiPicker)}>
        <IconButton onClick={toggleEmojiPicker} icon={EmojiIcon} />
      </div>
      {isOpen && (
        <div {...props(styes.pickerContainer, fonts.largeRegular)}>
          <Picker
            data={data}
            native={true}
            onEmojiSelect={handleEmojiSelect}
            theme="dark"
            emojiButtonRadius={2}
            previewPosition="none"
            skinTonePosition="none"
            emojiSize={18}
            set="native"
            emojiButtonSize={32}
            maxFrequentRows={2}
            i18n={languageCode === 'ja' ? japaneseLanguage : englishLanguage}
          />
        </div>
      )}
    </div>
  )
}
