/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddStreamRequest,
  AddStreamResponse,
  ApiErrorResponse,
  GetStreamConfigResponse,
  GetStreamInfo,
} from '../models/index';
import {
    AddStreamRequestFromJSON,
    AddStreamRequestToJSON,
    AddStreamResponseFromJSON,
    AddStreamResponseToJSON,
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetStreamConfigResponseFromJSON,
    GetStreamConfigResponseToJSON,
    GetStreamInfoFromJSON,
    GetStreamInfoToJSON,
} from '../models/index';

export interface StreamPutRequest {
    addStreamRequest?: AddStreamRequest;
}

/**
 * 
 */
export class StreamApi extends runtime.BaseAPI {

    /**
     * Get an AWS IVS stream configuration for the user
     */
    async streamConfigGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStreamConfigResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/stream/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStreamConfigResponseFromJSON(jsonValue));
    }

    /**
     * Get an AWS IVS stream configuration for the user
     */
    async streamConfigGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStreamConfigResponse> {
        const response = await this.streamConfigGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reset an AWS IVS stream key
     */
    async streamConfigResetPutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStreamConfigResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/stream/config/reset`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStreamConfigResponseFromJSON(jsonValue));
    }

    /**
     * Reset an AWS IVS stream key
     */
    async streamConfigResetPut(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStreamConfigResponse> {
        const response = await this.streamConfigResetPutRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the current stream information of the logged in users channel.  If the user has a live stream, it will return the title and category id of that stream session.  If the user has a previous stream, it will return the title and category id of the last stream session.  If the user has no stream, it will return null values.
     */
    async streamGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStreamInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/stream`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStreamInfoFromJSON(jsonValue));
    }

    /**
     * Get the current stream information of the logged in users channel.  If the user has a live stream, it will return the title and category id of that stream session.  If the user has a previous stream, it will return the title and category id of the last stream session.  If the user has no stream, it will return null values.
     */
    async streamGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStreamInfo> {
        const response = await this.streamGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Add a new stream for the user
     */
    async streamPutRaw(requestParameters: StreamPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddStreamResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/stream`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddStreamRequestToJSON(requestParameters.addStreamRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddStreamResponseFromJSON(jsonValue));
    }

    /**
     * Add a new stream for the user
     */
    async streamPut(requestParameters: StreamPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddStreamResponse> {
        const response = await this.streamPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
