import { create, props } from '@stylexjs/stylex'
import { color, elementSizes, spacing } from '../../styles/token.stylex'
import { Link } from '../../components/Link'
import { fonts } from '../../styles/font-styles'
import { StyleGuideMenuItem } from './types'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100dvh - ${elementSizes.headerHeight})`,
    padding: spacing.normal,
    backgroundColor: color.codGray,
  },
  mobile: {
    height: `calc(100dvh - ${elementSizes.headerHeight})`,
    maxHeight: '100%',
    width: '100%',
  },
  desktop: {
    width: elementSizes.sidebarWidth,
    height: `calc(100dvh - ${elementSizes.headerHeight})`,
  },
  linkContent: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.extraSmall,
    paddingBottom: spacing.extraSmall,
  },
})

type StyleGuideMenuProps = {
  items: StyleGuideMenuItem[]
  variant: 'desktop' | 'mobile'
}

export const StyleGuideMenu: React.FC<StyleGuideMenuProps> = ({
  items,
  variant,
}) => {
  return (
    <nav {...props(styles.container, variant && styles[variant])}>
      {items.map(({ label, route }) => (
        <Link key={route} to={route}>
          <div {...props(styles.linkContent)}>
            <span {...props(fonts.largeRegular)}>{label}</span>
          </div>
        </Link>
      ))}
    </nav>
  )
}
