/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  GetChannelInfoResponse,
  GetChannelLoggedInUserResponse,
  GetChannelRecommendedResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetChannelInfoResponseFromJSON,
    GetChannelInfoResponseToJSON,
    GetChannelLoggedInUserResponseFromJSON,
    GetChannelLoggedInUserResponseToJSON,
    GetChannelRecommendedResponseFromJSON,
    GetChannelRecommendedResponseToJSON,
} from '../models/index';

export interface ChannelsSlugGetRequest {
    slug: string;
}

export interface ChannelsSlugMeGetRequest {
    slug: string;
}

/**
 * 
 */
export class ChannelsApi extends runtime.BaseAPI {

    /**
     * Retrieves the list of channels which are recommended.
     */
    async channelsRecommendedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelRecommendedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/recommended`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelRecommendedResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves the list of channels which are recommended.
     */
    async channelsRecommendedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelRecommendedResponse> {
        const response = await this.channelsRecommendedGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves information about a channel based on its slug.
     */
    async channelsSlugGetRaw(requestParameters: ChannelsSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelInfoResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling channelsSlugGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelInfoResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves information about a channel based on its slug.
     */
    async channelsSlugGet(requestParameters: ChannelsSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelInfoResponse> {
        const response = await this.channelsSlugGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves channel information specific to the logged-in user by the channel\'s slug.
     */
    async channelsSlugMeGetRaw(requestParameters: ChannelsSlugMeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelLoggedInUserResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling channelsSlugMeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channels/{slug}/me`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelLoggedInUserResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves channel information specific to the logged-in user by the channel\'s slug.
     */
    async channelsSlugMeGet(requestParameters: ChannelsSlugMeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelLoggedInUserResponse> {
        const response = await this.channelsSlugMeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
