import { create, props } from '@stylexjs/stylex'
import { spacing } from '../styles/token.stylex'
import { DisplayName } from './DisplayName'
import { Creator } from './types'
import { fonts } from '../styles/font-styles'
import { Avatar } from './avatars/Avatar'

const styles = create({
  creatorInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.extraSmall,
  },
  instaStory: {
    flexDirection: 'column',
  },
})

type CreatorInfoProps = Creator & {
  maxNameWidth?: string
  variant?: 'instaStory'
  font?: keyof typeof fonts
  imageBorder?: boolean
  isLive?: boolean
}

export const CreatorInfo: React.FC<CreatorInfoProps> = ({
  profilePictureUrl,
  fullName,
  isVerified,
  avatarImageSize,
  profileUrl,
  variant,
  font = 'smallMedium',
  imageBorder,
  isLive,
}) => (
  <div {...props(styles.creatorInfo, variant && styles.instaStory)}>
    <Avatar
      src={profilePictureUrl}
      alt={`${fullName}'s profile`}
      url={profileUrl}
      showBorder={imageBorder}
      isLive={isLive}
      imageVariant={avatarImageSize}
    />
    <DisplayName
      text={fullName}
      verification={isVerified ? 'simple' : undefined}
      font={font}
      url={profileUrl}
      variant={variant}
    />
  </div>
)
