import { create, props } from '@stylexjs/stylex'
import { Comments } from '../components/icons/Comments'
import { IconButton } from '../components/IconButton'
import { Button } from '../components/Button'
import useLanguage from '../translations/useLanguage'
import { Currency } from '../components/icons/Currency'
import { color, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { LikeIcon } from './LikeIcon'
import { formatCount } from './../components/utils'

const styles = create({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.normal,
  },
  leftIcons: {
    display: 'flex',
    gap: spacing.small,
  },
  like: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxSmall,
  },
  comment: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxSmall,
  },
  count: {
    color: color.postCardLikeCountTextColor,
  },
})

type PostActionsProps = {
  onComment?: () => void
  onTip?: () => void
  onLike?: () => void
  likesCount: number
  isLiked: boolean
  likeInProgress: boolean
  commentsCount: number
}

export const PostActions: React.FC<PostActionsProps> = ({
  onLike,
  onComment,
  onTip,
  likesCount,
  isLiked,
  likeInProgress,
  commentsCount,
}) => {
  const { t } = useLanguage()

  const likeIcon = () => <LikeIcon disabled={!onLike} isLiked={isLiked} />

  return (
    <div {...props(styles.actions)}>
      <div {...props(styles.leftIcons)}>
        <div {...props(styles.like)}>
          <IconButton
            onClick={onLike}
            disabled={!onLike || likeInProgress}
            icon={likeIcon}
            tooltip={
              isLiked ? t('feeds.channel.unlike') : t('feeds.channel.like')
            }
            variant="large"
          />
          {likesCount !== 0 && (
            <span {...props(styles.count, fonts.smallRegular)}>
              {formatCount(likesCount)}
            </span>
          )}
        </div>
        {onComment && (
          <div {...props(styles.comment)}>
            <IconButton
              onClick={onComment}
              disabled={!onComment}
              icon={Comments}
              variant="large"
              tooltip={t('post.comment.title')}
            />
            {commentsCount !== 0 && (
              <span {...props(styles.count, fonts.smallRegular)}>
                {formatCount(commentsCount)}
              </span>
            )}
          </div>
        )}
      </div>
      {onTip && (
        <Button onClick={onTip} disabled={!onTip} variant="transparent">
          {t('tip.sendTip.title')}
          <Currency fill={color.gold} width={'1.25rem'} height={'1.25rem'} />
        </Button>
      )}
    </div>
  )
}
