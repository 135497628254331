import { useState, useEffect } from 'react'
import {
  GetChannelInfoResponse,
  GetChannelLoggedInUserResponse,
} from '../client'
import useLanguage from '../translations/useLanguage'

export const useSubscriptionState = (
  channel: GetChannelInfoResponse,
  me?: GetChannelLoggedInUserResponse
) => {
  const [isSubscribed, setIsSubscribed] = useState(!!me?.subscription)
  const [formattedPrice, setFormattedPrice] = useState<string | undefined>()
  const { formatCurrency } = useLanguage()

  const subscriptionPrice = channel.subscriptionOptions.subscribePrice.amount
  const isFreeSubscription = subscriptionPrice === 0

  useEffect(() => {
    setIsSubscribed(!!me?.subscription)

    if (!isFreeSubscription) {
      setFormattedPrice(
        formatCurrency(subscriptionPrice, {
          currency: channel.subscriptionOptions.subscribePrice.currency,
        })
      )
    } else {
      setFormattedPrice(undefined)
    }
  }, [
    channel,
    me,
    formatCurrency,
    isFreeSubscription,
    subscriptionPrice,
    isSubscribed,
  ])

  return { isSubscribed, isFreeSubscription, formattedPrice }
}
