import { create, props } from '@stylexjs/stylex'
import React, { SVGProps } from 'react'
import { color, fontWeight } from '../../styles/token.stylex'

const styles = create({
  container: {
    position: 'relative',
    display: 'inline-block',
    width: '1.5rem',
    height: '1.5rem',
  },
  badge: {
    position: 'absolute',
    top: 4,
    right: 3,
    transform: 'translate(50%, -50%)',
    backgroundColor: color.primary,
    borderRadius: '50%',
    fontSize: '.6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '1.3rem',
    height: '1rem',
    padding: '0 .2rem',
    fontWeight: fontWeight.medium,
    color: color.white,
  },
})

type MailProps = SVGProps<SVGElement> & {
  notificationCount?: number
}

export const Mail: React.FC<MailProps> = ({
  notificationCount,
  stroke = 'currentColor',
  width = '1.5rem',
  height = '1.5rem',
}) => {
  const badgeContent =
    notificationCount && notificationCount > 99 ? '99+' : notificationCount

  return (
    <div {...props(styles.container)} style={{ width, height }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L10.2286 8L17.9999 2M19 4.19995V11.8C19 12.9201 19.0002 13.4802 18.7822 13.908C18.5905 14.2844 18.2841 14.5902 17.9078 14.782C17.48 15 16.9203 15 15.8002 15H4.2002C3.08009 15 2.51962 15 2.0918 14.782C1.71547 14.5902 1.40973 14.2844 1.21799 13.908C1 13.4802 1 12.9201 1 11.8V4.19995C1 3.07985 1 2.51986 1.21799 2.09204C1.40973 1.71572 1.71547 1.40973 2.0918 1.21799C2.51962 1 3.08009 1 4.2002 1H15.8002C16.9203 1 17.48 1 17.9078 1.21799C18.2841 1.40973 18.5905 1.71572 18.7822 2.09204C19.0002 2.51986 19 3.07985 19 4.19995Z"
          stroke={stroke}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {!!notificationCount && (
        <span {...props(styles.badge)}>{badgeContent}</span>
      )}
    </div>
  )
}
