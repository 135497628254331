import type { SVGProps } from 'react'

export const Bookmark: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.625 17.5L9.99942 14.375L4.375 17.5V3.125C4.375 2.95924 4.44085 2.80027 4.55806 2.68306C4.67527 2.56585 4.83424 2.5 5 2.5H15C15.1658 2.5 15.3247 2.56585 15.4419 2.68306C15.5592 2.80027 15.625 2.95924 15.625 3.125V17.5Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
