import React from 'react'
import { GetNotificationResponse } from '../client'
import { AvatarImage } from '../components/avatars/AvatarImage'
import { routes } from '../router/routes'
import { create, props } from '@stylexjs/stylex'
import useLanguage from '../translations/useLanguage'
import { DisplayName } from '../components/DisplayName'
import { TextLink } from '../components/TextLink'
import { fonts } from '../styles/font-styles'
import { color, spacing } from '../styles/token.stylex'
import { NotificationText } from './NotificationText'
import { responsiveStyles } from '../layout/navbar/styles'

type NotificationItemProps = {
  notification: GetNotificationResponse
}

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing.normal,
    borderBottom: `1px solid ${color.dustyGray}`,
    padding: {
      default: spacing.extraSmall,
      [largeScreen]: spacing.normal,
    },
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  displayName: {
    color: color.dustyGray,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxSmall,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      default: spacing.xxxSmall,
      [largeScreen]: spacing.xxSmall,
    },
  },
  date: {
    color: color.dustyGray,
  },
})

export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
}) => {
  const { formatFullDate } = useLanguage()

  const displayName = notification.initiator.displayName
  const slug = notification.initiator.slug
  const profileUrl = routes.channel.home(slug)

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.avatar)}>
        <div {...props(responsiveStyles.desktopFlex)}>
          <AvatarImage
            src={notification.initiator.profilePicture?.source}
            url={routes.channel.home(notification.initiator.slug)}
            alt={notification.initiator.displayName}
            variant="large"
          />
        </div>
        <div {...props(responsiveStyles.phoneFlex)}>
          <AvatarImage
            src={notification.initiator.profilePicture?.source}
            url={routes.channel.home(notification.initiator.slug)}
            alt={notification.initiator.displayName}
            variant="normal"
          />
        </div>
      </div>
      <div {...props(styles.infoContainer)}>
        <div {...props(styles.nameContainer)}>
          <DisplayName
            text={displayName}
            url={profileUrl}
            font="smallSemiBold"
            underline={false}
          />
          <div {...props(styles.displayName, fonts.extraSmallRegular)}>
            <TextLink to={profileUrl} variant="gray">
              {`@${slug}`}
            </TextLink>
          </div>
        </div>
        <div>
          <NotificationText notification={notification} />
        </div>
        <div {...props(fonts.extraSmallRegular, styles.date)}>
          {formatFullDate(notification.createdAt)}
        </div>
      </div>
    </div>
  )
}
