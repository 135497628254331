import { create, props } from '@stylexjs/stylex'
import { useApi } from '../../api/ApiContext'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { EditForm } from '../EditForm'
import { Input } from '../input/Input'
import { queryKeys } from '../../constants/query-keys'
import { color } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'
import { bankInfoValidator, removeSpacesFromIban } from './utils'
import useLanguage from '../../translations/useLanguage'
import LoadingIndicator from '../indicators/LoadingIndicator'
import { UndefinedDataErrorPanel } from '../../api/UndefinedDataErrorPanel'
import { useErrorNotification } from '../../translations/useErrorNotification'

const styles = create({
  container: {
    marginInline: 'auto',
    width: '100%',
    maxWidth: '888px',
  },
  helperText: {
    color: color.dustyGray,
  },
  contentContainer: {
    display: 'grid',
    gap: '1rem',
  },
})

export type BankInfoData = {
  iban: string
  bic: string
}

export const SmartBankInfo: React.FC = () => {
  const { t } = useLanguage()
  const { bankInfoApi } = useApi()
  const queryClient = useQueryClient()
  const [isEditing, setIsEditing] = useState(false)
  const ibanInputRef = useRef<HTMLInputElement>(null)
  const bicInputRef = useRef<HTMLInputElement>(null)
  const notifyError = useErrorNotification()

  const resetForm = () => {
    setIsEditing(false)
    reset()
    ibanInputRef.current?.blur()
    bicInputRef.current?.blur()
  }

  const onEdit = () => setIsEditing(true)

  const onFormEdit = () => {
    onEdit()
    ibanInputRef.current?.focus()
  }

  const { data: currentBankInfo, isLoading: isGetLoading } = useQuery({
    queryKey: queryKeys.bankInfo.default,
    queryFn: () => bankInfoApi.bankinfoGet(),
    onError: notifyError(() => toast.error(t('bank.currentInfoError')), false),
  })

  const { control, reset, handleSubmit, formState } = useForm<BankInfoData>({
    defaultValues: {
      iban: '',
      bic: '',
    },
    mode: 'onChange',
    resolver: (data) => bankInfoValidator(data, t),
  })

  useEffect(() => {
    if (currentBankInfo?.bankInfo) {
      reset({
        iban: currentBankInfo.bankInfo.iban,
        bic: currentBankInfo.bankInfo.bic,
      })
    }
  }, [currentBankInfo, reset])

  const { mutateAsync: updateBankInfo, isLoading: isUpdateLoading } =
    useMutation({
      mutationFn: (bankInfo: BankInfoData) =>
        bankInfoApi.bankinfoPut({
          updateBankInfoRequest: { bic: bankInfo.bic, iban: bankInfo.iban },
        }),
      onSuccess: (data) => {
        toast.success(t('bank.updateSuccess'))
        queryClient.setQueryData(queryKeys.bankInfo.default, data)
        if (data) {
          reset({
            iban: data.iban,
            bic: data.bic,
          })
        }
        setIsEditing(false)
      },
      onError: notifyError(() => toast.error(t('bank.updateFailed')), false),
    })

  const isLoading = isUpdateLoading || isGetLoading

  const onSubmit = handleSubmit(async ({ iban, bic }: BankInfoData) => {
    if (!formState.isValid) return
    const ibanNoSpaces = removeSpacesFromIban(iban)
    await updateBankInfo({ iban: ibanNoSpaces, bic })
  })

  if (isGetLoading) return <LoadingIndicator />

  if (!isGetLoading && !currentBankInfo) return <UndefinedDataErrorPanel />

  return (
    <EditForm
      canEdit
      canSave={formState.isDirty && formState.isValid && !isLoading}
      isEditing={isEditing}
      onCancel={resetForm}
      onSubmit={onSubmit}
      onEdit={onFormEdit}
      isLoading={isLoading}
    >
      <div {...props(styles.contentContainer)}>
        <Controller
          control={control}
          name="iban"
          render={({ field }) => (
            <Input
              placeholder={t('bank.ibanPlaceholder').toUpperCase()}
              ref={ibanInputRef}
              id={field.name}
              value={field.value}
              onChange={field.onChange}
              onClick={onEdit}
              onEscape={resetForm}
              error={formState.errors.iban?.message}
              label={t('bank.ibanLabel').toUpperCase()}
              onFocus={onEdit}
            />
          )}
        />
        <Controller
          control={control}
          name="bic"
          render={({ field }) => (
            <Input
              placeholder={t('bank.bicPlaceholder').toUpperCase()}
              ref={bicInputRef}
              id={field.name}
              value={field.value}
              onChange={field.onChange}
              onClick={onEdit}
              onEscape={resetForm}
              error={formState.errors.bic?.message}
              label={t('bank.bicLabel').toUpperCase()}
              onFocus={onEdit}
            />
          )}
        />
        <p {...props(styles.helperText, fonts.extraSmallRegular)}>
          {t('bank.helperText')}
        </p>
      </div>
    </EditForm>
  )
}

export default SmartBankInfo
