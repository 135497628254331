/* eslint-disable react/jsx-no-literals */
import { TextLink } from '../../../components/TextLink'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { create, props } from '@stylexjs/stylex'

const styles = create({
  container: {
    maxWidth: 'fit-content',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    gap: '50px',
  },
  mailContainer: {
    display: 'flex',
    gap: '20px',
  },
})

const Links = () => {
  return (
    <StyleGuidePageContainer title="Text links">
      <div {...props(styles.container)}>
        <StyleGuidePanel direction="column">
          <TextLink to="/style-guide">Text link</TextLink>
          <TextLink disabled>Disabled link</TextLink>
          <TextLink onClick={() => console.log('Clicked')}>
            Onclick link
          </TextLink>
          <TextLink>Disable without target</TextLink>
        </StyleGuidePanel>
      </div>
    </StyleGuidePageContainer>
  )
}

export default Links
