import React from 'react'
import { props } from '@stylexjs/stylex'
import { StreamFeedFilterBar } from './filter/StreamFeedFilterBar'
import { StreamCardPlaceholder } from './card/StreamCardPlaceholder'
import { StreamFeedFilterType } from './filter/useStreamFeedFilter'
import { STREAMS_PER_PAGE } from '../constants'
import { styles } from './StreamFeedPageStyles'

type Props = {
  selectedFilter: StreamFeedFilterType
  onSelect: (filter: StreamFeedFilterType) => void
}

export const StreamsFeedPagePlaceholder: React.FC<Props> = ({
  selectedFilter,
  onSelect,
}) => {
  return (
    <div {...props(styles.container)}>
      <div {...props(styles.filter)}>
        <StreamFeedFilterBar selected={selectedFilter} onSelect={onSelect} />
      </div>
      <StreamCardPlaceholder count={STREAMS_PER_PAGE} />
    </div>
  )
}
