import { toast } from 'react-toastify'
import { LanguageKey } from '../translations/useLanguage'

export async function fetchImageDimensions(images: { source: string }[]) {
  return Promise.all(
    images.map((i) => {
      return new Promise<{ source: string; width: number; height: number }>(
        (resolve, reject) => {
          const img = new Image()
          img.src = i.source
          img.onload = () =>
            resolve({
              source: i.source,
              width: img.naturalWidth,
              height: img.naturalHeight,
            })
          img.onerror = () =>
            reject(new Error(`Failed to load image: ${i.source}`))
        }
      )
    })
  )
}

export const handlePostLinkCopy = (
  text: string,
  t: (key: LanguageKey) => string
) => {
  navigator.clipboard.writeText(text).then(
    () =>
      toast.success(t('post.postCard.postCardActions.copyLinkToPost.message')),
    () => toast.error(t('post.postCard.postCardActions.copyLinkToPost.failed'))
  )
}
