import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { PropsWithChildren } from 'react'

const styles = create({
  container: {
    borderRadius: spacing.xxSmall,
    padding: `${spacing.xxSmall} ${spacing.small}`,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: color.tagTextColor,
    backgroundColor: color.tagBackgroundColor,
    gap: spacing.xxSmall,
    width: 'fit-content',
  },
  small: {
    padding: `${spacing.xxxSmall} ${spacing.extraSmall}`,
  },
  disabled: {
    color: color.tagDisabledTextColor,
  },
})

type CategoryTagProps = {
  variant?: 'small'
  font?: keyof typeof fonts
  disabled?: boolean
}

export const CategoryTag: React.FC<PropsWithChildren<CategoryTagProps>> = ({
  variant = 'small',
  font = 'smallRegular',
  children,
  disabled,
}) => (
  <div
    {...props(
      styles.container,
      fonts[font],
      variant && styles[variant],
      disabled && styles.disabled
    )}
  >
    {children}
  </div>
)
