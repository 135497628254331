import { useUserContext } from '../../auth/user-context'
import { getMenuData } from './menu-data'
import { Menu } from './Menu'
import useLanguage from '../../translations/useLanguage'

export const ProfileMenu: React.FC = () => {
  const { data } = useUserContext()
  const { languageCode } = useLanguage()

  const { menuItems, footerItems, profile } = getMenuData(
    data,
    languageCode,
    data?.notificationCount ?? 0
  )

  return (
    <div>
      <Menu
        items={menuItems}
        footerItems={footerItems}
        profile={profile}
        variant="profile"
      />
    </div>
  )
}

export default ProfileMenu
