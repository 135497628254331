import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import useLanguage from '../translations/useLanguage'

const styles = create({
  container: {
    backgroundColor: color.codGrayLight,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.large,
    padding: spacing.small,
  },
})

export const CreatorRequestedStatusCard: React.FC = () => {
  const { t } = useLanguage()
  return (
    <div {...props(styles.container)}>
      <div>{t('becomeACreator.requestedStatus.messageLine1')}</div>
      <div>{t('becomeACreator.requestedStatus.messageLine2')}</div>
    </div>
  )
}

export default CreatorRequestedStatusCard
