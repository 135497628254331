import { create } from '@stylexjs/stylex'
import { spacing, fontSize, color } from '../../styles/token.stylex'
import { PreparedPhotoSwipeOptions } from 'photoswipe'

const mediaHeightDesktop = '700px'
const mediaHeightMobile = '450px'
const largeScreen = '@media (min-width: 768px)'

export const styles = create({
  swiperContainer: {
    marginLeft: `calc(${spacing.normal} * -1) !important`,
    marginRight: `calc(${spacing.normal} * -1) !important`,
    marginBottom: `calc(${spacing.normal} * -1) !important`,
    maxHeight: {
      default: mediaHeightMobile,
      [largeScreen]: mediaHeightDesktop,
    },
  },
  slideContainer: {
    display: 'flex !important',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    height: 'auto !important',
  },
  image: {
    maxWidth: '100%',
    maxHeight: {
      default: mediaHeightMobile,
      [largeScreen]: mediaHeightDesktop,
    },
    objectFit: 'contain',
    display: 'block',
    placeItems: 'center',
    placeSelf: 'center',
  },
  fullScreenImageContainer: {
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  backgroundBlurImage: {
    filter: 'blur(70px)',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -1,
    minHeight: '100%',
    minWidth: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  videoContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
})

export const swiperStyleProps = {
  '--swiper-navigation-color': color.gold,
  '--swiper-navigation-size': fontSize.large,
  '--swiper-pagination-color': color.gold,
} as React.CSSProperties

export const swiperNavigationStyle = create({
  navigation: {
    ':not(#_id) .swiper-button-next': {
      display: {
        default: 'none !important',
        [largeScreen]: 'flex !important',
      },
    },
    ':not(#_id) .swiper-button-prev': {
      display: {
        default: 'none !important',
        [largeScreen]: 'flex !important',
      },
    },
    ':not(#_id) .swiper-button-next.swiper-button-lock': {
      display: {
        default: 'none !important',
        [largeScreen]: 'none !important',
      },
    },
    ':not(#_id) .swiper-button-prev.swiper-button-lock': {
      display: {
        default: 'none !important',
        [largeScreen]: 'none !important',
      },
    },
  },
})

export const PhotoSwipeOptions: Partial<PreparedPhotoSwipeOptions> = {
  showHideAnimationType: 'fade',
  // closeSVG: closeSvgMarkup,
  // arrowPrevSVG: arrowPrevSvgMarkup,
  bgOpacity: 0.9,
  zoom: false,
}
