import { create, props } from '@stylexjs/stylex'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { spacing } from '../../../styles/token.stylex'
import { PostCard } from '../../../post/PostCard'
import { Share } from '../../../components/icons/Share'
import { Report } from '../../../components/icons/Report'
import { PostCardSkeleton } from './PostCardSkeleton'
import { replaceLinksWithAnchors } from '../../../common/utils/LinkUtils'
import { SmartPostCard } from '../../../post/SmartPostCard'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.normal,
  },
  container: {
    width: 'fit-content',
    maxWidth: {
      default: 'calc(100vw)',
      [largeScreen]: '400px',
    },
  },
})

export const PostCards: React.FC = () => {
  const imagePostCard = (
    <StyleGuidePanel
      title="Image"
      direction="column"
      variant="noPaddingOnMobile"
      border="noBorderOnMobile"
    >
      <PostCard
        canComment={false}
        showComments={false}
        postId="1"
        postUrl="/post/1"
        creator={{
          profilePictureUrl:
            'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
          fullName: 'AmandaPink',
          isVerified: true,
          profileUrl: '/creator2',
          avatarImageSize: 'small',
        }}
        text={replaceLinksWithAnchors(
          '@[pekopeter999555](08dcd363-b9b0-439f-833d-4beee48e8da2) Nehany kep a forgatasrol https://spinstream-qa.io/BeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStreamBeeStream  Nehany kep a forgatasrolNehany kep a forgatasrolNehany kep a forgatasrol'
        )}
        publishedAt={new Date(Date.now() - 2 * 60 * 60 * 1000)}
        onLike={() => console.log('Liked!')}
        onComment={() => console.log('Commented!')}
        onTip={() => console.log('Sent Tip!')}
        isLiked={true}
        likesCount={245000}
        commentsCount={24}
        contextMenuItems={[
          {
            label: 'Share link',
            action: () => console.log('Share link'),
            icon: Share,
          },
          {
            label: 'Report this user',
            action: () => console.log('Report user'),
            icon: Report,
          },
        ]}
      >
        <img
          src="https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default"
          alt="Post"
        />
      </PostCard>
    </StyleGuidePanel>
  )

  const videoPostCard = (
    <StyleGuidePanel
      title="Video"
      direction="column"
      variant="noPaddingOnMobile"
      border="noBorderOnMobile"
    >
      <PostCard
        showComments={false}
        canComment={false}
        postId="1"
        postUrl="/post/1"
        creator={{
          profilePictureUrl:
            'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
          fullName: 'AmandaPink',
          isVerified: true,
          profileUrl: '/creator3',
          avatarImageSize: 'small',
        }}
        commentsCount={24}
        text={replaceLinksWithAnchors(
          'リンクをクリックしてください 天使のランジェリーで早めのハロウィーンのドレスアップをしながら https://spinstream-qa.io/u1969418381u1969418381'
        )}
        publishedAt={new Date(Date.now() - 2 * 60 * 60 * 1000)}
        onLike={() => console.log('Liked!')}
        onComment={() => console.log('Commented!')}
        onTip={() => console.log('Sent Tip!')}
        likesCount={245000}
        contextMenuItems={[
          {
            label: 'Share link',
            action: () => console.log('Share link'),
            icon: Share,
          },
          {
            label: 'Report this user',
            action: () => console.log('Report user'),
            icon: Report,
          },
        ]}
      >
        <img
          src="https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/fb38dbf9-eacd-4df6-fc75-8166d7051200/default"
          alt="Post"
        />
      </PostCard>
    </StyleGuidePanel>
  )

  const unlockButtonPostCard = (
    <StyleGuidePanel
      title="Unlock button"
      direction="column"
      variant="noPaddingOnMobile"
      border="noBorderOnMobile"
    >
      <PostCard
        showComments
        canComment={false}
        postId="1"
        postUrl="/post/1"
        commentsCount={24}
        creator={{
          profilePictureUrl:
            'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
          fullName: 'AmandaPink',
          isVerified: true,
          profileUrl: '/creator4',
          avatarImageSize: 'small',
        }}
        text={replaceLinksWithAnchors(
          'みなさん、こんにちは！🎉 とてもワクワクすることをシェアしたくて投稿しました。数ヶ月にわたる努力、学び、自分の限界への挑戦を経て、ついに私の旅路で大きな節目を迎えることができました！🚀 完璧なアプリをコーディングしたり、理想の体を作り上げたり、カメラを通して世界を捉えたりすることなど、どんなことでも、情熱と継続があれば何でも可能です。💪📸💻'
        )}
        publishedAt={new Date(Date.now() - 2 * 60 * 60 * 1000)}
        onLike={() => console.log('Liked!')}
        onComment={() => console.log('Commented!')}
        onTip={() => console.log('Sent Tip!')}
        likesCount={245000}
        unlockable={{
          price: {
            amount: 9.99,
            currency: 'USD',
          },
          isPaid: false,
          payment: undefined,
          onUnlock: () => console.log('Unlocked Content!'),
        }}
        contextMenuItems={[
          {
            label: 'Share link',
            action: () => console.log('Share link'),
            icon: Share,
          },
          {
            label: 'Report this user',
            action: () => console.log('Report user'),
            icon: Report,
          },
        ]}
      >
        <img
          src="https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/c772a7db-bfb8-4f32-ed65-f07550017f00/default"
          alt="Post"
        />
      </PostCard>
    </StyleGuidePanel>
  )

  const skeletonPostCard = <PostCardSkeleton />

  const postCardSwiper = (
    <StyleGuidePanel
      title="Swiper"
      direction="column"
      variant="noPaddingOnMobile"
      border="noBorderOnMobile"
    >
      <SmartPostCard
        post={{
          postId: '1',
          text: "Playing some early Hallowe'en dressup with my angel lingerie while behaving not at all like an angel 😈",
          publishedAt: new Date(Date.now() - 2 * 60 * 60 * 1000),
          likeCount: 42,
          isLiked: false,
          mediaCount: 2,
          canComment: true,
          canLike: false,
          videosCount: 0,
          imagesCount: 4,
          commentsCount: 0,
          isLocked: false,
          purchase: {
            price: { amount: 5, currency: 'USD' },
            purchase: { amount: 5, currency: 'USD' },
            isPaid: true,
          },
          author: {
            id: 1,
            name: 'AmandaPink',
            slug: 'amandapink',
            profilePicture: {
              source:
                'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
              variants: [
                {
                  name: 'privatetestattila',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/c13aed2d-8bf8-481e-781e-f9cb4f062200/privatetestattila',
                  width: 0,
                  format: 'webp',
                },
                {
                  name: 'default',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/c13aed2d-8bf8-481e-781e-f9cb4f062200/default',
                  width: 0,
                  format: 'webp',
                },
              ],
              srcset:
                'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/c13aed2d-8bf8-481e-781e-f9cb4f062200/privatetestattila 0w, https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/c13aed2d-8bf8-481e-781e-f9cb4f062200/default 0w',
            },
            isVerified: true,
            isLive: false,
          },
          images: [
            {
              order: 1,
              isLocked: true,
              source: '../images/sample/girl2.png',
              variants: [
                {
                  name: 'privatetestattila',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/privatetestattila',
                  width: 0,
                  format: 'webp',
                },
                {
                  name: 'default',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/default',
                  width: 0,
                  format: 'webp',
                },
              ],
              srcset:
                'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/privatetestattila 0w, https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/default 0w',
            },
            {
              order: 2,
              isLocked: true,
              source: '../images/sample/sunset.jpg',
              variants: [
                {
                  name: 'privatetestattila',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/privatetestattila',
                  width: 0,
                  format: 'webp',
                },
                {
                  name: 'default',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/default',
                  width: 0,
                  format: 'webp',
                },
              ],
              srcset:
                'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/privatetestattila 0w, https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/default 0w',
            },
            {
              order: 3,
              isLocked: true,
              source: '../images/sample/yellows.jpg',
              variants: [
                {
                  name: 'privatetestattila',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/privatetestattila',
                  width: 0,
                  format: 'webp',
                },
                {
                  name: 'default',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/default',
                  width: 0,
                  format: 'webp',
                },
              ],
              srcset:
                'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/privatetestattila 0w, https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/default 0w',
            },
            {
              order: 4,
              isLocked: true,
              source: '../images/sample/girl.png',
              variants: [
                {
                  name: 'privatetestattila',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/privatetestattila',
                  width: 0,
                  format: 'webp',
                },
                {
                  name: 'default',
                  url: 'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/default',
                  width: 0,
                  format: 'webp',
                },
              ],
              srcset:
                'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/privatetestattila 0w, https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/acb4eaf6-cd8d-4d48-7478-4343f2b58a00/default 0w',
            },
          ],
          tipConfiguration: {
            maxAmount: 100,
            minAmount: 1,
          },
        }}
        isPostCardsRestricted={false}
      />
    </StyleGuidePanel>
  )

  return (
    <StyleGuidePageContainer title="Post Cards" variant="noPaddingOnMobile">
      <div {...props(styles.mainContainer)}>
        <div {...props(styles.container)}>{imagePostCard}</div>
        <div {...props(styles.container)}>{videoPostCard}</div>
        <div {...props(styles.container)}>{unlockButtonPostCard}</div>
        <div {...props(styles.container)}>{skeletonPostCard}</div>
        <div {...props(styles.container)}>{postCardSwiper}</div>
      </div>
    </StyleGuidePageContainer>
  )
}
