import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { spacing } from '../../styles/token.stylex'
import { TagButton } from '../../components/TagButton'
import useLanguage from '../../translations/useLanguage'
import { StreamFeedFilterType } from './useStreamFeedFilter'

const styles = create({
  container: {
    display: 'flex',
    gap: spacing.xSmall,
    overflowY: 'auto',
    paddingBottom: spacing.extraSmall,
    '::-webkit-scrollbar': { height: '5px' },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
})

type StreamFeedFilterBarProps = {
  selected: StreamFeedFilterType
  onSelect: (filter: StreamFeedFilterType) => void
}

export const StreamFeedFilterBar: React.FC<StreamFeedFilterBarProps> = ({
  selected,
  onSelect,
}) => {
  const { t } = useLanguage()

  const filters: StreamFeedFilterType[] = [
    'all',
    'live',
    'slotsAndCasino',
    'justChatting',
  ]

  return (
    <div {...props(styles.container)}>
      {filters.map((filter) => (
        <TagButton
          key={filter}
          isActive={selected === filter}
          onClick={() => onSelect(filter)}
        >
          {t(`feeds.filters.${filter}`)}
        </TagButton>
      ))}
    </div>
  )
}
