import { create, props } from '@stylexjs/stylex'
import { color, fontWeight, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { MouseEventHandler, PropsWithChildren } from 'react'
import { CheckedCircle } from './icons/CheckedCircle'
import { UncheckedCircle } from './icons/UncheckedCircle'

const styles = create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    padding: `${spacing.xSmall} ${spacing.small}`,
    borderRadius: spacing.extraSmall,
    cursor: 'pointer',
    gap: spacing.xxSmall,
    minWidth: 'fit-content',
    backgroundColor: color.tagButtonBackgroundColor,
    color: {
      default: color.tagButtonTextColor,
      ':hover': color.tagButtonHoverTextColor,
    },
  },
  active: {
    fontWeight: fontWeight.semiBold,
    color: color.tagButtonActiveTextColor,
  },
  selectable: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.small,
  },
})

type TagButtonProps = {
  variant?: 'selectable'
  isActive?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const TagButton: React.FC<PropsWithChildren<TagButtonProps>> = ({
  variant,
  isActive,
  disabled,
  onClick,
  children,
}) => (
  <button
    {...props(
      styles.container,
      variant === 'selectable' && styles.selectable,
      isActive && styles.active,
      isActive ? fonts.smallSemiBold : fonts.smallRegular
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {variant === 'selectable' &&
      (isActive ? (
        <CheckedCircle width="1rem" height="1rem" primary={color.gold} />
      ) : (
        <UncheckedCircle width="1rem" height="1rem" stroke={color.gold} />
      ))}
    {children}
  </button>
)
