/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CreatorOnboardingStatus = {
    RequirementsNotMet: 'requirementsNotMet',
    RequirementsFulfilled: 'requirementsFulfilled',
    Requested: 'requested',
    Approved: 'approved',
    Denied: 'denied'
} as const;
export type CreatorOnboardingStatus = typeof CreatorOnboardingStatus[keyof typeof CreatorOnboardingStatus];


export function CreatorOnboardingStatusFromJSON(json: any): CreatorOnboardingStatus {
    return CreatorOnboardingStatusFromJSONTyped(json, false);
}

export function CreatorOnboardingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatorOnboardingStatus {
    return json as CreatorOnboardingStatus;
}

export function CreatorOnboardingStatusToJSON(value?: CreatorOnboardingStatus | null): any {
    return value as any;
}

