/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

/**
 * 
 */
export class OnboardingApi extends runtime.BaseAPI {

    /**
     * Logs the request of the user to become a creator.  Creates a wallet for the user.
     */
    async onboardingBecomeCreatorPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/onboarding/become-creator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logs the request of the user to become a creator.  Creates a wallet for the user.
     */
    async onboardingBecomeCreatorPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.onboardingBecomeCreatorPostRaw(initOverrides);
    }

    /**
     */
    async onboardingTestBecomeCreatorPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/onboarding/test/become-creator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async onboardingTestBecomeCreatorPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.onboardingTestBecomeCreatorPostRaw(initOverrides);
    }

    /**
     */
    async onboardingTestSampleProtectedEndpointForCreatorsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/onboarding/test/sample-protected-endpoint-for-creators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async onboardingTestSampleProtectedEndpointForCreatorsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.onboardingTestSampleProtectedEndpointForCreatorsGetRaw(initOverrides);
    }

    /**
     */
    async onboardingTestUserpermissionsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/onboarding/test/userpermissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async onboardingTestUserpermissionsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.onboardingTestUserpermissionsGetRaw(initOverrides);
    }

}
