import { create, props } from '@stylexjs/stylex'
import useLanguage, { LanguageKey } from '../translations/useLanguage'
import { color, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { TextLink } from './TextLink'

type CategoryProps = {
  category: string
  color?: string
  font?: keyof typeof fonts
  url?: string
}

const styles = create({
  container: {
    display: 'flex',
    gap: spacing.extraSmall,
    alignItems: 'center',
  },
  customColor: (color: string) => ({ color: color }),
})

export const Category: React.FC<CategoryProps> = ({
  category,
  color: textColor = color.dustyGray,
  font = 'extraSmallRegular',
  url,
}) => {
  const { t } = useLanguage()

  return (
    <div {...props(styles.container)}>
      <span {...props(fonts[font], styles.customColor(textColor))}>
        {url ? (
          <TextLink to={url}>
            {t(`categories.${category}` as LanguageKey)}
          </TextLink>
        ) : (
          t(`categories.${category}` as LanguageKey)
        )}
      </span>
    </div>
  )
}
