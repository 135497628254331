import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import useLanguage from '../translations/useLanguage'
import { Panel } from '../components/Panel'
import { Button } from '../components/Button'
import { queryKeys } from '../constants/query-keys'
import { useApi } from '../api/ApiContext'
import { useInfiniteQuery } from 'react-query'
import {
  GetDepositHistoryResponse,
  GetDepositHistoryResponsePagedApiResponse,
} from '../client'
import { useErrorNotification } from '../translations/useErrorNotification'
import { toast } from 'react-toastify'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { PaymentHistoryRowItem } from './PaymentHistoryRowItem'
import { PageHeader } from '../layout/PageHeader'

const PAYMENTS_PER_PAGE = 10
const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xSmall,
    backgroundColor: color.codGrayLight,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: {
      default: spacing.xxxSmall,
      [largeScreen]: spacing.xSmall,
    },
    paddingBlock: spacing.xxxSmall,
    backgroundColor: color.codGrayLight,
    gap: spacing.normal,
  },
  header: {
    color: color.dustyGray,
    paddingBlock: spacing.xSmall,
  },
  wrapper: {
    width: '100%',
    maxWidth: '888px',
    marginInline: 'auto',
  },
  orderId: {
    color: color.dustyGray,
  },
  divider: {
    borderBottom: `1px solid ${color.dustyGray}`,
    paddingBottom: '0.5rem',
  },
  buttonContainer: {
    marginTop: '2rem',
    textAlign: 'center',
    ':not(#_id) button': {
      width: '100%',
    },
  },
  emptyList: {
    padding: '1rem',
    textAlign: 'center',
  },
})

export const PaymentHistoryPage: React.FC = () => {
  const { t } = useLanguage()
  const { paymentsApi } = useApi()
  const notifyError = useErrorNotification()

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery<GetDepositHistoryResponsePagedApiResponse, Error>({
      queryKey: queryKeys.paymentHistory.default,
      queryFn: async ({ pageParam = 1 }) => {
        return paymentsApi.paymentsTransactionsGet({
          pageNumber: pageParam,
          pageSize: PAYMENTS_PER_PAGE,
        })
      },
      getNextPageParam: (lastPage) =>
        (lastPage.pageNumber ?? 1) < (lastPage.totalPages ?? 1)
          ? (lastPage.pageNumber ?? 1) + 1
          : undefined,
      onError: notifyError(() => toast.error(t('paymentHistory.error'))),
    })

  if (!isLoading && data === undefined) {
    throw new Error('Payment history data is undefined')
  }

  const payments: GetDepositHistoryResponse[] = data
    ? data.pages.flatMap((page) => page.data)
    : []

  return (
    <div {...props(styles.wrapper)}>
      <PageHeader>{t('paymentHistory.title').toUpperCase()}</PageHeader>
      <Panel>
        {!payments.length && !isLoading && (
          <div {...props(styles.emptyList)}>
            {t('paymentHistory.noPayments')}
          </div>
        )}
        {isLoading && <LoadingScreen />}
        {payments.length !== 0 && (
          <div {...props(styles.container, fonts.smallRegular)}>
            {/* header */}
            <div {...props(styles.row)}>
              <div {...props(styles.header, fonts.smallMedium)}>
                {t('paymentHistory.date').toUpperCase()}
              </div>
              <div {...props(styles.header, fonts.smallMedium)}>
                {t('paymentHistory.amount').toUpperCase()}
              </div>
            </div>
            {/* rows */}
            {payments.map((row, rowIndex) => (
              <PaymentHistoryRowItem key={rowIndex} row={row} />
            ))}

            {hasNextPage && (
              <div {...props(styles.buttonContainer)}>
                <Button
                  onClick={() => fetchNextPage()}
                  disabled={isFetchingNextPage}
                  isLoading={isFetchingNextPage}
                >
                  {isFetchingNextPage
                    ? t('shared.buttons.loading')
                    : t('shared.buttons.showMore')}
                </Button>
              </div>
            )}
          </div>
        )}
      </Panel>
    </div>
  )
}

export default PaymentHistoryPage
