import { useRef, useState, useEffect } from 'react'
import useLanguage from '../translations/useLanguage'
import DefaultBannerUrl from '../assets/banner/banner.webp?url'
import { useMutation } from 'react-query'
import { useApi } from '../api/ApiContext'
import { toast } from 'react-toastify'
import {
  allowedImageTypes,
  FileValidationOptions,
  useFileValidator,
} from '../common/utils/useFileValidator'
import { Button } from '../components/Button'
import { create, props } from '@stylexjs/stylex'
import { IconButton } from '../components/IconButton'
import { Close } from '../components/icons/Close'
import { color } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { GetChannelInfoResponse } from '../client'
import { LoadingIndicator } from '../components/indicators/LoadingIndicator'

const styles = create({
  fileInput: {
    display: 'none',
  },
  wrapper: {
    display: 'grid',
    gap: '2rem',
    justifyItems: 'center',
    position: 'relative',
  },
  banner: {
    width: '100%',
    height: '266px',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    position: 'relative',
  },
  customBackground: (url: string) => ({
    backgroundImage: `url(${url})`,
  }),
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '266px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.4)',
    pointerEvents: 'none',
    zIndex: 2,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1.236rem',
  },
  buttonGroup: {
    display: 'flex',
    gap: '0.5rem',
  },
  instruction: {
    color: color.dustyGray,
    paddingInline: '1rem',
    textAlign: 'center',
  },
})

const fileValidationOptions: FileValidationOptions = {
  maxSizeMB: 4,
  minWidth: 1280,
  minHeight: 720,
  maxWidth: 3840,
  maxHeight: 2160,
}

type BannerImageProps = {
  channel: GetChannelInfoResponse
  isLoading?: boolean
  reloadChannel: () => void
}

export const BannerImage: React.FC<BannerImageProps> = ({
  channel,
  reloadChannel,
  isLoading,
}) => {
  const { validateFile } = useFileValidator()
  const { t } = useLanguage()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { userApi } = useApi()

  const officialBannerUrl = channel?.bannerPicture?.source || DefaultBannerUrl

  const [displayedBannerUrl, setDisplayedBannerUrl] =
    useState(officialBannerUrl)
  const [loadingNewBanner, setLoadingNewBanner] = useState(false)

  useEffect(() => {
    if (officialBannerUrl !== displayedBannerUrl) {
      setLoadingNewBanner(true)
      const tempImage = new Image()
      tempImage.onload = () => {
        setDisplayedBannerUrl(officialBannerUrl)
        setLoadingNewBanner(false)
      }
      tempImage.onerror = () => {
        setDisplayedBannerUrl(DefaultBannerUrl)
        setLoadingNewBanner(false)
      }
      tempImage.src = officialBannerUrl
    }
  }, [officialBannerUrl, displayedBannerUrl])

  const { mutate: uploadBanner, isLoading: isUploading } = useMutation({
    mutationFn: async (file: File) => {
      const result = await validateFile(file, fileValidationOptions)
      if (!result) {
        throw new Error('Invalid file')
      }
      await userApi.userUploadChannelBannerpicturePut({
        image: file,
      })
    },
    onSuccess: () => {
      reloadChannel()
      toast.success(t('myChannel.bannerImage.uploadSuccessMessage'))
    },
    onError: (error: Error) => {
      if (error.message !== 'Invalid file') {
        toast.error(t('myChannel.bannerImage.uploadErrorMessage'))
      }
    },
  })

  const { mutate: removeBanner, isLoading: isRemoving } = useMutation({
    mutationFn: async () => {
      await userApi.userUploadChannelBannerpicturePut({
        image: undefined,
      })
    },
    onSuccess: () => {
      reloadChannel()
      toast.success(t('myChannel.bannerImage.removeSuccessMessage'))
    },
    onError: () => {
      toast.error(t('myChannel.bannerImage.removeErrorMessage'))
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) {
      toast.error(t('myChannel.bannerImage.uploadErrorMessage'))
      return
    }
    uploadBanner(file)
  }

  const handleRemoveBanner = (event: React.MouseEvent) => {
    event.stopPropagation()
    removeBanner()
  }

  const showOverlay = isLoading || isUploading || isRemoving || loadingNewBanner

  return (
    <div {...props(styles.wrapper)}>
      <div
        {...props(styles.banner, styles.customBackground(displayedBannerUrl))}
        onClick={() => fileInputRef.current?.click()}
      >
        {channel?.bannerPicture && (
          <div>
            <IconButton
              isLoading={isRemoving}
              onClick={handleRemoveBanner}
              variant="large"
              tooltip={t('shared.buttons.remove')}
              icon={Close}
              background="transparent"
              color="white"
              disabled={showOverlay}
            />
          </div>
        )}

        {showOverlay && (
          <div {...props(styles.overlay)}>
            <LoadingIndicator height={'266px'} />
          </div>
        )}
      </div>

      <input
        type="file"
        accept={allowedImageTypes.join(', ')}
        ref={fileInputRef}
        onChange={handleFileChange}
        {...props(styles.fileInput)}
      />

      <div {...props(styles.footer)}>
        <div {...props(styles.buttonGroup)}>
          <Button
            disabled={showOverlay}
            onClick={() => fileInputRef.current?.click()}
          >
            {t('myChannel.bannerImage.editButtonLabel')}
          </Button>
        </div>

        <div {...props(styles.instruction, fonts.extraSmallRegular)}>
          {t('myChannel.bannerImage.requirements')}
        </div>
      </div>
    </div>
  )
}
