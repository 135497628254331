import React, { useMemo, useState } from 'react'
import { useApi } from '../../api/ApiContext'
import { useMutation, useQuery } from 'react-query'
import { useForm, Controller } from 'react-hook-form'
import { queryKeys } from '../../constants/query-keys'
import { Input } from '../../components/input/Input'
import { ReactSelect } from '../../components/input/Select'
import { Button } from '../../components/Button'
import { props } from '@stylexjs/stylex'
import { FullCreatorInfo } from '../../components/FullCreatorInfo'
import useLanguage, {
  SUPPORTED_LANGUAGES,
} from '../../translations/useLanguage'
import {
  DepositRequest,
  DepositResponse,
  PaymentsPayPostRequest,
  PaymentType,
  TipSource,
} from '../../client'
import {
  computeVatDetails,
  getDefaultPaymentMethod,
  getPaymentMethodOptions,
  handleKeyDown,
  sanitizeInput,
  tipValidator,
} from '../tip/utils'
import { styles } from './TipStyles'
import { Postfix } from './Postfix'
import { LoadingIndicator } from '../../components/indicators/LoadingIndicator'
import { FullCreator } from '../../components/types'
import { TipModalSkeleton } from './TipModalSkeleton'

type BaseTipProps = {
  tipSource: TipSource
  recipient: FullCreator & { userId: number }
  minimumPrice: number
  maximumPrice: number
  isOpen: boolean
}

type PostTipProps = BaseTipProps & {
  tipSource: typeof TipSource.Post
  postId: string
}

type ProfileTipProps = BaseTipProps & {
  tipSource: typeof TipSource.Profile
  channelId: string
}

type TipModalContentProps = PostTipProps | ProfileTipProps

type TipFormValues = {
  price: string
  paymentMethod: string
  comment?: string
}

export const TipModalContent: React.FC<TipModalContentProps> = ({
  tipSource,
  recipient,
  minimumPrice,
  maximumPrice,
  isOpen,
  ...restProps
}) => {
  const { t, formatCurrency, languageCode } = useLanguage()
  const { paymentsApi } = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const { control, handleSubmit, watch, setValue, formState } =
    useForm<TipFormValues>({
      defaultValues: {
        price: minimumPrice.toString(),
        paymentMethod: '',
        comment: '',
      },
      mode: 'onChange',
      resolver: (data) =>
        tipValidator(data, t, formatCurrency, minimumPrice, maximumPrice),
    })

  const { mutateAsync } = useMutation<
    DepositResponse,
    Error,
    PaymentsPayPostRequest
  >({
    mutationFn: async (requestParameters: PaymentsPayPostRequest) => {
      return paymentsApi.paymentsPayPost(requestParameters)
    },
  })

  const priceValue = watch('price')

  const { data, isFetching } = useQuery({
    queryKey: queryKeys.payments.vat(priceValue),
    queryFn: () =>
      paymentsApi.paymentsMethodsVatGet({
        type: PaymentType.Tip,
        price: Math.round(Number(priceValue)),
      }),
    enabled: isOpen,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onSuccess: (fetchedData) => {
      if (fetchedData.paymentMethods?.length && !watch('paymentMethod')) {
        const defaultMethod = getDefaultPaymentMethod(fetchedData)
        setValue('paymentMethod', defaultMethod, { shouldValidate: true })
      }
    },
    onSettled: () => {
      setIsInitialLoading(false)
    },
  })

  const { vatAmount, totalAmount } = useMemo(() => {
    return computeVatDetails(data, watch('paymentMethod'), Number(priceValue))
  }, [data, priceValue, watch])

  const onSubmit = async (formData: TipFormValues) => {
    setIsLoading(true)
    try {
      const language = SUPPORTED_LANGUAGES.find(
        (lang) => lang.code === languageCode
      )

      if (!language) {
        console.error('Language not found:', languageCode)
        setIsLoading(false)
        return
      }

      const requestBody = {
        language: language?.cultureCode,
        depositMethod: formData.paymentMethod,
        redirectUrl: window.location.href,
        amount: Number(formData.price),
        currency: 'JPY',
        paymentType: PaymentType.Tip,
        text: formData.comment,
        recipientUserId: recipient.userId,
        tipSource,
        ...(tipSource === TipSource.Post && {
          postId: (restProps as PostTipProps).postId,
        }),
      } as DepositRequest

      const response = await mutateAsync({ depositRequest: requestBody })

      const paymentUrl = response?.paymentPageSession?.paymentPageUrl
      if (!paymentUrl) {
        console.error('Payment URL not found in response.')
        setIsLoading(false)
        return
      }

      window.location.href = paymentUrl
    } catch (error) {
      console.error('Payment submission failed:', error)
      setIsLoading(false)
    }
  }

  const paymentMethodOptions = data
    ? getPaymentMethodOptions(data.paymentMethods, watch('paymentMethod'), t)
    : []

  if (isInitialLoading && isFetching) {
    return <TipModalSkeleton />
  }

  return (
    <div {...props(styles.container)}>
      <FullCreatorInfo
        slug={recipient.slug}
        fullName={recipient.fullName}
        isVerified={recipient.isVerified}
        profilePictureUrl={recipient.profilePictureUrl}
      />
      <form onSubmit={handleSubmit(onSubmit)} {...props(styles.formContainer)}>
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <Input
              required
              prefix="¥"
              placeholder={t('tip.sendTip.title')}
              {...field}
              type="number"
              error={formState.errors.price?.message}
              label={t('tip.sendTip.title')}
              postfix={
                <Postfix
                  formState={formState}
                  isFetching={isFetching}
                  data={data}
                  vatAmount={vatAmount}
                  totalAmount={totalAmount}
                />
              }
              onChange={(e) =>
                field.onChange(sanitizeInput(e.target.value, 10))
              }
              onKeyDown={handleKeyDown}
            />
          )}
        />
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...field}
              required
              id="payment-method-select"
              label={t('tip.paymentMethodLabel')}
              placeholder={t('tip.paymentMethodPlaceholder')}
              options={paymentMethodOptions}
              value={field.value}
            />
          )}
        />
        <Controller
          name="comment"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              label={t('tip.commentLabel')}
              placeholder={t('tip.commentPlaceholder')}
              {...field}
              value={field.value ?? ''}
              error={fieldState.error?.message}
              maxLength={100}
            />
          )}
        />
        <div {...props(styles.actionsContainer)}>
          <Button
            type="submit"
            variant="cta"
            disabled={!formState.isValid || isFetching}
          >
            {t('tip.sendTip.title')}
          </Button>
        </div>
      </form>
      <div {...props(!isLoading ? styles.hidden : styles.loading)}>
        <LoadingIndicator width={40} height={40} />
      </div>
    </div>
  )
}
