import { create, props } from '@stylexjs/stylex'
import { ContentType, GetChannelInfoResponse } from '../../client'
import { color, spacing } from '../../styles/token.stylex'
import { useApi } from '../../api/ApiContext'
import { useQuery } from 'react-query'
import { queryKeys } from '../../constants/query-keys'
import { Skeleton } from '../Skeleton'
import { Navigate } from 'react-router-dom'
import { routes } from '../../router/routes'
import { mapFeedItemToCardProps } from '../../mappers/streamFeedMapper'
import { IVideoPlayerProps, VideoPlayer } from '../player/VideoPlayer'
import { Avatar } from '../avatars/Avatar'
import ViewerCount from '../ViewerCount'
import { fonts } from '../../styles/font-styles'
import { DisplayName } from '../DisplayName'
import { Category } from '../Category'
import SmartBannerSlider from '../../channel/banners/SmartBannerSlider'
import CommentCard from '../../post/comment/CommentCard'
import { mockedChatMessages } from './mocks/mockChatMessages'
import useLanguage from '../../translations/useLanguage'

type VideoPageProps = {
  contentId: string
  channel: GetChannelInfoResponse
}

const largeScreen = '@media (min-width: 1200px)'

const styles = create({
  container: {
    width: '100%',
    padding: {
      default: null,
      [largeScreen]: spacing.extraLarge,
    },
    maxHeight: {
      default: `calc(100vh - 120px)`,
      [largeScreen]: 'unset',
    },
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  chatContainer: {
    visibility: 'hidden', // remove when chat is implemented
    position: {
      default: 'static',
      [largeScreen]: 'fixed',
    },
    right: 0,
    top: 60,
    height: 'calc(100vh - 60px)',
    width: {
      default: '100%',
      [largeScreen]: 340,
    },
    backgroundColor: color.codGray,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    borderLeft: `1px solid ${color.mineShaft}`,
    padding: spacing.normal,
    gap: spacing.normal,
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  contentContainer: {
    flex: 1,
    overflowY: 'auto',
    minHeight: 'max-content',
    marginRight: {
      default: '0',
      [largeScreen]: 308,
    },
    overflow: 'hidden',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  skeleton: {
    width: '100%',
    height: '100%',
  },
  viewerCount: {
    paddingTop: spacing.normal,
    flex: 1,
    justifyItems: 'self-end',
  },
  finishedAt: {
    paddingTop: spacing.normal,
    flex: 1,
    textAlign: 'end',
    minWidth: 'fit-content',
  },
  footer: {
    display: 'flex',
    gap: spacing.extraSmall,
    paddingLeft: spacing.normal,
    paddingRight: spacing.normal,
    paddingTop: spacing.normal,
    paddingBottom: 0,
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },
  creator: {
    cursor: 'pointer',
    height: 'fit-content',
    transition: 'transform 0.3s ease',
    transform: {
      default: 'scale(1)',
      ':hover': 'scale(1.05)',
    },
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: spacing.large,
  },
})

export const VideoPage: React.FC<VideoPageProps> = ({ contentId, channel }) => {
  const { streamFeedApi } = useApi()
  const { formatRelativeDate } = useLanguage()

  const {
    data: streamFeedItem,
    isLoading,
    isError,
  } = useQuery({
    queryKey: queryKeys.videos.single(contentId),
    queryFn: () =>
      streamFeedApi.streamfeedContentIdGet({
        contentId,
      }),
    enabled: !!contentId,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const { data: viewerCountData } = useQuery({
    queryKey: queryKeys.stream.viewerCount(contentId),
    queryFn: () =>
      streamFeedApi.streamfeedStreamSessionIdViewerCountGet({
        streamSessionId: contentId,
      }),
    enabled: streamFeedItem?.type === ContentType.LiveStream && !!contentId,
    retry: false,
    refetchInterval: 60000,
  })

  if (isLoading)
    return (
      <div {...props(styles.container)}>
        <Skeleton type="image" style={styles.skeleton} />
      </div>
    )
  if (!streamFeedItem || isError)
    return <Navigate to={routes.notFound} replace />

  const mapped = mapFeedItemToCardProps(streamFeedItem)
  if (!mapped) return <Navigate to={routes.notFound} replace />

  const videoJsOptions: IVideoPlayerProps = {
    options: {
      sources: [{ src: mapped.playbackUrl }],
      poster: mapped.thumbnailUrl,
      autoplay: true,
    },
    isLiveStream: mapped.type === ContentType.LiveStream,
  }

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.contentContainer)}>
        <div>
          <VideoPlayer {...videoJsOptions} />
        </div>
        <div {...props(styles.footer)}>
          <div {...props(styles.creator)}>
            <Avatar
              src={channel.user.profilePicture?.source}
              alt={`${channel.slug}'s profile`}
              url={routes.channel.home(channel.slug)}
              imageVariant="medium"
              isLive={mapped.viewerCount !== undefined}
            />
          </div>

          <div {...props(styles.footerContent)}>
            <p {...props(styles.title, fonts.smallSemiBold)}>{mapped.title}</p>
            <DisplayName
              text={channel.slug}
              url={routes.channel.home(channel.slug)}
              font="extraSmallSemiBold"
              underline={false}
              textLinkColor="gray"
            />
            <Category category={mapped.category.key} />
          </div>
          {mapped.type == ContentType.LiveStream && (
            <div {...props(styles.viewerCount)}>
              <ViewerCount viewerCount={viewerCountData?.viewerCount ?? 0} />
            </div>
          )}
          {mapped.type === ContentType.Vod && (
            <div {...props(styles.finishedAt, fonts.smallSemiBold)}>
              {formatRelativeDate(mapped.finishedAt)}
            </div>
          )}
        </div>
        <SmartBannerSlider channel={channel} hideControls type="video" />
      </div>
      <div {...props(styles.chatContainer)}>
        {mockedChatMessages.map((msg) => (
          <CommentCard
            key={msg.id}
            comment={msg}
            canDelete={false}
            onDelete={() => {}}
            isDeleting={false}
          />
        ))}
      </div>
    </div>
  )
}

export default VideoPage
