import { Gallery, Item } from 'react-photoswipe-gallery'
import { ValidatedFile } from '../../common/utils/fileValidationUtils'
import { props } from '@stylexjs/stylex'
import { styles } from './CreatePostStyles'
import { Close } from '../../components/icons/Close'
import { Edit } from '../../components/icons/Edit'
import { memo } from 'react'
import { VideoPlayer } from '../../components/player/VideoPlayer'
import useLanguage from '../../translations/useLanguage'
import { IconButton } from '../../components/IconButton'
import LoadingIndicator from '../../components/indicators/LoadingIndicator'
import { color } from '../../styles/token.stylex'

type FilePreviewProps = {
  fileData: ValidatedFile[]
  removeFile: (id: string) => void
  onEditFile: (file: ValidatedFile) => void
}

const CloseButton = () => <Close width="1.1rem" height="1.1rem" />
const EditButton = () => <Edit width="1.2rem" height="1.2rem" />

export const FilePreview: React.FC<FilePreviewProps> = memo(
  ({ fileData, removeFile, onEditFile }) => {
    const { t } = useLanguage()

    return (
      <Gallery id="post-creation-gallery">
        {fileData.map(({ file, url, id, dimensions, progress, isUploaded }) => {
          const isImage = file.type.startsWith('image/')
          const isVideo = file.type.startsWith('video/')

          return (
            <div {...props(styles.previewItem)} key={id}>
              {isImage && (
                <>
                  <Item
                    original={url}
                    thumbnail={url}
                    id={id}
                    width={dimensions.width}
                    height={dimensions.height}
                  >
                    {({ ref, open }) => (
                      <div {...props(styles.previewImageWrapper)}>
                        <img
                          {...props(
                            styles.previewImage,
                            progress !== 100 && styles.previewImageLoading
                          )}
                          ref={ref as unknown as React.Ref<HTMLImageElement>}
                          onClick={open}
                          src={url}
                          alt={`Preview of ${file.name}`}
                          draggable={false}
                        />
                        {progress !== 100 && (
                          <div {...props(styles.loadingIndicatorWrapper)}>
                            <LoadingIndicator
                              height={30}
                              width={30}
                              stroke={color.gold}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Item>
                  {progress === 100 && (
                    <div {...props(styles.editButton)}>
                      <IconButton
                        icon={EditButton}
                        tooltip={t('post.create.editImage')}
                        variant="large"
                        rounded
                        background="dark"
                        color="white"
                        onClick={() =>
                          onEditFile({
                            file,
                            url,
                            id,
                            dimensions,
                            isUploaded: false,
                          })
                        }
                      />
                    </div>
                  )}
                </>
              )}

              {isVideo && (
                <div {...props(styles.videoItemWrapper)}>
                  <div
                    {...props(
                      styles.videoPlayerWrapper,
                      progress !== 100 && styles.previewImageLoading
                    )}
                  >
                    <VideoPlayer
                      options={{
                        sources: [{ src: url, type: file.type }],
                      }}
                      previewMode
                    />
                  </div>
                  {progress !== 100 && (
                    <div {...props(styles.loadingIndicatorWrapper)}>
                      <LoadingIndicator
                        height={30}
                        width={30}
                        stroke={color.gold}
                      />
                    </div>
                  )}
                </div>
              )}

              {typeof progress === 'number' && (
                <div {...props(styles.progressBar)}>
                  <div
                    {...props(styles.progress)}
                    style={{ width: `${progress}%` }}
                  />
                </div>
              )}

              {isUploaded && (
                <div {...props(styles.deleteButton)}>
                  <IconButton
                    icon={CloseButton}
                    variant="large"
                    color="white"
                    rounded
                    background="dark"
                    tooltip={t('post.create.deleteMedia')}
                    onClick={() => removeFile(id)}
                  />
                </div>
              )}
            </div>
          )
        })}
      </Gallery>
    )
  }
)

FilePreview.displayName = 'FilePreview'
