import { useState } from 'react'
import { StreamFeedType } from '../../client'
import {
  JUST_CHATTING_CATEGORY_ID,
  SLOTS_AND_CASINO_CATEGORY_ID,
} from '../../constants'

export type StreamFeedFilterType =
  | 'all'
  | 'live'
  | 'slotsAndCasino'
  | 'justChatting'

type FilterConfig = {
  feedType: StreamFeedType
  categoryIds?: number[]
}

const FILTER_CONFIG: Record<StreamFeedFilterType, FilterConfig> = {
  all: {
    feedType: StreamFeedType.Mixed,
  },
  live: {
    feedType: StreamFeedType.LiveStreams,
  },
  slotsAndCasino: {
    feedType: StreamFeedType.Mixed,
    categoryIds: [SLOTS_AND_CASINO_CATEGORY_ID],
  },
  justChatting: {
    feedType: StreamFeedType.Mixed,
    categoryIds: [JUST_CHATTING_CATEGORY_ID],
  },
}

export const useStreamFeedFilter = () => {
  const [selectedFilter, setSelectedFilter] =
    useState<StreamFeedFilterType>('all')

  const getFeedType = () => FILTER_CONFIG[selectedFilter].feedType
  const getCategoryIds = () => FILTER_CONFIG[selectedFilter].categoryIds

  return {
    selectedFilter,
    setSelectedFilter,
    getFeedType,
    getCategoryIds,
  }
}
