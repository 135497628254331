import { create, props } from '@stylexjs/stylex'
import { TextLink } from '../../components/TextLink'
import { routes } from '../../router/routes'
import { fonts } from '../../styles/font-styles'
import { responsiveStyles } from '../../layout/navbar/styles'
import useLanguage from '../../translations/useLanguage'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {},
  icon: {
    display: {
      default: 'none',
      [largeScreen]: 'flex',
    },
    alignItems: 'center',
  },
})

export const AffiliateGuideText: React.FC = () => {
  const { t } = useLanguage()
  return (
    <div {...props(styles.container, fonts.smallSemiBold)}>
      {t('myChannel.promoPanel.affiliateSection.part1')}{' '}
      <br {...props(responsiveStyles.desktopFlex)} />
      <TextLink font="smallSemiBold" to={routes.howToBecomeAffiliate}>
        {t('myChannel.promoPanel.affiliateSection.part2')}
      </TextLink>{' '}
      {t('myChannel.promoPanel.affiliateSection.part3')}
    </div>
  )
}
