import type { SVGProps } from 'react'

export const Search: React.FC<SVGProps<SVGElement>> = ({
  fill = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.2824 19.4986L18.2819 19.4981L14.5092 15.7269L14.3524 15.5702L14.1781 15.707C12.6708 16.8903 10.8082 17.5327 8.89115 17.5296H8.89074C4.11896 17.5296 0.25 13.662 0.25 8.88979C0.25 4.11854 4.11688 0.25 8.88967 0.25C13.6615 0.25 17.5304 4.1165 17.5304 8.88871V8.88912C17.5336 10.807 16.8911 12.6683 15.7076 14.1766L15.5708 14.3509L15.7275 14.5076L19.4996 18.2826C19.4997 18.2826 19.4997 18.2827 19.4998 18.2828C19.8351 18.6207 19.8332 19.1656 19.4955 19.5007L19.4953 19.5009C19.3353 19.66 19.1182 19.7497 18.8912 19.7507C18.6617 19.7506 18.4431 19.6601 18.2824 19.4986ZM8.89104 1.97326H8.89074C5.07063 1.97326 1.97242 5.06997 1.97242 8.89086C1.97242 12.7117 5.06953 15.8085 8.89074 15.8085C12.712 15.8085 15.8091 12.7117 15.8091 8.89086V8.89057C15.8046 5.07209 12.7099 1.97773 8.89104 1.97326Z"
      fill={fill}
      stroke="#101010"
      strokeWidth="0.5"
    />
  </svg>
)
