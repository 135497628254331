/** The first section of routes (this shows that these are not channels) */

import { RESERVED_WORDS } from '../constants/reserved-words'

export const routes = {
  home: '/',
  feedsHome: `/${RESERVED_WORDS.feeds}`,
  login: `/${RESERVED_WORDS.login}`,
  logout: `/${RESERVED_WORDS.logout}`,
  signup: `/${RESERVED_WORDS.signUp}`,
  feeds: `/${RESERVED_WORDS.feeds}`,
  videos: `/${RESERVED_WORDS.videos}`,
  post: {
    create: `/${RESERVED_WORDS.post}/${RESERVED_WORDS.create}`,
  },
  auth: {
    callback: `/${RESERVED_WORDS.callback}`,
  },
  styleGuide: `/${RESERVED_WORDS.styleGuide}`,
  styleGuideRoutes: {
    api: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.api}`,
    fonts: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.fonts}`,
    colors: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.colors}`,
    banners: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.banners}`,
    buttons: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.buttons}`,
    inputs: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.inputs}`,
    icons: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.icons}`,
    creatorCards: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.creatorCards}`,
    postCard: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.postCard}`,
    streamCards: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.streamCards}`,
    links: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.links}`,
    mobileNavAndTapbar: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.mobileNavAndTapbar}`,
    loader: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.loader}`,
    notification: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.notification}`,
    toasts: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.toasts}`,
    dialogs: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.dialogs}`,
    media: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.media}`,
    layout: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.layout}`,
    menu: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.menu}`,
    navigation: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.navigation}`,
    messages: `/${RESERVED_WORDS.styleGuide}/${RESERVED_WORDS.messages}`,
  },
  profile: `/${RESERVED_WORDS.profile}`,
  profileRoutes: {
    myStream: `/${RESERVED_WORDS.profile}/${RESERVED_WORDS.myStream}`,
    myProfile: `/${RESERVED_WORDS.profile}/${RESERVED_WORDS.myProfile}`,
    streaming: `/${RESERVED_WORDS.profile}/${RESERVED_WORDS.streaming}`,
    subscriptionPrice: `/${RESERVED_WORDS.profile}/${RESERVED_WORDS.subscriptionPrice}`,
    paymentHistory: `/${RESERVED_WORDS.profile}/${RESERVED_WORDS.paymentHistory}`,
    statements: `/${RESERVED_WORDS.profile}/${RESERVED_WORDS.statements}`,
    subscriptions: `/${RESERVED_WORDS.profile}/${RESERVED_WORDS.subscriptions}`,
    bank: `/${RESERVED_WORDS.profile}/${RESERVED_WORDS.bank}`,
    banners: `/${RESERVED_WORDS.profile}/${RESERVED_WORDS.banners}`,
  },
  notifications: `/${RESERVED_WORDS.notifications}`,
  channel: {
    ...getChannelPaths('home', (slug: string) => `/${slug}`),
    ...getChannelPaths('streams', (slug: string) => `/${slug}/streams`),
    ...getChannelPaths(
      'postById',
      (postId: string, slug: string) => `/${postId}/${slug}`
    ),
    ...getChannelPaths(
      'videos',
      (slug: string, videoId: string) =>
        `/${slug}/${RESERVED_WORDS.videos}/${videoId}`
    ),
  },
  communityGuidelines: `/${RESERVED_WORDS.communityGuidelines}`,
  termsOfService: `/${RESERVED_WORDS.termsOfService}`,
  dmcaPolicy: `/${RESERVED_WORDS.dmcaPolicy}`,
  privacyPolicy: `/${RESERVED_WORDS.privacyPolicy}`,
  howToEmbed: `/${RESERVED_WORDS.howToEmbed}`,
  becomeACreator: `/${RESERVED_WORDS.becomeACreator}`,
  helpAndSupport: `/${RESERVED_WORDS.helpAndSupport}`,
  howToBecomeAffiliate: `/${RESERVED_WORDS.affiliateGuide}`,
  notFound: `/${RESERVED_WORDS.notFound}`,
}

/**
 * It creates these variants:
 *
 * - fooShort: the function that you give;
 * - fooFull: (slug: string, videoId: string) => `channel/${shortVersion}`;
 * - foo: fooShort or fooFull depending on the slug
 *
 * @param short the function for the short version which is used to create the others
 */
function getChannelPaths<
  TName extends string,
  T extends (slug: string, ...args: string[]) => string,
>(name: TName, short: T) {
  const words = RESERVED_WORDS as Record<string, string>
  return {
    [`${name}Short`]: short,
    [`${name}Full`]: ((slug, ...args) =>
      `/channel${short(slug, ...args)}`) as T,
    [name]: ((slug, ...args) => {
      if (words[slug] !== undefined) return `/channel${short(slug, ...args)}`
      return short(slug, ...args)
    }) as T,
  } as ChannelPaths<TName, T>
}

type ChannelPaths<
  TName extends string,
  T extends (slug: string, ...args: string[]) => string,
> = {
  [K in `${TName}Short` | `${TName}Full` | TName]: T
}
