import { create, props } from '@stylexjs/stylex'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { useChannelContext } from './context/channel-context'
import { spacing } from '../styles/token.stylex'
import { Tabs } from './tabs/Tabs'
import { ProfileInfo } from './ProfileInfo'
import { Navigate } from 'react-router-dom'
import { routes } from '../router/routes'
import { SmartSubscription } from '../subscription/Subscription'
import SmartBannerSlider from './banners/SmartBannerSlider'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  profileInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      default: spacing.small,
      [largeScreen]: spacing.normal,
    },
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      default: spacing.small,
      [largeScreen]: spacing.normal,
    },
    width: {
      default: '100%',
      [largeScreen]: '888px',
    },
    maxWidth: '100%',
    margin: '0 auto',
  },
})

export const SmartChannel: React.FC = () => {
  const { channel, me, isLoading } = useChannelContext()

  if (isLoading) return <LoadingScreen />

  if (!channel) return <Navigate to={routes.notFound} />

  return (
    <div>
      <div {...props(styles.profileContainer)}>
        <div {...props(styles.profileInfoContainer)}>
          <ProfileInfo channel={channel} expandableDescription />
          <SmartSubscription channel={channel} me={me} />
          <SmartBannerSlider channel={channel} />
        </div>
        <div>
          <Tabs channel={channel} />
        </div>
      </div>
    </div>
  )
}
