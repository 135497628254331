import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import useLanguage from '../../translations/useLanguage'
import { Stream } from '../icons/Stream'
import { fonts, titleFonts } from '../../styles/font-styles'
import { TextLink } from '../TextLink'
import { useUnderConstructionDialog } from '../../style-guide/components/under-construction/under-construction-context'

type EmptyVodsProps = {
  isOwner?: boolean
}

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.large,
    borderRadius: spacing.extraSmall,
    backgroundColor: color.deepGray,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    padding: spacing.extraLarge,
    minHeight: '300px',
  },
  logo: {
    color: color.gold,
  },
  title: {
    textAlign: 'center',
  },
  helpText: {
    textAlign: 'center',
    paddingTop: spacing.small,
  },
})

export const EmptyVods: React.FC<EmptyVodsProps> = ({ isOwner }) => {
  const { t } = useLanguage()
  const { openDialog } = useUnderConstructionDialog()

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.logo)}>
        <Stream width="4rem" height="4rem" />
      </div>
      <div {...props(styles.title, titleFonts.largeSemiBold)}>
        {t('vods.empty')}
      </div>
      {isOwner && (
        <div {...props(fonts.smallRegular, styles.helpText)}>
          {t('streamingPage.streamURLAndKey.helpText1')}
          <TextLink onClick={() => openDialog('86c0wzy6r')} isExternal>
            {t('streamingPage.streamURLAndKey.howToStream')}
          </TextLink>
          {t('streamingPage.streamURLAndKey.helpText2')}
        </div>
      )}
    </div>
  )
}
