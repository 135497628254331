import { create, props } from '@stylexjs/stylex'
import React from 'react'
import { fonts } from '../styles/font-styles'
import CountUp from 'react-countup'
import useLanguage from '../translations/useLanguage'
import { color, spacing } from '../styles/token.stylex'
import { People } from './icons/People'

type ViewerCountProps = {
  viewerCount: number
  font?: keyof typeof fonts
}

const styles = create({
  container: {
    display: 'flex',
    gap: spacing.xSmall,
    alignItems: 'center',
  },
  count: {
    color: color.torchRed,
  },
  viewers: {
    color: color.dustyGray,
  },
})

const ViewerCount: React.FC<ViewerCountProps> = ({
  viewerCount,
  font = 'smallSemiBold',
}) => {
  const { t } = useLanguage()

  return (
    <div {...props(styles.container, font && fonts[font])}>
      <People fill={color.white} />{' '}
      <span {...props(styles.count)}>
        <CountUp end={viewerCount || 0} duration={1.5} />
      </span>{' '}
      <span {...props(styles.viewers)}>{t('viewers.title')}</span>
    </div>
  )
}

export default ViewerCount
