import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'
import { Panel } from '../../components/Panel'
import {
  GetTransactionResponse,
  GetTransactionResponsePagedApiResponse,
} from '../../client'
import { useApi } from '../../api/ApiContext'
import { useErrorNotification } from '../../translations/useErrorNotification'
import { useInfiniteQuery } from 'react-query'
import { queryKeys } from '../../constants/query-keys'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import useLanguage from '../../translations/useLanguage'
import { EarningsRowItem } from './EarningsRowItem'
import { LoadingIndicator } from '../../components/indicators/LoadingIndicator'
import { UndefinedDataErrorPanel } from '../../api/UndefinedDataErrorPanel'

const PAYMENTS_PER_PAGE = 10

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xSmall,
    backgroundColor: color.codGrayLight,
  },
  buttonContainer: {
    marginTop: '2rem',
    textAlign: 'center',
    ':not(#_id) button': {
      width: '100%',
    },
  },
  emptyList: {
    padding: '1rem',
    textAlign: 'center',
  },
})

export const Earnings: React.FC = () => {
  const { t } = useLanguage()
  const { payoutsApi } = useApi()
  const notifyError = useErrorNotification()

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery<GetTransactionResponsePagedApiResponse, Error>({
      queryKey: queryKeys.payments.default,
      queryFn: async ({ pageParam = 1 }) => {
        return payoutsApi.payoutsTransactionsGet({
          pageNumber: pageParam,
          pageSize: PAYMENTS_PER_PAGE,
        })
      },
      getNextPageParam: (lastPage) =>
        (lastPage.pageNumber ?? 1) < (lastPage.totalPages ?? 1)
          ? (lastPage.pageNumber ?? 1) + 1
          : undefined,
      onError: notifyError(() => toast.error(t('earnings.error')), false),
    })

  if (!isLoading && data === undefined) {
    return <UndefinedDataErrorPanel />
  }

  const earnings: GetTransactionResponse[] = data
    ? data.pages.flatMap((page) => page.data)
    : []
  return (
    <Panel title={t('earnings.title')}>
      {!earnings.length && !isLoading && (
        <div {...props(styles.emptyList)}>{t('earnings.noEarnings')}</div>
      )}
      {isLoading && <LoadingIndicator />}
      {earnings.length !== 0 && (
        <div {...props(styles.container, fonts.smallRegular)}>
          {/* rows */}
          {earnings.map((row, rowIndex) => (
            <EarningsRowItem key={rowIndex} row={row} />
          ))}
          {hasNextPage && (
            <div {...props(styles.buttonContainer)}>
              <Button
                onClick={() => fetchNextPage()}
                disabled={isFetchingNextPage}
                isLoading={isFetchingNextPage}
              >
                {isFetchingNextPage
                  ? t('shared.buttons.loading')
                  : t('shared.buttons.showMore')}
              </Button>
            </div>
          )}
        </div>
      )}
    </Panel>
  )
}
