import { create } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'

export const promoPanelEditorStyles = create({
  empty: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '300px',
    justifyContent: 'center',
    backgroundColor: color.codGrayLight,
    borderRadius: spacing.small,
    cursor: 'pointer',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    padding: spacing.normal,
    backgroundColor: color.codGrayLight,
    borderRadius: spacing.small,
  },
  imageUploadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${spacing.xSmall} 0 ${spacing.small} 0`,
    ':not(#_id) button': {
      width: '100%',
    },
  },
  imageInput: {
    display: 'none',
  },
  actions: {
    display: 'flex',
    gap: spacing.normal,
    justifyContent: 'space-between',
    ':not(#_id) button': {
      width: {
        default: '100%',
      },
    },
  },
})
