import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Navbar } from './navbar/Navbar'
import { useUserContext } from '../auth/user-context'
import { TapBar } from './navbar/TapBar'
import { create, props } from '@stylexjs/stylex'
import { useUnderConstructionDialog } from '../style-guide/components/under-construction/under-construction-context'
import { Suspense, useEffect } from 'react'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { routes } from '../router/routes'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  tapBarFixedContainer: {
    bottom: 0,
    position: 'fixed',
    display: {
      default: 'block',
      [largeScreen]: 'none',
    },
    width: '100%',
    zIndex: 9998,
  },
})

export const RootLayout: React.FC = () => {
  const { data } = useUserContext()
  const { openDialog } = useUnderConstructionDialog()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [location])

  const openMail = () => openDialog('86c0yv871')
  const openNotification = () => navigate(routes.notifications)
  const openContentCreator = () => openDialog('86c0zjg9y')

  return (
    <>
      <Navbar
        displayName={data?.displayName}
        slug={data?.streamerChannel.slug}
        avatarImageSrc={data?.profilePicture?.source}
        notificationCount={data?.notificationCount}
        onNotificationClick={openNotification}
        onMailClick={openMail}
      />
      <main>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </main>
      {data && (
        <div {...props(styles.tapBarFixedContainer)}>
          <TapBar
            displayName={data?.displayName}
            avatarImageSrc={data?.profilePicture?.source}
            notificationCount={data?.notificationCount}
            onNotificationClick={openNotification}
            onContentCreatorClick={openContentCreator}
            onMailClick={openMail}
            isContentCreator={data?.isContentCreator}
          />
        </div>
      )}
    </>
  )
}
