import { create, props } from '@stylexjs/stylex'
import React, { useEffect, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { queryKeys } from '../../constants/query-keys'
import { useApi } from '../../api/ApiContext'
import { Input } from '../../components/input/Input'
import { ReactSelect } from '../../components/input/Select'
import LoadingIndicator from '../../components/indicators/LoadingIndicator'
import { fonts } from '../../styles/font-styles'
import { EditForm } from '../../components/EditForm'
import useLanguage, { LanguageKey } from '../../translations/useLanguage'
import { UndefinedDataErrorPanel } from '../../api/UndefinedDataErrorPanel'
import { useErrorNotification } from '../../translations/useErrorNotification'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    paddingBlock: '1rem',
    paddingInline: {
      default: '0.5rem',
      [largeScreen]: '1rem',
    },
    display: 'grid',
    gap: '1.5rem',
    maxWidth: '888px',
  },
})

type StreamFormData = {
  title: string
  category: number
}

export const EditStreamInfo: React.FC = () => {
  const { streamApi, categoriesApi } = useApi()
  const [isEditing, setIsEditing] = useState(false)
  const titleRef = useRef<HTMLInputElement>(null)
  const { t } = useLanguage()
  const defaultStreamTitle = t('streamingPage.editStreamInfo.defaultTitle')
  const defaultStreamCategory = 10
  const notifyError = useErrorNotification()

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
  } = useQuery({
    queryKey: queryKeys.categories.topCategories,
    queryFn: () => categoriesApi.categoriesTopGet(),
    onError: notifyError(
      () =>
        toast.error(t('streamingPage.editStreamInfo.categoriesErrorMessage')),
      false
    ),
  })

  const { data, isLoading: isCurrentStreamInfoLoading } = useQuery({
    queryKey: queryKeys.stream.currentInfo,
    queryFn: () => streamApi.streamGet(),
    onError: notifyError(
      () =>
        toast.error(t('streamingPage.editStreamInfo.streamInfoErrorMessage')),
      false
    ),
  })

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<StreamFormData>({
    defaultValues: {
      title: defaultStreamTitle,
      category: defaultStreamCategory,
    },
    mode: 'onChange',
  })

  useEffect(() => {
    reset({
      title: data?.currentStream?.title || defaultStreamTitle,
      category: data?.currentStream?.categoryId || defaultStreamCategory,
    })
  }, [data, defaultStreamTitle, reset])

  const { mutateAsync: updateStream, isLoading: isUpdating } = useMutation({
    mutationFn: (values: StreamFormData) =>
      streamApi.streamPut({
        addStreamRequest: {
          title: values.title,
          categoryId: values.category,
        },
      }),
    onSuccess: (data) => {
      toast.success(t('streamingPage.editStreamInfo.updateSuccessMessage'))
      setIsEditing(false)
      reset({
        title: data.title,
        category: data.categoryId,
      })
    },
    onError: notifyError(
      () => toast.error(t('streamingPage.editStreamInfo.updateErrorMessage')),
      false
    ),
  })

  const onSubmit = handleSubmit(async (formData) => {
    if (!isValid) return
    await updateStream(formData)
  })

  const categoryOptions =
    categories?.map((cat) => ({
      value: cat.id.toString(),
      label: t(`categories.${cat.slug}` as LanguageKey),
    })) || []

  const isLoading = isCategoriesLoading || isCurrentStreamInfoLoading

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (!isLoading && (!data || !categories)) {
    return <UndefinedDataErrorPanel />
  }

  const handleEdit = () => {
    setIsEditing(true)
    titleRef.current?.focus()
  }

  const handleSelectEdit = () => {
    setIsEditing(true)
  }

  const handleCancel = () => {
    setIsEditing(false)
    reset()
    titleRef.current?.blur()
  }

  const titleValidator = (title: string) => {
    if (title.trim() !== title) {
      return t('streamingPage.editStreamInfo.noSpaces')
    }
  }

  return (
    <EditForm
      canEdit
      canSave={isDirty && isValid && !isUpdating}
      isEditing={isEditing}
      onCancel={handleCancel}
      onSubmit={onSubmit}
      onEdit={handleEdit}
      isLoading={isUpdating}
    >
      <div {...props(styles.container, fonts.smallRegular)}>
        <Controller
          control={control}
          name="title"
          rules={{
            required: t('streamingPage.editStreamInfo.titleRequiredWarning'),
            validate: titleValidator,
          }}
          render={({ field }) => (
            <Input
              id="title"
              ref={titleRef}
              label={t('streamingPage.editStreamInfo.titleLabel')}
              value={field.value}
              onChange={field.onChange}
              onClick={handleEdit}
              onEscape={handleCancel}
              onFocus={handleEdit}
              error={errors.title?.message}
              maxLength={140}
              instruction={t('streamingPage.editStreamInfo.titleInstruction')}
            />
          )}
        />
        <Controller
          control={control}
          name="category"
          rules={{
            required: t('streamingPage.editStreamInfo.categoryRequired'),
          }}
          render={({ field }) => (
            <ReactSelect
              id="category"
              label={t('streamingPage.editStreamInfo.categoryLabel')}
              value={field.value.toString()}
              options={categoryOptions}
              onChange={field.onChange}
              onMenuOpen={handleSelectEdit}
              isDisabled={isCategoriesError}
            />
          )}
        />
      </div>
    </EditForm>
  )
}

export default EditStreamInfo
