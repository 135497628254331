import { PropsWithChildren, useCallback } from 'react'
import { useApi } from '../../api/ApiContext'
import { useQuery, useQueryClient } from 'react-query'
import { ChannelContext } from './channel-context'
import { queryKeys } from '../../constants/query-keys'
import { useAuth0 } from '@auth0/auth0-react'
import { ApiError } from '../../api/apiError'
import { useErrorNotification } from '../../translations/useErrorNotification'

type ChannelContextProviderProps = {
  slug: string
}

export const ChannelContextProvider: React.FC<
  PropsWithChildren<ChannelContextProviderProps>
> = ({ children, slug }) => {
  const { channelsApi } = useApi()
  const auth = useAuth0()
  const notifyError = useErrorNotification()
  const formattedSlug = slug.toLowerCase()

  const queryClient = useQueryClient()

  const handleError = (error: unknown) => {
    if (
      error instanceof ApiError &&
      error.statusCode !== undefined &&
      error.statusCode >= 500
    ) {
      notifyError(() => {})(error)
    }
  }

  const channelQuery = useQuery({
    queryKey: queryKeys.channel.slug(formattedSlug),
    queryFn: () => channelsApi.channelsSlugGet({ slug: formattedSlug }),
    onError: handleError,
  })

  const meQuery = useQuery({
    queryKey: queryKeys.channel.me(formattedSlug),
    enabled: auth.isAuthenticated && !!channelQuery.data,
    queryFn: () => channelsApi.channelsSlugMeGet({ slug: formattedSlug }),
    onError: handleError,
  })

  const reloadChannel = useCallback(() => {
    queryClient.invalidateQueries(queryKeys.channel.slug(formattedSlug))
  }, [queryClient, formattedSlug])

  const reloadMe = useCallback(() => {
    queryClient.invalidateQueries(queryKeys.channel.me(formattedSlug))
  }, [queryClient, formattedSlug])

  return (
    <ChannelContext.Provider
      value={{
        isLoading: channelQuery.isLoading || meQuery.isLoading,
        isError: channelQuery.isError,
        channel: channelQuery.data,
        me: meQuery.data,
        reloadChannel,
        reloadMe,
      }}
    >
      {children}
    </ChannelContext.Provider>
  )
}
