import type { SVGProps } from 'react'

export const People: React.FC<SVGProps<SVGElement>> = ({
  fill = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    height={height}
    viewBox="0 -72 496 496"
    width={width}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m98.113281 168.125c-31.730469 0-57.542969-25.578125-57.542969-57.007812 0-31.433594 25.8125-57.011719 57.542969-57.011719 31.726563 0 57.535157 25.574219 57.535157 57.011719 0 31.433593-25.808594 57.007812-57.535157 57.007812zm0-98.019531c-22.910156 0-41.542969 18.398437-41.542969 41.011719 0 22.609374 18.632813 41.007812 41.542969 41.007812 22.902344 0 41.535157-18.398438 41.535157-41.007812 0-22.613282-18.632813-41.011719-41.535157-41.011719zm0 0" />
    <path d="m121.261719 325.96875h-100.351563c-11.53125 0-20.910156-9.320312-20.910156-20.777344v-26.167968c0-53.570313 44.011719-97.15625 98.113281-97.15625 21.277344 0 41.53125 6.640624 58.554688 19.199218 3.554687 2.625 4.3125 7.632813 1.6875 11.191406-2.625 3.550782-7.628907 4.308594-11.1875 1.6875-14.253907-10.515624-31.214844-16.078124-49.054688-16.078124-45.277343 0-82.113281 36.410156-82.113281 81.15625v26.167968c0 2.632813 2.203125 4.777344 4.910156 4.777344h100.355469c4.414063 0 8 3.582031 8 8 0 4.414062-3.585937 8-8.003906 8zm0 0" />
    <path d="m475.089844 319.914062h-100.351563c-4.414062 0-8-3.582031-8-8 0-4.414062 3.585938-8 8-8h100.355469c2.707031 0 4.910156-2.140624 4.910156-4.773437v-26.167969c0-44.753906-36.835937-81.15625-82.113281-81.15625-18.910156 0-37.371094 6.511719-51.984375 18.339844-3.429688 2.777344-8.464844 2.25-11.25-1.183594-2.777344-3.433594-2.253906-8.472656 1.1875-11.253906 17.449219-14.121094 39.484375-21.902344 62.046875-21.902344 54.097656 0 98.109375 43.582032 98.109375 97.15625v26.167969c0 11.453125-9.378906 20.773437-20.910156 20.773437zm0 0" />
    <path d="m397.886719 164.171875c-31.726563 0-57.535157-25.574219-57.535157-57.007813 0-31.433593 25.808594-57.011718 57.535157-57.011718 31.730469 0 57.542969 25.574218 57.542969 57.011718s-25.816407 57.007813-57.542969 57.007813zm0-98.019531c-22.902344 0-41.535157 18.398437-41.535157 41.011718 0 22.613282 18.632813 41.007813 41.535157 41.007813 22.910156 0 41.542969-18.394531 41.542969-41.007813 0-22.613281-18.636719-41.011718-41.542969-41.011718zm0 0" />
    <path d="m356.578125 352h-217.15625c-14.425781 0-26.160156-11.648438-26.160156-25.96875v-36.804688c0-73.554687 60.4375-133.394531 134.738281-133.394531s134.738281 59.839844 134.738281 133.394531v36.804688c0 14.320312-11.734375 25.96875-26.160156 25.96875zm-108.578125-180.164062c-65.472656 0-118.738281 52.664062-118.738281 117.394531v36.804687c0 5.496094 4.558593 9.96875 10.160156 9.96875h217.152344c5.601562 0 10.160156-4.472656 10.160156-9.96875v-36.804687c.003906-64.730469-53.261719-117.394531-118.734375-117.394531zm0 0" />
    <path d="m245.21875 146.484375c-40.765625 0-73.9375-32.855469-73.9375-73.246094 0-40.386719 33.167969-73.238281 73.9375-73.238281 40.773438 0 73.941406 32.855469 73.941406 73.242188 0 40.390624-33.171875 73.242187-73.941406 73.242187zm0-130.484375c-31.945312 0-57.9375 25.679688-57.9375 57.242188 0 31.566406 25.992188 57.246093 57.9375 57.246093 31.949219 0 57.941406-25.679687 57.941406-57.246093 0-31.5625-25.992187-57.242188-57.941406-57.242188zm0 0" />
  </svg>
)
