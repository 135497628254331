/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreatorOnboardingStatus } from './CreatorOnboardingStatus';
import {
    CreatorOnboardingStatusFromJSON,
    CreatorOnboardingStatusFromJSONTyped,
    CreatorOnboardingStatusToJSON,
} from './CreatorOnboardingStatus';

/**
 * Represents the response for a user's onboarding status.
 * @export
 * @interface GetOnboardingResponse
 */
export interface GetOnboardingResponse {
    /**
     * Indicates whether the user's bio is set.
     * @type {boolean}
     * @memberof GetOnboardingResponse
     */
    isBioSet: boolean;
    /**
     * Indicates whether the user's display name is set.
     * @type {boolean}
     * @memberof GetOnboardingResponse
     */
    isDisplayNameSet: boolean;
    /**
     * Indicates whether the user's profile picture is set.
     * @type {boolean}
     * @memberof GetOnboardingResponse
     */
    isProfilePictureSet: boolean;
    /**
     * Indicates whether the user's username is set.
     * @type {boolean}
     * @memberof GetOnboardingResponse
     */
    isUsernameSet: boolean;
    /**
     * 
     * @type {CreatorOnboardingStatus}
     * @memberof GetOnboardingResponse
     */
    status: CreatorOnboardingStatus;
}

/**
 * Check if a given object implements the GetOnboardingResponse interface.
 */
export function instanceOfGetOnboardingResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isBioSet" in value;
    isInstance = isInstance && "isDisplayNameSet" in value;
    isInstance = isInstance && "isProfilePictureSet" in value;
    isInstance = isInstance && "isUsernameSet" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetOnboardingResponseFromJSON(json: any): GetOnboardingResponse {
    return GetOnboardingResponseFromJSONTyped(json, false);
}

export function GetOnboardingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOnboardingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isBioSet': json['isBioSet'],
        'isDisplayNameSet': json['isDisplayNameSet'],
        'isProfilePictureSet': json['isProfilePictureSet'],
        'isUsernameSet': json['isUsernameSet'],
        'status': CreatorOnboardingStatusFromJSON(json['status']),
    };
}

export function GetOnboardingResponseToJSON(value?: GetOnboardingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isBioSet': value.isBioSet,
        'isDisplayNameSet': value.isDisplayNameSet,
        'isProfilePictureSet': value.isProfilePictureSet,
        'isUsernameSet': value.isUsernameSet,
        'status': CreatorOnboardingStatusToJSON(value.status),
    };
}

