import { ErrorCodes, ModelError } from '../client'
import { LanguageKey } from '../translations/useLanguage'

const defaultErrorCode: LanguageKey = 'errors.general.technicalError'

const errorCodeMap: Record<ErrorCodes | string, LanguageKey> = {
  TECHNICAL_ERROR: defaultErrorCode,
  REQUEST_VALIDATION_ERROR: 'errors.general.validationError',

  DISPLAYNAME_ALREADY_EXISTS: 'errors.specific.displayNameAlreadyExists',
  FORBIDDEN_ACCESS: 'errors.general.unauthorized',
  USERNAME_NOT_AVAILABLE: 'errors.specific.usernameNotAvailable',
}

export class ApiError extends Error {
  public error: ModelError
  public message: LanguageKey
  public code?: ErrorCodes
  public statusCode?: number

  constructor(error: ModelError, statusCode?: number) {
    const message = errorCodeMap[error.code || ''] ?? defaultErrorCode
    super(message)
    this.message = message
    this.error = error
    this.statusCode = statusCode
    this.code = error.code as ErrorCodes
  }
}

export const getErrorMessage = (e: unknown): LanguageKey =>
  e instanceof ApiError ? e.message : defaultErrorCode
