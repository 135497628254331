import React from 'react'
import { useInfiniteQuery } from 'react-query'
import { Button } from '../components/Button'
import { toast } from 'react-toastify'
import { props } from '@stylexjs/stylex'
import useLanguage from '../translations/useLanguage'
import { SmartStreamCard } from './card/SmartStreamCard'
import {
  GetStreamFeedItemResponse,
  GetStreamFeedItemResponsePagedApiResponse,
} from '../client'
import { queryKeys } from '../constants/query-keys'
import { STREAMS_PER_PAGE } from '../constants'
import { useApi } from '../api/ApiContext'
import { StreamFeedFilterBar } from './filter/StreamFeedFilterBar'
import { useStreamFeedFilter } from './filter/useStreamFeedFilter'
import { styles } from './StreamFeedPageStyles'
import { StreamsFeedPagePlaceholder } from './StreamsFeedPagePlaceholder'
import { NoResult } from './filter/NoResult'

export const StreamsFeedPage: React.FC = () => {
  const { streamFeedApi } = useApi()
  const { t } = useLanguage()

  const { selectedFilter, setSelectedFilter, getFeedType, getCategoryIds } =
    useStreamFeedFilter()

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery<GetStreamFeedItemResponsePagedApiResponse, Error>({
      queryKey: [queryKeys.stream.feed, selectedFilter],
      queryFn: async ({ pageParam = 1 }) =>
        streamFeedApi.streamfeedGet({
          pageNumber: pageParam,
          pageSize: STREAMS_PER_PAGE,
          feedType: getFeedType(),
          categoryIds: getCategoryIds(),
        }),
      getNextPageParam: (lastPage) =>
        (lastPage.pageNumber ?? 1) < lastPage.totalPages
          ? (lastPage.pageNumber ?? 1) + 1
          : undefined,
      onError: () => toast.error(t('feeds.errorToast')),
    })

  if (isLoading)
    return (
      <StreamsFeedPagePlaceholder
        selectedFilter={selectedFilter}
        onSelect={setSelectedFilter}
      />
    )

  if (!data) return null

  const streams: GetStreamFeedItemResponse[] = data.pages.flatMap(
    (page) => page.data
  )

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.filter)}>
        <StreamFeedFilterBar
          selected={selectedFilter}
          onSelect={setSelectedFilter}
        />
      </div>
      {streams.length === 0 && <NoResult selectedFilter={selectedFilter} />}
      <div {...props(styles.contentContainer)}>
        {streams.map((stream) => (
          <SmartStreamCard
            key={stream.referenceId}
            stream={stream}
            variant="streamFeed"
          />
        ))}
      </div>

      {hasNextPage && (
        <div {...props(styles.loadMoreButton)}>
          <Button
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
            isLoading={isFetchingNextPage}
          >
            {isFetchingNextPage
              ? t('shared.buttons.loading')
              : t('shared.buttons.showMore')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default StreamsFeedPage
