import { create, props } from '@stylexjs/stylex'
import React from 'react'
import { GetTransactionResponse } from '../../client'
import { TextLink } from '../../components/TextLink'
import { color, spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'
import useLanguage from '../../translations/useLanguage'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    paddingInline: {
      default: spacing.xxxSmall,
      [largeScreen]: spacing.xSmall,
    },
    paddingBlock: spacing.xxxSmall,
    backgroundColor: color.codGrayLight,
    gap: spacing.normal,
  },
  orderId: {
    color: color.dustyGray,
  },
  divider: {
    borderBottom: `1px solid ${color.dustyGray}`,
    paddingBottom: '0.5rem',
  },
  cellTitle: {
    color: color.dustyGray,
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  dateCell: {
    height: '100%',
  },
})

type EarningsRowItemProps = {
  row: GetTransactionResponse
  key: number
}

const renderDate = (fullDate: Date, languageCode: 'en' | 'ja') => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZoneName: 'short',
  }
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  const date = new Intl.DateTimeFormat(languageCode, dateOptions).format(
    fullDate
  )
  const time = new Intl.DateTimeFormat(languageCode, timeOptions).format(
    fullDate
  )
  return (
    <>
      <div {...props(fonts.extraSmallSemiBold, styles.cellTitle)}>{date}</div>
      <div>{time}</div>
    </>
  )
}

export const EarningsRowItem: React.FC<EarningsRowItemProps> = ({ row }) => {
  const { formatCurrency, t, languageCode } = useLanguage()

  return (
    <React.Fragment>
      {/* upper row */}
      <div {...props(styles.row)}>
        <div {...props(styles.dateCell)}>
          {renderDate(row.createdAt, languageCode)}
        </div>
        <div {...props(fonts.smallRegular, styles.cell)}>
          <div {...props(fonts.extraSmallSemiBold, styles.cellTitle)}>
            {t('earnings.amount').toUpperCase()}
          </div>
          {formatCurrency(row.amount, {
            currency: row.currency,
            maximumFractionDigits: 0,
          })}
        </div>
        <div {...props(fonts.smallRegular, styles.cell)}>
          <div {...props(fonts.extraSmallSemiBold, styles.cellTitle)}>
            {t('earnings.fee').toUpperCase()}
          </div>
          {formatCurrency(row.fee, {
            currency: row.currency,
            maximumFractionDigits: 0,
          })}
        </div>
        <div {...props(fonts.smallSemiBold, styles.cell)}>
          <div {...props(fonts.extraSmallSemiBold, styles.cellTitle)}>
            {t('earnings.net').toUpperCase()}
          </div>
          {formatCurrency(row.net, {
            currency: row.currency,
            maximumFractionDigits: 0,
          })}
        </div>
      </div>
      {/* bottom row */}
      <div {...props(styles.row)}>
        <div>
          {t(`earnings.${row.paymentType}`)}
          <TextLink font="smallRegular" to={`/${row.user.slug}`}>
            {row.user.displayName}
          </TextLink>
        </div>
      </div>
      {/* divider */}
      <div
        {...props(
          styles.row,
          styles.divider,
          styles.orderId,
          fonts.extraSmallRegular
        )}
      ></div>
    </React.Fragment>
  )
}
