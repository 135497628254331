/* eslint-disable react/jsx-no-literals */
import { create, props } from '@stylexjs/stylex'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { Button } from '../../../components/Button'
import { spacing } from '../../../styles/token.stylex'
import { toast } from 'react-toastify'
import { IconButton } from '../../../components/IconButton'
import { Currency } from '../../../components/icons/Currency'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.large,
    width: '100%',
  },
  container: { width: 'fit-content' },
})

export const ToastNotifications: React.FC = () => {
  const renderToasts = (
    <StyleGuidePanel title="Toast notifications" direction="column">
      <Button
        variant="cta"
        onClick={() => toast.info('Info toast notification!')}
      >
        Info toast Button
      </Button>
      <Button
        variant="cta"
        onClick={() => toast.success('Data saved successfully!')}
      >
        Success toast Button
      </Button>
      <Button
        variant="cta"
        onClick={() => toast.error('Error occured, please try again!')}
      >
        Error toast Button
      </Button>
    </StyleGuidePanel>
  )

  const renderTooltips = (
    <StyleGuidePanel title="Tooltip" direction="column">
      <IconButton
        variant="large"
        onClick={() => toast.info('Thank you for the tip!')}
        tooltip="Tip something"
        icon={Currency}
      />
    </StyleGuidePanel>
  )

  return (
    <StyleGuidePageContainer title="Toast + tooltip">
      <div {...props(styles.mainContainer)}>
        <div {...props(styles.container)}>{renderToasts}</div>
        <div {...props(styles.container)}>{renderTooltips}</div>
      </div>
    </StyleGuidePageContainer>
  )
}
