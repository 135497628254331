import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import { LiveBadge } from '../../components/LiveBadge'
import { Link } from '../../components/Link'
import { StreamCardInfoBadge } from './StreamCardInfoBadge'
import { formatDurationToTime } from './utils'
import useLanguage from '../../translations/useLanguage'
import { TextLink } from '../../components/TextLink'
import { Category } from '../../components/Category'
import { Avatar } from '../../components/avatars/Avatar'
import { ContextMenu } from '../../components/ContextMenu'
import { IconButton } from '../../components/IconButton'
import { ContextMenuDots } from '../../components/icons/ContextMenuDots'
import { DisplayName } from '../../components/DisplayName'
import { fonts } from '../../styles/font-styles'
import { ContextMenuItem } from '../../components/types'
import { StreamCardProps } from './types'
import DefaultStreamCardThumbnail from '/default-stream-card-thumbnail.png'
import { useState } from 'react'
import { routes } from '../../router/routes'

const MAX_CARD_WIDTH = 500
const largeScreen = '@media (min-width: 600px)'
const MAX_IMAGE_HEIGHT = 290

const POSITIONS = {
  topLeft: { top: 10, left: 15 },
  bottomLeft: { bottom: 15, left: 15 },
  topRight: { top: 10, right: 15 },
}

const styles = create({
  container: {
    maxWidth: MAX_CARD_WIDTH,
    position: 'relative',
    width: '100%',
  },
  streamFeed: {
    marginLeft: {
      default: `calc(${spacing.large} * -1)`,
      [largeScreen]: 0,
    },
    marginRight: {
      default: `calc(${spacing.large} * -1)`,
      [largeScreen]: 0,
    },
  },
  channelPage: {
    marginLeft: {
      default: `calc(${spacing.extraSmall} * -1)`,
      [largeScreen]: 0,
    },
    marginRight: {
      default: `calc(${spacing.extraSmall} * -1)`,
      [largeScreen]: 0,
    },
  },
  thumbnailContainer: {
    position: 'relative',
    aspectRatio: '16 / 9',
    maxWidth: '100vw',
  },
  thumbnail: {
    height: 'auto',
    maxHeight: MAX_IMAGE_HEIGHT,
    maxWidth: '100%',
    objectFit: 'cover',
    borderRadius: {
      default: 0,
      [largeScreen]: spacing.extraSmall,
    },
    width: '100%',
    transform: {
      default: 'scale(1)',
      ':hover': {
        default: null,
        [largeScreen]: 'scale(1.03)',
      },
    },
    transition: 'transform 0.3s ease',
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: spacing.extraSmall,
    paddingTop: spacing.xxSmall,
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: spacing.large,
  },
  liveBadge: {
    position: 'absolute',
    ...POSITIONS.topLeft,
  },
  viewerCount: {
    position: 'absolute',
    ...POSITIONS.bottomLeft,
    color: color.white,
  },
  duration: {
    position: 'absolute',
    ...POSITIONS.bottomLeft,
  },
  finishedAt: {
    position: 'absolute',
    ...POSITIONS.topRight,
  },
  creator: {
    cursor: 'pointer',
    height: 'fit-content',
    transition: 'transform 0.3s ease',
    transform: {
      default: 'scale(1)',
      ':hover': 'scale(1.05)',
    },
  },
  contextMenu: {
    padding: `0 ${spacing.small}`,
  },
})

export const StreamCard = ({
  streamer,
  category,
  title,
  route,
  thumbnailUrl,
  viewerCount,
  duration,
  finishedAt,
  contextMenuItems,
  variant,
}: StreamCardProps & {
  contextMenuItems?: ContextMenuItem[]
}) => {
  const { t, formatRelativeDate } = useLanguage()
  const [imageSrc, setImageSrc] = useState(
    thumbnailUrl || DefaultStreamCardThumbnail
  )

  return (
    <div {...props(styles.container)}>
      <Link to={route}>
        <div {...props(styles.thumbnailContainer, variant && styles[variant])}>
          <img
            src={imageSrc}
            alt={title}
            {...props(styles.thumbnail)}
            onError={() => setImageSrc(DefaultStreamCardThumbnail)}
          />
          {viewerCount !== undefined && (
            <>
              <div {...props(styles.liveBadge)}>
                <LiveBadge />
              </div>
              <div {...props(styles.viewerCount)}>
                <StreamCardInfoBadge>
                  {t('streamingGeneral.viewerCount', {
                    count: viewerCount.toString(),
                  })}
                </StreamCardInfoBadge>
              </div>
            </>
          )}
          {duration !== undefined && (
            <div {...props(styles.duration)}>
              <StreamCardInfoBadge>
                {formatDurationToTime(duration)}
              </StreamCardInfoBadge>
            </div>
          )}
          {finishedAt !== undefined && (
            <div {...props(styles.finishedAt)}>
              <StreamCardInfoBadge>
                {formatRelativeDate(finishedAt)}
              </StreamCardInfoBadge>
            </div>
          )}
        </div>
      </Link>
      <div {...props(styles.footer)}>
        <div {...props(styles.creator)}>
          <Avatar
            src={streamer.profilePicture?.source ?? ''}
            alt={`${streamer.name}'s profile`}
            url={routes.channel.home(streamer.channelSlug)}
            imageVariant="medium"
            isLive={viewerCount !== undefined}
          />
        </div>

        <div {...props(styles.footerContent)}>
          <TextLink to={route} variant="white">
            <p {...props(styles.title, fonts.smallSemiBold)}>{title}</p>
          </TextLink>
          <DisplayName
            text={streamer.name}
            url={routes.channel.home(streamer.channelSlug)}
            font="extraSmallSemiBold"
            underline={false}
            textLinkColor="gray"
          />
          <Category category={category.key} />
        </div>
        {!!contextMenuItems?.length && (
          <div {...props(styles.contextMenu)}>
            <ContextMenu items={contextMenuItems}>
              <IconButton icon={ContextMenuDots} variant="large" />
            </ContextMenu>
          </div>
        )}
      </div>
    </div>
  )
}
