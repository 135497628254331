import type { SVGProps } from 'react'

export const Send: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25012 12.6436L16.6799 20.0618C16.7774 20.1476 16.8956 20.2066 17.0228 20.2331C17.15 20.2595 17.2819 20.2524 17.4055 20.2126C17.5292 20.1727 17.6403 20.1014 17.7281 20.0056C17.8159 19.9099 17.8774 19.7929 17.9064 19.6663L21.4327 4.27886C21.4632 4.14582 21.4568 4.00698 21.4142 3.87731C21.3716 3.74763 21.2944 3.63204 21.191 3.54298C21.0876 3.45392 20.9618 3.39478 20.8272 3.37192C20.6927 3.34906 20.5544 3.36336 20.4274 3.41326L3.12517 10.2106C2.97438 10.2698 2.84683 10.3763 2.76159 10.5141C2.67636 10.6518 2.63803 10.8135 2.65235 10.9749C2.66666 11.1363 2.73285 11.2887 2.841 11.4093C2.94915 11.5299 3.09346 11.6123 3.25233 11.6441L8.25012 12.6436Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 12.6437L21.0105 3.42773"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.46 16.3491L9.53033 19.2788C9.42544 19.3837 9.2918 19.4551 9.14632 19.4841C9.00083 19.513 8.85003 19.4982 8.71299 19.4414C8.57594 19.3846 8.45881 19.2885 8.3764 19.1652C8.29399 19.0418 8.25 18.8968 8.25 18.7485V12.6443"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
