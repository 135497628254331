import { MessagePreview } from '../../../message/MessagePreview'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { create } from '@stylexjs/stylex'

const styles = create({
  mediaContainer: {
    maxWidth: '500px',
  },
})

export const Messages: React.FC = () => (
  <StyleGuidePageContainer title="Message preview" variant="noPaddingOnMobile">
    <StyleGuidePanel
      direction="column"
      title="Message preview"
      variant="noPaddingOnMobile"
      border="noBorderOnMobile"
      width="fit"
      contentStyle={styles.mediaContainer}
    >
      <MessagePreview
        message="Hello, how are you?"
        date={new Date('2025-03-07T19:05:00Z')}
        fullName="Amanda Pink"
        profilePictureUrl="../images/sample/girl.png"
        isVerified
        isRead={true}
        onClick={() => console.log('Message clicked')}
        onClose={() => console.log('Close clicked')}
      />
      <MessagePreview
        message="Hello, how are you?"
        date={new Date('2025-03-07T19:05:00Z')}
        fullName="Amanda Pink"
        profilePictureUrl="../images/sample/girl.png"
        isVerified
        isActive
        isRead={true}
        onClick={() => console.log('Message clicked')}
        onClose={() => console.log('Close clicked')}
      />
      <MessagePreview
        message="Hello, how are you?"
        date={new Date('2025-03-06T19:05:00Z')}
        fullName="Amanda Pink"
        profilePictureUrl="../images/sample/girl2.png"
        isVerified
        isRead={false}
        onClick={() => console.log('Message clicked')}
        onClose={() => console.log('Close clicked')}
      />
      <MessagePreview
        message="DOUBLE THE FILTH, ME and my HOT GIRLFRIEND we're going ALL OUT"
        date={new Date('2025-03-06T19:05:00Z')}
        fullName="Jaqueline ❤️🔥 #1 LATINA IN THE WORLD"
        profilePictureUrl="../images/sample/girl3.png"
        isVerified
        isRead={true}
        onClick={() => console.log('Message clicked')}
        onClose={() => console.log('Close clicked')}
      />
    </StyleGuidePanel>
  </StyleGuidePageContainer>
)
