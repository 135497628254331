import { create, props } from '@stylexjs/stylex'
import { color, fontSize, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { MouseEventHandler } from 'react'
import { ButtonLoader } from './icons/ButtonLoader'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    borderRadius: '.5rem',
    padding: `${spacing.extraSmall} ${spacing.normal}`,
    cursor: {
      default: 'pointer',
      ':disabled': 'default',
    },
    color: {
      default: color.buttonTextColorDefault,
      ':disabled': color.buttonTextColorDisabled,
    },
    backgroundColor: {
      default: color.buttonBackgroundDefault,
      ':hover:enabled': color.buttonBackgroundHover,
    },
    border: 0,
  },
  cta: {
    backgroundColor: {
      default: color.primary,
      ':hover:enabled': color.torchRedDark,
      ':disabled': color.primaryDark,
    },
    outline: {
      ':focus': `2px solid ${color.primaryDark}`,
      ':active': 0,
    },
    outlineOffset: {
      ':focus': '-2px',
    },
  },
  fullWidth: {
    width: '100%',
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  textLink: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: {
      default: color.textLinkColorDefault,
      ':active': color.textLinkColorActive,
      [largeScreen]: {
        ':hover': color.textLinkColorHover,
      },
    },
    padding: 0,
    background: 'none',
    outline: 'unset',
    border: 'unset',
    fontSize: fontSize.extraSmall,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.xxSmall,
    transition: 'all 1s',
  },
  loader: {
    width: '1.125rem',
    height: '1.125rem',
    marginLeft: spacing.xxSmall,
    animation: 'spin 1s linear infinite',
  },
})

type ButtonProps = {
  variant?: 'cta' | 'transparent' | 'textLink'
  disabled?: boolean
  isLoading?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  type?: 'submit' | 'reset' | 'button'
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  variant,
  disabled,
  isLoading,
  type,
  onClick,
  children,
}) => (
  <button
    {...props(
      styles.container,
      fonts.smallSemiBold,
      variant && styles[variant]
    )}
    disabled={disabled || isLoading}
    onClick={onClick}
    type={type}
  >
    <div {...props(styles.content)}>
      {children}
      {isLoading && (
        <div {...props(styles.loader)}>
          <ButtonLoader />
        </div>
      )}
    </div>
  </button>
)
