import { ErrorCodes } from '../client'
import { Middleware, ResponseContext } from '../client/runtime'
import { ApiError } from './apiError'
import { isModelErrorResponse } from './utils'

const toApiError = (message: string, statusCode?: number): ApiError =>
  new ApiError(
    {
      code: ErrorCodes.TechnicalError,
      message,
    },
    statusCode
  )

const readError = async (response: Response): Promise<unknown> => {
  try {
    const contentType = response.headers.get('content-type')
    if (contentType?.includes('application/json')) {
      return await response.json()
    }

    return await response.text()
  } catch (error) {
    console.error('Error parsing response:', error)
    throw toApiError('Error parsing response', response.status)
  }
}

export const createErrorHandlerMiddleware = (
  refreshToken: () => Promise<void>
): Middleware => ({
  post: async ({ response }: ResponseContext) => {
    if (response.status >= 400) {
      const error = await readError(response)

      if (
        error &&
        typeof error === 'object' &&
        'error' in error &&
        isModelErrorResponse(error.error)
      ) {
        if (response.status === 403) {
          if (
            error.error.code === ErrorCodes.UnauthorizedContentCreatorAccess
          ) {
            await refreshToken()
            throw new ApiError(error.error, response.status)
          }

          throw new ApiError(
            { code: ErrorCodes.ForbiddenAccess },
            response.status
          )
        }

        throw new ApiError(error.error, response.status)
      }

      if (response.status === 403) {
        throw new ApiError(
          { code: ErrorCodes.ForbiddenAccess },
          response.status
        )
      }

      if (typeof error === 'string') throw toApiError(error, response.status)

      throw toApiError('Unknown error type', response.status)
    }
  },
})
