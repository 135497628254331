import { create } from '@stylexjs/stylex'
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from '../../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'
const defaultFamily =
  '"Poppins", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";'

export const styles = create({
  video: {
    width: '100%',
    height: '100%',
    ':not(#_) .video-js': {
      paddingTop: '0 !important',
      aspectRatio: '16/9 !important',
      height: '100% !important',
      maxHeight: '80vh !important',
    },
    ':not(#_) .vjs-menu': {
      left: 'unset !important',
      right: '0 !important',
      ':not(#_) .vjs-menu-content': {
        bottom: '2.5em !important',
      },
    },
    ':not(#_) .vjs-control-bar': {
      height: '4em !important',
    },
    ':not(#_) .vjs-time-control': {
      fontFamily: defaultFamily,
      fontSize: `${fontSize.extraSmall} !important`,
      lineHeight: `${lineHeight.normal} !important`,
      fontWeight: fontWeight.semiBold,
    },
    ':not(#_) .vjs-button': {
      outline: 'none !important',
    },
    ':not(#_) .vjs-button > .vjs-icon-placeholder:before': {
      fontSize: '25px !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ':not(#_) .vjs-control .vjs-progress-holder': {
      height: '5px !important',
    },
    ':not(#_) .vjs-control .vjs-play-progress::before': {
      top: '1px !important',
    },
    ':not(#_) .vjs-control:hover': {
      color: `${color.primary} !important`,
    },
    ':not(#_) .vjs-menu-item': {
      color: `${color.white} !important`,
    },
    ':not(#_) .vjs-menu-item.vjs-selected': {
      color: `${color.deepGray} !important`,
    },
    ':not(#_) .vjs-volume-panel': {
      display: {
        default: 'none !important',
        [largeScreen]: 'flex !important',
      },
      alignItems: 'center !important',
    },
    ':not(#_) .vjs-big-play-button': {
      borderRadius: '50% !important',
      top: '50% !important',
      left: '50% !important',
      transform: 'translate(-50%, -50%) !important',
      margin: '0 !important',
      height: '50px !important',
      width: '50px !important',
      borderWidth: '0 !important',
      backgroundColor: `${color.mineShaft} !important`,
    },
    ':not(#_) .vjs-big-play-button .vjs-icon-placeholder:before': {
      borderRadius: '50% !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ':hover': {
        color: color.white,
        backgroundColor: color.primary,
      },
    },
    ':not(#_) .vjs-remaining-time': {
      alignContent: 'center !important',
    },
    ':not(#_) .vjs-live-display': {
      paddingLeft: spacing.small,
      fontSize: fontSize.extraSmall,
      alignSelf: 'center !important',
      display: 'flex !important',
      alignItems: 'center !important',
      gap: '6px !important',
      '::before': {
        content: '""',
        display: 'inline-block',
        width: '7px',
        height: '7px',
        borderRadius: '50%',
        backgroundColor: color.primary,
      },
    },
  },
  preview: {
    ':not(#_) .vjs-control-bar': {
      display: 'none !important',
    },
    ':not(#__) > div': {
      paddingTop: '0 !important',
      height: '100% !important',
    },
  },
})
