import { create, props } from '@stylexjs/stylex'

import { useEffect, useState } from 'react'
import { spacing } from '../../../styles/token.stylex'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { Skeleton } from '../../../components/Skeleton'
import { PostCard } from '../../../post/PostCard'
import { Share } from '../../../components/icons/Share'
import { Report } from '../../../components/icons/Report'
import { replaceLinksWithAnchors } from '../../../common/utils/LinkUtils'

const styles = create({
  container: {
    position: 'relative',
  },
  skeletonContainer: {
    display: 'grid',
    flexDirection: 'column',
    gap: spacing.normal,
    padding: spacing.extraLarge,
    borderRadius: spacing.extraSmall,
    position: 'absolute',
    top: 40,
    left: 0,
    width: '100%',
  },
  skeletonText: {
    height: 66,
  },
  skeletonImage: {
    height: 224,
  },
  hidden: {
    visibility: 'hidden',
  },
})

export const PostCardSkeleton: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)

  // Simulate a loading delay
  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 5000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div {...props(styles.container)}>
      <StyleGuidePanel
        title="Skeleton Loading"
        direction="column"
        variant="noPaddingOnMobile"
        border="noBorderOnMobile"
      >
        {isLoading && (
          <div {...props(styles.skeletonContainer)}>
            <Skeleton type="text" />
            <Skeleton type="text" style={styles.skeletonText} />
            <Skeleton type="image" style={styles.skeletonImage} />
            <Skeleton type="text" style={styles.skeletonText} />
            <Skeleton type="text" />
          </div>
        )}

        <div {...props(isLoading && styles.hidden)}>
          <PostCard
            postUrl="/post/1"
            showComments={false}
            canComment={false}
            postId="1"
            commentsCount={24}
            creator={{
              profilePictureUrl:
                'https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default',
              fullName: 'AmandaPink',
              isVerified: true,
              profileUrl: '/creator5',
            }}
            text={replaceLinksWithAnchors(
              "Playing some early Hallowe'en dressup with my angel lingerie while behaving not at all like an angel 😈"
            )}
            publishedAt={new Date(Date.now() - 2 * 60 * 60 * 1000)}
            onLike={() => console.log('Liked!')}
            onComment={() => console.log('Commented!')}
            onTip={() => console.log('Sent Tip!')}
            likesCount={245000}
            unlockable={{
              price: {
                amount: 9.99,
                currency: 'USD',
              },
              isPaid: false,
              payment: undefined,
              onUnlock: () => console.log('Unlocked Content!'),
            }}
            contextMenuItems={[
              {
                label: 'Share link',
                action: () => console.log('Share link'),
                icon: Share,
              },
              {
                label: 'Report this user',
                action: () => console.log('Report user'),
                icon: Report,
              },
            ]}
          >
            <img
              src="https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/c772a7db-bfb8-4f32-ed65-f07550017f00/default"
              alt="Post"
            />
          </PostCard>
        </div>
      </StyleGuidePanel>
    </div>
  )
}
