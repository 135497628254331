import { create, props } from '@stylexjs/stylex'
import { fonts } from '../styles/font-styles'
import { color, spacing } from '../styles/token.stylex'
import useLanguage from '../translations/useLanguage'
import { Panel } from '../components/Panel'

const styles = create({
  container: {
    paddingBlock: spacing.larger,
    paddingInline: spacing.xxxSmall,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.dustyGray,
  },
})

export const UndefinedDataErrorPanel: React.FC = () => {
  const { t } = useLanguage()
  return (
    <Panel>
      <div {...props(styles.container, fonts.smallSemiBold)}>
        {t('error.undefinedData')}
      </div>
    </Panel>
  )
}
