/* eslint-disable react/jsx-no-literals */
import { create, props } from '@stylexjs/stylex'
import { titleFonts, fonts } from '../../../styles/font-styles'
import { fontSize, fontWeight, spacing } from '../../../styles/token.stylex'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.large,
  },
  tableWrapper: {
    marginBottom: spacing.large,
    overflowY: 'auto',
    width: {
      default: '500px',
      [largeScreen]: '100%',
    },
  },
  table: {
    minWidth: {
      default: 'auto',
      [largeScreen]: '1200px',
    },
    borderCollapse: 'collapse',
    marginTop: spacing.small,
  },
  th: {
    textAlign: 'left',
    fontWeight: fontWeight.semiBold,
    fontSize: fontSize.normal,
    padding: {
      default: spacing.small,
      [largeScreen]: spacing.small,
    },
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
  },
  td: {
    padding: {
      default: spacing.small,
      [largeScreen]: spacing.normal,
    },
    fontSize: fontSize.small,
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  preview: {
    textAlign: 'left',
  },
})

type FontVariant =
  | (typeof titleFonts)[keyof typeof titleFonts]
  | (typeof fonts)[keyof typeof fonts]

type FontDescription = {
  size: string
  lineHeight: string
  weight: string
}

type FontData = {
  style: string
  name: string
  variant: FontVariant
  description: FontDescription
}

const titleFontData: FontData[] = [
  {
    style: '24 - Semibold',
    name: 'xxlSemiBold',
    variant: titleFonts.xxlSemiBold,
    description: { size: '24px', lineHeight: '32px', weight: 'Semibold (600)' },
  },
  {
    style: '20 - Semibold',
    name: 'xlSemiBold',
    variant: titleFonts.xlSemiBold,
    description: { size: '20px', lineHeight: '30px', weight: 'Semibold (600)' },
  },
  {
    style: '20 - Regular',
    name: 'xlRegular',
    variant: titleFonts.xlRegular,
    description: { size: '20px', lineHeight: '30px', weight: 'Regular (400)' },
  },
  {
    style: '18 - Semibold',
    name: 'largeSemiBold',
    variant: titleFonts.largeSemiBold,
    description: { size: '18px', lineHeight: '28px', weight: 'Semibold (600)' },
  },
  {
    style: '16 - Semibold',
    name: 'normalSemibold',
    variant: titleFonts.normalSemibold,
    description: { size: '16px', lineHeight: '24px', weight: 'Semibold (600)' },
  },
  {
    style: '16 - Regular',
    name: 'normalRegular',
    variant: titleFonts.normalRegular,
    description: { size: '16px', lineHeight: '24px', weight: 'Regular (400)' },
  },
]

const bodyFontData: FontData[] = [
  {
    style: '18 - Regular',
    name: 'largeRegular',
    variant: fonts.largeRegular,
    description: { size: '18px', lineHeight: '28px', weight: 'Regular (400)' },
  },
  {
    style: '16 - Semibold',
    name: 'normalSemibold',
    variant: fonts.normalSemiBold,
    description: { size: '16px', lineHeight: '24px', weight: 'Semibold (600)' },
  },
  {
    style: '16 - Medium',
    name: 'normalMedium',
    variant: fonts.normalMedium,
    description: { size: '16px', lineHeight: '24px', weight: 'Medium (500)' },
  },
  {
    style: '16 - Regular',
    name: 'normalRegular',
    variant: fonts.normalRegular,
    description: { size: '14px', lineHeight: '22px', weight: 'Regular (400)' },
  },
  {
    style: '14 - Semibold',
    name: 'smallSemiBold',
    variant: fonts.smallSemiBold,
    description: { size: '14px', lineHeight: '22px', weight: 'Semibold (600)' },
  },
  {
    style: '14 - Regular',
    name: 'smallRegular',
    variant: fonts.smallRegular,
    description: { size: '14px', lineHeight: '22px', weight: 'Regular (400)' },
  },
  {
    style: '12 - Semibold',
    name: 'extraSmallSemiBold',
    variant: fonts.extraSmallSemiBold,
    description: { size: '12px', lineHeight: '18px', weight: 'Semibold (600)' },
  },
  {
    style: '12 - Regular',
    name: 'extraSmallRegular',
    variant: fonts.extraSmallRegular,
    description: { size: '12px', lineHeight: '18px', weight: 'Regular (400)' },
  },
]

const Fonts = () => {
  const renderTable = (title: string, data: typeof titleFontData) => (
    <div>
      <h3>{title}</h3>
      <div {...props(styles.tableWrapper)}>
        <table {...props(styles.table)}>
          <thead>
            <tr>
              <th {...props(styles.th)}>Style</th>
              <th {...props(styles.th)}>Variable</th>
              <th {...props(styles.th)}>Size</th>
              <th {...props(styles.th)}>Line height</th>
              <th {...props(styles.th)}>Font</th>
              <th {...props(styles.th)}>Weight</th>
            </tr>
          </thead>
          <tbody>
            {data.map((font, index) => (
              <tr key={index}>
                <td {...props(styles.td, styles.preview)}>
                  <span {...props(font.variant)}>{font.style}</span>
                </td>
                <td {...props(styles.td)}>{font.name}</td>
                <td {...props(styles.td)}>{font.description.size}</td>
                <td {...props(styles.td)}>{font.description.lineHeight}</td>
                <td {...props(styles.td)}>Poppins</td>
                <td {...props(styles.td)}>{font.description.weight}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  return (
    <StyleGuidePageContainer title="Fonts">
      <div {...props(styles.container)}>
        {renderTable('Titles', titleFontData)}
        {renderTable('Body', bodyFontData)}
      </div>
    </StyleGuidePageContainer>
  )
}

export default Fonts
