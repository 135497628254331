import React, { forwardRef } from 'react'
import { Dialog } from '../dialog/Dialog'
import { LanguageKey } from '../../translations/useLanguage'

export type ConfirmDialogOptions = {
  title: string
  description: string
  onConfirm: () => void
  isLoading?: boolean
  closeOnConfirm?: boolean
  translate(key: LanguageKey, replacements?: Record<string, string>): string
}

type ConfirmDialogProps = {
  options: ConfirmDialogOptions
}

const ConfirmDialogInternal = (
  { options }: ConfirmDialogProps,
  ref: React.Ref<HTMLDialogElement>
) => {
  const {
    title,
    description,
    onConfirm,
    isLoading,
    translate,
    closeOnConfirm,
  } = options

  return (
    <Dialog
      ref={ref}
      title={title}
      description={description}
      showCloseButton={false}
      closeOnOutsideClick={false}
      cancel={{
        label: translate('shared.buttons.cancel'),
        disabled: isLoading,
        action: () =>
          (ref as React.RefObject<HTMLDialogElement>).current?.close(),
      }}
      ok={{
        label: translate('shared.buttons.confirm'),
        action: () => {
          onConfirm()
          if (closeOnConfirm) {
            ;(ref as React.RefObject<HTMLDialogElement>).current?.close()
          }
        },
        isLoading: isLoading,
      }}
      variant="dual"
    />
  )
}

export const ConfirmDialog = forwardRef(ConfirmDialogInternal)
