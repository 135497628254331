import { create, props } from '@stylexjs/stylex'
import useLanguage from '../../translations/useLanguage'
import { spacing } from '../../styles/token.stylex'
import { StreamFeedFilterType } from './useStreamFeedFilter'
import { fonts } from '../../styles/font-styles'

type NoResultProps = {
  selectedFilter: StreamFeedFilterType
}

const styles = create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: spacing.large,
    paddingBottom: spacing.large,
    gap: spacing.small,
  },
})

export const NoResult: React.FC<NoResultProps> = ({ selectedFilter }) => {
  const { t } = useLanguage()

  return (
    <div {...props(styles.wrapper)}>
      <p {...props(fonts.normalMedium)}>
        {t('feeds.filters.empty', {
          category: t(`feeds.filters.${selectedFilter}`),
        })}
      </p>
    </div>
  )
}
