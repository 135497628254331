import { create, props } from '@stylexjs/stylex'
import { fontSize, fontWeight, spacing } from '../../../../styles/token.stylex'
import ColorItem from './ColorItem'
import StyleGuidePageContainer from '../../../layout/StyleGuidePageContainer'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },
  category: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
    paddingBottom: spacing.normal,
  },
  categoryTitle: {
    fontSize: fontSize.extraLarge,
    fontWeight: fontWeight.semiBold,
  },
  colorGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.normal,
  },
})

type ColorCategory = {
  name: string
  colors: { name: string; hex: string; variant: string }[]
}

const rawColors: Record<string, string> = {
  codGray: '#101010',
  mineShaft: '#2f2f2f',
  dustyGray: '#9a9a9a',
  white: '#fff',
  gold: '#ffd700',
  torchRed: '#ff1d49',
  brightTurquoise: '#2bd5fa',
  pasterGreen: '#53e773',
  helitrope: '#9975ff',
}

const colorPalette: ColorCategory[] = [
  {
    name: 'Neutrals',
    colors: [
      {
        name: 'Black 100',
        hex: rawColors.codGray,
        variant: 'codGray',
      },
      {
        name: 'Dark Gray 100',
        hex: rawColors.mineShaft,
        variant: 'mineShaft',
      },
      {
        name: 'Light Gray 100',
        hex: rawColors.dustyGray,
        variant: 'dustyGray',
      },
      { name: 'White 100', hex: rawColors.white, variant: 'white' },
    ],
  },
  {
    name: 'Main',
    colors: [
      {
        name: 'Gold',
        hex: rawColors.gold,
        variant: 'gold',
      },
      {
        name: 'Torch red',
        hex: rawColors.torchRed,
        variant: 'torchRed',
      },
      {
        name: 'Bright turquoise',
        hex: rawColors.brightTurquoise,
        variant: 'brightTurquoise',
      },
      {
        name: 'Paster green',
        hex: rawColors.pasterGreen,
        variant: 'pasterGreen',
      },
      {
        name: 'Helitrope',
        hex: rawColors.helitrope,
        variant: 'helitrope',
      },
    ],
  },
]

const Colors = () => (
  <StyleGuidePageContainer title="Colors">
    <div {...props(styles.container)}>
      {colorPalette.map((category) => (
        <div key={category.name} {...props(styles.category)}>
          <h2 {...props(styles.categoryTitle)}>{category.name}</h2>
          <div {...props(styles.colorGrid)}>
            {category.colors.map((color) => (
              <ColorItem
                key={color.hex}
                name={color.name}
                hex={color.hex}
                variant={color.variant}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  </StyleGuidePageContainer>
)

export default Colors
