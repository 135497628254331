import { create, props } from '@stylexjs/stylex'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { spacing } from '../../../styles/token.stylex'
import { useState } from 'react'
import { Input } from '../../../components/input/Input'
import { TextArea } from '../../../components/input/TextArea'
import { ReactSelect } from '../../../components/input/Select'
import { Wallet } from '../../../components/icons/Wallet'
import { Currency } from '../../../components/icons/Currency'
import { Coin } from '../../../components/icons/Coin'
import { RadioButton } from '../../../components/RadioButton'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.large,
  },
  container: { width: '400px' },
  radioButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
  },
})

const paymentMethodOptions = [
  {
    value: 'wallet',
    label: 'Wallet Credits',
    icon: Wallet,
    active: true,
  },
  {
    value: 'applePay',
    label: 'Apple Pay',
    icon: Currency,
  },
  {
    value: 'googlePay',
    label: 'Google Pay',
    icon: Coin,
  },
]

export const Inputs: React.FC = () => {
  const [inputValue, setInputValue] = useState('AmandaPink')
  const [textAreaValue, setTextAreaValue] = useState(
    'Description text goes here'
  )
  const [errorInputValue, setErrorInputValue] = useState('AmandaPink')
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('wallet')
  const [selectedValue, setSelectedValue] = useState('1')

  const renderInputStates = (
    <StyleGuidePanel title="Inputs" direction="column">
      <Input
        id="default"
        value={inputValue}
        label="Full name"
        required
        placeholder="Enter your full name"
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Input
        id="hovered"
        value={inputValue}
        label="Full name"
        required
        placeholder="Enter your full name"
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Input
        id="focused"
        value={inputValue}
        label="Full name"
        required
        placeholder="Enter your full name"
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Input
        id="error"
        value={errorInputValue}
        label="Full name"
        required
        error="Error description text goes here"
        onChange={(e) => setErrorInputValue(e.target.value)}
      />
      <Input
        id="disabled"
        value="AmandaPink"
        label="Full name"
        required
        disabled
      />
      <Input
        id="with-description"
        value={inputValue}
        label="Full name"
        required
        instruction="Description text goes here"
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Input
        id="only-placeholder"
        value=""
        placeholder="Only placeholder"
        label="Full name"
        required
        instruction="Description text goes here"
        onChange={(e) => setInputValue(e.target.value)}
      />
    </StyleGuidePanel>
  )

  const renderTextAreaStates = (
    <StyleGuidePanel title="Text Areas" direction="column">
      <TextArea
        id="default-textarea"
        value={textAreaValue}
        label="Description"
        required
        placeholder="Enter description here"
        rows={4}
        onChange={(e) => setTextAreaValue(e.target.value)}
      />
      <TextArea
        id="error-textarea"
        value={textAreaValue}
        label="Description"
        required
        error="Error description text goes here"
        rows={4}
        onChange={(e) => setTextAreaValue(e.target.value)}
      />
      <TextArea
        id="disabled-textarea"
        value={textAreaValue}
        label="Description"
        rows={4}
        disabled
      />
      <TextArea
        id="only-placeholder-textarea"
        value=""
        placeholder="Only placeholder"
        label="Description"
        rows={4}
        disabled
      />
    </StyleGuidePanel>
  )

  const renderSelectStates = (
    <StyleGuidePanel title="Select" direction="column">
      <ReactSelect
        required
        id="default-select"
        label="Payment method"
        options={paymentMethodOptions}
        placeholder="Select payment method.."
        value={selectedPaymentMethod}
        onChange={(newValue) => setSelectedPaymentMethod(newValue)}
      />
    </StyleGuidePanel>
  )

  const renderRadioButtonStates = (
    <StyleGuidePanel title="Radio Buttons" direction="column">
      <div {...props(styles.radioButtonContainer)}>
        <p>{'States'}</p>
        <RadioButton
          id="1"
          label="Checked"
          value="1"
          onChange={(e) => setSelectedValue(e.target.value)}
          selectedValue={selectedValue}
        />
        <RadioButton
          id="2"
          label="Unchecked enabled"
          value="2"
          onChange={(e) => setSelectedValue(e.target.value)}
          selectedValue={selectedValue}
        />
        <RadioButton
          id="3"
          label="Unchecked disabled"
          value="3"
          onChange={(val) => console.log(val)}
          selectedValue={selectedValue}
          disabled
        />
        <RadioButton
          id="4"
          label="Checked disabled"
          value="1"
          onChange={(val) => console.log(val)}
          disabled
          selectedValue={'1'}
        />
      </div>
    </StyleGuidePanel>
  )

  return (
    <StyleGuidePageContainer title="Inputs">
      <div {...props(styles.mainContainer)}>
        <div {...props(styles.container)}>{renderInputStates}</div>
        <div {...props(styles.container)}>{renderTextAreaStates}</div>
        <div {...props(styles.container)}>{renderSelectStates}</div>
        <div {...props(styles.container)}>{renderRadioButtonStates}</div>
      </div>
    </StyleGuidePageContainer>
  )
}
