/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ErrorCode = {
    TechnicaLError: 'technicaL_ERROR',
    RequesTValidationError: 'requesT_VALIDATION_ERROR',
    EntitYNotFound: 'entitY_NOT_FOUND',
    ForbiddeNAccess: 'forbiddeN_ACCESS',
    UnauthorizeDAccess: 'unauthorizeD_ACCESS',
    UnauthorizeDContentCreatorAccess: 'unauthorizeD_CONTENT_CREATOR_ACCESS',
    DisplaynamEAlreadyExists: 'displaynamE_ALREADY_EXISTS',
    UsernamENotAvailable: 'usernamE_NOT_AVAILABLE',
    RequirementSNotMeet: 'requirementS_NOT_MEET',
    ChanneLIsAlreadyVerified: 'channeL_IS_ALREADY_VERIFIED',
    UseRIsBannedFromChat: 'useR_IS_BANNED_FROM_CHAT',
    OnlYOnePinnedMessageAllowed: 'onlY_ONE_PINNED_MESSAGE_ALLOWED',
    UseRAlreadyModerator: 'useR_ALREADY_MODERATOR',
    UseRNotAuthorizedToSeePost: 'useR_NOT_AUTHORIZED_TO_SEE_POST',
    PosTNotFound: 'posT_NOT_FOUND'
} as const;
export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode];


export function ErrorCodeFromJSON(json: any): ErrorCode {
    return ErrorCodeFromJSONTyped(json, false);
}

export function ErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorCode {
    return json as ErrorCode;
}

export function ErrorCodeToJSON(value?: ErrorCode | null): any {
    return value as any;
}

