import { create } from '@stylexjs/stylex'
import { fontSize, fontWeight, lineHeight } from './token.stylex'

const defaultFamily =
  '"Poppins", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";'

export const titleFonts = create({
  errorSemiBold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.xxxl,
    lineHeight: lineHeight.xxxl,
    fontWeight: fontWeight.semiBold,
  },
  xxxlSemiBold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.extraLarger,
    lineHeight: lineHeight.xxl,
    fontWeight: fontWeight.semiBold,
  },
  xxlSemiBold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.xxl,
    lineHeight: lineHeight.xxl,
    fontWeight: fontWeight.semiBold,
  },
  xlSemiBold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.extraLarge,
    lineHeight: lineHeight.extraLarge,
    fontWeight: fontWeight.semiBold,
  },
  xlRegular: {
    fontFamily: defaultFamily,
    fontSize: fontSize.extraLarge,
    lineHeight: lineHeight.extraLarge,
    fontWeight: fontWeight.regular,
  },
  largeSemiBold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.large,
    lineHeight: lineHeight.large,
    fontWeight: fontWeight.semiBold,
  },
  normalSemibold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.normal,
    lineHeight: lineHeight.normal,
    fontWeight: fontWeight.semiBold,
  },
  normalMedium: {
    fontFamily: defaultFamily,
    fontSize: fontSize.normal,
    lineHeight: lineHeight.normal,
    fontWeight: fontWeight.medium,
  },
  normalRegular: {
    fontFamily: defaultFamily,
    fontSize: fontSize.normal,
    lineHeight: lineHeight.normal,
    fontWeight: fontWeight.regular,
  },
})

export const fonts = create({
  largeRegular: {
    fontFamily: defaultFamily,
    fontSize: fontSize.large,
    lineHeight: lineHeight.large,
    fontWeight: fontWeight.regular,
  },
  normalSemiBoldCreatorInfo: {
    fontFamily: defaultFamily,
    fontSize: fontSize.normal,
    lineHeight: lineHeight.extraSmall,
    fontWeight: fontWeight.semiBold,
  },
  normalSemiBold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.normal,
    lineHeight: lineHeight.normal,
    fontWeight: fontWeight.semiBold,
  },
  normalMedium: {
    fontFamily: defaultFamily,
    fontSize: fontSize.normal,
    lineHeight: lineHeight.normal,
    fontWeight: fontWeight.medium,
  },
  normalRegular: {
    fontFamily: defaultFamily,
    fontSize: fontSize.normal,
    lineHeight: lineHeight.normal,
    fontWeight: fontWeight.regular,
  },
  smallSemiBold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.small,
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.semiBold,
  },
  smallRegular: {
    fontFamily: defaultFamily,
    fontSize: fontSize.small,
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.regular,
  },
  extraSmallSemiBold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.extraSmall,
    lineHeight: lineHeight.extraSmall,
    fontWeight: fontWeight.semiBold,
  },
  extraSmallRegular: {
    fontFamily: defaultFamily,
    fontSize: fontSize.extraSmall,
    lineHeight: lineHeight.extraSmall,
    fontWeight: fontWeight.regular,
  },
  smallMedium: {
    fontFamily: defaultFamily,
    fontSize: fontSize.small,
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.medium,
  },
  xxSmallRegular: {
    fontFamily: defaultFamily,
    fontSize: fontSize.xxSmall,
    lineHeight: lineHeight.xxSmall,
    fontWeight: fontWeight.regular,
  },
  xxSmallSemiBold: {
    fontFamily: defaultFamily,
    fontSize: fontSize.xxSmall,
    lineHeight: lineHeight.xxSmall,
    fontWeight: fontWeight.semiBold,
  },
})
