import { Navbar } from '../../../layout/navbar/Navbar'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { create, props } from '@stylexjs/stylex'
import { useUnderConstructionDialog } from '../under-construction/under-construction-context'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
  },
  desktopContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
})

export const Layout: React.FC = () => {
  const { openDialog } = useUnderConstructionDialog()

  const openMail = () => openDialog('86c0yv871')
  const openNotification = () => openDialog('86c0xeyp9')

  return (
    <StyleGuidePageContainer title="Layout">
      <div {...props(styles.container)}>
        <StyleGuidePanel
          direction="column"
          title="Desktop Navbar (change view width to see responsiveness)"
          gap="small"
        >
          <div {...props(styles.desktopContainer)}>
            <Navbar
              onMailClick={openMail}
              onNotificationClick={openNotification}
            />
            <Navbar
              avatarImageSrc="images/sample/girl2.png"
              onMailClick={openMail}
              onNotificationClick={openNotification}
              displayName="@JohnDoe"
            />
            <Navbar
              avatarImageSrc="images/sample/girl2.png"
              notificationCount={10}
              onMailClick={openMail}
              onNotificationClick={openNotification}
              displayName="@JohnDoe"
            />
            <Navbar
              notificationCount={10}
              onMailClick={openMail}
              onNotificationClick={openNotification}
              displayName="@JohnDoe"
            />
          </div>
        </StyleGuidePanel>
      </div>
    </StyleGuidePageContainer>
  )
}
