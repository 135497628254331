import React, { useRef, useState } from 'react'
import { PostCard } from './PostCard'
import { replaceLinksWithAnchors } from '../common/utils/LinkUtils'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useUserContext } from '../auth/user-context'
import { GetPostResponse } from './../client/models/GetPostResponse'
import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../api/ApiContext'
import { Slides } from './slides/Slides'
import useLanguage from '../translations/useLanguage'
import { queryKeys } from '../constants/query-keys'
import { toast } from 'react-toastify'
import { Dialog } from '../components/dialog/Dialog'
import { ReportingModalContent } from '../reporting/ReportModalContent'
import { TipModalContent } from './tip/TipModalContent'
import { routes } from '../router/routes'
import { UnlockCard } from './restricted-media/UnlockCard'
import { Unlockable } from '../common/types'
import { PpvModalContent } from './ppv/PpvModalContent'
import { ConfirmDialog } from '../components/confirm-dialog/ConfirmDialog'
import { useNavigate } from 'react-router-dom'
import { handlePostLinkCopy } from './utils'

type SmartPostCardProps = {
  post: GetPostResponse
  isPostCardsRestricted: boolean
  showComments?: boolean
}

type LikeState = {
  isLiked: boolean
  likesCount: number
  likeInProgress: boolean
  onLike?: () => void
}

export const SmartPostCard: React.FC<SmartPostCardProps> = ({
  post,
  showComments = false,
}) => {
  const { data: currentUser } = useUserContext()
  const { postApi } = useApi()
  const { t } = useLanguage()
  const confirmDialogRef = useRef<HTMLDialogElement>(null)
  const reportDialogRef = useRef<HTMLDialogElement>(null)
  const tipDialogRef = useRef<HTMLDialogElement>(null)
  const ppvDialogRef = useRef<HTMLDialogElement>(null)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const commentSectionRef = useRef<HTMLDivElement>(null)

  const [isPpvModalOpen, setIsPpvModalOpen] = useState(false)
  const [isTipModalOpen, setIsTipModalOpen] = useState(false)

  const openTipModal = () => {
    setIsTipModalOpen(true)
    tipDialogRef.current?.showModal()
  }

  const closeTipModal = () => {
    setIsTipModalOpen(false)
    tipDialogRef.current?.close()
  }

  const openPpvModal = () => {
    setIsPpvModalOpen(true)
    ppvDialogRef.current?.showModal()
  }

  const closePpvModal = () => {
    setIsPpvModalOpen(false)
    ppvDialogRef.current?.close()
  }

  const [likeState, setLikeState] = useState<LikeState>({
    isLiked: post.isLiked,
    likesCount: post.likeCount,
    likeInProgress: false,
    onLike: () => mutation.mutate(),
  })

  const isOwnPost = currentUser?.id === post.author.id

  const handleComment = () => {
    if (commentSectionRef.current) {
      commentSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    } else {
      navigate(routes.channel.postById(post.postId, post.author.slug))
    }
  }

  const mutation = useMutation({
    mutationFn: () => postApi.postPostIdLikePost({ postId: post.postId }),
    onMutate: async () => {
      setLikeState((prev) => ({
        ...prev,
        isLiked: !prev.isLiked,
        likesCount: prev.isLiked ? prev.likesCount - 1 : prev.likesCount + 1,
        likeInProgress: true,
      }))
    },
    onSettled: () => {
      setLikeState((prev) => ({
        ...prev,
        likeInProgress: false,
      }))
    },
    onError: () => {
      setLikeState((prev) => ({
        ...prev,
        isLiked: !prev.isLiked,
        likesCount: prev.isLiked ? prev.likesCount - 1 : prev.likesCount + 1,
        likeInProgress: false,
      }))
    },
  })

  const { mutate: deletePost, isLoading } = useMutation({
    mutationFn: () => postApi.postPostIdDelete({ postId: post.postId }),
    onSuccess: () => queryClient.invalidateQueries(queryKeys.posts.default),
    onSettled: () => confirmDialogRef.current?.close(),
    onError: () => {
      toast.error(t('post.postCard.postCardActions.delete.failed'))
    },
  })

  const contextMenuItems = [
    {
      label: t('post.postCard.postCardActions.copyLinkToPost.title'),
      action: () => handlePostLinkCopy(window.location.href, t),
    },
    ...(isOwnPost
      ? [
          {
            label: t('post.postCard.postCardActions.delete.title'),
            action: () => confirmDialogRef.current?.showModal(),
          },
        ]
      : []),
    ...(currentUser && !isOwnPost
      ? [
          {
            label: t('post.postCard.postCardActions.report.title'),
            action: () => reportDialogRef.current?.showModal(),
          },
        ]
      : []),
  ]

  const unlockable: Unlockable | undefined = post.isLocked
    ? {
        price: {
          amount: post.purchase.price.amount,
          currency: post.purchase.price.currency,
        },
        onUnlock: () => {
          openPpvModal()
        },
        payment: {
          amount: post.purchase.purchase?.amount || 0,
          currency: post.purchase.purchase?.currency || 'JPY',
        },
        isPaid: post.purchase.isPaid,
        showUnlockButton: !isOwnPost && !post.purchase.isPaid,
      }
    : undefined

  const canViewPost = isOwnPost || post.purchase.isPaid || !post.isLocked

  return (
    <div>
      <PostCard
        postId={post.postId}
        commentsCount={post.commentsCount}
        showFooter={!!currentUser}
        onComment={post.canComment ? handleComment : undefined}
        canComment={post.canComment}
        showComments={showComments}
        onLike={post.canLike ? likeState.onLike : undefined}
        likesCount={likeState.likesCount}
        isLiked={likeState.isLiked}
        onTip={!isOwnPost ? () => openTipModal() : undefined}
        likeInProgress={likeState.likeInProgress}
        creator={{
          fullName: post.author.name,
          profilePictureUrl: post.author.profilePicture?.source,
          isVerified: post.author.isVerified,
          avatarImageSize: 'small',
          profileUrl: `/${post.author.slug}`,
        }}
        contextMenuItems={contextMenuItems}
        unlockable={unlockable}
        text={replaceLinksWithAnchors(post.text)}
        publishedAt={post.publishedAt!}
        postUrl={routes.channel.postById(post.postId, post.author.slug)}
        commentSectionRef={commentSectionRef}
        isLive={post.author.isLive}
      >
        {!canViewPost && (
          <UnlockCard
            stats={{
              videosCount: post.videosCount,
              photosCount: post.imagesCount,
            }}
          />
        )}
        {canViewPost && (
          <Slides images={post.images ?? []} videos={post.videos ?? []} />
        )}
      </PostCard>
      <ConfirmDialog
        ref={confirmDialogRef}
        options={{
          title: t('post.postCard.postCardActions.delete.title'),
          description: t('post.postCard.postCardActions.delete.message'),
          onConfirm: () => deletePost(),
          isLoading: isLoading,
          translate: t,
        }}
      />
      {!isOwnPost && !!currentUser && (
        <Dialog
          ref={reportDialogRef}
          title={t('post.postCard.postCardActions.report.title')}
          showCloseButton={true}
          cancel={{
            label: t('shared.buttons.cancel'),
            action: () => reportDialogRef.current?.close(),
          }}
        >
          <ReportingModalContent
            onClose={() => reportDialogRef.current?.close()}
            resourceId={post.postId}
            reportType="post"
          />
        </Dialog>
      )}
      {!isOwnPost && !!currentUser && (
        <Dialog
          closeOnOutsideClick={false}
          ref={tipDialogRef}
          title={t('tip.sendTip.title')}
          showCloseButton={true}
          cancel={{
            label: t('shared.buttons.cancel'),
            action: () => closeTipModal(),
          }}
        >
          <TipModalContent
            maximumPrice={post.tipConfiguration.maxAmount}
            minimumPrice={post.tipConfiguration.minAmount}
            postId={post.postId}
            recipient={{
              slug: post.author.slug,
              fullName: post.author.name,
              isVerified: post.author.isVerified,
              profilePictureUrl: post.author.profilePicture?.source,
              userId: post.author.id,
            }}
            tipSource="post"
            isOpen={isTipModalOpen}
          />
        </Dialog>
      )}

      {!!currentUser && !isOwnPost && post.isLocked && (
        <div>
          <Dialog
            ref={ppvDialogRef}
            title={t('ppv.title')}
            showCloseButton={true}
            cancel={{
              label: t('shared.buttons.cancel'),
              action: () => {
                closePpvModal()
              },
            }}
            closeOnOutsideClick={false}
          >
            <PpvModalContent post={post} isOpen={isPpvModalOpen} />
          </Dialog>
        </div>
      )}
    </div>
  )
}
