import { GetChannelPromoPanelResponse } from '../client'
import { PanelItem } from '../channel/banners/BannerSlider'

export const mapPromoPanelsToSlides = (
  promoPanels: GetChannelPromoPanelResponse[]
): PanelItem[] =>
  promoPanels.map((panel) => ({
    id: panel.id,
    src: panel.image?.source,
    linkUrl: panel.imageLinkUrl || undefined,
  }))
