import { create, props } from '@stylexjs/stylex'
import { color, fontSize, fontWeight, spacing } from '../../styles/token.stylex'
import { PropsWithChildren } from 'react'

const styles = create({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000061',
    color: color.white,
    padding: `0 ${spacing.extraSmall}`,
    borderRadius: '0.5rem',
    textTransform: 'lowercase',
    fontSize: fontSize.extraSmall,
    fontWeight: fontWeight.medium,
  },
})

export const StreamCardInfoBadge: React.FC<PropsWithChildren> = ({
  children,
}) => <div {...props(styles.container)}>{children}</div>
