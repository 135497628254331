/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetNotificationResponsePagedApiResponse,
  NotificationType,
} from '../models/index';
import {
    GetNotificationResponsePagedApiResponseFromJSON,
    GetNotificationResponsePagedApiResponseToJSON,
    NotificationTypeFromJSON,
    NotificationTypeToJSON,
} from '../models/index';

export interface NotificationsGetRequest {
    type?: NotificationType;
    pageNumber?: number;
    pageSize?: number;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * Retrieves a paginated list of notifications for the logged-in user, with optional filtering by notification type.
     */
    async notificationsGetRaw(requestParameters: NotificationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNotificationResponsePagedApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNotificationResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a paginated list of notifications for the logged-in user, with optional filtering by notification type.
     */
    async notificationsGet(requestParameters: NotificationsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNotificationResponsePagedApiResponse> {
        const response = await this.notificationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Marks all unread notifications as read for the logged-in user.
     */
    async notificationsReadPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/notifications/read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Marks all unread notifications as read for the logged-in user.
     */
    async notificationsReadPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsReadPostRaw(initOverrides);
    }

    /**
     * Injects a test notification into the database for testing purposes.
     */
    async notificationsTestInjectNotificationPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/notifications/test/inject-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Injects a test notification into the database for testing purposes.
     */
    async notificationsTestInjectNotificationPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsTestInjectNotificationPostRaw(initOverrides);
    }

    /**
     * Retrieves the count of unread notifications for the logged-in user.
     */
    async notificationsTestUnreadCountGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/notifications/test/unread-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Retrieves the count of unread notifications for the logged-in user.
     */
    async notificationsTestUnreadCountGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.notificationsTestUnreadCountGetRaw(initOverrides);
        return await response.value();
    }

}
