import { create, props } from '@stylexjs/stylex'
import React from 'react'
import {
  DepositStatus,
  GetDepositHistoryResponse,
  PaymentType,
} from '../client'
import { TextLink } from '../components/TextLink'
import { color, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import useLanguage from '../translations/useLanguage'
import { routes } from '../router/routes'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xSmall,
    backgroundColor: color.codGrayLight,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: {
      default: spacing.xxxSmall,
      [largeScreen]: spacing.xSmall,
    },
    paddingBlock: spacing.xxxSmall,
    backgroundColor: color.codGrayLight,
    gap: spacing.normal,
  },
  header: {
    color: color.dustyGray,
    paddingBlock: spacing.xSmall,
  },
  wrapper: {
    width: '100%',
    maxWidth: '888px',
    marginInline: 'auto',
  },
  orderId: {
    color: color.dustyGray,
  },
  divider: {
    borderBottom: `1px solid ${color.dustyGray}`,
    paddingBottom: '0.5rem',
  },
  buttonContainer: {
    marginTop: '2rem',
    textAlign: 'center',
  },
  emptyList: {
    padding: '1rem',
    textAlign: 'center',
  },
})

type PaymentHistoryRowItemProps = {
  row: GetDepositHistoryResponse
  key: number
}

export const PaymentHistoryRowItem: React.FC<PaymentHistoryRowItemProps> = ({
  row,
}) => {
  const { formatFullDate, formatCurrency, t } = useLanguage()

  const renderStatus = (status: DepositStatus) => {
    let statusColor = color.gold
    if (status === 'success') statusColor = color.pasterGreen
    else if (status === 'failed') statusColor = color.torchRed

    return (
      <div style={{ color: statusColor }}>
        {t(`paymentHistory.${status}`).toUpperCase()}
      </div>
    )
  }

  const getPaymentHistoryText = () => {
    if (row.paymentType === PaymentType.Ppv) {
      return (
        <span>
          {row.post && (
            <>
              <TextLink
                to={routes.channel.postById(row.post.postId, row.post.slug)}
              >
                {t('paymentHistory.post')}
              </TextLink>{' '}
              {t('paymentHistory.ppv')}
            </>
          )}
          {!row.post && <>{t('paymentHistory.postPpv')}</>}
        </span>
      )
    }

    return (
      <span>{row.paymentType && t(`paymentHistory.${row.paymentType}`)}</span>
    )
  }

  return (
    <React.Fragment>
      <div {...props(styles.row)}>
        <div {...props(fonts.smallSemiBold)}>
          {formatFullDate(row.createdAt)}
        </div>
        <div {...props(fonts.smallSemiBold)}>
          {`${formatCurrency(row.amount + row.vatAmount, {
            currency: row.currency,
          })} (${t('paymentHistory.vat').toUpperCase()} ${row.vatPercentage * 100}%)`}
        </div>
      </div>
      <div {...props(styles.row)}>
        <div>
          {getPaymentHistoryText()}
          <TextLink font="smallRegular" to={`/${row.user.slug}`}>
            {row.user.displayName}
          </TextLink>
        </div>
        <div>{row.status && renderStatus(row.status)}</div>
      </div>
      <div
        {...props(
          styles.row,
          styles.divider,
          styles.orderId,
          fonts.extraSmallRegular
        )}
      >
        {`ID: ${row.orderId}`}
      </div>
    </React.Fragment>
  )
}
