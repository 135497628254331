/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ContentType = {
    LiveStream: 'liveStream',
    Vod: 'vod'
} as const;
export type ContentType = typeof ContentType[keyof typeof ContentType];


export function ContentTypeFromJSON(json: any): ContentType {
    return ContentTypeFromJSONTyped(json, false);
}

export function ContentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentType {
    return json as ContentType;
}

export function ContentTypeToJSON(value?: ContentType | null): any {
    return value as any;
}

