import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { titleFonts } from '../styles/font-styles'
import useLanguage from '../translations/useLanguage'

const styles = create({
  container: {
    backgroundColor: color.codGrayLight,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.large,
    padding: spacing.small,
  },
  description: {
    paddingTop: spacing.normal,
  },
})

export const CreatorDeniedStatusCard: React.FC = () => {
  const { t } = useLanguage()
  return (
    <div {...props(styles.container)}>
      <div {...props(titleFonts.xlSemiBold)}>
        {t('becomeACreator.deniedStatus.title')}
      </div>
      <div {...props(styles.description)}>
        {t('becomeACreator.deniedStatus.statusMessage')}
      </div>
    </div>
  )
}

export default CreatorDeniedStatusCard
