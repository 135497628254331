import { create, props } from '@stylexjs/stylex'
import { Panel } from '../components/Panel'
import { useUserContext } from '../auth/user-context'
import useLanguage from '../translations/useLanguage'
import { PageHeader } from '../layout/PageHeader'
import { CreatorOnboardingStatus } from '../client'
import CreatorRequirementsNotMetCard from './CreatorRequirementsNotMetCard'
import CreatorRequirementsFulfilledCard from './CreatorRequirementsFulfilledCard'
import CreatorRequestedStatusCard from './CreatorRequestedStatusCard'
import CreatorDeniedStatusCard from './CreatorDeniedStatusCard'

const styles = create({
  container: {
    maxWidth: '888px',
    width: '100%',
    marginInline: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  panelContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
})

export const BecomeACreator = () => {
  const { data: user } = useUserContext()
  const { t } = useLanguage()

  if (!user) {
    throw new Error('User is not defined')
  }

  return (
    <div {...props(styles.container)}>
      <PageHeader>{t('becomeACreator.title').toUpperCase()}</PageHeader>
      <Panel>
        {user.onboarding.status ===
          CreatorOnboardingStatus.RequirementsNotMet && (
          <CreatorRequirementsNotMetCard />
        )}
        {user.onboarding.status ===
          CreatorOnboardingStatus.RequirementsFulfilled && (
          <CreatorRequirementsFulfilledCard />
        )}
        {user.onboarding.status === CreatorOnboardingStatus.Requested && (
          <CreatorRequestedStatusCard />
        )}
        {user.onboarding.status === CreatorOnboardingStatus.Denied && (
          <CreatorDeniedStatusCard />
        )}
      </Panel>
    </div>
  )
}

export default BecomeACreator
