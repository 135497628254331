/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCommentAuthorResponse } from './GetCommentAuthorResponse';
import {
    GetCommentAuthorResponseFromJSON,
    GetCommentAuthorResponseFromJSONTyped,
    GetCommentAuthorResponseToJSON,
} from './GetCommentAuthorResponse';

/**
 * Represents the response for retrieving a comment.
 * @export
 * @interface GetCommentResponse
 */
export interface GetCommentResponse {
    /**
     * Gets or sets the unique identifier of the comment.
     * @type {string}
     * @memberof GetCommentResponse
     */
    id: string;
    /**
     * Gets or sets the unique identifier of the post to which the comment belongs.
     * @type {string}
     * @memberof GetCommentResponse
     */
    postId: string;
    /**
     * Gets or sets the text content of the comment.
     * @type {string}
     * @memberof GetCommentResponse
     */
    text: string;
    /**
     * Gets or sets the date and time when the comment was posted.
     * @type {Date}
     * @memberof GetCommentResponse
     */
    postedAt: Date;
    /**
     * 
     * @type {GetCommentAuthorResponse}
     * @memberof GetCommentResponse
     */
    author: GetCommentAuthorResponse;
    /**
     * Gets or sets a value indicating whether the current user can delete the comment.
     * @type {boolean}
     * @memberof GetCommentResponse
     */
    canDelete: boolean;
}

/**
 * Check if a given object implements the GetCommentResponse interface.
 */
export function instanceOfGetCommentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "postId" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "postedAt" in value;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "canDelete" in value;

    return isInstance;
}

export function GetCommentResponseFromJSON(json: any): GetCommentResponse {
    return GetCommentResponseFromJSONTyped(json, false);
}

export function GetCommentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCommentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'postId': json['postId'],
        'text': json['text'],
        'postedAt': (new Date(json['postedAt'])),
        'author': GetCommentAuthorResponseFromJSON(json['author']),
        'canDelete': json['canDelete'],
    };
}

export function GetCommentResponseToJSON(value?: GetCommentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'postId': value.postId,
        'text': value.text,
        'postedAt': (value.postedAt.toISOString()),
        'author': GetCommentAuthorResponseToJSON(value.author),
        'canDelete': value.canDelete,
    };
}

