import React, { useRef } from 'react'
import { useQuery } from 'react-query'
import { create, props } from '@stylexjs/stylex'
import { Button } from '../../components/Button'
import { fonts, titleFonts } from '../../styles/font-styles'
import { color, spacing } from '../../styles/token.stylex'
import { WithdrawalDialog } from './WithdrawalDialog'
import { useApi } from '../../api/ApiContext'
import { queryKeys } from '../../constants/query-keys'
import { GetWalletBalance } from '../../client'
import useLanguage from '../../translations/useLanguage'
import { LoadingIndicator } from '../../components/indicators/LoadingIndicator'
import { toast } from 'react-toastify'
import { useErrorNotification } from '../../translations/useErrorNotification'
import { UndefinedDataErrorPanel } from '../../api/UndefinedDataErrorPanel'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xSmall,
    padding: spacing.normal,
    backgroundColor: color.codGrayLight,
    borderRadius: '8px',
    color: color.white,
    width: {
      default: '100%',
      [largeScreen]: 'calc(50% - 1.25rem)',
    },
    marginLeft: '0px',
    minHeight: '222px',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xSmall,
  },
  balanceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xSmall,
    width: 'fit-content',
  },
  balance: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: spacing.normal,
  },
  description: {
    color: color.dustyGray,
    marginTop: spacing.small,
    marginBottom: spacing.normal,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  title: {
    color: color.dustyGray,
  },
})

export const Balance: React.FC = () => {
  const { formatCurrency, t } = useLanguage()
  const withdrawalDialog = useRef<HTMLDialogElement>(null)
  const { payoutsApi } = useApi()
  const notifyError = useErrorNotification()

  const { data: wallet, isLoading } = useQuery<GetWalletBalance>({
    queryKey: queryKeys.payouts.balance,
    queryFn: () => payoutsApi.payoutsBalancesGet(),
    onError: notifyError(() => toast.error(t('balance.error')), false),
  })

  if (!isLoading && wallet === undefined) {
    return <UndefinedDataErrorPanel />
  }

  return (
    <div {...props(styles.wrapper)}>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <div {...props(styles.container)}>
          <div {...props(styles.balanceContainer)}>
            <div {...props(styles.balance, titleFonts.xlSemiBold)}>
              <div>{t('balance.balance')}</div>
              <div>
                {formatCurrency(wallet.payoutAvailable || 0, {
                  currency: wallet.currency,
                  maximumFractionDigits: 0,
                })}
              </div>
            </div>
            <div {...props(styles.balance, styles.title, fonts.smallSemiBold)}>
              <div>{t('balance.pendingBalance')}</div>
              <div>
                {formatCurrency(wallet.payoutPending || 0, {
                  currency: wallet.currency,
                  maximumFractionDigits: 0,
                })}
              </div>
            </div>
          </div>
          <div {...props(styles.description, fonts.extraSmallRegular)}>
            {t('balance.description', {
              payoutPendingDays: wallet.payoutPendingDays.toString(),
            })}
          </div>
          <div {...props(styles.buttonContainer)}>
            <Button
              onClick={() => withdrawalDialog.current?.showModal()}
              variant="cta"
            >
              {t('balance.requestWithdrawal')}
            </Button>
          </div>
          <WithdrawalDialog
            ref={withdrawalDialog}
            currency={wallet.currency}
            maxPayout={wallet.maxPayout}
            minPayout={wallet.minPayout}
          />
        </div>
      )}
    </div>
  )
}
