/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetStreamerResponse
 */
export interface GetStreamerResponse {
    /**
     * Gets or sets the name of the author.
     * @type {string}
     * @memberof GetStreamerResponse
     */
    name: string;
    /**
     * Gets or sets the unique identifier of the author.
     * @type {number}
     * @memberof GetStreamerResponse
     */
    id: number;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetStreamerResponse
     */
    profilePicture?: ImageVariantsResponse;
    /**
     * Gets or sets the slug URL-friendly of the author.
     * @type {string}
     * @memberof GetStreamerResponse
     */
    channelSlug: string;
}

/**
 * Check if a given object implements the GetStreamerResponse interface.
 */
export function instanceOfGetStreamerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "channelSlug" in value;

    return isInstance;
}

export function GetStreamerResponseFromJSON(json: any): GetStreamerResponse {
    return GetStreamerResponseFromJSONTyped(json, false);
}

export function GetStreamerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStreamerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : ImageVariantsResponseFromJSON(json['profilePicture']),
        'channelSlug': json['channelSlug'],
    };
}

export function GetStreamerResponseToJSON(value?: GetStreamerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'profilePicture': ImageVariantsResponseToJSON(value.profilePicture),
        'channelSlug': value.channelSlug,
    };
}

