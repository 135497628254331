import { ChannelContextProvider } from '../channel/context/ChannelContextProvider'
import { useUserContext } from '../auth/user-context'

import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { Dashboard } from './Dashboard'

export const MyProfile: React.FC = () => {
  const { data, isLoading } = useUserContext()

  if (isLoading) return <LoadingScreen />

  // NOTE: handle it nicely, this may indicate that data fetchnig failed
  if (!data) return <LoadingScreen />

  return (
    <ChannelContextProvider slug={data.streamerChannel.slug}>
      <Dashboard />
    </ChannelContextProvider>
  )
}

export default MyProfile
