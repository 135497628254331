/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Response model representing the current viewer count for a stream session.
 * @export
 * @interface ViewerCountResponse
 */
export interface ViewerCountResponse {
    /**
     * Gets or sets the viewer count.
     * @type {number}
     * @memberof ViewerCountResponse
     */
    viewerCount?: number;
}

/**
 * Check if a given object implements the ViewerCountResponse interface.
 */
export function instanceOfViewerCountResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ViewerCountResponseFromJSON(json: any): ViewerCountResponse {
    return ViewerCountResponseFromJSONTyped(json, false);
}

export function ViewerCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewerCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewerCount': !exists(json, 'viewerCount') ? undefined : json['viewerCount'],
    };
}

export function ViewerCountResponseToJSON(value?: ViewerCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'viewerCount': value.viewerCount,
    };
}

