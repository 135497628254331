import { PropsWithChildren } from 'react'
import { create, props, StyleXStyles } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    gap: spacing.small,
    display: 'flex',
    borderRadius: spacing.xxSmall,
  },
  border: {
    border: `2px dashed ${color.helitrope}`,
  },
  noBorder: {
    border: 'none',
  },
  column: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  small: { gap: spacing.xSmall },

  title: {
    padding: spacing.normal,
  },
  childrenWrapper: {
    display: 'flex',
    padding: spacing.small,
    width: '100%',
    gap: spacing.normal,
  },
  darkTheme: {
    backgroundColor: color.codGray,
  },
  lightTheme: {
    backgroundColor: color.codGrayLight,
  },
  fit: {
    width: 'fit-content',
  },
  full: {
    width: '100%',
  },
  wrapOnMobile: {
    flexWrap: {
      default: 'wrap',
      [largeScreen]: 'unset',
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  padding: {
    padding: `${spacing.extraSmall} ${spacing.small}`,
  },
  noPadding: {
    padding: 0,
  },
  noBorderOnMobile: {
    border: {
      default: 'none',
      [largeScreen]: `2px dashed ${color.helitrope}`,
    },
  },
  noPaddingOnMobile: {
    padding: {
      default: 0,
      [largeScreen]: `${spacing.extraSmall} ${spacing.small}`,
    },
  },
})

type StyleGuidePanelProps = {
  title?: string
  direction?: 'row' | 'column'
  gap?: 'small'
  border?: 'border' | 'noBorder' | 'noBorderOnMobile'
  titleFontSize?: 'normalSemiBold' | 'smallSemiBold'
  variant?: 'padding' | 'noPadding' | 'noPaddingOnMobile'
  theme?: 'dark' | 'light'
  width?: 'fit' | 'full'
  wrap?: 'wrapOnMobile' | 'wrap' | 'noWrap'
  contentStyle?: StyleXStyles<{
    height?: string
    width?: string
    maxWidth?: string
  }>
}

export const StyleGuidePanel: React.FC<
  PropsWithChildren<StyleGuidePanelProps>
> = ({
  title,
  direction = 'column',
  border = 'border',
  wrap,
  gap,
  titleFontSize = 'normalSemiBold',
  children,
  width = 'full',
  contentStyle,
  theme = 'light',
  variant = 'padding',
}) => {
  return (
    <div>
      {title && (
        <div {...props(styles.title, fonts[titleFontSize])}>{title}</div>
      )}
      <div
        {...props(
          styles.container,
          direction && styles[direction],
          border && styles[border],
          styles[`${theme}Theme`],
          width && styles[width],
          variant && styles[variant]
        )}
      >
        <div
          {...props(
            styles.childrenWrapper,
            gap && styles[gap],
            contentStyle,
            wrap && styles[wrap],
            variant && styles[variant]
          )}
          style={{ flexDirection: direction }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
