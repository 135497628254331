import { create, props } from '@stylexjs/stylex'
import { PropsWithChildren } from 'react'
import { color, spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
  },
  label: {
    paddingBottom: spacing.xxSmall,
  },
  instruction: {
    color: color.dustyGray,
  },
  required: {
    color: color.inputOutlineFocus,
  },
  error: {
    color: color.error,
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    gap: spacing.extraLarge,
  },
  instructionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxSmall,
  },
})

type InputPanelProps = {
  id?: string
  required?: boolean
  label?: string
  instruction?: string
  error?: string
  prefix?: React.ReactNode
  maxLength?: number
  characterCount?: number
  secondInstruction?: string
}

export const InputPanel: React.FC<PropsWithChildren<InputPanelProps>> = ({
  id,
  required,
  label,
  error,
  instruction,
  children,
  maxLength,
  characterCount,
  secondInstruction,
}) => (
  <div {...props(styles.container)}>
    {!!label && (
      <label htmlFor={id} {...props(styles.label, fonts.smallMedium)}>
        <span>{label}</span>
        {required && <span {...props(styles.required)}>{' *'}</span>}
      </label>
    )}

    {children}
    <div {...props(styles.labelContainer)}>
      <div {...props(styles.instructionContainer)}>
        {!error && instruction && (
          <p {...props(styles.instruction, fonts.extraSmallRegular)}>
            {instruction}
          </p>
        )}

        {!!error && (
          <p {...props(styles.error, fonts.extraSmallRegular)}>{error}</p>
        )}
        {!error && secondInstruction && (
          <p {...props(styles.instruction, fonts.extraSmallRegular)}>
            {secondInstruction}
          </p>
        )}
      </div>
      {!!maxLength && characterCount !== undefined && (
        <p
          {...props(styles.instruction, fonts.extraSmallRegular)}
        >{`${characterCount}/${maxLength}`}</p>
      )}
    </div>
  </div>
)
