import type { SVGProps } from 'react'

export const ChatDots: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 12.3333V5.99994V5.99721C17.5 5.06561 17.5 4.59952 17.3185 4.24335C17.1587 3.92974 16.9034 3.67476 16.5898 3.51497C16.2333 3.33331 15.7669 3.33331 14.8335 3.33331H5.16683C4.23341 3.33331 3.76635 3.33331 3.40983 3.51497C3.09623 3.67476 2.84144 3.92974 2.68166 4.24335C2.5 4.59987 2.5 5.06652 2.5 5.99994V15.5591C2.5 16.4472 2.5 16.8913 2.68205 17.1194C2.84037 17.3177 3.08036 17.4331 3.33415 17.4328C3.62596 17.4325 3.97287 17.155 4.66634 16.6003L5.93631 15.5843C6.20703 15.3677 6.34237 15.2595 6.4931 15.1825C6.62683 15.1142 6.76899 15.0642 6.9161 15.034C7.0819 15 7.25544 15 7.60213 15H14.8334C15.7668 15 16.2333 15 16.5898 14.8183C16.9034 14.6585 17.1587 14.4036 17.3185 14.09C17.5 13.7338 17.5 13.2676 17.5 12.336V12.3333Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
