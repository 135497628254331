import { StyleGuidePanel } from '../StyleGuidePanel'
import { create, props } from '@stylexjs/stylex'
import { DisplayName } from '../../../components/DisplayName'
import { spacing } from '../../../styles/token.stylex'
import { CreatorInfo } from '../../../components/CreatorInfo'
import { AvatarImage } from '../../../components/avatars/AvatarImage'
import { Avatar } from '../../../components/avatars/Avatar'
import { FullCreatorInfo } from '../../../components/FullCreatorInfo'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  mailContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.large,
    width: '100%',
  },
})

const CreatorCards = () => (
  <StyleGuidePageContainer title="Creator cards">
    <div {...props(styles.mailContainer)}>
      <StyleGuidePanel direction="row" title="Avatar Image" wrap="wrapOnMobile">
        <AvatarImage
          src="../images/sample/girl.png"
          alt="Profile image"
          variant="large"
        />
        <AvatarImage alt="Profile image" variant="large" />
        <AvatarImage
          src="../images/sample/girl.png"
          alt="Profile image"
          variant="medium"
        />
        <AvatarImage alt="Profile image" variant="medium" />
        <AvatarImage src="../images/sample/girl.png" alt="Profile image" />
        <AvatarImage alt="Profile image" />
      </StyleGuidePanel>
      <StyleGuidePanel direction="row" title="Avatar">
        <Avatar
          src="https://imagedelivery.net/j2Fhii6F3JKCIpNNgswK6g/f5073878-a7cf-4891-5d5e-22d8417b8000/default"
          alt="Image"
        />
        <Avatar src="../images/sample/girl.png" alt="Image" isLive={true} />
        <Avatar alt="Image" />
        <Avatar alt="Image" isLive />
      </StyleGuidePanel>
      <StyleGuidePanel direction="column" title="Display Name">
        <DisplayName text="AmandaPink" />
        <DisplayName text="AmandaPink" verification="simple" />
        <DisplayName text="AmandaPink" verification="fancy" />
        <DisplayName text="Joshua293" color="#2BD5FA" />
      </StyleGuidePanel>
      <StyleGuidePanel direction="column" title="Creator Info">
        <CreatorInfo
          profilePictureUrl="../images/sample/girl2.png"
          fullName="Amanda"
        />
        <CreatorInfo
          profilePictureUrl="../images/sample/girl3.png"
          fullName="Lucia"
          isVerified
        />
      </StyleGuidePanel>
      <StyleGuidePanel direction="column" title="Creator">
        <CreatorInfo
          profilePictureUrl="../images/sample/girl4.png"
          fullName="AmandaPink"
        />
        <DisplayName text="AmandaPink" verification="fancy" />
        <DisplayName text="Joshua293" color="#2BD5FA" />
      </StyleGuidePanel>
      <StyleGuidePanel direction="column" title="Full creator">
        <FullCreatorInfo
          profilePictureUrl="../images/sample/girl2.png"
          fullName="Amanda Pink"
          slug="amandapink"
        />
        <FullCreatorInfo
          profilePictureUrl="../images/sample/girl.png"
          fullName="Amanda Pink"
          slug="creatoramanda"
          profileUrl="/style-guide"
        />
      </StyleGuidePanel>
      <StyleGuidePanel direction="column" title="Vertical creator">
        <CreatorInfo
          profilePictureUrl="../images/sample/girl.png"
          fullName="12345678901234567890"
          variant="instaStory"
          avatarImageSize="large"
          maxNameWidth="72px"
          font="extraSmallRegular"
          profileUrl="/style-guide"
        />
      </StyleGuidePanel>
    </div>
  </StyleGuidePageContainer>
)

export default CreatorCards
