import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { create, props } from '@stylexjs/stylex'
import { color } from '../../styles/token.stylex'
import DefaultProfilePicture from '/default-profile-image.png'
import { LoadingIndicator } from '../indicators/LoadingIndicator'

const styles = create({
  container: {
    position: 'relative',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color.avatarImageBackground,
    width: '1.5rem',
    height: '1.5rem',
    transition: 'transform 0.3s ease',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',
  },
  xsmall: {
    width: '1.8rem',
    height: '1.8rem',
  },
  small: {
    width: '2.06rem',
    height: '2.06rem',
  },
  medium: {
    width: '2.625rem',
    height: '2.625rem',
  },
  normal: {
    width: '3.5rem',
    height: '3.5rem',
  },
  large: {
    width: '4.5rem',
    height: '4.5rem',
  },
  xlarge: {
    width: '6rem',
    height: '6rem',
  },
  border: {
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: '50%',
    borderColor: color.avatarImageBorder,
  },
  avatar: {
    transform: {
      default: 'scale(1)',
      ':hover': 'scale(1.05)',
    },
  },
  loaderOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
})

export type AvatarImageSizeVariant =
  | 'xlarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'xsmall'
  | 'normal'

type AvatarImageProps = {
  src?: string
  alt: string
  variant?: AvatarImageSizeVariant
  url?: string
  border?: boolean
  isLoading?: boolean
}

export const AvatarImage: React.FC<AvatarImageProps> = ({
  src,
  alt,
  variant,
  border,
  url,
  isLoading,
}) => {
  const [displayedSrc, setDisplayedSrc] = useState(src || DefaultProfilePicture)
  const [loadingNewImage, setLoadingNewImage] = useState(false)

  useEffect(() => {
    if (!src) {
      setDisplayedSrc(DefaultProfilePicture)
      return
    }
    if (src !== displayedSrc) {
      setLoadingNewImage(true)
      const image = new Image()
      image.onload = () => {
        setDisplayedSrc(src)
        setLoadingNewImage(false)
      }
      image.onerror = () => {
        setDisplayedSrc(DefaultProfilePicture)
        setLoadingNewImage(false)
      }
      image.src = src
    }
  }, [src, displayedSrc])

  const avatarContent = (
    <div
      {...props(
        styles.container,
        variant && styles[variant],
        url ? styles.avatar : undefined,
        border && styles.border
      )}
    >
      <img {...props(styles.image)} src={displayedSrc} alt={alt} />
      {(isLoading || loadingNewImage) && (
        <div {...props(styles.loaderOverlay)}>
          <LoadingIndicator />
        </div>
      )}
    </div>
  )

  return url ? <Link to={url}>{avatarContent}</Link> : avatarContent
}
