import { create, props } from '@stylexjs/stylex'
import { color, elementSizes } from '../../styles/token.stylex'
import { Logo } from '../../components/logos/Logo'
import { Menu } from '../../components/icons/Menu'
import { NavLink } from 'react-router-dom'
import { routes } from '../../router/routes'
import { Link } from '../../components/Link'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  navbar: {
    top: 0,
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingInline: '1rem',
    backgroundColor: color.desktopNavbarBackground,
    height: elementSizes.headerHeight,
    zIndex: 9998,
  },
  toggler: {
    display: {
      default: 'flex',
      [largeScreen]: 'none',
    },
  },
})

export const StyleGuideNavbar: React.FC = () => (
  <nav {...props(styles.navbar)}>
    <NavLink to="/">
      <Logo />
    </NavLink>
    <div {...props(styles.toggler)}>
      <Link exact to={routes.styleGuide}>
        <Menu />
      </Link>
    </div>
  </nav>
)
