import { create, props } from '@stylexjs/stylex'
import { Panel } from '../../components/Panel'
import { PageHeader } from '../../layout/PageHeader'
import useLanguage from '../../translations/useLanguage'
import { color } from '../../styles/token.stylex'
import SmartBankInfo from './SmartBankInfo'

const styles = create({
  container: {
    marginInline: 'auto',
    width: '100%',
    maxWidth: '888px',
  },
  helperText: {
    color: color.dustyGray,
  },
  contentContainer: {
    display: 'grid',
    gap: '1rem',
  },
})

export type BankInfoData = {
  iban: string
  bic: string
}

export const BankPage: React.FC = () => {
  const { t } = useLanguage()

  return (
    <div {...props(styles.container)}>
      <PageHeader>{t('bank.pageTitle').toUpperCase()}</PageHeader>
      <Panel title={t('bank.bankInfoSectionHeader').toUpperCase()}>
        <SmartBankInfo />
      </Panel>
    </div>
  )
}

export default BankPage
