import { create, props } from '@stylexjs/stylex'
import { Input } from '../../components/input/Input'
import { Controller, useForm } from 'react-hook-form'
import useLanguage from '../../translations/useLanguage'
import { useApi } from '../../api/ApiContext'
import { useEffect, useRef, useState } from 'react'
import { EditForm } from '../../components/EditForm'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useUserContext } from '../../auth/user-context'
import { Panel } from '../../components/Panel'
import { MINIMUM_PRICE, PriceData, priceValidator } from './utils'
import { parseIntWithDefault } from '../../common/utils'
import { PageHeader } from '../../layout/PageHeader'
import { useErrorNotification } from '../../translations/useErrorNotification'

const styles = create({
  container: {
    marginInline: 'auto',
    width: '100%',
    maxWidth: '888px',
  },
})

const getPriceInputValue = (price: number | undefined): string => {
  if (!price) return ''

  return price.toString()
}

export const SubscriptionPricePage: React.FC = () => {
  const { t, formatCurrency } = useLanguage()
  const { userApi } = useApi()
  const [isEditing, setIsEditing] = useState(false)
  const { data, reloadUser } = useUserContext()
  const inputRef = useRef<HTMLInputElement>(null)
  const notifyError = useErrorNotification()

  const resetForm = () => {
    setIsEditing(false)
    reset()
    inputRef.current?.blur()
  }

  const onEdit = () => setIsEditing(true)

  const { control, reset, handleSubmit, formState, setValue } =
    useForm<PriceData>({
      defaultValues: {
        price: getPriceInputValue(data?.subscriptionPrice),
      },
      mode: 'onChange',
      resolver: (data) => priceValidator(data, t, formatCurrency),
    })

  const { mutateAsync: changeSubscriptionPrice, isLoading } = useMutation({
    mutationFn: (subscribePrice: number) =>
      userApi.userSubscribepricePut({
        updateSubscribePriceRequest: { subscribePrice },
      }),
    onSuccess: () => {
      toast.success(t('subscription.priceUpdateSuccess'))
      setIsEditing(false)
      reloadUser()
    },
    onError: notifyError(
      () => toast.error(t('subscription.failedToUpdate')),
      false
    ),
  })

  useEffect(() => {
    reset({ price: getPriceInputValue(data?.subscriptionPrice) })
  }, [data, reset])

  const onSubmit = handleSubmit(async ({ price }: PriceData) => {
    if (!formState.isValid) return

    const parsedPrice = parseIntWithDefault(price, 0)

    await changeSubscriptionPrice(parsedPrice)
  })

  useEffect(() => {
    if (isEditing) inputRef.current?.focus()
  }, [isEditing])

  const onPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    if (/^0+$/.test(value)) {
      value = '0'
    }
    setValue('price', value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return (
    <div {...props(styles.container)}>
      <PageHeader>{t('subscription.pageTitle').toUpperCase()}</PageHeader>
      <Panel>
        <EditForm
          canEdit
          canSave={formState.isValid && formState.isDirty && !isLoading}
          isEditing={isEditing}
          onCancel={resetForm}
          onSubmit={onSubmit}
          onEdit={onEdit}
          isLoading={isLoading}
        >
          <Controller
            control={control}
            name="price"
            render={({ field }) => (
              <Input
                prefix="¥"
                placeholder={t('subscription.free')}
                ref={inputRef}
                type="number"
                id={field.name}
                value={field.value}
                onChange={onPriceChange}
                onClick={onEdit}
                onEscape={resetForm}
                error={formState.errors.price?.message}
                instruction={t('subscription.minimumPrice', {
                  minimumPrice: formatCurrency(MINIMUM_PRICE, {
                    currency: 'JPY',
                  }),
                })}
                label={t('subscription.pricePerMonth')}
              />
            )}
          />
        </EditForm>
      </Panel>
    </div>
  )
}

export default SubscriptionPricePage
