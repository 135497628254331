import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'
import { GetSubscriptionResponse } from '../../client'
import useLanguage from '../../translations/useLanguage'
import { CreatorInfo } from '../../components/CreatorInfo'
import { FullCreatorInfo } from '../../components/FullCreatorInfo'
import { gridStyle } from './subscriptionStyles'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  rowSpacing: {
    paddingBlock: spacing.small,
    gap: spacing.xxSmall,
  },
  cell: {
    display: 'flex',
    justifyContent: 'end',
  },
  creatorInfoContainer: {
    display: {
      default: 'block',
      [largeScreen]: 'none',
    },
  },
  fullCreatorInfoContainer: {
    display: {
      default: 'none',
      [largeScreen]: 'block',
    },
  },
  expired: {
    color: color.dustyGray,
  },
})

type SubscriptionsRowItemProps = {
  row: GetSubscriptionResponse
}

export const SubscriptionsRowItem: React.FC<SubscriptionsRowItemProps> = ({
  row,
}) => {
  const { formatShortDateWithYear, formatCurrency, t } = useLanguage()
  return (
    <div {...props(gridStyle.gridRow, styles.rowSpacing)}>
      <div {...props(styles.creatorInfoContainer)}>
        <CreatorInfo
          profilePictureUrl={row.user.profilePicture?.source}
          fullName={row.user.displayName}
          avatarImageSize="xsmall"
          profileUrl={`/${row.user.slug}`}
          maxNameWidth="16vw"
        />
      </div>
      <div {...props(styles.fullCreatorInfoContainer)}>
        <FullCreatorInfo
          slug={row.user.slug}
          fullName={row.user.displayName}
          avatarImageSize={'small'}
          profileUrl={`/${row.user.slug}`}
          profilePictureUrl={row.user.profilePicture?.source}
        />
      </div>
      <div {...props(fonts.smallSemiBold, styles.cell)}>
        {row.isActive ? (
          t('subscriptions.status.active')
        ) : (
          <div {...props(styles.expired)}>
            {t('subscriptions.status.expired')}
          </div>
        )}
      </div>
      <div {...props(fonts.smallSemiBold, styles.cell)}>
        {row.price === 0
          ? t('subscriptions.free')
          : formatCurrency(row.price, {
              currency: 'JPY',
              maximumFractionDigits: 0,
            })}
      </div>
      <div {...props(fonts.smallSemiBold, styles.cell)}>
        {row.price === 0 ? '' : formatShortDateWithYear(row.expiresAt)}
      </div>
    </div>
  )
}
