/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContentType } from './ContentType';
import {
    ContentTypeFromJSON,
    ContentTypeFromJSONTyped,
    ContentTypeToJSON,
} from './ContentType';
import type { GetCategoryResponse } from './GetCategoryResponse';
import {
    GetCategoryResponseFromJSON,
    GetCategoryResponseFromJSONTyped,
    GetCategoryResponseToJSON,
} from './GetCategoryResponse';
import type { GetStreamInfoResponse } from './GetStreamInfoResponse';
import {
    GetStreamInfoResponseFromJSON,
    GetStreamInfoResponseFromJSONTyped,
    GetStreamInfoResponseToJSON,
} from './GetStreamInfoResponse';
import type { GetStreamerResponse } from './GetStreamerResponse';
import {
    GetStreamerResponseFromJSON,
    GetStreamerResponseFromJSONTyped,
    GetStreamerResponseToJSON,
} from './GetStreamerResponse';
import type { GetVideoInfoResponse } from './GetVideoInfoResponse';
import {
    GetVideoInfoResponseFromJSON,
    GetVideoInfoResponseFromJSONTyped,
    GetVideoInfoResponseToJSON,
} from './GetVideoInfoResponse';

/**
 * 
 * @export
 * @interface GetStreamFeedItemResponse
 */
export interface GetStreamFeedItemResponse {
    /**
     * 
     * @type {ContentType}
     * @memberof GetStreamFeedItemResponse
     */
    type: ContentType;
    /**
     * The unique reference ID of the VoD or stream.
     * @type {string}
     * @memberof GetStreamFeedItemResponse
     */
    referenceId: string;
    /**
     * 
     * @type {GetStreamerResponse}
     * @memberof GetStreamFeedItemResponse
     */
    streamer: GetStreamerResponse;
    /**
     * 
     * @type {GetVideoInfoResponse}
     * @memberof GetStreamFeedItemResponse
     */
    videoInfo?: GetVideoInfoResponse;
    /**
     * 
     * @type {GetStreamInfoResponse}
     * @memberof GetStreamFeedItemResponse
     */
    streamInfo?: GetStreamInfoResponse;
    /**
     * 
     * @type {GetCategoryResponse}
     * @memberof GetStreamFeedItemResponse
     */
    category: GetCategoryResponse;
}

/**
 * Check if a given object implements the GetStreamFeedItemResponse interface.
 */
export function instanceOfGetStreamFeedItemResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "referenceId" in value;
    isInstance = isInstance && "streamer" in value;
    isInstance = isInstance && "category" in value;

    return isInstance;
}

export function GetStreamFeedItemResponseFromJSON(json: any): GetStreamFeedItemResponse {
    return GetStreamFeedItemResponseFromJSONTyped(json, false);
}

export function GetStreamFeedItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStreamFeedItemResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': ContentTypeFromJSON(json['type']),
        'referenceId': json['referenceId'],
        'streamer': GetStreamerResponseFromJSON(json['streamer']),
        'videoInfo': !exists(json, 'videoInfo') ? undefined : GetVideoInfoResponseFromJSON(json['videoInfo']),
        'streamInfo': !exists(json, 'streamInfo') ? undefined : GetStreamInfoResponseFromJSON(json['streamInfo']),
        'category': GetCategoryResponseFromJSON(json['category']),
    };
}

export function GetStreamFeedItemResponseToJSON(value?: GetStreamFeedItemResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ContentTypeToJSON(value.type),
        'referenceId': value.referenceId,
        'streamer': GetStreamerResponseToJSON(value.streamer),
        'videoInfo': GetVideoInfoResponseToJSON(value.videoInfo),
        'streamInfo': GetStreamInfoResponseToJSON(value.streamInfo),
        'category': GetCategoryResponseToJSON(value.category),
    };
}

