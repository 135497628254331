import { RESERVED_WORDS } from '../../constants/reserved-words'
import { LanguageKey } from '../../translations/useLanguage'

const RESERVED_WORDS_LIST = Object.values(RESERVED_WORDS)

export type UsernameData = {
  username: string
}

export type DisplayNameData = {
  displayName: string
}

type NameValidationError = {
  type: 'validation' | 'limit'
  message: string
}

export const displayNameValidator = (
  data: DisplayNameData,
  translate: (key: LanguageKey) => string
) => {
  const displayName = data?.displayName
  const errors: Record<string, NameValidationError> = {}

  if (displayName.length >= 40) {
    errors.displayName = {
      type: 'validation',
      message: translate('myChannel.changeDisplayName.info.validation'),
    }
    return { values: data, errors }
  }

  if (RESERVED_WORDS_LIST.includes(displayName.toLowerCase())) {
    errors.displayName = {
      type: 'validation',
      message: translate('myChannel.changeUsername.info.reservedWord'),
    }
    return { values: data, errors }
  }

  return { values: data, errors }
}

export const usernameValidator = (
  data: UsernameData,
  translate: (key: LanguageKey) => string
) => {
  const username = data?.username
  const errors: Record<string, NameValidationError> = {}

  // 0. Check if username is a reserved word
  if (RESERVED_WORDS_LIST.includes(username.toLowerCase())) {
    errors.username = {
      type: 'validation',
      message: translate('myChannel.changeUsername.info.reservedWord'),
    }
    return { values: data, errors }
  }

  // 1. Check if username is provided (NotNull & NotEmpty)
  if (!username || username.trim() === '') {
    errors.username = {
      type: 'validation',
      message: translate('myChannel.changeUsername.info.isEmpty'),
    }
    return { values: data, errors }
  }

  // 2. Validate maximum length of 24 characters
  if (username.length >= 24) {
    errors.username = {
      type: 'validation',
      message: translate('myChannel.changeUsername.info.lengthWarning'),
    }
    return { values: data, errors }
  }

  // 3. Validate against slug pattern: only lowercase letters, numbers, and single hyphens between segments
  const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/
  if (!slugRegex.test(username)) {
    errors.username = {
      type: 'validation',
      message: translate('myChannel.changeUsername.info.invalidCharacters'),
    }
    return { values: data, errors }
  }

  return { values: data, errors }
}
