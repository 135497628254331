import { Controller, useForm } from 'react-hook-form'
import useLanguage from '../../translations/useLanguage'
import { useEffect, useRef, useState } from 'react'
import { useApi } from '../../api/ApiContext'
import { toast } from 'react-toastify'
import { Input } from '../../components/input/Input'
import { DisplayNameData, displayNameValidator } from './utils'
import { EditForm } from '../../components/EditForm'
import { useMutation } from 'react-query'
import { useUserContext } from '../../auth/user-context'
import { GetUserInfoResponse } from '../../client'

type ChangeDisplayNameProps = {
  user: GetUserInfoResponse
}

export const ChangeDisplayName: React.FC<ChangeDisplayNameProps> = ({
  user,
}) => {
  const { t } = useLanguage()
  const { userApi } = useApi()
  const [isEditing, setIsEditing] = useState(false)
  const { reloadUser } = useUserContext()

  const inputRef = useRef<HTMLInputElement>(null)

  const { displayName } = user

  useEffect(() => {
    if (isEditing) inputRef.current?.focus()
  }, [isEditing])

  const resetForm = () => {
    setIsEditing(false)
    reset()
    inputRef.current?.blur()
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
  } = useForm<DisplayNameData>({
    defaultValues: {
      displayName: displayName,
    },
    resolver: (data) => displayNameValidator(data, t),
    mode: 'onChange',
  })

  useEffect(() => {
    reset({ displayName })
  }, [displayName, reset])

  const handleError = (error: Error) => {
    if (error.message === 'errors.specific.displayNameAlreadyExists') {
      setError('displayName', {
        message: t('myChannel.changeDisplayName.info.displaynameAlreadyTaken'),
      })
    }
  }

  const { mutateAsync: changeDisplayName, isLoading: isMutationLoading } =
    useMutation({
      mutationFn: (newDisplayName: string) =>
        userApi.userDisplaynamePut({
          updateDisplayNameRequest: { newDisplayName },
        }),
      onSuccess: () => {
        toast.success(t('myChannel.changeDisplayName.success'))
        setIsEditing(false)
        reloadUser()
      },
      onError: (error: Error) => handleError(error),
    })

  const onEdit = () => {
    setIsEditing(true)
  }

  const onSubmit = handleSubmit(
    async ({ displayName }: { displayName: string }) => {
      if (errors.displayName) return
      await changeDisplayName(displayName.trim())
    }
  )

  return (
    <EditForm
      canEdit={true}
      canSave={isDirty && !isMutationLoading && !errors.displayName}
      isEditing={isEditing}
      onCancel={resetForm}
      onSubmit={onSubmit}
      onEdit={onEdit}
      isLoading={isMutationLoading}
    >
      <Controller
        control={control}
        name="displayName"
        render={({ field }) => (
          <Input
            ref={inputRef}
            id={field.name}
            value={field.value}
            onChange={(e) => {
              field.onChange(e)
            }}
            onFocus={onEdit}
            onEscape={resetForm}
            maxLength={40}
            error={errors.displayName?.message}
            label={t('myChannel.changeDisplayName.title')}
            instruction={t('myChannel.changeDisplayName.instruction')}
          />
        )}
      />
    </EditForm>
  )
}
