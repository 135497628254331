/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCategoryResponse
 */
export interface GetCategoryResponse {
    /**
     * The id of the category.
     * @type {number}
     * @memberof GetCategoryResponse
     */
    id: number;
    /**
     * The name of the category.
     * @type {string}
     * @memberof GetCategoryResponse
     */
    name: string;
    /**
     * The slug of the category.
     * @type {string}
     * @memberof GetCategoryResponse
     */
    key: string;
}

/**
 * Check if a given object implements the GetCategoryResponse interface.
 */
export function instanceOfGetCategoryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "key" in value;

    return isInstance;
}

export function GetCategoryResponseFromJSON(json: any): GetCategoryResponse {
    return GetCategoryResponseFromJSONTyped(json, false);
}

export function GetCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'key': json['key'],
    };
}

export function GetCategoryResponseToJSON(value?: GetCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'key': value.key,
    };
}

