import { useRef } from 'react'
import useLanguage from '../translations/useLanguage'
import { create, props } from '@stylexjs/stylex'
import { AvatarImage } from '../components/avatars/AvatarImage'
import { Button } from '../components/Button'
import {
  allowedImageTypes,
  FileValidationOptions,
  useFileValidator,
} from '../common/utils/useFileValidator'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { Bin } from '../components/icons/Bin'
import { fonts } from '../styles/font-styles'
import { color } from '../styles/token.stylex'
import { useApi } from '../api/ApiContext'
import { IconButton } from '../components/IconButton'
import { GetChannelUserResponse } from '../client'

const styles = create({
  container: {
    display: 'flex',
    paddingInline: '1rem',
    gap: '1.5rem',
  },
  wrapper: {
    display: 'flex',
    gap: '1.5rem',
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: 'fit-content',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '1rem',
  },
  fileInput: {
    display: 'none',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  avatar: {
    minWidth: 'fit-content',
    cursor: 'pointer',
  },
  instruction: {
    color: color.dustyGray,
  },
})

const validationOptions: FileValidationOptions = {
  maxSizeMB: 4,
  minHeight: 128,
  minWidth: 128,
  maxHeight: 3000,
  maxWidth: 3000,
}

type ProfilePreviewProps = {
  user: GetChannelUserResponse
  isLoading?: boolean
  reloadUser: () => void
  reloadChannel: () => void
}

const BinIcon = () => <Bin width={'1rem'} height={'1rem'} />

export const ProfilePreview: React.FC<ProfilePreviewProps> = ({
  user,
  reloadUser,
  reloadChannel,
  isLoading,
}) => {
  const { t } = useLanguage()

  const fileInput = useRef<HTMLInputElement>(null)
  const { validateFile } = useFileValidator()
  const { userApi } = useApi()

  const { mutate: uploadPicture, isLoading: isUploading } = useMutation({
    mutationFn: async (file: File) => {
      const result = await validateFile(file, validationOptions)
      if (!result) {
        throw new Error('Invalid file')
      }
      await userApi.userUploadProfilepicturePut({ image: file })
    },
    onSuccess: () => {
      toast.success(t('myChannel.profilePreview.modal.uploadSuccessMessage'))
      reloadUser()
      reloadChannel()
    },
    onError: (error: Error) => {
      if (error.message !== 'Invalid file')
        toast.error(t('myChannel.profilePreview.uploadErrorMessage'))
    },
  })

  const { mutate: removePicture, isLoading: isRemoving } = useMutation({
    mutationFn: async () => {
      await userApi.userUploadProfilepicturePut({
        image: undefined,
      })
    },
    onSuccess: () => {
      toast.success(t('myChannel.profilePreview.modal.removeSuccessMessage'))
      reloadUser()
      reloadChannel()
    },
    onError: () => {
      toast.error(t('myChannel.profilePreview.removeErrorMessage'))
    },
  })

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) {
      toast.error(t('myChannel.profilePreview.uploadErrorMessage'))
      return
    }
    uploadPicture(file)
  }

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.avatar)} onClick={() => fileInput.current?.click()}>
        <AvatarImage
          alt="avatar image"
          variant="xlarge"
          src={user.profilePicture?.source}
          isLoading={isUploading || isLoading}
        />
      </div>
      <input
        type="file"
        accept={allowedImageTypes.join(', ')}
        ref={fileInput}
        onChange={handleFileChange}
        {...props(styles.fileInput)}
      />
      <div {...props(styles.rightContainer)}>
        <div {...props(styles.buttonContainer)}>
          <Button
            onClick={() => fileInput.current?.click()}
            disabled={isUploading || isLoading}
          >
            {t('myChannel.profilePreview.editButtonLabel')}
          </Button>
          <IconButton
            onClick={() => removePicture()}
            isLoading={isRemoving}
            disabled={!user.profilePicture || isUploading || isLoading}
            variant="large"
            tooltip={t('myChannel.profilePreview.removeButtonLabel')}
            icon={BinIcon}
          />
        </div>
        <div {...props(styles.instruction, fonts.extraSmallRegular)}>
          {t('myChannel.profilePreview.modal.requirements')}
        </div>
      </div>
    </div>
  )
}
