import { useParams } from 'react-router-dom'
import { ChannelContextProvider } from '../../channel/context/ChannelContextProvider'
import SmartVideoPage from './SmartVideoPage'

export const SingleVideoPage: React.FC = () => {
  const { slug, videoId } = useParams()

  if (!slug) throw new Error('Slug not found')
  if (!videoId) throw new Error('Video ID not found')

  return (
    <ChannelContextProvider slug={slug}>
      <SmartVideoPage contentId={videoId} />
    </ChannelContextProvider>
  )
}
