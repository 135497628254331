import { toast } from 'react-toastify'
import useLanguage from '../translations/useLanguage'
import { getErrorMessage } from '../api/apiError'

export const useErrorNotification = () => {
  const { t } = useLanguage()

  return (
      callback: (error: unknown) => void,
      showGeneralErrorToast: boolean = true
    ) =>
    (error: unknown): void => {
      if (showGeneralErrorToast) toast.error(t(getErrorMessage(error)))
      callback(error)
    }
}
