/* eslint-disable react/jsx-no-literals */
import { useRef, useState } from 'react'
import { create, props } from '@stylexjs/stylex'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { Button } from '../../../components/Button'
import { spacing } from '../../../styles/token.stylex'
import { Dialog } from '../../../components/dialog/Dialog'
import { FullCreatorInfo } from '../../../components/FullCreatorInfo'
import { Input } from '../../../components/input/Input'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  container: { width: 'fit-content' },
  confirmContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.large,
  },
  tipContent: {
    minWidth: '350px',
  },
})

export const Dialogs: React.FC = () => {
  const infoDialogRef = useRef<HTMLDialogElement>(null)
  const confirmDialogRef = useRef<HTMLDialogElement>(null)
  const confirmDialogWithoutCloseRef = useRef<HTMLDialogElement>(null)
  const errorDialogRef = useRef<HTMLDialogElement>(null)
  const confirmTransactionRef = useRef<HTMLDialogElement>(null)
  const [inputValue, setInputValue] = useState('$15')

  const openDialog = (dialogRef: React.RefObject<HTMLDialogElement>) => {
    dialogRef.current?.showModal()
  }

  const closeDialog = (dialogRef: React.RefObject<HTMLDialogElement>) => {
    dialogRef.current?.close()
  }

  const renderDialogs = (
    <StyleGuidePanel direction="column">
      <Button variant="cta" onClick={() => openDialog(infoDialogRef)}>
        Show Full width Confirm Dialog
      </Button>
      <Dialog
        ref={infoDialogRef}
        title="Information"
        description="This is an informational dialog. Use this for general information."
        cancel={{
          label: 'Close',
          action: () => closeDialog(infoDialogRef),
        }}
        ok={{
          label: 'Confirm',
          action: () => {
            console.log('Confirmed')
            closeDialog(infoDialogRef)
          },
        }}
        variant="single"
      />

      <Button variant="cta" onClick={() => openDialog(confirmDialogRef)}>
        Show Confirmation Dialog
      </Button>
      <Dialog
        ref={confirmDialogRef}
        title="Confirmation"
        description="Are you sure you want to perform this action?"
        cancel={{
          label: 'Cancel',
          action: () => closeDialog(confirmDialogRef),
        }}
        ok={{
          label: 'Confirm',
          action: () => {
            console.log('Confirmed')
            closeDialog(confirmDialogRef)
          },
        }}
        variant="dual"
      />
      <Button
        variant="cta"
        onClick={() => openDialog(confirmDialogWithoutCloseRef)}
      >
        Show Confirmation Dialog without Close button
      </Button>
      <Dialog
        ref={confirmDialogWithoutCloseRef}
        title="Confirmation"
        description="Are you sure you want to perform this action?"
        showCloseButton={false}
        cancel={{
          label: 'Cancel',
          action: () => closeDialog(confirmDialogWithoutCloseRef),
        }}
        ok={{
          label: 'Confirm',
          action: () => {
            console.log('Confirmed')
            closeDialog(confirmDialogWithoutCloseRef)
          },
        }}
        variant="dual"
      />

      <Button variant="cta" onClick={() => openDialog(errorDialogRef)}>
        Show Error Dialog (Does not close on outside click)
      </Button>
      <Dialog
        ref={errorDialogRef}
        title="Error"
        description="An error occurred while processing your request."
        cancel={{
          label: 'Close',
          action: () => closeDialog(errorDialogRef),
        }}
        variant="single"
        showCloseButton={true}
        closeOnOutsideClick={false}
      />

      <Button variant="cta" onClick={() => openDialog(confirmTransactionRef)}>
        Confirm transaction
      </Button>
      <Dialog
        ref={confirmTransactionRef}
        title="Confirm Transaction"
        cancel={{
          label: 'Close',
          action: () => closeDialog(confirmTransactionRef),
        }}
        showCloseButton={true}
      >
        <div {...props(styles.confirmContainer)}>
          <div {...props(styles.tipContent, styles.confirmContainer)}>
            <FullCreatorInfo
              profilePictureUrl="images/sample/girl.png"
              fullName="AmandaPink"
              slug="@amandapink"
              isVerified
            />
            <Input
              id="with-description"
              value={inputValue}
              label="Tip Amount"
              required
              instruction="Minimum $5"
              onChange={(e) => setInputValue(e.target.value)}
            />
            <Button
              variant="cta"
              onClick={() => closeDialog(confirmTransactionRef)}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
    </StyleGuidePanel>
  )

  return (
    <StyleGuidePageContainer title="Dialogs">
      <div {...props(styles.container)}>{renderDialogs}</div>
    </StyleGuidePageContainer>
  )
}
