import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { Verified } from './icons/Verified'
import { VerifiedSecondary } from './icons/VerifiedSecondary'
import { TextLink } from './TextLink'

const styles = create({
  nameContainer: {
    display: 'flex',
    gap: spacing.extraSmall,
    alignItems: 'center',
  },
  customColor: (color: string) => ({ color: color }),
  instaStory: {
    maxWidth: '4.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  messagePreview: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

type DisplayNameProps = {
  text: string
  color?: string
  verification?: 'simple' | 'fancy'
  isSecondaryVerificationIcon?: boolean
  font?: keyof typeof fonts
  url?: string
  variant?: 'instaStory' | 'messagePreview'
  underline?: boolean
  textLinkColor?: 'white' | 'gray' | 'footer'
}

export const DisplayName: React.FC<DisplayNameProps> = ({
  text,
  color: textColor = color.displayNameTextColor,
  verification,
  font = 'extraSmallRegular',
  url,
  variant,
  underline = true,
  textLinkColor,
}) => {
  const displayNameContent = (
    <div {...props(styles.nameContainer)}>
      <span
        {...props(
          fonts[font],
          !textLinkColor && styles.customColor(textColor),
          variant && styles[variant]
        )}
      >
        {text}
      </span>
      {verification &&
        (verification === 'fancy' ? <VerifiedSecondary /> : <Verified />)}
    </div>
  )

  return url ? (
    <TextLink to={url} underline={underline} variant={textLinkColor}>
      {displayNameContent}
    </TextLink>
  ) : (
    displayNameContent
  )
}
