import {
  GetNotificationResponse,
  NotificationSubType,
  NotificationType,
} from '../client'
import { TextLink } from '../components/TextLink'
import { routes } from '../router/routes'
import useLanguage from '../translations/useLanguage'

type NotificationTextProps = {
  notification: GetNotificationResponse
}

export const NotificationText: React.FC<NotificationTextProps> = ({
  notification,
}) => {
  const { t, formatCurrency } = useLanguage()
  const { type, subType, amount, currency, relatedPost } = notification

  const amountFormatted = amount
    ? formatCurrency(amount, {
        currency: currency,
      })
    : ''

  switch (type) {
    case NotificationType.Tip:
      if (subType === NotificationSubType.TipToChannel) {
        return t('notifications.tip.channel', { amount: amountFormatted })
      }
      if (subType === NotificationSubType.TipToPost && relatedPost) {
        return (
          <>
            {t('notifications.tip.post', { amount: amountFormatted })}{' '}
            <TextLink
              to={routes.channel.postById(
                relatedPost.id,
                relatedPost.channelSlug
              )}
            >
              {t('notifications.post')}
            </TextLink>
          </>
        )
      }
      break

    case NotificationType.Purchase:
      if (subType === NotificationSubType.PostPurchase && relatedPost) {
        return (
          <>
            {t('notifications.purchase.post.before')}
            <TextLink
              to={routes.channel.postById(
                relatedPost.id,
                relatedPost.channelSlug
              )}
            >
              {t('notifications.post')}{' '}
            </TextLink>
            {t('notifications.purchase.post.after', {
              amount: amountFormatted,
            })}
          </>
        )
      }
      break

    case NotificationType.Subscription:
      if (subType === NotificationSubType.SubscriptionToProfile) {
        return t('notifications.subscription.profile')
      }
      break

    case NotificationType.Comment:
      if (subType === NotificationSubType.NewComment && relatedPost) {
        return (
          <>
            {t('notifications.comment.newComment')}{' '}
            <TextLink
              to={routes.channel.postById(
                relatedPost.id,
                relatedPost.channelSlug
              )}
            >
              {t('notifications.post')}{' '}
            </TextLink>
          </>
        )
      }
      break

    default:
      return t('notifications.default')
  }
}
