/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelRole } from './ChannelRole';
import {
    ChannelRoleFromJSON,
    ChannelRoleFromJSONTyped,
    ChannelRoleToJSON,
} from './ChannelRole';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetChannelResponse
 */
export interface GetChannelResponse {
    /**
     * 
     * @type {string}
     * @memberof GetChannelResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetChannelResponse
     */
    slug: string;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetChannelResponse
     */
    bannerPicture?: ImageVariantsResponse;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelResponse
     */
    isBanned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetChannelResponse
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelResponse
     */
    isVerified?: boolean;
    /**
     * 
     * @type {ChannelRole}
     * @memberof GetChannelResponse
     */
    role?: ChannelRole;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelResponse
     */
    isChannelOwner?: boolean;
    /**
     * Indicates if the channel is currently livestreaming.
     * @type {boolean}
     * @memberof GetChannelResponse
     */
    isLive?: boolean;
}

/**
 * Check if a given object implements the GetChannelResponse interface.
 */
export function instanceOfGetChannelResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "slug" in value;

    return isInstance;
}

export function GetChannelResponseFromJSON(json: any): GetChannelResponse {
    return GetChannelResponseFromJSONTyped(json, false);
}

export function GetChannelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'slug': json['slug'],
        'bannerPicture': !exists(json, 'bannerPicture') ? undefined : ImageVariantsResponseFromJSON(json['bannerPicture']),
        'isBanned': !exists(json, 'isBanned') ? undefined : json['isBanned'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isVerified': !exists(json, 'isVerified') ? undefined : json['isVerified'],
        'role': !exists(json, 'role') ? undefined : ChannelRoleFromJSON(json['role']),
        'isChannelOwner': !exists(json, 'isChannelOwner') ? undefined : json['isChannelOwner'],
        'isLive': !exists(json, 'isLive') ? undefined : json['isLive'],
    };
}

export function GetChannelResponseToJSON(value?: GetChannelResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'bannerPicture': ImageVariantsResponseToJSON(value.bannerPicture),
        'isBanned': value.isBanned,
        'description': value.description,
        'isVerified': value.isVerified,
        'role': ChannelRoleToJSON(value.role),
        'isChannelOwner': value.isChannelOwner,
        'isLive': value.isLive,
    };
}

