import React from 'react'
import { GetCommentResponse } from '../../client'
import { AvatarImage } from '../../components/avatars/AvatarImage'
import { create, props } from '@stylexjs/stylex'
import { ContextMenu } from '../../components/ContextMenu'
import { IconButton } from '../../components/IconButton'
import { TextLink } from '../../components/TextLink'
import { color, spacing } from '../../styles/token.stylex'
import { DisplayName } from '../../components/DisplayName'
import { fonts } from '../../styles/font-styles'
import useLanguage from '../../translations/useLanguage'
import { ExpandableText } from '../../components/ExpandableText'
import { replaceLinksWithAnchors } from '../../common/utils/LinkUtils'
import { ContextMenuDots } from '../../components/icons/ContextMenuDots'
import { routes } from '../../router/routes'
import { ContextMenuItem } from '../../components/types'
import LoadingIndicator from '../../components/indicators/LoadingIndicator'

type CommentCardProps = {
  comment: GetCommentResponse
  canDelete: boolean
  onDelete: () => void
  isDeleting: boolean
}

const styles = create({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraSmall,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLeft: {
    display: 'flex',
    gap: spacing.extraSmall,
    alignItems: 'center',
  },
  headerRight: {
    display: 'flex',
    gap: spacing.extraSmall,
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  divider: {
    borderBottom: `1px solid ${color.mineShaft}`,
    marginLeft: `calc(${spacing.normal} * -1)`,
    marginRight: `calc(${spacing.normal} * -1)`,
  },
})

export const CommentCard: React.FC<CommentCardProps> = ({
  comment,
  canDelete,
  onDelete,
  isDeleting,
}) => {
  const { formatDateRelativeToday, t } = useLanguage()

  const contextMenuItems: ContextMenuItem[] = canDelete
    ? [
        {
          label: t('post.comment.actions.delete.title'),
          action: () => onDelete(),
        },
      ]
    : []

  if (isDeleting) return <LoadingIndicator />

  return (
    <div {...props(styles.cardContainer)}>
      <div {...props(styles.header)}>
        <div {...props(styles.headerLeft)}>
          <AvatarImage
            src={comment.author.profilePicture?.source}
            url={routes.channel.home(comment.author.slug)}
            alt={comment.author.name}
          />
          <DisplayName
            text={comment.author.name}
            url={comment.author.slug}
            font="extraSmallSemiBold"
          />
        </div>
        <div {...props(styles.headerRight)}>
          <TextLink variant="gray">
            <span {...props(fonts.extraSmallRegular)}>
              {formatDateRelativeToday(comment.postedAt)}
            </span>
          </TextLink>
          {contextMenuItems.length > 0 && (
            <ContextMenu items={contextMenuItems}>
              <IconButton icon={ContextMenuDots} variant="large" />
            </ContextMenu>
          )}
        </div>
      </div>
      <div {...props(styles.content)}>
        <ExpandableText
          text={replaceLinksWithAnchors(comment.text)}
          maxLines={3}
          font="extraSmallRegular"
        />
      </div>
      <div {...props(styles.divider)}></div>
    </div>
  )
}

export default CommentCard
