import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { Button } from '../components/Button'
import { TextLink } from '../components/TextLink'
import { color, spacing } from '../styles/token.stylex'
import { titleFonts } from '../styles/font-styles'
import { useUserContext } from '../auth/user-context'
import { routes } from '../router/routes'
import useLanguage from '../translations/useLanguage'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    backgroundColor: color.codGrayLight,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.large,
    padding: spacing.small,
  },
  description: {
    paddingTop: spacing.normal,
  },
  list: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    width: 'fit-content',
  },
  buttonContainer: {
    marginTop: spacing.normal,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    ':not(#_id) button': {
      width: {
        [largeScreen]: '15.625rem',
      },
    },
  },
})

export const CreatorRequirementsNotMetCard: React.FC = () => {
  const { data: user } = useUserContext()
  const { t } = useLanguage()

  if (!user) {
    throw new Error('User is not defined')
  }

  return (
    <div {...props(styles.container)}>
      <div {...props(titleFonts.xlSemiBold)}>
        {t('becomeACreator.requirementsNotMet.title')}
      </div>
      <div {...props(styles.description)}>
        {t('becomeACreator.requirementsNotMet.subtitle1')}{' '}
        <TextLink font="normalRegular" to="/profile/my-profile">
          {t('becomeACreator.requirementsNotMet.subtitle2')}{' '}
        </TextLink>{' '}
        {t('becomeACreator.requirementsNotMet.subtitle3')}
      </div>
      <div {...props(styles.list)}>
        {!user.onboarding.isUsernameSet && (
          <TextLink to={routes.profileRoutes.myProfile} font="smallRegular">
            {t('becomeACreator.requirementsNotMet.setUsername')}
          </TextLink>
        )}
        {!user.onboarding.isDisplayNameSet && (
          <TextLink to={routes.profileRoutes.myProfile} font="smallRegular">
            {t('becomeACreator.requirementsNotMet.setDisplayName')}
          </TextLink>
        )}
        {!user.onboarding.isProfilePictureSet && (
          <TextLink to={routes.profileRoutes.myProfile} font="smallRegular">
            {t('becomeACreator.requirementsNotMet.setProfilePicture')}
          </TextLink>
        )}
        {!user.onboarding.isBioSet && (
          <TextLink to={routes.profileRoutes.myProfile} font="smallRegular">
            {t('becomeACreator.requirementsNotMet.setBio')}
          </TextLink>
        )}
      </div>
      <div {...props(styles.buttonContainer)}>
        <div {...props(styles.button)}>
          <Button disabled variant="cta">
            {t('becomeACreator.requirementsNotMet.buttonTitle')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreatorRequirementsNotMetCard
