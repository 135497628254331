/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPostAssetImageResponse } from './GetPostAssetImageResponse';
import {
    GetPostAssetImageResponseFromJSON,
    GetPostAssetImageResponseFromJSONTyped,
    GetPostAssetImageResponseToJSON,
} from './GetPostAssetImageResponse';
import type { GetPostAssetVideoResponse } from './GetPostAssetVideoResponse';
import {
    GetPostAssetVideoResponseFromJSON,
    GetPostAssetVideoResponseFromJSONTyped,
    GetPostAssetVideoResponseToJSON,
} from './GetPostAssetVideoResponse';
import type { GetPostAuthorResponse } from './GetPostAuthorResponse';
import {
    GetPostAuthorResponseFromJSON,
    GetPostAuthorResponseFromJSONTyped,
    GetPostAuthorResponseToJSON,
} from './GetPostAuthorResponse';
import type { GetPostPurchaseResponse } from './GetPostPurchaseResponse';
import {
    GetPostPurchaseResponseFromJSON,
    GetPostPurchaseResponseFromJSONTyped,
    GetPostPurchaseResponseToJSON,
} from './GetPostPurchaseResponse';
import type { GetTipConfigurationResponse } from './GetTipConfigurationResponse';
import {
    GetTipConfigurationResponseFromJSON,
    GetTipConfigurationResponseFromJSONTyped,
    GetTipConfigurationResponseToJSON,
} from './GetTipConfigurationResponse';

/**
 * 
 * @export
 * @interface GetPostResponse
 */
export interface GetPostResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPostResponse
     */
    postId: string;
    /**
     * 
     * @type {string}
     * @memberof GetPostResponse
     */
    text: string;
    /**
     * 
     * @type {Date}
     * @memberof GetPostResponse
     */
    publishedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetPostResponse
     */
    likeCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostResponse
     */
    isLiked: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetPostResponse
     */
    readonly mediaCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPostResponse
     */
    readonly videosCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPostResponse
     */
    readonly imagesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPostResponse
     */
    commentsCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostResponse
     */
    canComment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostResponse
     */
    canLike: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostResponse
     */
    isLocked: boolean;
    /**
     * 
     * @type {GetPostPurchaseResponse}
     * @memberof GetPostResponse
     */
    purchase: GetPostPurchaseResponse;
    /**
     * 
     * @type {GetPostAuthorResponse}
     * @memberof GetPostResponse
     */
    author: GetPostAuthorResponse;
    /**
     * 
     * @type {Array<GetPostAssetImageResponse>}
     * @memberof GetPostResponse
     */
    images?: Array<GetPostAssetImageResponse> | null;
    /**
     * 
     * @type {Array<GetPostAssetVideoResponse>}
     * @memberof GetPostResponse
     */
    videos?: Array<GetPostAssetVideoResponse> | null;
    /**
     * 
     * @type {GetTipConfigurationResponse}
     * @memberof GetPostResponse
     */
    tipConfiguration: GetTipConfigurationResponse;
}

/**
 * Check if a given object implements the GetPostResponse interface.
 */
export function instanceOfGetPostResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "postId" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "likeCount" in value;
    isInstance = isInstance && "isLiked" in value;
    isInstance = isInstance && "commentsCount" in value;
    isInstance = isInstance && "canComment" in value;
    isInstance = isInstance && "canLike" in value;
    isInstance = isInstance && "isLocked" in value;
    isInstance = isInstance && "purchase" in value;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "tipConfiguration" in value;

    return isInstance;
}

export function GetPostResponseFromJSON(json: any): GetPostResponse {
    return GetPostResponseFromJSONTyped(json, false);
}

export function GetPostResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPostResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postId': json['postId'],
        'text': json['text'],
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'likeCount': json['likeCount'],
        'isLiked': json['isLiked'],
        'mediaCount': !exists(json, 'mediaCount') ? undefined : json['mediaCount'],
        'videosCount': !exists(json, 'videosCount') ? undefined : json['videosCount'],
        'imagesCount': !exists(json, 'imagesCount') ? undefined : json['imagesCount'],
        'commentsCount': json['commentsCount'],
        'canComment': json['canComment'],
        'canLike': json['canLike'],
        'isLocked': json['isLocked'],
        'purchase': GetPostPurchaseResponseFromJSON(json['purchase']),
        'author': GetPostAuthorResponseFromJSON(json['author']),
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(GetPostAssetImageResponseFromJSON)),
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(GetPostAssetVideoResponseFromJSON)),
        'tipConfiguration': GetTipConfigurationResponseFromJSON(json['tipConfiguration']),
    };
}

export function GetPostResponseToJSON(value?: GetPostResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postId': value.postId,
        'text': value.text,
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'likeCount': value.likeCount,
        'isLiked': value.isLiked,
        'commentsCount': value.commentsCount,
        'canComment': value.canComment,
        'canLike': value.canLike,
        'isLocked': value.isLocked,
        'purchase': GetPostPurchaseResponseToJSON(value.purchase),
        'author': GetPostAuthorResponseToJSON(value.author),
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(GetPostAssetImageResponseToJSON)),
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(GetPostAssetVideoResponseToJSON)),
        'tipConfiguration': GetTipConfigurationResponseToJSON(value.tipConfiguration),
    };
}

