/* eslint-disable sonarjs/media-has-caption */
import { props } from '@stylexjs/stylex'
import { styles } from './VideoPlayerStyles'
import { PlayerOptions, useVideoJsPlayer } from './useVideoJSPlayer'

export type IVideoPlayerProps = {
  options: PlayerOptions
  previewMode?: boolean
  isLiveStream?: boolean
}

export const VideoPlayer: React.FC<IVideoPlayerProps> = ({
  options,
  previewMode = false,
  isLiveStream,
}) => {
  const { videoRef, isFullscreen } = useVideoJsPlayer({
    options,
    previewMode,
    isLiveStream,
  })

  return (
    <div
      {...props(styles.video, !isFullscreen && previewMode && styles.preview)}
    >
      <video
        ref={videoRef}
        className="video-js vjs-show-big-play-button-on-pause"
        playsInline
        muted={options.autoPlay}
        autoPlay={options.autoplay}
        preload="auto"
      />
    </div>
  )
}
