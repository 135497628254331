import type { SVGProps } from 'react'

export const Report: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 9.00006V13.0001M16.8499 20.0001H7.15039C5.33152 20.0001 4.42222 20.0001 3.89649 19.6181C3.43792 19.2849 3.14206 18.7728 3.08281 18.2091C3.01489 17.5628 3.46947 16.7753 4.37891 15.2001L9.22865 6.80011C10.1381 5.22492 10.5927 4.43722 11.1863 4.17291C11.7041 3.94236 12.2957 3.94236 12.8135 4.17291C13.4071 4.43722 13.8621 5.22492 14.7715 6.80011L19.6212 15.2001C20.5307 16.7753 20.9852 17.5628 20.9173 18.2091C20.8581 18.7728 20.5622 19.2849 20.1036 19.6181C19.5779 20.0001 18.6687 20.0001 16.8499 20.0001ZM12.0498 16.0001V16.1001L11.9502 16.1V16.0001H12.0498Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
