import { useUserContext } from '../auth/user-context'
import { ChannelContextProvider } from '../channel/context/ChannelContextProvider'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import SmartEditor from './SmartEditor'

export const PromoPanelEditorPage = () => {
  const { data, isLoading } = useUserContext()

  if (isLoading || !data) return <LoadingScreen />

  return (
    <ChannelContextProvider slug={data.streamerChannel.slug}>
      <SmartEditor />
    </ChannelContextProvider>
  )
}

export default PromoPanelEditorPage
