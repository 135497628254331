import { useApi } from '../../api/ApiContext'
import { toast } from 'react-toastify'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import useLanguage from '../../translations/useLanguage'
import { Input } from '../../components/input/Input'
import { Button } from '../../components/Button'
import { create, props } from '@stylexjs/stylex'
import { fonts } from '../../styles/font-styles'
import { TextLink } from '../../components/TextLink'
import { useUnderConstructionDialog } from '../../style-guide/components/under-construction/under-construction-context'
import { Dialog } from '../../components/dialog/Dialog'
import { useRef, useState } from 'react'
import { Copy } from '../../components/icons/Copy'
import { queryKeys } from '../../constants/query-keys'
import LoadingIndicator from '../../components/indicators/LoadingIndicator'
import { useErrorNotification } from '../../translations/useErrorNotification'
import { UndefinedDataErrorPanel } from '../../api/UndefinedDataErrorPanel'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  buttonContainer: {
    display: 'flex',
    gap: '1.5rem',
    minWidth: 'fit-content',
  },
  inputRow: {
    display: 'flex',
    flexDirection: {
      default: 'column',
      [largeScreen]: 'row',
    },
    alignItems: {
      default: 'start',
      [largeScreen]: 'end',
    },
    gap: {
      default: '1rem',
      [largeScreen]: '1.5rem',
    },
  },
  inputContainer: {
    width: '100%',
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  helpText: {
    paddingTop: '2rem',
  },
  contentWrapper: {
    paddingBlock: '1rem',
    paddingInline: {
      default: '0.5rem',
      [largeScreen]: '1rem',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
})

export const StreamKeyAndURL: React.FC = () => {
  const { t } = useLanguage()
  const { openDialog } = useUnderConstructionDialog()
  const { streamApi } = useApi()
  const queryClient = useQueryClient()
  const resetDialogRef = useRef<HTMLDialogElement>(null)
  const [hasUrlCopied, setHasUrlCopied] = useState(false)
  const [hasKeyCopied, setHasKeyCopied] = useState(false)
  const notifyError = useErrorNotification()

  const { data, isLoading } = useQuery({
    queryKey: queryKeys.stream.config,
    queryFn: () => streamApi.streamConfigGet(),
    onError: notifyError(
      () =>
        toast.error(t('streamingPage.streamURLAndKey.configGetFailedMessage')),
      false
    ),
  })

  const resetStreamConfigMutation = useMutation(
    () => streamApi.streamConfigResetPut(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.stream.config)
        toast.success(t('streamingPage.streamURLAndKey.resetedMessage'))
      },
      onError: notifyError(() =>
        toast.error(t('streamingPage.streamURLAndKey.keyResetFailedMessage'))
      ),
    }
  )

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value)
  }

  if (isLoading) return <LoadingIndicator />

  if (!isLoading && !data) return <UndefinedDataErrorPanel />

  const { ingestServer, streamKey } = data

  const onReset = () => {
    resetStreamConfigMutation.mutate()
    resetDialogRef.current?.close()
    setHasKeyCopied(false)
  }

  const onUrlCopy = () => {
    handleCopy(ingestServer)
    setHasUrlCopied((prev) => !prev)
    setTimeout(() => setHasUrlCopied(false), 5500)
  }

  const onKeyCopy = () => {
    handleCopy(streamKey)
    setHasKeyCopied((prev) => !prev)
    setTimeout(() => setHasKeyCopied(false), 5500)
  }

  return (
    <div {...props(styles.contentWrapper)}>
      <div {...props(styles.inputRow)}>
        <div {...props(styles.inputContainer)}>
          <Input
            id="server"
            value={ingestServer}
            label={t('streamingPage.streamURLAndKey.ingestServerLabel')}
            readOnly
          />
        </div>
        <div {...props(styles.buttonContainer)}>
          <Button disabled={hasUrlCopied} onClick={onUrlCopy}>
            <div {...props(styles.buttonContent)}>
              <Copy width={'1rem'} height={'1rem'} />
              {hasUrlCopied
                ? t('streamingPage.streamURLAndKey.copiedButtonLabel')
                : t('streamingPage.streamURLAndKey.copyButtonLabel')}
            </div>
          </Button>
        </div>
      </div>
      <div {...props(styles.inputRow)}>
        <div {...props(styles.inputContainer)}>
          <Input
            id="key"
            value={streamKey}
            label={t('streamingPage.streamURLAndKey.keyLabel')}
            type="password"
            readOnly
          />
        </div>
        <div {...props(styles.buttonContainer)}>
          <Button
            disabled={hasKeyCopied}
            onClick={onKeyCopy}
            isLoading={resetStreamConfigMutation.isLoading}
          >
            <div {...props(styles.buttonContent)}>
              <Copy width={'1rem'} height={'1rem'} />
              {hasKeyCopied
                ? t('streamingPage.streamURLAndKey.copiedButtonLabel')
                : t('streamingPage.streamURLAndKey.copyButtonLabel')}
            </div>
          </Button>
          <Button
            onClick={() => resetDialogRef.current?.showModal()}
            isLoading={resetStreamConfigMutation.isLoading}
            variant="cta"
          >
            {t('streamingPage.streamURLAndKey.resetButtonLabel')}
          </Button>
        </div>
      </div>
      <div {...props(fonts.smallRegular, styles.helpText)}>
        {t('streamingPage.streamURLAndKey.helpText1')}
        <TextLink onClick={() => openDialog('86c0wzy6r')} isExternal>
          {t('streamingPage.streamURLAndKey.howToStream')}
        </TextLink>
        {t('streamingPage.streamURLAndKey.helpText2')}
      </div>
      <Dialog
        ref={resetDialogRef}
        description={t('streamingPage.streamURLAndKey.dialogDescription')}
        cancel={{
          label: t('shared.buttons.cancel'),
          action: () => resetDialogRef.current?.close(),
        }}
        showCloseButton={false}
        ok={{
          label: t('shared.buttons.reset'),
          action: onReset,
        }}
      />
    </div>
  )
}

export default StreamKeyAndURL
