import { StyleGuidePanel } from '../StyleGuidePanel'
import { create, props } from '@stylexjs/stylex'
import { NotificationItem } from '../../../components/NotificationItem'
import { TextLink } from '../../../components/TextLink'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const styles = create({
  container: {
    maxWidth: '400px',
  },
})

export const Notification: React.FC = () => (
  <StyleGuidePageContainer title="Notification item">
    <div {...props(styles.container)}>
      <StyleGuidePanel direction="column" gap="small">
        <NotificationItem
          displayName={'Joshua293'}
          time={'Today'}
          displayNameColor="#2bd5fa"
          verification="simple"
        >
          {<TextLink to={'/joshua293'}>{'Joshua293'}</TextLink>}
          {' subscribed to your profile'}
        </NotificationItem>
        <NotificationItem
          displayName={'Devid958'}
          time={'Today'}
          displayNameColor="#53e773"
        >
          {<TextLink to={'devid958'}>{'Devid958'}</TextLink>}
          {' tipped you $15'}
        </NotificationItem>
      </StyleGuidePanel>
    </div>
  </StyleGuidePageContainer>
)
