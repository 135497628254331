import { PropsWithChildren } from 'react'
import useLanguage from '../translations/useLanguage'
import { Button } from './Button'
import { Edit } from './icons/Edit'
import { create, props } from '@stylexjs/stylex'
import { spacing } from '../styles/token.stylex'

const styles = create({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraSmall,
  },
  buttons: {
    display: 'flex',
    gap: spacing.small,
    alignSelf: 'flex-end',
  },
})

type EditFormProps = {
  canEdit?: boolean
  isEditing: boolean
  canSave: boolean
  isLoading?: boolean
  onEdit: React.MouseEventHandler<HTMLButtonElement>
  onCancel: React.MouseEventHandler<HTMLButtonElement>
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
}

export const EditForm: React.FC<PropsWithChildren<EditFormProps>> = ({
  canEdit,
  children,
  isEditing,
  canSave,
  isLoading = false,
  onSubmit,
  onEdit,
  onCancel,
}) => {
  const { t } = useLanguage()
  return (
    <form {...props(styles.form)} onSubmit={onSubmit}>
      {children}
      <div {...props(styles.buttons)}>
        {!isEditing && canEdit && (
          <Button onClick={onEdit}>
            <Edit height="1.3rem" width="1.3rem" />
            {t('shared.buttons.edit')}
          </Button>
        )}
        {isEditing && (
          <>
            <Button onClick={onCancel} disabled={isLoading}>
              {t('shared.buttons.cancel')}
            </Button>
            <Button
              variant="cta"
              type="submit"
              disabled={!canSave}
              isLoading={isLoading}
            >
              {t('shared.buttons.save')}
            </Button>
          </>
        )}
      </div>
    </form>
  )
}
