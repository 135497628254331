import { useEffect, useState } from 'react'
import { PanelItem, BannerSlider } from '../../../channel/banners/BannerSlider'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { create, props } from '@stylexjs/stylex'

const styles = create({
  container: {
    maxWidth: '888px',
    width: '100%',
    margin: '0 auto',
  },
})

const panels: PanelItem[] = [
  {
    id: '1',
    src: '../images/banners/1.png',
  },
  {
    id: '2',
    src: '../images/banners/2.png',
  },
  {
    id: '3',
    src: '../images/banners/3.png',
  },
  {
    id: '4',
    src: '../images/banners/2.png',
  },
  {
    id: '5',
    src: '../images/banners/3.png',
  },
  {
    id: '6',
    src: '../images/banners/2.png',
  },
  {
    id: '7',
    src: '../images/banners/3.png',
  },
  {
    id: '8',
    src: '../images/banners/2.png',
  },
  {
    id: '9',
    src: '../images/banners/3.png',
  },
]

export const Banners: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false)
    }, 10000)

    return () => clearTimeout(timeout)
  })

  return (
    <StyleGuidePageContainer title="Banners" variant="noPaddingOnMobile">
      <div>
        <StyleGuidePanel
          direction="column"
          title="Promo"
          variant="noPaddingOnMobile"
          border="noBorderOnMobile"
        >
          <div {...props(styles.container)}>
            <BannerSlider slides={panels} isLoading={isLoading} />
          </div>
        </StyleGuidePanel>
      </div>
    </StyleGuidePageContainer>
  )
}
