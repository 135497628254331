import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ChannelContextProvider } from './context/ChannelContextProvider'
import { SmartChannel } from './SmartChannel'
import { useEffect } from 'react'

export const ChannelPage: React.FC = () => {
  const { slug } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  if (!slug) throw new Error('Slug not found')

  useEffect(() => {
    const currentPath = location.pathname
    const lowercasePath = currentPath.toLowerCase()

    if (currentPath !== lowercasePath) {
      navigate(lowercasePath + location.search, { replace: true })
    }
  }, [location, navigate])

  return (
    <ChannelContextProvider slug={slug}>
      <SmartChannel />
    </ChannelContextProvider>
  )
}
