import React from 'react'
import { PanelItem } from './BannerSlider'
import { create, props } from '@stylexjs/stylex'

const MEDIA_MAX_HEIGHT = 210
const MEDIA_MIN_HEIGHT = 100
const largeScreen = '@media (min-width: 768px)'

const styles = create({
  image: {
    maxWidth: '100%',
    width: {
      default: '100%',
      [largeScreen]: 'auto',
    },
    objectFit: 'cover',
    aspectRatio: '16/9',
    maxHeight: MEDIA_MAX_HEIGHT,
    minHeight: MEDIA_MIN_HEIGHT,
  },
})

export const AffiliateImage: React.FC<PanelItem> = ({ src, linkUrl }) => {
  const image = src ? (
    <img {...props(styles.image)} src={src} alt={'Promo'} />
  ) : null

  return (
    <>
      {linkUrl && (
        <a target="_blank" href={linkUrl} rel="noreferrer">
          {image}
        </a>
      )}
      {!linkUrl && image}
    </>
  )
}
