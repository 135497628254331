/* eslint-disable react/display-name */
import { Coin } from '../../components/icons/Coin'
import { Edit } from '../../components/icons/Edit'
import { HelpAndSupport } from '../../components/icons/HelpAndSupport'
import { House } from '../../components/icons/House'
import { Logout } from '../../components/icons/Logout'
import { Star } from '../../components/icons/Star'
import { routes } from '../../router/routes'
import { HELPDESK_URL } from '../../constants/sites'
import { MenuItem } from './types'
import { FullCreator } from '../../components/types'
import { GetUserInfoResponse } from '../../client'
import { Tag } from '../../components/icons/Tag'
import { Receipt } from '../../components/icons/Receipt'
import { MediaIcon } from '../../components/icons/MediaIcon'
import { PlusSign } from '../../components/icons/PlusSign'
import { NotificationBell } from '../../components/icons/NotificationBell'
import { Bell } from '../../components/icons/Bell'
import { Bank } from '../../components/icons/Bank'
import { Stream } from '../../components/icons/Stream'

const creatorSpecificItems: MenuItem[] = [
  {
    icon: Tag,
    labelKey: 'userMenu.subscriptionPrice',
    route: routes.profileRoutes.subscriptionPrice,
  },
  {
    icon: Coin,
    labelKey: 'userMenu.statements',
    route: routes.profileRoutes.statements,
  },
  {
    icon: Stream,
    labelKey: 'userMenu.streaming',
    route: routes.profileRoutes.streaming,
  },
  {
    icon: Bank,
    labelKey: 'userMenu.bank',
    route: routes.profileRoutes.bank,
    hasSeparator: true,
  },
]

const loggedInFooterItems: MenuItem[] = [
  {
    icon: Logout,
    labelKey: 'userMenu.logout',
    route: routes.logout,
  },
]

const nonCreatorItem: MenuItem = {
  icon: Star,
  labelKey: 'userMenu.becomeACreator',
  route: routes.becomeACreator,
  hasSeparator: true,
}

const createAPostItem: MenuItem = {
  icon: PlusSign,
  labelKey: 'userMenu.createAPost',
  route: routes.post.create,
  isCreatorButton: true,
}

const basicLoggedInItems: MenuItem[] = [
  {
    icon: Edit,
    labelKey: 'userMenu.editProfile',
    route: routes.profileRoutes.myProfile,
  },
  {
    icon: MediaIcon,
    labelKey: 'userMenu.subscriptions',
    route: routes.profileRoutes.subscriptions,
    hasSeparator: true,
  },
]

const home: MenuItem = {
  icon: House,
  labelKey: 'userMenu.home',
  route: routes.home,
}

const basicLoggedOutItems: MenuItem[] = [home]

const getNotificationIcon = (notificationCount: number) => () =>
  notificationCount > 0 ? (
    <NotificationBell notificationCount={notificationCount} />
  ) : (
    <Bell />
  )

const getNotificationItem = (notificationCount: number): MenuItem => {
  return {
    icon: getNotificationIcon(notificationCount),
    labelKey: 'userMenu.notifications',
    route: routes.notifications,
    hasSeparator: true,
  }
}

const getHelpdeskItem = (languageCode: string): MenuItem => ({
  icon: HelpAndSupport,
  labelKey: 'userMenu.helpAndSupport',
  route: `${HELPDESK_URL}/${languageCode}`,
  isExternal: true,
})

const paymentHistoryItem: MenuItem = {
  icon: Receipt,
  labelKey: 'userMenu.paymentHistory',
  route: routes.profileRoutes.paymentHistory,
}

const getLoggedInItems = (
  isCreator: boolean,
  profileUrl: string,
  languageCode: string,
  notificationCount: number
) => {
  return [
    home,
    getNotificationItem(notificationCount),
    ...basicLoggedInItems,
    ...(isCreator ? creatorSpecificItems : [nonCreatorItem]),
    paymentHistoryItem,
    getHelpdeskItem(languageCode),
    ...(isCreator ? [createAPostItem] : []),
  ]
}

const getLoggedOutItems = (languageCode: string) => {
  const loggedOutItems: MenuItem[] = [
    ...basicLoggedOutItems,
    getHelpdeskItem(languageCode),
  ]

  return loggedOutItems
}

export const getMenuData = (
  data: GetUserInfoResponse | undefined,
  languageCode: string,
  notificationCount: number
): {
  menuItems: MenuItem[]
  footerItems?: MenuItem[]
  profile?: FullCreator
} => {
  if (!data) return { menuItems: getLoggedOutItems(languageCode) }

  const profileUrl = `/${data.streamerChannel.slug}`

  const profile: FullCreator = {
    slug: data.streamerChannel.slug,
    profilePictureUrl: data.profilePicture?.source,
    fullName: data.displayName,
    isVerified: data.streamerChannel.isVerified,
    profileUrl,
    isLive: data.streamerChannel.isLive,
  }

  return {
    menuItems: getLoggedInItems(
      data.isContentCreator,
      profileUrl,
      languageCode,
      notificationCount
    ),
    footerItems: loggedInFooterItems,
    profile,
  }
}
