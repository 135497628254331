/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BankInfoResponse,
  GetBankInfoResponse,
  UpdateBankInfoRequest,
} from '../models/index';
import {
    BankInfoResponseFromJSON,
    BankInfoResponseToJSON,
    GetBankInfoResponseFromJSON,
    GetBankInfoResponseToJSON,
    UpdateBankInfoRequestFromJSON,
    UpdateBankInfoRequestToJSON,
} from '../models/index';

export interface BankinfoPutRequest {
    updateBankInfoRequest?: UpdateBankInfoRequest;
}

/**
 * 
 */
export class BankInfoApi extends runtime.BaseAPI {

    /**
     * Retrieves the bank information of the logged in user. If the user hasn\'t set their bank information yet, it returns null.
     */
    async bankinfoGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBankInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/bankinfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBankInfoResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves the bank information of the logged in user. If the user hasn\'t set their bank information yet, it returns null.
     */
    async bankinfoGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBankInfoResponse> {
        const response = await this.bankinfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates or creates the banking information of the user.
     */
    async bankinfoPutRaw(requestParameters: BankinfoPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BankInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/bankinfo`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBankInfoRequestToJSON(requestParameters.updateBankInfoRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BankInfoResponseFromJSON(jsonValue));
    }

    /**
     * Updates or creates the banking information of the user.
     */
    async bankinfoPut(requestParameters: BankinfoPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BankInfoResponse> {
        const response = await this.bankinfoPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
