/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Details related to a live stream.
 * @export
 * @interface GetStreamInfoResponse
 */
export interface GetStreamInfoResponse {
    /**
     * Title of the stream.
     * @type {string}
     * @memberof GetStreamInfoResponse
     */
    title: string;
    /**
     * URL to playback the stream.
     * @type {string}
     * @memberof GetStreamInfoResponse
     */
    playbackUrl: string;
    /**
     * URL of the stream thumbnail.
     * @type {string}
     * @memberof GetStreamInfoResponse
     */
    thumbnailUrl: string;
    /**
     * Number of current viewers watching the stream.
     * @type {number}
     * @memberof GetStreamInfoResponse
     */
    viewerCount: number;
}

/**
 * Check if a given object implements the GetStreamInfoResponse interface.
 */
export function instanceOfGetStreamInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "playbackUrl" in value;
    isInstance = isInstance && "thumbnailUrl" in value;
    isInstance = isInstance && "viewerCount" in value;

    return isInstance;
}

export function GetStreamInfoResponseFromJSON(json: any): GetStreamInfoResponse {
    return GetStreamInfoResponseFromJSONTyped(json, false);
}

export function GetStreamInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStreamInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'playbackUrl': json['playbackUrl'],
        'thumbnailUrl': json['thumbnailUrl'],
        'viewerCount': json['viewerCount'],
    };
}

export function GetStreamInfoResponseToJSON(value?: GetStreamInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'playbackUrl': value.playbackUrl,
        'thumbnailUrl': value.thumbnailUrl,
        'viewerCount': value.viewerCount,
    };
}

