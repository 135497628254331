import { GetStreamFeedItemResponse, ContentType } from '../client'
import { LiveStreamCardProps, VodStreamCardProps } from '../stream/card/types'
import { routes } from '../router/routes'

type MappedStreamCardProps = LiveStreamCardProps | VodStreamCardProps

export const mapFeedItemToCardProps = (
  item: GetStreamFeedItemResponse
): MappedStreamCardProps | null => {
  if (item.type === ContentType.LiveStream && item.streamInfo) {
    return {
      type: 'liveStream',
      referenceId: item.referenceId,
      streamer: item.streamer,
      category: item.category,
      title: item.streamInfo.title,
      route: routes.channel.videos(item.streamer.channelSlug, item.referenceId),
      playbackUrl: item.streamInfo.playbackUrl,
      thumbnailUrl: item.streamInfo.thumbnailUrl,
      viewerCount: item.streamInfo.viewerCount,
    }
  }

  if (item.type === ContentType.Vod && item.videoInfo) {
    return {
      type: 'vod',
      referenceId: item.referenceId,
      streamer: item.streamer,
      category: item.category,
      title: item.videoInfo.title,
      route: routes.channel.videos(item.streamer.channelSlug, item.referenceId),
      playbackUrl: item.videoInfo.sourceUrl,
      thumbnailUrl: item.videoInfo.thumbnailUrl,
      duration: item.videoInfo.duration,
      finishedAt: item.videoInfo.finishedAt,
    }
  }

  return null
}
