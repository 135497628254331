/* eslint-disable react/jsx-no-literals */
import { useState } from 'react'
import { ErrorCode } from '../../../client'
import { useApi } from '../../../api/ApiContext'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { ReactSelect, Option } from '../../../components/input/Select'
import { Button } from '../../../components/Button'
import { useErrorNotification } from '../../../translations/useErrorNotification'
import { queryKeywords, queryKeys } from '../../../constants/query-keys'
import { create, props } from '@stylexjs/stylex'
import { spacing } from '../../../styles/token.stylex'
import { StyleGuidePanel } from '../StyleGuidePanel'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'

const errorCodes: Option[] = Object.keys(ErrorCode).map((key) => ({
  label: key.toUpperCase(),
  value: ErrorCode[key as keyof typeof ErrorCode],
}))

const styles = create({
  mainContainer: {
    //maxWidth: 'fit-content',
  },
  buttonContainer: { display: 'flex', gap: spacing.large },
})

export const Api = () => {
  const { errorApi } = useApi()
  const queryClient = useQueryClient()
  const [errorCode, setErrorCode] = useState<ErrorCode>()
  const [fetchEnabled, setFetchEnabled] = useState<string>()
  const notifyError = useErrorNotification()

  const getError = async () => {
    if (errorCode) {
      return await errorApi.errorsandboxSpecificErrorGet({ errorCode })
    }

    return null
  }

  const onErrorChange = (newValue: string) => {
    setFetchEnabled(undefined)
    setErrorCode(newValue as ErrorCode)
  }

  const onInvalidate = (queryKey: string) => {
    setFetchEnabled(queryKey)
    queryClient.invalidateQueries(queryKey)
  }

  useQuery({
    queryKey: queryKeys.apiSandbox.default(errorCode),
    queryFn: getError,
    enabled: fetchEnabled === queryKeywords.sandboxDefaultError,
  })

  useQuery({
    queryKey: queryKeys.apiSandbox.notifyCallbackError(errorCode),
    queryFn: getError,
    enabled: fetchEnabled === queryKeywords.sandboxNotifyCallbackError,
    onError: notifyError(() => {
      console.log('notify with callback')
      toast.success('Error while fetching error')
    }),
  })

  useQuery({
    queryKey: queryKeys.apiSandbox.customError(errorCode),
    queryFn: getError,
    enabled: fetchEnabled === queryKeywords.sandboxCustomError,
    onError: (error) => {
      console.error('Error while fetching error', error)
    },
  })

  return (
    <StyleGuidePageContainer title="API">
      <StyleGuidePanel width="fit">
        <ReactSelect
          id="default-select"
          label="Error Code"
          options={errorCodes}
          placeholder="Select error code.."
          value={errorCode}
          onChange={onErrorChange}
        />
        <div {...props(styles.buttonContainer)}>
          <Button
            onClick={() => onInvalidate(queryKeywords.sandboxDefaultError)}
          >
            Use default handling
          </Button>
          <Button
            onClick={() =>
              onInvalidate(queryKeywords.sandboxNotifyCallbackError)
            }
          >
            Use notify handling
          </Button>
          <Button
            onClick={() => onInvalidate(queryKeywords.sandboxCustomError)}
          >
            Use custom handling
          </Button>
        </div>
      </StyleGuidePanel>
    </StyleGuidePageContainer>
  )
}
