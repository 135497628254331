import { useAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import english from './en.json'
import japanese from './ja.json'
import Cookies from 'js-cookie'
import { formatDistanceToNow, isSameDay } from 'date-fns'
import { ja, enUS } from 'date-fns/locale'
import { SVGProps } from 'react'
import { UnitedStates } from '../components/icons/flags/UnitedStates'
import { Japan } from '../components/icons/flags/Japan'

type Paths<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${'' | `.${Paths<T[K]>}`}`
    }[keyof T]
  : never
export type LanguageKey = Paths<typeof english>

type Language = {
  code: 'en' | 'ja'
  cultureCode: string
  countryCode: string
  englishName: string
  name: string
  icon: React.FC<SVGProps<SVGElement>>
}

export type FormatCurrencyOptions = {
  currency?: string
  displayCurrency?: boolean
  locale?: string
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}

export const SUPPORTED_LANGUAGES: Language[] = [
  {
    code: 'en' as const,
    countryCode: 'us',
    cultureCode: 'en-US',
    englishName: 'English',
    name: 'English',
    icon: UnitedStates,
  },
  {
    code: 'ja' as const,
    countryCode: 'jp',
    cultureCode: 'ja',
    englishName: 'Japanese',
    name: '日本語',
    icon: Japan,
  },
]

const domain = '.' + document.location.hostname.replace(/:3000/, '')

const cookieStorage = createJSONStorage<Language['code']>(() => {
  return {
    getItem: (key) => Cookies.get(key) as Language['code'],
    setItem: (key, value) => {
      Cookies.set(key, value, {
        domain,
      })
    },
    removeItem: (key) =>
      Cookies.remove(key, {
        domain,
      }),
  }
})

const isDevelopment = window.location.hostname.includes('localhost')
const defaultLanguageCode = isDevelopment
  ? SUPPORTED_LANGUAGES[0].code
  : SUPPORTED_LANGUAGES[1].code

const languageAtom = atomWithStorage(
  'lang',
  defaultLanguageCode,
  cookieStorage,
  {
    getOnInit: true,
  }
)

export default function useLanguage() {
  const [languageCode, setLanguageCode] = useAtom(languageAtom)
  const translation = languageCode === 'en' ? english : japanese

  function t(key: LanguageKey, replacements?: Record<string, string>): string {
    let result = replaceObjectInString(
      getNestedTranslation(key, translation),
      replacements
    )

    if (!result) {
      console.error(`Translation not found for key '${key}'.`)
      result = `%%% ${key} %%%`
    }
    return result
  }

  function getNestedTranslation(
    key: LanguageKey,
    translation: typeof english
  ): string {
    if (!translation) return ''
    const keys = key.split('.')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return keys.reduce((obj: any, key: string) => obj?.[key], translation)
  }

  function formatCurrency(
    value: number,
    {
      currency = languageCode === 'ja' ? 'JPY' : 'USD',
      displayCurrency = true,
      locale = languageCode === 'ja' ? 'ja-JP' : 'en-US',
      minimumFractionDigits = 0,
      maximumFractionDigits = 2,
    }: FormatCurrencyOptions = {}
  ): string {
    const options: Intl.NumberFormatOptions = {
      style: displayCurrency ? 'currency' : 'decimal',
      currency: displayCurrency ? currency : undefined,
      roundingMode: 'trunc',
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    }

    return new Intl.NumberFormat(locale, options).format(value)
  }

  function replaceObjectInString(
    text: string,
    replacements?: Record<string, string>
  ) {
    if (!replacements) return text
    // eslint-disable-next-line sonarjs/slow-regex
    return text.replace(/{{([^}]+)}}/g, (match, p1) => {
      return replacements[p1]
    })
  }

  function formatDate(date: Date) {
    return new Intl.DateTimeFormat(languageCode, {
      month: 'short',
      day: 'numeric',
    }).format(date)
  }

  function formatFullDate(date: string | Date): string {
    const parsedDate = typeof date === 'string' ? new Date(date) : date
    return new Intl.DateTimeFormat(languageCode, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(parsedDate)
  }

  function formatRelativeDate(date: Date) {
    const locale = languageCode === 'ja' ? ja : enUS

    const relativeTime = formatDistanceToNow(new Date(date), {
      addSuffix: true,
      locale,
    })

    return relativeTime.replace(/^(about\s|less than\s)/, '')
  }

  function formatMessageDate(date: Date): string {
    const now = new Date()
    const language = languageCode === 'ja' ? 'ja-JP' : 'en-US'

    if (isSameDay(date, now)) {
      return new Intl.DateTimeFormat(language, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }).format(date)
    }

    const yesterday = new Date()
    yesterday.setDate(now.getDate() - 1)
    if (isSameDay(date, yesterday)) {
      return languageCode === 'ja' ? '昨日' : 'Yesterday'
    }

    return new Intl.DateTimeFormat(language, {
      month: 'short',
      day: 'numeric',
    }).format(date)
  }

  /**
   * Formats a given date as a relative time (e.g., "2 hours ago") if it is today.
   * Otherwise, it formats the date in a short, localized format (e.g., "Dec 17").
   *
   * @param {Date} date - The date to format.
   * @returns {string} A formatted string representing the date.
   *
   * @example
   * // Assuming today is June 10, 2024
   * formatDateRelativeToday(new Date('2024-06-10')) // "2 hours ago"
   * formatDateRelativeToday(new Date('2024-06-09')) // "Jun 9"
   */
  function formatDateRelativeToday(date: Date): string {
    if (isSameDay(date, new Date())) {
      return formatRelativeDate(date)
    }

    return formatDate(date)
  }

  function formatShortDateWithYear(date: Date) {
    return new Intl.DateTimeFormat(languageCode, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(date)
  }

  return {
    languageCode,
    setLanguageCode,
    t,
    formatDate,
    formatCurrency,
    formatRelativeDate,
    formatMessageDate,
    formatDateRelativeToday,
    formatFullDate,
    formatShortDateWithYear,
  }
}
