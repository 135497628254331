import { UnlockableItem } from '../../../post/restricted-media/UnlockableItem'
import StyleGuidePageContainer from '../../layout/StyleGuidePageContainer'
import { StyleGuidePanel } from '../StyleGuidePanel'
import { create, props } from '@stylexjs/stylex'

const styles = create({
  mobileContainer: {
    width: '6.8125rem',
    height: '7.375rem',
  },
  desktopContainer: {
    width: '20.9375rem',
    height: '14rem',
  },
  mediaContainer: {
    maxWidth: 'fit-content',
  },
})

export const Media: React.FC = () => (
  <StyleGuidePageContainer title="Unlockable media">
    <div {...props(styles.mediaContainer)}>
      <StyleGuidePanel direction="row" theme="dark">
        <div {...props(styles.mobileContainer)}>
          <UnlockableItem />
        </div>
        <div {...props(styles.desktopContainer)}>
          <UnlockableItem iconSize="large" />
        </div>
      </StyleGuidePanel>
    </div>
  </StyleGuidePageContainer>
)
