import { Outlet } from 'react-router-dom'
import { create, props } from '@stylexjs/stylex'
import { StyleGuideNavbar } from './StyleGuideNavbar'
import { StyleGuideMenu } from './StyleGuideMenu'
import { styleGuideMenuItems } from './menuItems'
import { elementSizes } from '../../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
  },
  sideColumn: {
    display: {
      default: 'none',
      [largeScreen]: 'block',
    },
    position: 'fixed',
    width: elementSizes.sidebarWidth,
    height: `calc(100dvh - ${elementSizes.headerHeight})`,
    top: elementSizes.headerHeight,
    zIndex: 1000,
  },
  main: {
    flex: 1,
    paddingLeft: {
      default: 0,
      [largeScreen]: elementSizes.sidebarWidth,
    },
    minHeight: `calc(100dvh - ${elementSizes.headerHeight})`,
  },
})

export const StyleGuideLayout: React.FC = () => {
  return (
    <>
      <StyleGuideNavbar />
      <div {...props(styles.container)}>
        <div {...props(styles.sideColumn)}>
          <StyleGuideMenu items={styleGuideMenuItems} variant="desktop" />
        </div>
        <main {...props(styles.main)}>
          <Outlet />
        </main>
      </div>
    </>
  )
}
