import { useQuery } from 'react-query'
import { create, props } from '@stylexjs/stylex'
import { useApi } from '../api/ApiContext'
import { queryKeys } from '../constants/query-keys'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { PromoPanelEditor } from './PromoPanelEditor'
import { spacing } from '../styles/token.stylex'
import { GetChannelInfoResponse } from '../client'
import EditButton from './EditButton'

const styles = create({
  promoPanels: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.small,
    justifyContent: 'center',
    paddingTop: spacing.large,
  },
  editor: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: spacing.normal,
    paddingTop: spacing.large,
  },
})

type SmartPromoPanelEditorProps = {
  channel: GetChannelInfoResponse
}

export const SmartPromoPanelEditor: React.FC<SmartPromoPanelEditorProps> = ({
  channel,
}) => {
  const { promoPanelApi } = useApi()

  const { data: promoPanels, isLoading } = useQuery({
    queryKey: queryKeys.promoPanel.default(channel.id),
    queryFn: () =>
      promoPanelApi.promopanelChannelIdPromoPanelsGet({
        channelId: channel.id,
      }),
  })

  if (isLoading) return <LoadingScreen />
  if (!promoPanels) throw new Error('Promo panels not found')

  return (
    <section>
      <EditButton channel={channel} />
      <div {...props(styles.editor)}>
        {promoPanels.map((panel) => (
          <PromoPanelEditor
            key={panel.id}
            type="edit"
            channelId={channel.id}
            panel={panel}
          />
        ))}
        <PromoPanelEditor
          type="new"
          overrideEditing={promoPanels.length === 0}
          panel={undefined}
          channelId={channel.id}
        />
      </div>
    </section>
  )
}
