import { routes } from '../../router/routes'
import { StyleGuideMenuItem } from './types'

export const styleGuideMenuItems: StyleGuideMenuItem[] = [
  { label: 'API', route: routes.styleGuideRoutes.api },
  { label: 'Banners', route: routes.styleGuideRoutes.banners },
  { label: 'Buttons', route: routes.styleGuideRoutes.buttons },
  { label: 'Colors', route: routes.styleGuideRoutes.colors },
  { label: 'Creator Cards', route: routes.styleGuideRoutes.creatorCards },
  { label: 'Dialogs', route: routes.styleGuideRoutes.dialogs },
  { label: 'Fonts', route: routes.styleGuideRoutes.fonts },
  { label: 'Icons', route: routes.styleGuideRoutes.icons },
  { label: 'Inputs', route: routes.styleGuideRoutes.inputs },
  { label: 'Layout', route: routes.styleGuideRoutes.layout },
  { label: 'Loading Indicators', route: routes.styleGuideRoutes.loader },
  {
    label: 'Mobile bars',
    route: routes.styleGuideRoutes.mobileNavAndTapbar,
  },
  { label: 'Menu', route: routes.styleGuideRoutes.menu },
  { label: 'Messages', route: routes.styleGuideRoutes.messages },
  { label: 'Navigation', route: routes.styleGuideRoutes.navigation },
  { label: 'Notifications', route: routes.styleGuideRoutes.notification },
  { label: 'Post Card', route: routes.styleGuideRoutes.postCard },
  { label: 'Stream Cards', route: routes.styleGuideRoutes.streamCards },
  { label: 'Text Links', route: routes.styleGuideRoutes.links },
  { label: 'Toast + tooltip', route: routes.styleGuideRoutes.toasts },
  { label: 'Unlockable media', route: routes.styleGuideRoutes.media },
]
